import * as axios from 'axios'
import { redirectLogin, redirectPermission, refreshToken } from '../../helper/helper'

const { REACT_APP_API_MANAGEMENT } = process.env

let token = localStorage.getItem('token')
const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const consentList = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createConsent = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent`
    const response = await axios.post(URL, obj, config)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data
      }
    }
  }
  return res
}

const deleteConsent = async id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/${id}`
    const response = await axios.delete(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const categoryList = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }

  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-category-list/${consent_id}`
    const response = await axios.get(URL, configHeader)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const cookieCategory = async id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-category/${id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createCookieCategory = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-category`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const deleteCookieCategory = async (consent_id, id) => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-category/${id}`
    const response = await axios.delete(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const updateCookieCategory = async (consent_id, obj, id) => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-category/${id}`
    const response = await axios.put(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getConfigBanner = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/config-banner/${consent_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const updateConfigBanner = async (obj, consent_id) => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/config-banner/${consent_id}`
    const response = await axios.put(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getCookieList = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-lists?consent_id=${obj.consent_id}&cookie_category_id=${obj.cookie_category_id}&page=${obj.page}&limit=${obj.limit}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const scanCookie = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/scan-cookie`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const deleteCookie = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/delete-cookie-id/${obj.cookie_id}/${obj.consent_id}`
    const response = await axios.delete(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createCookie = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/create-cookie`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const updateCookie = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/update-cookie`
    const response = await axios.patch(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const consentById = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/${consent_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const cookieDetail = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/cookie-id/${obj.cookie_id}/${obj.consent_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const mapCookieCategory = async (obj, cookie_category_id, consent_id) => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/map-category/${cookie_category_id}`
    const response = await axios.patch(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const acceptConsentList = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/accept-consent?${obj.params}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const consentExportCSV = (consent_id, start_date, end_date) => {
  let res = {}

  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin',
    responseType: 'arraybuffer'
  }
  return new Promise(function (resolve, reject) {
    let URL = `${REACT_APP_API_MANAGEMENT}/accept-consent/csv/${consent_id}?start_date=${start_date}&end_date=${end_date}`
    axios
      .get(URL, customConfigHeader)
      .then(response => {
        refreshToken(response.headers.access_token)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${consent_id}.csv`)
        document.body.appendChild(link)
        link.click()
        res = {
          status: 200,
          data: 'success'
        }
        resolve(res)
      })
      .catch(error => {
        if (error.response?.status === 401) {
          redirectLogin()
        } else if (error.response?.status === 403) {
          redirectPermission()
        } else {
          res = {
            status: error.response.status,
            data: 'error'
          }
        }
        resolve(res)
      })
  })
}

// const consentExportCSV = async (consent_id, start_date, end_date) => {
//   let res = {}
//   const configHeader = {
//     headers: {
//       'content-Type': 'application/json',
//       Accept: '/',
//       'Cache-Control': 'no-cache',
//       consent_id: consent_id,
//       Authorization: `Bearer ${token}`
//     },
//     credentials: 'same-origin'
//   }
//   try {
//     let URL = `${REACT_APP_API_MANAGEMENT}/accept-consent/csv/${consent_id}?start_date=${start_date}&end_date=${end_date}`
//     axios.get(URL, configHeader).then(response => {
//       res = {
//         status: 200,
//         data: 'success'
//       }
//       console.log('res : ', res)
//       refreshToken(response.headers.access_token)
//       const url = window.URL.createObjectURL(new Blob([response.data]))
//       const link = document.createElement('a')
//       link.href = url
//       link.setAttribute('download', `${consent_id}.csv`) //or any other extension
//       document.body.appendChild(link)
//       link.click()

//       return res
//     })
//   } catch (error) {
//     if (error.response?.status === 401) {
//       redirectLogin()
//     } else if (error.response?.status === 403) {
//       redirectPermission()
//     } else {
//       res = {
//         status: error.response?.status,
//         data: error.message
//       }
//     }
//   }
//   return res
// }

const publishConsent = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/public`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const publicConsentList = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent-public?consent_id=${consent_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const publicConsentListById = async (consent_id, publish_id) => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent-public/${publish_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const statusFile = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/check-file-consent/${consent_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const activityLog = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/activityLog/consent?text_search=${obj.text_search}&page=${obj.page}&limit=${obj.limit}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const permissionUser = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/permission-consent`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const inviteRoleUser = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/permission-consent/invite`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const resendInviteRoleUser = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/permission-consent/resend-confirm`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const deleteUserPermission = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/permission-consent/${obj.permission_id}`
    const response = await axios.delete(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const updateUserPermission = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/permission-consent/${obj.permission_id}`
    const response = await axios.patch(URL, { role: obj.role }, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const confirmInviteUser = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      // consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/permission-consent/confirm`
    const response = await axios.post(URL, obj)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const uploadLogo = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'multipart/form-data',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }

  const formData = new FormData()
  formData.append('logo', obj.logo)

  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/upload_logo`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}
const packageDetailAll = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/get-package-detail?payment_detail=${obj.payment_detail}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const packageDetail = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/get-package-detail?query_type=session`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}
const consentSetting = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/setting/${consent_id}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const updateConsent = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/change-package`
    const response = await axios.patch(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.code
      }
    }
  }
  return res
}
const updateConsentSetting = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/setting`
    const response = await axios.post(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}
const consentOwner = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent-owner`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const switchCookieCategory = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/enable-cookie-category/${obj.category_id}`
    const response = await axios.patch(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const stopScanCookie = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/stop-scan-cookie`
    const response = await axios.post(URL, {}, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data
      }
    }
  }
  return res
}
const updateConsentSuspend = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/consent/charge-package-suspend`
    const response = await axios.patch(URL, obj, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const periodUsage = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }

  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/package/period-usage`
    const response = await axios.get(URL, configHeader)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export {
  consentList,
  createConsent,
  deleteConsent,
  categoryList,
  cookieCategory,
  createCookieCategory,
  deleteCookieCategory,
  updateCookieCategory,
  getConfigBanner,
  updateConfigBanner,
  getCookieList,
  scanCookie,
  deleteCookie,
  createCookie,
  updateCookie,
  consentById,
  cookieDetail,
  mapCookieCategory,
  acceptConsentList,
  consentExportCSV,
  publishConsent,
  publicConsentList,
  publicConsentListById,
  statusFile,
  activityLog,
  permissionUser,
  inviteRoleUser,
  resendInviteRoleUser,
  deleteUserPermission,
  updateUserPermission,
  confirmInviteUser,
  uploadLogo,
  packageDetailAll,
  packageDetail,
  updateConsent,
  consentSetting,
  updateConsentSetting,
  consentOwner,
  switchCookieCategory,
  stopScanCookie,
  updateConsentSuspend,
  periodUsage
}
