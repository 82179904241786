import React, { Fragment, useState, useEffect, useRef } from 'react'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const NotfoundComponent = props => {
  const [lang, setLang] = useState(getCookie('lang'))

  return (
    <Fragment>
      <div className=" container">
        <div className="row justify-content-md-center">
          <div className="col-md-8" style={{ margin: '5% auto' }}>
            <div className="text-center">
              <div>
                <img
                  className="col-md-4"
                  src="/assets/images/logo-dark.svg"
                  alt="cookieplus"
                  width="100%"
                />
              </div>
              <div>
                <img
                  className="my-4"
                  src="/assets/images/404-icon.png"
                  alt="cookieplus"
                  width="100%"
                />
              </div>
              <div>
                <h1 className="text-uppercase">{i18n[lang].error}</h1>
                <h3>{i18n[lang].OpsCantFindPage}</h3>
                <a href="/domain" className="btn btn-primary px-5 text-16 mt-5" type="submit">
                  {i18n[lang].backToHome}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NotfoundComponent
