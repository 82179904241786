import styled from 'styled-components'

const TableBox = styled.div`
  div:first-child {
    white-space: unset;
    text-overflow: ellipsis;
    
  }
  // .ifOHjV{
  //   position: fixed;
  // }

  .sc-dmRaPn {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: auto;
    min-height: 0;
  }

`
const TooltipBox = styled.div`
  .tooltips {
    display: inline-block;
  }

  .tooltips .tooltiptexts {
    // visibility: hidden;
    display: none;
    width: 100%;
    top: 70%;
    left: 50%;
    margin-left: -60px;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 1;
  }

  .tooltips:hover .tooltiptexts {
    // visibility: visible;
    display: block;
  }

  .tooltiptexts {
    .box_tooltips {
      background: #000;
      position: static;
      width: 178px;
      border-radius: 5px;
    }
  }

  .float-left-mb0 {
    margin-bottom: 0;
    float: left;
  }

`

export { TableBox, TooltipBox }
