import React, { Fragment, useState, useEffect, useRef } from 'react'

import {
  userProfile,
  updateUserProfile,
  changePasswordWithToken,
  updatePrivacyProfile
} from '../../apis/authenication/api'
import { UserSettingBox } from './style'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env

const UserSettingComponent = props => {
  const [profile, setprofile] = useState(false)

  const [shownOldPassword, setShownOldPassword] = useState(false)
  const [shownCF, setShownCF] = useState(false)
  const [shownPass, setShownPass] = useState(false)

  const [invalidPassword, setInvalidPassword] = useState(false)

  const [checkedLetter, setCheckedLetter] = useState(false)
  const [checkedCapital, setCheckedCapital] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState(false)
  const [checkedSpecial, setCheckedSpecial] = useState(false)
  const [checkedLength, setCheckedLength] = useState(false)

  const [verifyCFPassword, setVerifyCFPassword] = useState(false)

  const [passwordInput, setPasswordInput] = useState('')
  const [CFPassword, setConfirmPassword] = useState('')
  const [checkContain, setCheckContain] = useState(false)

  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)

  const [isResetPasswordFail, setResetPasswordFail] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')

  const [checkedNews, setCheckedNews] = useState(false)
  const [checkedFeature, setCheckedFeature] = useState(false)
  const [checkedPromotion, setCheckedPromotion] = useState(false)

  const [oldPassword, setOldPassword] = useState('')

  const [isSaveUser, setIsSaveUser] = useState({
    show: false,
    status: true,
    msg: ''
  })
  const [lang, setLang] = useState(getCookie('lang'))

  const [isShowLoading, setIsShowLoading] = useState(false)

  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])
  const checkPassword = e => {
    const letter = document.getElementById('pass-lower')
    const capital = document.getElementById('pass-upper')
    const number = document.getElementById('pass-number')
    const special = document.getElementById('pass-special')
    const length = document.getElementById('pass-length')

    setPasswordInput(e.target.value)
    if (e.target.value) {
      setInvalidPassword(false)
    }
    const lowerCaseLetters = /[a-z]/g
    if (e.target.value.match(lowerCaseLetters)) {
      letter.classList.remove('invalid')
      letter.classList.add('valid')
      setCheckedLetter(true)
    } else {
      letter.classList.remove('valid')
      letter.classList.add('invalid')
      setCheckedLetter(false)
    }

    const upperCaseLetters = /[A-Z]/g
    if (e.target.value.match(upperCaseLetters)) {
      capital.classList.remove('invalid')
      capital.classList.add('valid')
      setCheckedCapital(true)
    } else {
      capital.classList.remove('valid')
      capital.classList.add('invalid')
      setCheckedCapital(false)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if (e.target.value.match(numbers)) {
      number.classList.remove('invalid')
      number.classList.add('valid')
      setCheckedNumber(true)
    } else {
      number.classList.remove('valid')
      number.classList.add('invalid')
      setCheckedNumber(false)
    }

    const specials = /[!@#$%^*_&=]/g
    if (e.target.value.match(specials)) {
      special.classList.remove('invalid')
      special.classList.add('valid')
      setCheckedSpecial(true)
    } else {
      special.classList.remove('valid')
      special.classList.add('invalid')
      setCheckedSpecial(false)
    }

    // Validate length
    if (e.target.value.length >= 8) {
      length.classList.remove('invalid')
      length.classList.add('valid')
      setCheckedLength(true)
    } else {
      length.classList.remove('valid')
      length.classList.add('invalid')
      setCheckedLength(false)
    }
  }
  useEffect(() => {
    document.title = 'ตั้งค่าผู้ใช้งาน | CookiePlus'
    getUserProfile()
  }, [])

  const updateUser = async () => {
    let obj = {
      firstname: firstName,
      lastname: lastName,
      phone_number: phoneNumber
    }
    const { data, status } = await updateUserProfile(obj)
    if (data && status === 200) {
      setIsSaveUser({
        show: true,
        status: true,
        msg: i18n[lang].profileUpdated
      })
      setIsShowLoading(false)
    } else {
      setIsSaveUser({
        show: true,
        status: false,
        msg: i18n[lang].profileUpdateFailed
      })
      setIsShowLoading(false)
    }
    setTimeout(() => {
      setIsSaveUser({
        show: false,
        status: true,
        msg: ''
      })
    }, 5000)
  }

  const updatePrivacyUser = async option => {
    let obj = {
      privacy: {
        accept_new: checkedNews,
        accept_feature: checkedFeature,
        accept_promotion: checkedPromotion
      }
    }

    const { data, status } = await updatePrivacyProfile(obj)
    if (data && status === 200) {
      setIsSaveUser({
        show: true,
        status: true,
        msg:
          option === 'Privacy'
            ? i18n[lang].privacyUpdated
            : i18n[lang].profileUpdated
      })
    } else {
      setIsSaveUser({
        show: true,
        status: false,
        msg:
          option === 'Privacy'
            ? i18n[lang].privacyUpdateFailed
            : i18n[lang].profileUpdateFailed
      })
    }
    setTimeout(() => {
      setIsSaveUser({
        show: false,
        status: true,
        msg: ''
      })
    }, 5000)
  }

  const getUserProfile = async () => {
    const { data, status } = await userProfile()
    if (data && data.data && status === 200) {
      setFirstName(data.data.firstname ? data.data.firstname : '')
      setLastName(data.data.lastname ? data.data.lastname : '')
      setPhoneNumber(data.data.phone_number ? data.data.phone_number : '')
      setEmail(data.data.email)
      setCheckedNews(data.data.privacy.accept_new)
      setCheckedFeature(data.data.privacy.accept_feature)
      setCheckedPromotion(data.data.privacy.accept_promotion)
    }
  }

  const checkedPrivacy = (e, option) => {
    if (option === 'news') {
      setCheckedNews(e.target.checked)
    } else if (option === 'feature') {
      setCheckedFeature(e.target.checked)
    } else {
      setCheckedPromotion(e.target.checked)
    }
  }

  const updatePassword = async () => {
    let obj = {
      old_password: oldPassword,
      new_password: passwordInput
    }

    if (passwordInput) {
      if (checkedLetter && checkedCapital && checkedNumber && checkedSpecial && checkedLength) {
        setInvalidPassword(false)
      } else {
        setInvalidPassword(true)
      }
    } else {
      setInvalidPassword(true)
    }
    if (passwordInput !== CFPassword) {
      setVerifyCFPassword(true)
    } else {
      if (!CFPassword) {
        setVerifyCFPassword(true)
      } else {
        setVerifyCFPassword(false)

        const { data, status } = await changePasswordWithToken(obj)
        if (data && status === 200) {
          setIsSaveUser({
            show: true,
            status: true,
            msg: i18n[lang].passwordChanged
          })
          setOldPassword('')
          setPasswordInput('')
          setConfirmPassword('')
          setIsShowLoading(false)
        } else {
          setIsSaveUser({
            show: true,
            status: false,
            msg: i18n[lang].passwordChangeFailed
          })
          setIsShowLoading(false)
        }
        setTimeout(() => {
          setIsSaveUser({
            show: false,
            status: true,
            msg: ''
          })
        }, 5000)
      }
    }
  }

  return (
    <Fragment>
      <div className="main-content">
        {isSaveUser.show && (
          <div
            className={`alert ${isSaveUser.status ? 'alert-success' : 'alert-danger'
              }  alert-dismissible alert-solid alert-label-icon fade show xs-alert-center`}
            role="alert"
          >
            {isSaveUser.status ? (
              <i className="ri-check-double-line label-icon"></i>
            ) : (
              <i className="ri-error-warning-line label-icon"></i>
            )}

            {isSaveUser.msg}
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={() => setIsSaveUser({ show: false, status: true, msg: '' })}
            ></button>
          </div>
        )}

        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body py-2">
                <div className="row">
                  <div className="col-auto">
                    <h6 className="card-title mb-0 py-2">{i18n[lang].userSettings} </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#personalDetails"
                      role="tab"
                      onClick={() => getUserProfile()}
                    >
                      {i18n[lang].profile}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                      {i18n[lang].changePassword}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => getUserProfile()}
                      data-bs-toggle="tab"
                      href="#privacy"
                      role="tab"
                    >
                      ความเป็นส่วนตัว
                    </a>
                  </li> */}
                </ul>
              </div>
              <UserSettingBox>
                <div className="card-body p-4">
                  <div className="tab-content">
                    <div className="tab-pane active" id="personalDetails" role="tabpanel">
                      <div className="card-table-fix overflow-hidden">
                        <form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="firstnameInput" className="form-label">
                                  {i18n[lang].name} <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={firstName}
                                  onChange={e => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="lastnameInput" className="form-label">
                                  {i18n[lang].surname} <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={lastName}
                                  onChange={e => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="numberInput" className="form-label">
                                  {i18n[lang].mobileNumber}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={phoneNumber}
                                  onChange={e => setPhoneNumber(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">
                                  {i18n[lang].email}
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          {/* <button
                          type="button"
                          className="btn btn-outline-primary waves-effect waves-light px-5"
                        >
                          ยกเลิก
                        </button> */}
                          <button className="btn btn-primary px-5 btn-load"
                            disabled={isShowLoading ? isShowLoading : firstName && lastName ? false : true}
                            onClick={() => (updateUser(), setIsShowLoading(true))}>
                            <span className="d-flex align-items-center">

                              {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                              </span> <span className="flex-grow-1 ms-2 mr-2">
                                  {i18n[lang].update}
                                </span> </Fragment> : i18n[lang].update
                              }

                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="changePassword" role="tabpanel">
                      <div className="card-table-fix overflow-hidden">
                        <form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <div>
                                  <label className="form-label text-16 ">
                                    {i18n[lang].changePassword} <span className="text-danger">*</span>
                                  </label>
                                  <div className="position-relative auth-pass-inputgroup mb-3">
                                    <input
                                      placeholder={i18n[lang].password}
                                      className={`form-control`}
                                      type={shownOldPassword ? 'text' : 'password'}
                                      value={oldPassword}
                                      onChange={e => setOldPassword(e.target.value)}
                                    />

                                    <button
                                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted show_password"
                                      type="button"
                                      id="password-addon"
                                      onClick={() => setShownOldPassword(!shownOldPassword)}
                                    >
                                      <span>
                                        {shownCF ? (
                                          <i className="ri-eye-fill align-middle"></i>
                                        ) : (
                                          <i className="ri-eye-fill align-middle"></i>
                                        )}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6"></div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <div>
                                  <label className="form-label text-16 ">
                                    {i18n[lang].newPassword} <span className="text-danger">*</span>
                                  </label>
                                  <div className={`position-relative auth-pass-inputgroup`}>
                                    <input
                                      placeholder={i18n[lang].password}
                                      onFocus={e => setCheckContain(true)}
                                      onBlur={e => setCheckContain(false)}
                                      onChange={e => checkPassword(e)}
                                      value={passwordInput}
                                      className={`form-control ${invalidPassword ? 'is-invalid' : ''
                                        }`}
                                      type={shownPass ? 'text' : 'password'}
                                    />

                                    <label className="invalid-feedback-err">
                                      {invalidPassword && i18n[lang].incorrectPassword}
                                    </label>
                                    <button
                                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted show_password"
                                      type="button"
                                      id="password-addon"
                                      onClick={() => setShownPass(!shownPass)}
                                    >
                                      <span>
                                        {shownPass ? (
                                          <i className="ri-eye-fill align-middle"></i>
                                        ) : (
                                          <i className="ri-eye-fill align-middle"></i>
                                        )}
                                      </span>
                                    </button>
                                    <div
                                      id="password-contain"
                                      className={`p-3 pt-0 bg-white mb-2 rounded ${checkContain ? 'd-block' : 'd-none'
                                        }`}
                                    >
                                      <h5 className="fs-14">{i18n[lang].passwordMustContain}</h5>
                                      <p id="pass-length" className="invalid mb-2">
                                        {i18n[lang].atLeast8Characters}
                                      </p>
                                      <p id="pass-lower" className="invalid mb-2">
                                        {i18n[lang].atLeastOneLowercase}
                                      </p>
                                      <p id="pass-upper" className="invalid mb-2">
                                        {i18n[lang].atLeastOneUppercase}
                                      </p>
                                      <p id="pass-number" className="invalid mb-2">
                                        {i18n[lang].atLeastOneNumber}
                                      </p>
                                      <p id="pass-special" className="invalid mb-0">
                                        {i18n[lang].atLeastOneSpecialChar}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <div>
                                  <label className="form-label text-16 ">
                                    {i18n[lang].confirmNewPassword} <span className="text-danger">*</span>
                                  </label>
                                  <div className="position-relative auth-pass-inputgroup mb-3">
                                    <input
                                      placeholder={i18n[lang].password}
                                      className={`form-control ${verifyCFPassword ? 'is-invalid' : ''
                                        }`}
                                      value={CFPassword}
                                      type={shownCF ? 'text' : 'password'}
                                      onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                    <label className="invalid-feedback-err">
                                      {verifyCFPassword && i18n[lang].passwordConfirmationIncorrect}
                                    </label>
                                    <button
                                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted show_password"
                                      type="button"
                                      id="password-addon"
                                      onClick={() => setShownCF(!shownCF)}
                                    >
                                      <span>
                                        {shownCF ? (
                                          <i className="ri-eye-fill align-middle"></i>
                                        ) : (
                                          <i className="ri-eye-fill align-middle"></i>
                                        )}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          {/* <button
                          type="button"
                          className="btn btn-outline-primary waves-effect waves-light px-5"
                        >
                          ยกเลิก
                        </button> */}
                          <button
                            type="submit"
                            className="btn btn-primary px-5 btn-load"
                            disabled={isShowLoading ? isShowLoading : oldPassword && passwordInput && CFPassword ? false : true}
                            onClick={() => (updatePassword(), setIsShowLoading(true))}
                          >

                            <span className="d-flex align-items-center">

                              {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                              </span> <span className="flex-grow-1 ms-2 mr-2">
                                  {i18n[lang].changePassword}
                                </span> </Fragment> : i18n[lang].changePassword
                              }

                            </span>

                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <div className="tab-pane" id="privacy" role="tabpanel">
                      <p className="fs-16 font-weight-bold">รับข้อมูลข่าวสารและการอัพเดต</p>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck1"
                          onChange={e => checkedPrivacy(e, 'news')}
                          checked={checkedNews}
                        />
                        <label className="form-check-label" htmlFor="formCheck1">
                          <p className="fs-14 text-Secondary m-0">
                            ฉันต้องการรับข้อมูลข่าวสารจากทาง CookiePlus
                          </p>
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck2"
                          onChange={e => checkedPrivacy(e, 'feature')}
                          checked={checkedFeature}
                        />
                        <label className="form-check-label" htmlFor="formCheck2">
                          <p className="fs-14 text-Secondary m-0">
                            ฉันต้องการรับข้อมูลเกี่ยวกับการอัพเดตฟีเจอร์ใหม่ๆ จากทาง CookiePlus
                          </p>
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck3"
                          onChange={e => checkedPrivacy(e, 'promotion')}
                          checked={checkedPromotion}
                        />
                        <label className="form-check-label" htmlFor="formCheck3">
                          <p className="fs-14 text-Secondary">
                            ฉันต้องการรับข้อมูลโปรโมชั่น หรือข้อเสนอต่างๆ จากทาง CookiePlus
                          </p>
                        </label>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
               
                          <button
                            type="submit"
                            className="btn btn-primary px-5"
                            
                            onClick={() => updatePrivacyUser('Privacy')}
                          >
                            อัปเดต
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </UserSettingBox>


            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default UserSettingComponent
