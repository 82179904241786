import styled from 'styled-components'

const RegisterBox = styled.div`
@media (max-width: 821px){
  
    .container {
        width: 100% !important;
        max-width: 459px; 
    }
    p , a {
        font-size: small;
    }
    .xx-sm {
        font-size: 8px;
    }
    .form-check , a.fw-semibold {
        font-size: 12px;
    }
    .big-subtitle{
        font-size: 24px;
    }
    .text-email{
        font-size: 16px;
    }
    .form-check {
        font-size: 14px !important;
    }
    .footer-page {
        bottom: 25px;
        padding: 20px calc(1.5rem / 2);
        position: absolute;
        right: 0;
        color: var(--css-Light);
        left: 250px;
        height: 40px;
        background-color: transparent;
    }
    .d-inline{
        display: block !important;  
     }

  }
`
export { RegisterBox }
