import styled from 'styled-components'

const TableBox = styled.div`
  div:first-child {
    white-space: unset;
    text-overflow: ellipsis;
  }
`

const ContentBox = styled.div`
  .content-scroll {
    height: 70vh;
    overflow-y: auto;
  }

  .table > thead {
    position: sticky;
    top: 0;
  }

  .page-content {
    padding-bottom: 25px;
  }
`

const FooterBox = styled.div`
  .footer {
    bottom: 0;
    padding: 10px calc(1.5rem / 2);
    position: fixed;
    right: 0;
    color: var(--css-Secondary);
    left: 250px;
    height: 30px;
    background-color: #dbe1e5;
    z-index: 9;
  }
`

const TooltipBox = styled.div`
  .tooltips {
    display: inline-block;
  }

  .tooltips .tooltiptexts {
    display: none;
    width: 100%;
    top: 70%;
    margin-left: -65px;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 20;
  }


  .tooltips:hover .tooltiptexts {
    // visibility: visible;
    display: block;
  }
  .tooltiptexts {
    .box_tooltips {
      background: #000;
      position: static;
      width: 200px;
      border-radius: 5px;    
    }
  }

  .float-left-mb0 {
    margin-bottom: 0;
    float: left;
  }
`

export { TableBox, TooltipBox, FooterBox, ContentBox }
