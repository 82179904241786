import React, { Fragment, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { useHistory, useLocation } from 'react-router-dom'
import { consentList, createConsent, deleteConsent } from '../../apis/management/api'
import List from 'list.js'
import Modal from 'react-modal'
import { TableBox, TooltipBox } from './style'
import { enumCode } from '../../helper/enum'
import Tooltip from '@mui/material/Tooltip'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getCookie } from '../../helper/helper'
import { i18n } from '../../helper/i18n'
const { REACT_APP_PREDICTIVE } = process.env
const DomainComponent = props => {
  const history = useHistory()
  const [listDomain, setListDomain] = useState()
  const [domainName, setDomainName] = useState()
  const [deleteId, setDeleteId] = useState()
  const [confirmDelete, setConfirmDelete] = useState()
  const [openPopupCeate, setOpenPopupCeate] = useState(false)
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)

  const [inValidateDomainName, setInValidateDomainName] = useState(false)

  const [skeletonBox, setSkeletonBox] = useState()
  const [zeroStageBox, setZeroStageBox] = useState()

  const [sessionQuota, setSessionQuota] = useState('10000')
  const [sessionUsed, setSessionUsed] = useState('7500')
  const [lang, setLang] = useState(getCookie('lang'))

  const [openPopupCustomerCheck, setOpenPopupCustomerCheck] = useState(false)
  const [openPopupAccountOwnerCheck, setOpenPopupAccountOwnerCheck] = useState(false)
  const [openPopupPackageCheck, setOpenPopupPackageCheck] = useState(false)

  const [activeDomain, setActiveDomain] = useState()
  const [activeDomainID, setActiveDomainID] = useState()

  const [errorMsg, setErrorMsg] = useState()
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }

  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      }
    }
  }

  const columns = [
    {
      name: `${i18n[lang].domain}`,
      selector: row => row.domain,
      width: '250px',
      sortable: true
    },
    {
      name: '',
      selector: row => row.package,
      width: ''
    },
    // {
    //   name: 'Sessions',
    //   selector: row => row.sessions,
    //   width: ''
    // },
    {
      name: `${i18n[lang].numberOfCookies}`,
      selector: row => row.cookie,
      sortable: true
    },
    // {
    //   name: "Pageviews",
    //   selector: (row) => row.pageview,
    // },
    {
      name: `${i18n[lang].status}`,
      selector: row => row.status
    },
    {
      name: `${i18n[lang].bannerVersion}`,
      selector: row => row.version
    },
    {
      name: `${i18n[lang].publicationDate}`,
      selector: row => row.updated_at
    },
    {
      name: '',
      selector: row => row.options,
      width: '60px'
    }
  ]

  useEffect(() => {
    document.title = 'รายชื่อโดเมน | CookiePlus'
    setSkeletonBox(true)
    if (!listDomain) {
      getConsentList()
      setSkeletonBox(false)
      setZeroStageBox(true)
    } else {
      setZeroStageBox(false)
    }
  }, [])

  useEffect(() => {
    setLang(props.langFn)
    if (listDomain) {
      getConsentList()
    }
  }, [props.langFn])

  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }

  const getConsentList = async () => {
    const { data, status } = await consentList()
    let res = []
    if (data && data.data && data.data.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.length; i++) {
        _tempData = {
          domain: (
            <a
              className="btn-link"
              onClick={() => (handelClickDomain(data.data[i]), setActiveDomain(data.data[i].domain), setActiveDomainID(data.data[i].id))
              }
            >
              {data.data[i].domain}
              {/* <span className=' text-danger'>{data.data[i].package_transactions[0].type} {data.data[i].permission_consents[0].role}</span> */}
            </a>
          ),
          package: data.data[i]?.package_transactions[0]?.is_limit_exceed ? (
            <span className="badge px-3 badge-Limit">Limit Exceeded</span>
          ) : data.data[i]?.package_transactions[0]?.package_expired ? (
            <span className="badge px-3 badge-Suspended">Suspended</span>
          ) : (
            <span
              className={`badge px-3 badge-${data.data[i].status === 'pending'
                ? data.data[i].package_transactions.length &&
                  data.data[i].package_transactions[0].package.package_name === 'Ultimate Plan'
                  ? 'Pending-Ultimate'
                  : data.data[i].package_transactions.length &&
                    data.data[i].package_transactions[0].package.package_name === 'Pro Plan'
                    ? 'Pending-Pro'
                    : data.data[i].package_transactions.length &&
                      data.data[i].package_transactions[0].package.package_name === 'Basic Plan'
                      ? 'Pending-Basic'
                      : ''
                : data.data[i].package_transactions.length &&
                  data.data[i].package_transactions[0].package.package_name === 'Ultimate Plan'
                  ? 'Ultimate'
                  : data.data[i].package_transactions.length &&
                    data.data[i].package_transactions[0].package.package_name ===
                    'Complimentary Plan'
                    ? 'Complimentary'
                    : data.data[i].package_transactions.length &&
                      data.data[i].package_transactions[0].package.package_name === 'Free Plan'
                      ? 'Free'
                      : data.data[i].package_transactions.length &&
                        data.data[i].package_transactions[0].package.package_name === 'Basic Plan'
                        ? 'Basic'
                        : data.data[i].package_transactions.length &&
                          data.data[i].package_transactions[0].package.package_name === 'Suspended Plan'
                          ? 'Suspended'
                          : data.data[i].package_transactions.length &&
                            data.data[i].package_transactions[0].package.package_name === 'Pro Plan'
                            ? 'Pro'
                            : data.data[i].package_transactions.length &&
                              data.data[i].package_transactions[0].package.package_name === 'Enterprise Plan'
                              ? 'Enterprise'
                              : ''
                } `}
            >
              {data.data[i].status === 'pending'
                ? data.data[i].package_transactions.length &&
                  data.data[i].package_transactions[0].package.package_name === 'Ultimate Plan'
                  ? 'Pending (Ultimate)'
                  : data.data[i].package_transactions.length &&
                    data.data[i].package_transactions[0].package.package_name === 'Pro Plan'
                    ? 'Pending (Pro)'
                    : data.data[i].package_transactions.length &&
                      data.data[i].package_transactions[0].package.package_name === 'Basic Plan'
                      ? 'Pending (Basic)'
                      : ''
                : data.data[i].package_transactions.length &&
                  data.data[i].package_transactions[0].package.package_name === 'Ultimate Plan'
                  ? 'Ultimate'
                  : data.data[i].package_transactions.length &&
                    data.data[i].package_transactions[0].package.package_name === 'Complimentary Plan'
                    ? 'Complimentary'
                    : data.data[i].package_transactions.length &&
                      data.data[i].package_transactions[0].package.package_name === 'Free Plan'
                      ? 'Free'
                      : data.data[i].package_transactions.length &&
                        data.data[i].package_transactions[0].package.package_name === 'Basic Plan'
                        ? 'Basic'
                        : data.data[i].package_transactions.length &&
                          data.data[i].package_transactions[0].package.package_name === 'Suspended Plan'
                          ? 'Suspended'
                          : data.data[i].package_transactions.length &&
                            data.data[i].package_transactions[0].package.package_name === 'Pro Plan'
                            ? 'Pro'
                            : data.data[i].package_transactions.length &&
                              data.data[i].package_transactions[0].package.package_name === 'Enterprise Plan'
                              ? 'Enterprise'
                              : ''}
            </span>
          ),
          // sessions: <span> {sessionUsed} / {sessionQuota} </span>,
          cookie: data.data[i].cookie_count, //data.data[i].cookie,
          // pageview: 0, //data.data[i].pageviews[0].pageview,
          status: (
            <TooltipBox>
              <p className="float-left-mb0">
                {data.data[i].published ? (
                  <Fragment>
                    <span className={`badge badge-soft-success `}>
                      {i18n[props.langFn].published}
                    </span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <span className={`badge badge-soft-dark `}>
                      {i18n[props.langFn].notYetPublished}
                    </span>
                  </Fragment>
                )}
              </p>
              <div className="tooltips">
                {data.data[i].published ? (
                  <Fragment>
                    {data.data[i].status !== 'publish' && (
                      <Fragment>
                        <Tooltip
                          title={
                            <span className="tooltiptexts">
                              <div className="text-left px-3 box_tooltips">
                                <p className="py-2 my-2">
                                  {i18n[props.langFn].updatesNotPublished}{' '}
                                </p>
                              </div>
                            </span>
                          }
                        >
                          <i
                            onClick={() => history.push(`/publishcookie/${data.data[i].id}`)}
                            className={`mdi mdi-information label-icon fs-16 mx-2 link-edit`}
                          ></i>
                        </Tooltip>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  ''
                )}
              </div>
            </TooltipBox>
          ),
          version: <Fragment>{data.data[i].published ? data.data[i].version : ' - '} </Fragment>,
          updated_at: (
            <Fragment>
              {data.data[i].published ? convertDate(data.data[i].updated_at) : ' - '}{' '}
            </Fragment>
          ),
          options:
            data.data[i]?.package_transactions[0]?.package?.package_name !== 'Free Plan'
              ? ''
              : data.data[i]?.permission_consents[0]?.role === 'owner' && (
                <span className="link-trash" onClick={() => popupDelete(data.data[i].id)}>
                  <i className="mdi mdi-trash-can"></i>
                </span>
              )
        }
        res.push(_tempData)
      }

      setListDomain(res)
      setZeroStageBox(false)
    } else {
      setListDomain([])
      setZeroStageBox(true)
    }
  }

  const createDomain = async e => {
    e.preventDefault()
    let regx = /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/ //[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
    if (!domainName) {
      setInValidateDomainName(true)
      setErrorMsg(i18n[lang].urlFormatError)
    } else {
      if (domainName.match(regx)) {
        setInValidateDomainName(false)
        let obj = {
          consent_domain: domainName,
          package_id: 1
        }
        const { data, status } = await createConsent(obj)
        if (status === 200) {
          setOpenPopupCeate(false)
          await getConsentList()
        } else {
          setErrorMsg(enumCode['th'][data.code])
          setInValidateDomainName(true)
        }
      } else {
        setInValidateDomainName(true)
        setErrorMsg(i18n[lang].urlFormatError)
      }
    }
  }

  const popupDelete = id => {
    setOpenPopupDelete(true)
    setDeleteId(id)
  }
  const handelClickDomain = data => {
    if (data.status !== 'pending') {
      if (!data.package_transactions[0]?.package_expired) {
        history.push(`/dashboard/${data.id}`)
      } else {
        if (data.package_transactions[0].type === 'invoice') {
          setOpenPopupCustomerCheck(true)
        } else {
          if (data.permission_consents[0].role === 'owner') {

            setOpenPopupPackageCheck(true)

          } else {
            setOpenPopupAccountOwnerCheck(true)
          }

        }

      }
    }
  }

  const removeDomain = async () => {
    const { data, status } = await deleteConsent(deleteId)
    if (data && status === 200) {
      setOpenPopupDelete(false)
      await getConsentList()
      setIsConfirmDelete(false)
    } else {
      await getConsentList()
    }
  }

  const openModalCreate = () => {
    setDomainName('')
    setOpenPopupCeate(true)
    setInValidateDomainName(false)
  }

  const SkeletonPost = () => {
    return (
      <Fragment>
        <div className="table-responsive mb-1 card-max-fix">
          <table className="table table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <td style={{ width: '250px' }}>{i18n[lang].domain}</td>
                <td>{i18n[lang].numberOfCookies}</td>
                <td>{i18n[lang].status}</td>
                <td>{i18n[lang].bannerVersion}</td>
                <td>{i18n[lang].publicationDate}</td>
                <td style={{ width: '80px' }}></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    )
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header py-2">
              <button
                // data-bs-toggle="modal"
                // data-bs-target="#zoomInModal"
                onClick={() => history.push('/adddomain')}
                className="btn btn-primary btn-label  float-end"
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-plus-circle-outline label-icon align-middle fs-16 me-2"></i>
                  </div>
                  <div className="flex-grow-1">{i18n[lang].addDomain}</div>
                </div>
              </button>

              <h6 className="card-title mb-0 py-2">{i18n[lang].manageDomain}</h6>
            </div>
            <div className="card-body card-table-fix p-0">
              <div id="customerList">
                <div className="table-responsive mb-1">
                  <TableBox>
                    {listDomain ? (
                      listDomain.length > 0 ? (
                        <DataTable
                          pagination={true}
                          className="-fadeIn mt-0 none-overflow"
                          center={true}
                          columns={columns}
                          data={listDomain}
                          customStyles={tableStyles}
                        />
                      ) : (
                        <Fragment>
                          <div className="zero-center">
                            <div className="zero-content">
                              <img
                                src="../assets/images/Zero-domain.png"
                                alt=""
                                className="img-fluid"
                              />
                              <p className="text-Secondary mt-2">
                                {i18n[lang].addDomainButton}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      )
                    ) : (
                      <SkeletonPost />
                    )}
                  </TableBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupCeate}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupCeate(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="zoomInModalLabel">
                {i18n[lang].addDomain}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenPopupCeate(false)}
              ></button>
            </div>
            <form onSubmit={e => createDomain(e)}>
              <div className="modal-body">
                <label htmlFor="basiInput" className="form-label">
                  {i18n[lang].domainName}
                </label>
                <input
                  placeholder="http://www.example.com"
                  type="text"
                  className={`form-control ${inValidateDomainName ? 'is-invalid' : ''}`}
                  onChange={e => setDomainName(e.target.value)}
                  value={domainName}
                  autoFocus
                />
                {inValidateDomainName && <span className="invalid-feedback-err">{errorMsg}</span>}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect waves-light"
                  // data-bs-dismiss="modal"
                  onClick={() => setOpenPopupCeate(false)}
                >
                  {i18n[lang].cancel}
                </button>
                <button type="submit" className="btn btn-primary ">
                  {i18n[lang].save}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupDelete}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupDelete(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                delay="1000"
                colors="primary:#ed3451,secondary:#c72e46"
                style={{ width: '80px', height: '90px' }}
              ></lord-icon>
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].deleteDomainPrompt}</strong>
                </label>
                <br />
                <span>
                  {i18n[lang].deleteDomainWarning}
                  <strong> ' Delete '</strong> {i18n[lang].deleteDomainWarningInput}
                </span>
                <input
                  type="text"
                  className={`form-control mt-2 text-center`}
                  onChange={e =>
                    e.target.value === 'Delete'
                      ? setIsConfirmDelete(true)
                      : setIsConfirmDelete(false)
                  }
                  value={confirmDelete}
                />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() => removeDomain()}
                type="button"
                className={`btn w-lg ${isConfirmDelete
                  ? 'btn-outline-danger waves-effect waves-light'
                  : 'btn-outline-danger waves-effect waves-light'
                  }  `}
                disabled={isConfirmDelete ? false : true}
              >
                {i18n[lang].confirmDomainDeletion}
              </button>
              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"
                // data-bs-dismiss="modal"
                onClick={() => setOpenPopupDelete(false)}
              >
                {i18n[lang].cancel}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupCustomerCheck}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupCustomerCheck(false)}
      >
        {/* popup type invoice */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src="/assets/images/warning_red.png" alt="" height="70" />
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].domainSuspended}</strong>
                </label>
                <br />
                <p>
                  {i18n[lang].domainCurrentlySuspendedDomain} <b>{activeDomain}</b> {i18n[lang].domainCurrentlySuspended}
                </p>
                <p className='mt-2'>
                  {i18n[lang].packageExpired}<br />
                  {i18n[lang].packageExpiredContact}<br />
                  {i18n[lang].packageExpiredUpgrade}
                </p>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() => setOpenPopupCustomerCheck(false)}
                type="button"
                className={`btn w-lg btn-outline-danger waves-effect waves-light `}

              >
                {i18n[lang].close}
              </button>

              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"

                onClick={() => (window.open('https://meetings.hubspot.com/hataipatt/th-cookieplus-support', '_blank', 'noopener,noreferrer'), setOpenPopupCustomerCheck(false))}
              >
                {i18n[lang].contactCookiePlusTeam}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupAccountOwnerCheck}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupAccountOwnerCheck(false)}
      >
        {/* popup type not invoice not Owner */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src="/assets/images/warning_red.png" alt="" height="70" />


              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].domainSuspended}</strong>
                </label>
                <br />

                <p>
                  {i18n[lang].domainCurrentlySuspendedDomain} <b>{activeDomain}</b> {i18n[lang].domainCurrentlySuspended}
                </p>

                <p className='mt-2'>
                  {i18n[lang].packageExpired}<br />
                  {i18n[lang].packageExpiredAccountOwnerPlease}<b>{i18n[lang].packageExpiredAccountOwnerAccount}</b>{i18n[lang].packageExpiredAccountOwnerUpgrade}<br />
                  {i18n[lang].packageExpiredUpgrade}
                </p>

              </div>
            </div>
            <div className="modal-footer justify-content-center">


              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"
                // data-bs-dismiss="modal"
                onClick={() => setOpenPopupAccountOwnerCheck(false)}
              >
                {i18n[lang].close}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupPackageCheck}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupPackageCheck(false)}
      >
        {/* popup type not invoice and owner */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src="/assets/images/warning_red.png" alt="" height="70" />


              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].domainSuspended}</strong>
                </label>
                <br />

                <p>
                  {i18n[lang].domainCurrentlySuspendedDomain} <b>{activeDomain}</b> {i18n[lang].domainCurrentlySuspended}
                </p>

                <p className='mt-2'>
                  {i18n[lang].packageExpired}<br />
                  {i18n[lang].renewOrUpgradePackage}<br />
                  {/* {i18n[lang].packageExpiredUpgrade} */}
                </p>

              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() => setOpenPopupPackageCheck()}
                type="button"
                className={`btn w-lg btn-outline-danger waves-effect waves-light `}

              >
                {i18n[lang].close}
              </button>
              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"
                // data-bs-dismiss="modal"
                onClick={() => (history.push(`/package?activeID=${activeDomainID}&status=suspended`), setOpenPopupPackageCheck(false))}
              >
                {i18n[lang].renewPackage}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-Gray text-8">
              Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
            </div>
            <div className="col-sm-6 text-Gray text-8">
              <div className="text-sm-end d-none d-sm-block">
                <a
                  className="text-Gray"
                  href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                  target="_blank"
                >
                  Privacy Policy.
                </a>{' '}
                <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default DomainComponent
