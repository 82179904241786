import React, { Fragment, useState, useEffect, useRef } from 'react'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import 'flatpickr/dist/themes/material_blue.css'
import { numberWithCommas } from '../../helper/helper'
import LoadingComponent from '../layout/loading'
import Flatpickr from 'react-flatpickr'
import {
  summaryConsentCategory,
  summaryConsent,
  summaryPage,
  summaryConsentGraph,
  summaryDevices
} from '../../apis/dashboard/api'
import * as echarts from 'echarts'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env
const DashboardComponent = props => {
  const id = props.match.params.id

  let [filterActive, setFilterActive] = useState('seven')
  let [chartConsentCategory, setChartConsentCategory] = useState()
  let [acceptSummary, setAcceptSummary] = useState()
  let [numberPageList, setNumberPageList] = useState()
  let [numberDevicesList, setNumberDevicesList] = useState()
  const [startDateSelected, setStartDateSelected] = useState('')
  const [endDateSelected, setEndDateSelected] = useState('')

  const [loadingPage, setLoadingPage] = useState(true)
  const [loadingSummaryConsent, setLoadingSummaryConsent] = useState(true)
  const [loadingSummaryPage, setLoadingSummaryPage] = useState(true)
  const [loadingSummaryDevices, setLoadingSummaryDevices] = useState(true)
  const [loadingConsent, setLoadingConsent] = useState(true)
  const [loadingOverview, setLoadingOverview] = useState(true)
  const fp = useRef(null)
  const [rangeDate, setRangeDate] = useState()
  let [maxDate, setMaxDate] = useState()

  const [allColorCategory, setAllColorCategory] = useState([
    '#4EDAFF',
    '#299CDB',
    '#B5ECFF',
    '#1B4098',
    '#299CDB',
    '#4EDAFF',
    '#B5ECFF',
    '#062264',
    '#0068A1',
    '#17ADD5',
    '#97E9FF',
    '#001549',
    '#4EDAFF',
    '#299CDB',
    '#B5ECFF',
    '#1B4098',
    '#299CDB',
    '#4EDAFF',
    '#B5ECFF',
    '#062264',
    '#0068A1',
    '#17ADD5',
    '#97E9FF',
    '#001549'
  ])

  const [allColor, setAllColor] = useState([
    '#1B4098',
    '#4EDAFF',
    '#B5ECFF',
    '#299CDB',
    '#1891B2',
    '#35BAE9',
    '#6A30B5',
    '#9B55E5',
    '#CE2469',
    '#F9327A',
    '#F97F06',
    '#FCC103',
    '#1AA086',
    '#42DABF'
  ])

  const [lang, setLang] = useState(getCookie('lang'))

  useEffect(() => {
    setLang(props.langFn)
    if (startDateSelected && endDateSelected) {
      getSummaryConsentGraph(startDateSelected, endDateSelected)
      getConsentCategoryChart(startDateSelected, endDateSelected)
      getChartConsent()
    }
  }, [props.langFn])

  useEffect(() => {
    const { startDate, endDate } = customDate(6)
    setStartDateSelected(startDate)
    setEndDateSelected(endDate)
    if (startDate) {
      getConsentCategoryChart(startDate, endDate)
      getSummaryConsentDetail(startDate, endDate)
      getSummaryConsentGraph(startDate, endDate)
      getSummaryPage(startDate, endDate)
      getSummaryDevice(startDate, endDate)
    }
  }, [])

  useEffect(() => {
    document.title = 'แดซบอร์ด | CookiePlus'
    let today = new Date()
    setMaxDate(today.setDate(today.getDate()))
    // getChartProportionConsent()
    getChartConsent()
    setTimeout(() => {
      setLoadingPage(false)
    }, 2000)
  }, [acceptSummary])

  const getDataWithPreviosMonth = () => {
    setFilterActive('premonth')
    fp.current.flatpickr.clear()
    let date = new Date()
    let startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    let endDate = new Date(date.getFullYear(), date.getMonth(), 0)
    startDate =
      startDate.toLocaleString('sv-SE', { timeZone: 'UTC' }).split(' ')[0] + 'T17:00:00.000Z'
    endDate = endDate.toLocaleString('sv-SE').split(' ')[0] + 'T16:59:59.000Z'

    setStartDateSelected(startDate)
    setEndDateSelected(endDate)

    getConsentCategoryChart(startDate, endDate)
    getSummaryConsentDetail(startDate, endDate)
    getSummaryConsentGraph(startDate, endDate)
    getSummaryPage(startDate, endDate)
    getSummaryDevice(startDate, endDate)
  }

  const getDataToday = () => {
    setFilterActive('today')
    fp.current.flatpickr.clear()

    const now = new Date()

    let startDate = now.toLocaleDateString('sv-SE') + 'T00:00:00.000Z'
    let endDate = now.toLocaleDateString('sv-SE') + 'T23:59:59.000Z'
    getConsentCategoryChart(startDate, endDate)
    getSummaryConsentDetail(startDate, endDate)
    getSummaryConsentGraph(startDate, endDate)
    getSummaryPage(startDate, endDate)
    getSummaryDevice(startDate, endDate)
  }

  const getDataLastSevenDay = () => {
    setFilterActive('seven')
    fp.current.flatpickr.clear()

    const now = new Date()
    const sevenDaysAgo = new Date(now)
    sevenDaysAgo.setDate(now.getDate() - 6)

    let startDate = sevenDaysAgo.toLocaleDateString('sv-SE') + 'T00:00:00.000Z'
    let endDate = now.toLocaleDateString('sv-SE') + 'T23:59:59.000Z'
    setStartDateSelected(startDate)
    setEndDateSelected(endDate)

    getConsentCategoryChart(startDate, endDate)
    getSummaryConsentDetail(startDate, endDate)
    getSummaryConsentGraph(startDate, endDate)
    getSummaryPage(startDate, endDate)
    getSummaryDevice(startDate, endDate)
  }

  const getDataMonth = () => {
    setFilterActive('month')
    fp.current.flatpickr.clear()
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    let startDate =
      firstDay.toLocaleString('sv-SE', { timeZone: 'UTC' }).split(' ')[0] + 'T17:00:00.000Z'
    let endDate = now.toLocaleString('sv-SE').split(' ')[0] + 'T16:59:59.000Z'

    setStartDateSelected(startDate)
    setEndDateSelected(endDate)

    getConsentCategoryChart(startDate, endDate)
    getSummaryConsentDetail(startDate, endDate)
    getSummaryConsentGraph(startDate, endDate)
    getSummaryPage(startDate, endDate)
    getSummaryDevice(startDate, endDate)
  }

  const getDataAll = () => {
    setFilterActive('all')
    fp.current.flatpickr.clear()
    const { startDate, endDate } = customDate(9999)
    getConsentCategoryChart(startDate, endDate)
    getSummaryConsentDetail(startDate, endDate)
    getSummaryConsentGraph(startDate, endDate)
    getSummaryPage(startDate, endDate)
    getSummaryDevice(startDate, endDate)
  }

  const getArrayDateOfLength = (startDate, endDate, minLength = 7) => {
    let start = new Date(startDate)
    let end = new Date(endDate)
    let today = new Date()
    let dateArray = []

    // สร้างอาเรย์จาก startDate ถึง endDate
    while (start <= end) {
      let year = start.getFullYear()
      let month = String(start.getMonth() + 1).padStart(2, '0')
      let day = String(start.getDate()).padStart(2, '0')
      dateArray.push(`${year}-${month}-${day}`)
      start.setDate(start.getDate() + 1)
    }

    let totalDays = dateArray.length

    if (totalDays < minLength) {
      let prependDays = minLength - totalDays

      // เพิ่มวันที่ย้อนกลับไปที่จุดเริ่มต้นของอาเรย์
      for (let i = 0; i < prependDays; i++) {
        let newDate = new Date(dateArray[0])
        newDate.setDate(newDate.getDate() - 1)
        let year = newDate.getFullYear()
        let month = String(newDate.getMonth() + 1).padStart(2, '0')
        let day = String(newDate.getDate()).padStart(2, '0')
        dateArray.unshift(`${year}-${month}-${day}`)
      }
    }

    return dateArray
  }

  const getSummaryConsentGraph = async (startDate, endDate) => {
    try {
      setLoadingOverview(true)
      let obj = {
        consent_id: id,
        start_date: startDate,
        end_date: endDate
      }
      let resMonth = []
      let resAccept = []
      let resDecline = []
      let resPartial = []
      let closeBannerX = []
      const { data, status } = await summaryConsentGraph(obj)
      let allAccept = [
        parseInt(data.summary[0] && data.summary[0].accept_all ? data.summary[0].accept_all : 0),
        parseInt(data.summary[0] && data.summary[0].decline_all ? data.summary[0].decline_all : 0),
        parseInt(
          data.summary[0] && data.summary[0].partial_accept ? data.summary[0].partial_accept : 0
        )
      ]
      if (data && status === 200) {
        const arrDate = getArrayDateOfLength(startDate, endDate)
        if (arrDate.length <= 32) {
          for (let i = 0; i < arrDate.length; i++) {
            resMonth.push(arrDate[i])
          }
          const resultAcceptAll = arrDate.map(date => {
            const found = data.summary.find(item => item.date === date)
            return found ? found.accept_all : '0'
          })

          resAccept = resultAcceptAll

          const resultDeclineAll = arrDate.map(date => {
            const found = data.summary.find(item => item.date === date)
            return found ? found.decline_all : '0'
          })
          resDecline = resultDeclineAll

          const resultPartialAccept = arrDate.map(date => {
            const found = data.summary.find(item => item.date === date)
            return found ? found.partial_accept : '0'
          })
          resPartial = resultPartialAccept

          const resultCloseBanner = arrDate.map(date => {
            const found = data.summary.find(item => item.date === date)
            return found ? found.close_banner : '0'
          })

          closeBannerX = resultCloseBanner
        } else {
          for (let i = 0; i < data.summary.length; i++) {
            resMonth.push(data.summary[i].month)
            resAccept.push(data.summary[i].accept_all)
            resDecline.push(data.summary[i].decline_all)
            resPartial.push(data.summary[i].partial_accept)
            closeBannerX.push(data?.summary[i]?.close_banner)
          }
        }

        var myChart = echarts.init(document.getElementById('lineChart'))
        myChart.setOption({
          legend: {
            orient: 'horizontal',
            top: 'bottom',
            data: [
              i18n[props.langFn].acceptAll,
              i18n[props.langFn].acceptSome,
              i18n[props.langFn].declineAll,
              i18n[props.langFn].closeBannerX
            ]
          },
          color: allColor,
          tooltip: {
            trigger: 'item',
            show: true,
            formatter: function (params) {
              return `${params.seriesName} : <b> ${numberWithCommas(params.data)}</b>`
            }
          },
          xAxis: {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLabel: {
              rotate: 30,
              formatter: function (value, index) {
                var date = new Date(value)
                var texts =
                  arrDate.length <= 32
                    ? [
                        date.getDate(),
                        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
                        date.getFullYear()
                      ]
                    : [
                        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
                        date.getFullYear()
                      ]

                return texts.join('-')
              }
            },
            data: resMonth
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: resAccept && resAccept.length > 0 ? resAccept : [0],
              name: i18n[props.langFn].acceptAll,
              type: 'line',
              smooth: true,
              areaStyle: {
                color: allColor[0],
                opacity: 0.5
              }
            },
            {
              data: resPartial && resPartial.length > 0 ? resPartial : [0],
              name: i18n[props.langFn].acceptSome,
              type: 'line',
              smooth: true,
              areaStyle: {
                color: allColor[1],
                opacity: 0.5
              }
            },
            {
              data: resDecline && resDecline.length > 0 ? resDecline : [0],
              name: i18n[props.langFn].declineAll,
              type: 'line',
              smooth: true,
              areaStyle: {
                color: allColor[2],
                opacity: 0.5
              }
            },
            {
              data: closeBannerX && closeBannerX.length > 0 ? closeBannerX : [0],
              name: i18n[props.langFn].closeBannerX,
              type: 'line',
              smooth: true,
              areaStyle: {
                color: allColor[3],
                opacity: 0.5
              }
            }
          ]
        })

        window.addEventListener('resize', function () {
          myChart.resize()
        })

        setTimeout(() => {
          setLoadingOverview(false)
        }, 2000)
      } else {
        setTimeout(() => {
          setLoadingOverview(false)
        }, 1000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getSummaryConsentDetail = async (startDate, endDate) => {
    setLoadingConsent(true)
    let obj = {
      consent_id: id,
      start_date: startDate,
      end_date: endDate,
      lang: 'th'
    }
    const { data, status } = await summaryConsent(obj)
    if (data && status === 200) {
      setAcceptSummary(data.summary)
      setTimeout(() => {
        setLoadingConsent(false)
      }, 1500)
    } else {
      setTimeout(() => {
        setLoadingConsent(false)
      }, 1500)
    }
  }

  const getSummaryPage = async (startDate, endDate) => {
    setLoadingSummaryPage(true)
    let obj = {
      consent_id: id,
      start_date: startDate,
      end_date: endDate
    }

    const { data, status } = await summaryPage(obj)
    if (data && status === 200) {
      setNumberPageList(data.summary)
      setTimeout(() => {
        setLoadingSummaryPage(false)
      }, 2000)
    }
  }

  const getSummaryDevice = async (startDate, endDate) => {
    setLoadingSummaryDevices(true)
    let obj = {
      consent_id: id,
      start_date: startDate,
      end_date: endDate
    }

    const { data, status } = await summaryDevices(obj)
    if (data && status === 200) {
      const dataWeb = data.summary.filter(item => item.device === 'web')
      const dataMobile = data.summary.filter(item => item.device === 'mobile')
      const dataTablet = data.summary.filter(item => item.device === 'tablet')
      const dataOther = data.summary.filter(item => item.device === 'others')
      const newData = [
        {
          device: `web`,
          total: dataWeb[0]?.total ? dataWeb[0]?.total : '0',
          percent: dataWeb[0]?.percent ? dataWeb[0]?.percent : '0.00%'
        },
        {
          device: `mobile`,
          total: dataMobile[0]?.total ? dataMobile[0]?.total : '0',
          percent: dataMobile[0]?.percent ? dataMobile[0]?.percent : '0.00%'
        },

        {
          device: `others`,
          total: dataOther[0]?.total ? dataOther[0]?.total : '0',
          percent: dataOther[0]?.percent ? dataOther[0]?.percent : '0.00%'
        }
      ]
      setNumberDevicesList(newData)

      setTimeout(() => {
        setLoadingSummaryDevices(false)
      }, 2000)
    }
  }

  const customDate = days => {
    var date = new Date()
    var endDate = `${date.toLocaleString('sv-SE').split(' ')[0]}T23:59:59.000Z`
    date.setDate(date.getDate() - days)
    var startDate = `${
      date.toLocaleString('sv-SE', { timeZone: 'UTC' }).split(' ')[0]
    }T00:00:00.000Z`
    return { startDate, endDate }
  }

  const getChartConsent = () => {
    let chart = echarts.init(document.getElementById('chartConsent'))
    chart.setOption({
      tooltip: {
        trigger: 'item',
        show: true
      },
      legend: {
        top: '0%',
        left: 'center',
        show: false
      },
      color: allColor,
      series: [
        {
          name: i18n[lang].consented,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: acceptSummary?.consent_by_category?.accept_all
                ? acceptSummary.consent_by_category.accept_all
                : 0,
              name: i18n[lang].acceptAll
            },
            {
              value: acceptSummary?.consent_by_category?.partial_accept
                ? acceptSummary.consent_by_category.partial_accept
                : 0,
              name: i18n[lang].acceptSome
            },
            {
              value: acceptSummary?.consent_by_category?.decline_all
                ? acceptSummary.consent_by_category.decline_all
                : 0,
              name: i18n[lang].declineAll
            },
            {
              value: acceptSummary?.consent_by_category?.close_banner
                ? acceptSummary.consent_by_category.close_banner
                : 0,
              name: i18n[lang].closeBannerX
            }
          ]
        }
      ]
    })

    window.addEventListener('resize', function () {
      chart.resize()
    })
  }

  const getTwoYearsAgo = () => {
    const date = new Date()
    date.setDate(date.getDate())
    date.setFullYear(date.getFullYear() - 2)
    return date.toLocaleDateString('sv-SE') // ใช้ฟอร์แมต sv-SE เพื่อให้ได้ YYYY-MM-DD
  }
  const getChartProportionConsent = () => {
    // setLoadingProportionConsent(true)
    let chart = echarts.init(document.getElementById('chartProportionConsent'))
    chart.setOption({
      tooltip: {
        trigger: 'item',
        show: true
      },
      legend: {
        top: '0%',
        left: 'center',
        show: false
      },
      color: allColor,
      series: [
        {
          name: i18n[lang].consentProportion,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value:
                acceptSummary && acceptSummary.consent_ratio.get_accept_all
                  ? acceptSummary.consent_ratio.get_accept_all
                  : 0,
              name: i18n[lang].acceptAll
            },
            {
              value:
                acceptSummary && acceptSummary.consent_ratio.get_consent_all
                  ? acceptSummary.consent_ratio.get_consent_all
                  : 0,
              name: i18n[lang].allConsent
            }
          ]
        }
      ]
    })

    window.addEventListener('resize', function () {
      chart.resize()
    })
  }

  const getConsentCategoryChart = async (startDate, endDate) => {
    setLoadingSummaryConsent(true)
    let obj = {
      consent_id: id,
      start_date: startDate,
      end_date: endDate,
      lang: props.langFn
    }
    let res = []
    let _temp = {}
    const { data, status } = await summaryConsentCategory(obj)
    if (data && status === 200) {
      for (let i = 0; i < data.summary.length; i++) {
        _temp = {
          value: data.summary[i].total,
          name:
            props.langFn === 'th' ? data.summary[i].accept_name_th : data.summary[i].accept_name_en
        }
        res.push(_temp)
      }
      setChartConsentCategory(res)
      let chart = echarts.init(document.getElementById('chartConsentCategory'))
      chart.setOption({
        tooltip: {
          trigger: 'item',
          show: true
        },
        legend: {
          top: '0%',
          left: 'center',
          show: false
        },
        color: allColorCategory,
        series: [
          {
            name: i18n[props.langFn].consentByCategory,
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: res && res.length > 0 ? res : [{ value: 0, name: '' }]
          }
        ]
      })

      window.addEventListener('resize', function () {
        chart.resize()
      })

      setTimeout(() => {
        setLoadingSummaryConsent(false)
      }, 2000)
    } else {
      setTimeout(() => {
        setLoadingSummaryConsent(false)
      }, 2000)
    }
  }
  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }
  const datePickr = date => {
    if (date[1]) {
      setFilterActive('date')
      let startDate = new Date(date[0])
      let endDate = new Date(date[1])

      let sDate = `${
        startDate.toLocaleString('sv-SE', { timeZone: 'UTC' }).split(' ')[0]
      }T17:00:00.000Z`
      let eDate = `${endDate.toLocaleString('sv-SE').split(' ')[0]}T16:59:59.000Z`

      setStartDateSelected(sDate)
      setEndDateSelected(eDate)

      getConsentCategoryChart(sDate, eDate)
      getSummaryConsentDetail(sDate, eDate)
      getSummaryConsentGraph(sDate, eDate)
      getSummaryPage(sDate, eDate)
      getSummaryDevice(sDate, eDate)
    }
  }

  const fixedHeightStyle = {
    height: '422px'
  }

  const scrollableStyle = {
    height: '346px',
    overflowY: 'auto'
  }

  const scrollableConsentCategoryStyle = {
    height: '160px',
    overflowY: 'auto'
  }

  function getDevicePath(device) {
    let isDevice
    switch (device) {
      case 'web':
        isDevice = '../assets/images/ic_device_desktop.svg'
        break
      case 'mobile':
        isDevice = '../assets/images/ic_device_smart_phone.svg'
        break
      case 'tablet':
        isDevice = '../assets/images/ic_device_tablet.svg'
        break
      case 'smartTv':
        isDevice = '../assets/images/ic_device_smart_tv.svg'
        break
      case 'others':
        isDevice = '../assets/images/ic_device_other.svg'
        break
    }
    return isDevice
  }

  function getDeviceName(device) {
    let isDevice
    switch (device) {
      case 'web':
        isDevice = `${i18n[lang].computer}`
        break
      case 'mobile':
        isDevice = `${i18n[lang].mobileAndTablet}`
        break
      case 'tablet':
        isDevice = `${i18n[lang].tablet}`
        break
      case 'smartTv':
        isDevice = `${i18n[lang].smartTv}`
        break
      case 'others':
        isDevice = `${i18n[lang].others}`
        break
    }
    return isDevice
  }

  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills nav-primary">
                  <li className="nav-item waves-effect waves-light">
                    <p className="nav-link mb-0 fs-16 text-dark font-weight-bold mx-3">
                      {i18n[lang].dashboard}
                    </p>
                  </li>

                  <li className="nav-item waves-effect waves-light">
                    <a
                      onClick={() => getDataLastSevenDay()}
                      className={`nav-link ${filterActive === 'seven' ? 'active' : ''}`}
                    >
                      {i18n[lang].lastSevenDay}
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      onClick={() => getDataMonth()}
                      className={`nav-link ${filterActive === 'month' ? 'active' : ''}`}
                    >
                      {i18n[lang].thisMonth}
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      onClick={() => getDataWithPreviosMonth()}
                      className={`nav-link ${filterActive === 'premonth' ? 'active' : ''}`}
                    >
                      {i18n[lang].previousMonth}
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <div className="nav-link mb-0 fs-16 text-dark font-weight-bold mx-3 p-0">
                      <div className="input-group">
                        <Flatpickr
                          style={{ width: '232px' }}
                          ref={fp}
                          placeholder="YYYY-MM-DD to YYYY-MM-DD"
                          options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            minDate: '2020-01',
                            maxDate: maxDate,
                            disable: [
                              {
                                from: '2020-01',
                                to: getTwoYearsAgo()
                              }
                            ]
                          }}
                          value={rangeDate}
                          onChange={date => datePickr(date)}
                        />
                        <div
                          className="input-group-text btn-primary text-white"
                          onClick={() => {
                            fp.current.flatpickr.open()
                          }}
                        >
                          <i className="ri-calendar-2-line"></i>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li style={{ color: '#667085', alignContent: 'center' }}>
                    {i18n[lang].historicalData2year}
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="tab-content text-muted">
              {/* <div
                className="card card-loader w-100 "
                style={{ display: loadingPage ? 'flex' : 'none' }}
              >
                <div className="loader">
                  <img src={`/assets/images/loading.svg`} alt="" className="w-100" />
                </div>
              </div> */}
              {/* <div className={loadingPage ? 'd-visible' : 'fade-in'}> */}
              <div>
                <div className={`tab-pane active`} id="dashboard-all" role="tabpanel">
                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <div className="card card-animate">
                        <div className="card-body">
                          {loadingConsent ? (
                            <LoadingComponent />
                          ) : (
                            <div className="d-flex justify-content-between fade-in">
                              <div>
                                <p className="fw-medium text-muted mb-0">
                                  {i18n[lang].allConsentLog}
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">
                                  <span className="counter-value" data-target="3.40">
                                    {acceptSummary
                                      ? numberWithCommas(
                                          acceptSummary?.accept_all_summary?.total != 0
                                            ? acceptSummary?.accept_all_summary?.total
                                            : '0'
                                        )
                                      : '-'}
                                  </span>
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      className="feather feather-activity text-info"
                                    >
                                      <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6">
                      <div className="card card-height-100">
                        <div className="card-header border-bottom-dashed align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">{i18n[lang].consented}</h4>
                        </div>
                        <div className="card-body">
                          <div
                            style={{
                              display: loadingConsent ? 'block' : 'none',
                              marginTop: 170,
                              marginBottom: -200
                            }}
                          >
                            <LoadingComponent />
                          </div>
                          <div className=" text-center">
                            <div className={`donut ${loadingConsent ? 'd-visible' : 'fade-in'}`}>
                              <div
                                className="chart"
                                id="chartConsent"
                                style={{ height: 300, top: -20 }}
                              ></div>
                            </div>
                          </div>
                          <ul
                            className={`list-group list-group-flush border-dashed mb-0 ${
                              loadingConsent ? 'd-none' : 'fade-in'
                            }`}
                          >
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-grow-1 ms-2">
                                  <p className="fs-12 mb-0 text-muted">
                                    <i
                                      style={{ color: allColor[0] }}
                                      className="mdi mdi-circle fs-10 align-middle me-1"
                                    ></i>
                                    {i18n[lang].acceptAll}
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end pr-1">
                                  <p className="text-dark fs-12 mb-0">
                                    {' '}
                                    {acceptSummary && acceptSummary.consent_by_category.accept_all
                                      ? numberWithCommas(
                                          acceptSummary.consent_by_category.accept_all
                                        )
                                      : 0}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-grow-1 ms-2">
                                  <p className="fs-12 mb-0 text-muted">
                                    <i
                                      style={{ color: allColor[1] }}
                                      className="mdi mdi-circle fs-10 align-middle me-1"
                                    ></i>
                                    {i18n[lang].acceptSome}
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end pr-1">
                                  <p className="text-dark fs-12 mb-0">
                                    {acceptSummary &&
                                    acceptSummary.consent_by_category.partial_accept
                                      ? numberWithCommas(
                                          acceptSummary.consent_by_category.partial_accept
                                        )
                                      : 0}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-grow-1 ms-2">
                                  <p className="fs-12 mb-0 text-muted">
                                    <i
                                      style={{ color: allColor[2] }}
                                      className="mdi mdi-circle fs-10 align-middle me-1"
                                    ></i>
                                    {i18n[lang].declineAll}
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end pr-1">
                                  <p className="text-dark fs-12 mb-0">
                                    {acceptSummary && acceptSummary.consent_by_category.decline_all
                                      ? numberWithCommas(
                                          acceptSummary.consent_by_category.decline_all
                                        )
                                      : 0}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="d-flex">
                                <div className="flex-grow-1 ms-2">
                                  <p className="fs-12 mb-0 text-muted">
                                    <i
                                      style={{ color: allColor[3] }}
                                      className="mdi mdi-circle fs-10 align-middle me-1"
                                    ></i>
                                    {i18n[lang].closeBannerX}
                                  </p>
                                </div>
                                <div className="flex-shrink-0 text-end pr-1">
                                  <p className="text-dark fs-12 mb-0">
                                    {acceptSummary && acceptSummary.consent_by_category.close_banner
                                      ? numberWithCommas(
                                          acceptSummary.consent_by_category.close_banner
                                        )
                                      : 0}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6">
                      <div className="card card-height-100">
                        <div className="card-header border-bottom-dashed align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            {i18n[lang].consentByCategory}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div
                            style={{
                              display: loadingSummaryConsent ? 'block' : 'none',
                              marginTop: 170,
                              marginBottom: -200
                            }}
                          >
                            <LoadingComponent />
                          </div>

                          <div
                            className={`donut ${loadingSummaryConsent ? 'd-visible' : 'fade-in'}`}
                          >
                            <div
                              className="chart"
                              id="chartConsentCategory"
                              style={{ height: 300, top: -20 }}
                            ></div>
                          </div>
                          <div style={scrollableConsentCategoryStyle}>
                            <ul
                              className={`list-group list-group-flush border-dashed mb-0 ${
                                loadingSummaryConsent ? 'd-visible' : 'fade-in'
                              }`}
                            >
                              {chartConsentCategory && chartConsentCategory.length > 0 ? (
                                chartConsentCategory.map((item, i) => {
                                  return (
                                    <li key={i} className="list-group-item px-0">
                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-2">
                                          <p className="fs-12 mb-0 text-muted">
                                            <i
                                              style={{ color: allColorCategory[i] }}
                                              className={`mdi mdi-circle fs-10 align-middle me-1`}
                                            ></i>
                                            {item.name}
                                          </p>
                                        </div>
                                        <div className="flex-shrink-0 text-end pr-1">
                                          <p className="text-dark fs-12 mb-0">
                                            {numberWithCommas(item.value)}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                })
                              ) : (
                                <p className="text-center text-gray">{i18n[lang].noResultsFound}</p>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12">
                      <div className="card card-height-100">
                        <div className="card-header border-bottom-dashed align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            {i18n[lang].consentOverview}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div
                            style={{
                              display: loadingOverview ? 'block' : 'none',
                              marginTop: 100,
                              marginBottom: -200
                            }}
                          >
                            <LoadingComponent />
                          </div>
                          <div
                            className={`text-center ${loadingOverview ? 'd-visible' : 'fade-in'}`}
                          >
                            <div
                              id="lineChart"
                              className="chart"
                              style={{ height: 300, width: '100%' }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-xl-6">
                      <div className="card">
                        <div className="card-header border-bottom-dashed align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">{i18n[lang].pageRank}</h4>
                        </div>
                        <div className="card-body" style={{ minHeight: '422px' }}>
                          {loadingSummaryPage ? (
                            <div style={{ marginTop: 150 }}>
                              <LoadingComponent />
                            </div>
                          ) : (
                            <Fragment>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  height: '44px',
                                  fontWeight: 500,
                                  borderBottom: '1px solid',
                                  borderColor: '#e9ebec'
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: 'left',
                                    width: '50%',
                                    alignContent: 'center',
                                    color: '#000000',
                                    paddingRight: '12%'
                                  }}
                                >
                                  {i18n[lang].page}
                                </div>
                                <div
                                  style={{
                                    textAlign: 'center',
                                    width: '25%',
                                    alignContent: 'center',
                                    color: '#000000'
                                  }}
                                >
                                  {i18n[lang].consent}
                                </div>
                                <div
                                  style={{
                                    textAlign: 'center',
                                    width: '25%',
                                    alignContent: 'center',
                                    color: '#000000'
                                  }}
                                >
                                  {i18n[lang].proportion}
                                </div>
                              </div>
                              <div style={scrollableStyle}>
                                <table className="table w-100 fade-in">
                                  <tbody>
                                    {numberPageList && numberPageList.length > 0 ? (
                                      numberPageList.map((item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td
                                              className="table-dashboard"
                                              style={{
                                                width: '50%'
                                              }}
                                            >
                                              <p className="m-0">{item.page}</p>
                                            </td>
                                            <td style={{ textAlign: 'center', width: '25%' }}>
                                              {numberWithCommas(item.total)}
                                            </td>
                                            <td style={{ textAlign: 'center', width: '25%' }}>
                                              {item.percent}
                                            </td>
                                          </tr>
                                        )
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="3">
                                          <p
                                            className="text-center"
                                            style={{ paddingTop: '134px', paddingBottom: '134px' }}
                                          >
                                            {i18n[lang].noResultsFound}
                                          </p>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-xl-6">
                      <div className="card ">
                        <div className="card-header border-bottom-dashed align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">{i18n[lang].deviceRatio}</h4>
                        </div>
                        <div className="" style={fixedHeightStyle}>
                          {loadingSummaryDevices ? (
                            <div style={{ marginTop: 150 }}>
                              <LoadingComponent />
                            </div>
                          ) : (
                            <Fragment>
                              <div className=" w-full ">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '44px',
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    marginTop: '16px',
                                    fontWeight: 500
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      width: '50%',
                                      alignContent: 'center',
                                      color: '#000000',
                                      paddingRight: '12%'
                                    }}
                                  >
                                    {i18n[lang].devices}
                                  </div>
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      width: '25%',
                                      alignContent: 'center',
                                      color: '#000000'
                                    }}
                                  >
                                    {i18n[lang].consent}
                                  </div>
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      width: '20%',
                                      alignContent: 'center',
                                      color: '#000000'
                                    }}
                                  >
                                    {i18n[lang].proportion}
                                  </div>
                                </div>

                                <div
                                  className="flex w-full "
                                  style={{ paddingLeft: '24px', paddingRight: '24px' }}
                                >
                                  {numberDevicesList ? (
                                    numberDevicesList.map((item, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="flex w-full mx-2 mb-3 border border-1 rounded-lg "
                                          style={{
                                            borderRadius: '8px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: '62px'
                                          }}
                                        >
                                          <div
                                            className="py-3 "
                                            style={{ width: '50%', alignContent: 'center' }}
                                          >
                                            <div
                                              className="flex items-center "
                                              style={{ paddingLeft: '30px', color: '#000000' }}
                                            >
                                              <img
                                                src={getDevicePath(item.device)}
                                                alt={item.device}
                                                width="20px"
                                                className="mr-3"
                                              />
                                              {getDeviceName(item.device)}
                                            </div>
                                          </div>
                                          <div
                                            className="p-3 text-center text-"
                                            style={{
                                              width: '25%',
                                              color: '#000000',
                                              alignContent: 'center'
                                            }}
                                          >
                                            {numberWithCommas(item.total)}
                                          </div>
                                          <div
                                            className="p-3 text-center "
                                            style={{
                                              width: '20%',
                                              color: '#000000',
                                              alignContent: 'center'
                                            }}
                                          >
                                            {item.percent}
                                          </div>
                                        </div>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="3">
                                        <p className="text-center mt-3">
                                          {i18n[lang].noResultsFound}
                                        </p>
                                      </td>
                                    </tr>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default DashboardComponent
