import * as axios from 'axios'
import { redirectLogin, redirectPermission, refreshToken } from '../../helper/helper'

const { REACT_APP_OMISE_URL, REACT_APP_OMISE_SECRET_KEY, REACT_APP_API_MANAGEMENT } = process.env

let token = localStorage.getItem('token')
const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const omiseToken = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    },
    credentials: 'same-origin',
    auth: {
      username: REACT_APP_OMISE_SECRET_KEY
    }
  }
  try {
    let URL = `${REACT_APP_OMISE_URL}/token`
    const response = await axios.post(URL, obj, configHeader)
    res = response
  } catch (error) {
    console.log('omiseToken error : ', error)
  }
  return res
}

const getChargeDetail = async obj => {
  let res = {}
  let customConfig = config
  if (obj && obj.type === 'package') {
    customConfig = {
      headers: {
        'content-Type': 'application/json',
        Accept: '/',
        'Cache-Control': 'no-cache',
        consent_id: obj.consent_id,
        Authorization: `Bearer ${token}`
      },
      credentials: 'same-origin'
    }
  }

  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/charge-detail?package_id=${obj.package_id}&package_type=${obj.package_type}&invoiceType=${obj.invoice_type}&discount_code=${obj.discount_code}`
    const response = await axios.get(URL, customConfig)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getPaymentAccount = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/account`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getChargeConsentStatus = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/check-status-charge-consent`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const addTaxInvoice = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/tax-invoice`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const addNewCard = async tokenCard => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/card`
    const response = await axios.post(
      URL,
      {
        token_card: tokenCard
      },
      config
    )
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getChargeNewCardStatus = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/check-status-charge-new-card`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const cancelPackageSubscribe = async consent_id => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/package/cancel-packageSubscribe`
    const response = await axios.post(URL, {}, configHeader)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getAllReceipt = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/receipt`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getAccountCredit = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/payment/credit-account`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export {
  omiseToken,
  getChargeDetail,
  getPaymentAccount,
  getChargeConsentStatus,
  addTaxInvoice,
  addNewCard,
  getChargeNewCardStatus,
  cancelPackageSubscribe,
  getAllReceipt,
  getAccountCredit
}
