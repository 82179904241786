import styled from 'styled-components'
const LoadingBox = styled.div`
  .loadingbox {
    text-align: center;
    margin: auto;
    width: 50px;
    display: grid;
    align-content: center;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
  }
  .loading-sm {
    width: 44px !important;
  }
`

export { LoadingBox }
