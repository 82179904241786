import React from 'react'
import { LoadingBox } from './style'

const LoadingComponent = () => {
  return (
    <LoadingBox>
      <div className="loadingbox">
        <img src="/assets/images/loading.gif" className="loading-sm" />
      </div>
    </LoadingBox>
  )
}

export default LoadingComponent
