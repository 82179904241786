import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { getPaymentAccount, getChargeDetail } from '../../apis/Payment/api'
import {
  createConsent,
  updateConsent,
  consentById,
  updateConsentSuspend
} from '../../apis/management/api'
import Modal from 'react-modal'
import { enumCode } from '../../helper/enum'
import { numberWithCommas } from '../../helper/helper'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

import { SwitchBox, RadioBox, TooltipBox } from './style'
const { REACT_APP_PREDICTIVE, REACT_APP_OMISE_SECRET_KEY } = process.env

const PaymentComponent = props => {
  const packageId = parseInt(props.match.params.package)
  const type = props.match.params.type
  const page = props.match.params.page
  const domain = decodeURIComponent(props.match.params.domain)
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [address, setAddress] = useState('')
  const [subDistrict, setSubDistrict] = useState('')
  const [district, setDistrict] = useState('')
  const [province, setProvince] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [consentId, setConsentId] = useState('')
  const [cardID, setCardID] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardCVV, setCardCVV] = useState('')
  const [cardType, setCardType] = useState('')
  const [isWaiting, setIsWaiting] = useState(false)
  const [loading, setLoading] = useState(true)

  const [infoData, setInfoData] = useState(false)

  const [infoChargeDetail, setInfoChargeDetail] = useState()

  const [omiseToken, setOmiseToken] = useState()

  const [invoiceType, setInvoiceType] = useState('individual')
  const [updateInfoData, setUpdateInfoData] = useState(false)

  const [inValidateFirstName, setValidateFirstName] = useState(false)
  const [inValidateLastName, setValidateLastName] = useState(false)
  const [inValidatePhoneNumber, setValidatePhoneNumber] = useState(false)
  const [inValidateAddress, setValidateAddress] = useState(false)
  const [inValidateSubDistrict, setValidateSubDistrict] = useState(false)
  const [inValidateDistrict, setValidateDistrict] = useState(false)
  const [inValidateProvince, setValidateProvince] = useState(false)
  const [inValidateZipCode, setValidateZipCode] = useState(false)
  const [inValidateCardID, setValidateCardID] = useState(false)
  const [inValidateCardName, setValidateCardName] = useState(false)
  const [inValidateCardDate, setValidateCardDate] = useState(false)
  const [inValidateCardCVV, setValidateCardCVV] = useState(false)
  const [inValidateCompanyName, setValidateCompanyName] = useState(false)
  const [inValidateTaxNumber, setValidateTaxNumber] = useState(false)
  const [alertStatusBox, setAlertStatusBox] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [useCode, setUseCode] = useState(false)
  const [checkUseCode, setCheckUseCode] = useState('')
  const [inValidateUseCode, setInValidateUseCode] = useState(false)
  const [discountCode, setDiscountCode] = useState(false)
  const [removeUseCode, setRemoveUseCode] = useState(false)
  const [discount, setDiscount] = useState('')
  const [discountAmount, setDiscountAmount] = useState('')
  const specialChars = /[||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/
  const [openDiscounthFailed, setOpenPopupDiscountFailed] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))
  const customStylesModalsm = {
    content: {
      background: 'none',
      border: 0
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const search = useLocation().search
  const statusDomain = new URLSearchParams(search).get('status')

  const [openPopupCodeQuota, setOpenPopupCodeQuota] = useState(false)
  const [openPopupGoChangePackage, setOpenPopupGoChangePackage] = useState(false)
  const [openPopupGeneralSupport, setOpenPopupGeneralSupport] = useState(false)

  useEffect(() => {
    document.title = 'ชำระเงิน | CookiePlus'
    window.scrollTo(0, 0)
    if (window) {
      window.Omise.setPublicKey(REACT_APP_OMISE_SECRET_KEY)
    }
    paymentAccount()
  }, [])

  const paymentAccount = async () => {
    const { data, status } = await getPaymentAccount()

    if (data?.data?.tax_invoice && status === 200) {
      const { tax_invoice } = data.data
      setInfoData(data.data)
      setFirstName(tax_invoice.firstname)
      setLastName(tax_invoice.lastname)
      setAddress(tax_invoice.address)
      setSubDistrict(tax_invoice.subDistrict)
      setDistrict(tax_invoice.district)
      setProvince(tax_invoice.province)
      setZipCode(tax_invoice.zipcode)
      setInvoiceType(tax_invoice.type)
      setPhoneNumber(tax_invoice.phone)
      setCompanyName(tax_invoice.companyName)
      setTaxNumber(tax_invoice.taxNumber)
      chargeDetail(tax_invoice.type)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } else if (data?.data?.card_detail && status === 200) {
      setLoading(false)
      setInfoData(data.data)
      setUpdateInfoData(true)
    } else {
      setUpdateInfoData(true)
      setInfoData(data.data)
      chargeDetail(invoiceType)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
    if (page === 'package') {
      const { data, status } = await consentById(domain)
      if (data?.data?.domain && status === 200) {
        setConsentId(data.data.domain)
      }
    }
  }

  const chargeDetail = async (invoiceType, option = '') => {
    let obj = {
      type: page,
      consent_id: domain,
      package_id: packageId,
      package_type: type,
      invoice_type: invoiceType,
      discount_code: option === 'removeCode' ? '' : checkUseCode
    }

    await checkRefCode(obj)
  }
  const checkRefCode = async obj => {
    const { data, status } = await getChargeDetail(obj)
    if (status === 200) {
      setInfoChargeDetail(data.data)
      if (data.data?.discount_detail) {
        if (data.data.discount_detail.status === 'fail') {
          setErrorMsg(i18n[lang].promotionExpired)
          setOpenPopupDiscountFailed(true)
          setDiscountCode(false)
          return false
        } else {
          setDiscountCode(true)
          setRemoveUseCode(true)
          setDiscountAmount(
            data.data.discount_detail?.discount_amount
              ? data.data.discount_detail.discount_amount
              : ''
          )
          return true
        }
      }
    }
  }
  const createDomain = async token => {
    setIsWaiting(true)
    let objPayment = {}
    if (token) {
      objPayment = {
        consent_id: consentId ? domain : '',
        consent_domain: domain,
        package_id: packageId,
        package_type: type,
        tax_invoice: {
          type: invoiceType,
          firstname: firstName,
          lastname: lastName,
          address: address,
          district: district,
          subDistrict: subDistrict,
          province: province,
          zipcode: zipCode,
          phone: phoneNumber,
          companyName: invoiceType !== 'individual' ? companyName : '',
          taxNumber: invoiceType !== 'individual' ? taxNumber : ''
        },
        token_card: token,
        discount_code: checkUseCode
      }
    } else {
      objPayment = {
        consent_id: consentId ? domain : '',
        consent_domain: domain,
        package_id: packageId,
        package_type: type,
        tax_invoice: {
          type: invoiceType,
          firstname: firstName,
          lastname: lastName,
          address: address,
          district: district,
          subDistrict: subDistrict,
          province: province,
          zipcode: zipCode,
          phone: phoneNumber,
          companyName: invoiceType !== 'individual' ? companyName : '',
          taxNumber: invoiceType !== 'individual' ? taxNumber : ''
        },

        discount_code: checkUseCode
      }
    }
    // updateConsent
    const { data, status } =
      page === 'adddomain'
        ? await createConsent(objPayment)
        : statusDomain === 'suspended'
          ? await updateConsentSuspend(objPayment)
          : await updateConsent(objPayment)
    if (status === 200) {
      setIsWaiting(false)
      if (data.data.authorize_uri_omise) {
        window.location.href = data.data.authorize_uri_omise
      } else if (data.data.id) {
        history.push(`/checkpayment/${data.data.id}`)
      } else {
        history.push(`/checkpayment/${domain}`)
      }
    } else {
      setIsWaiting(false)
      if (data === 6110) {
        setErrorMsg(i18n[lang].promotionExpired)
        setOpenPopupDiscountFailed(true)
      } else if (data === 1101) {
        // 1101: 'Consent - consent not found' - Use existing error
        setErrorMsg(i18n[lang].paymentFailed)
      } else if (data === 5101) {
        // 5101: 'Package - package not found' - Use existing error
        setErrorMsg(i18n[lang].paymentFailed)
      } else if (data === 5102) {
        // popup 5102: 'Package - current permission user consent greater than new quota package'
        setOpenPopupCodeQuota(true)
      } else if (data === 5103) {
        // popup 5103: 'Package - current accept consent greater than new quota package'
        setOpenPopupCodeQuota(true)
      } else if (data === 5106) {
        // popup 5106: 'Package - unable to change the current package', // กรณีเปลี่ยน free เป็น free ไม่ยอมให้ทำได้
        setOpenPopupGoChangePackage(true)
      } else {
        //General - please contact support
        setOpenPopupGeneralSupport(true)
        // setAlertStatusBox(true)
        // setErrorMsg(enumCode['th'][data.code]) รอเทส
        // setErrorMsg(`${i18n[lang].paymentFailed}  ${data?.code ? `(${data?.code})` : ''}`)
      }
    }
  }

  const createTokenOmise = async () => {
    let resToken = ''
    window.OmiseCard.configure({
      publicKey: REACT_APP_OMISE_SECRET_KEY
    })
    let exMount = cardDate.split('/')[0]
    let exYear = cardDate.split('/')[1]
    var cardInformation = {
      name: cardName,
      number: cardID,
      expiration_month: exMount,
      expiration_year: exYear,
      security_code: cardCVV
    }
    window.Omise.createToken('card', cardInformation, async (statusCode, response) => {
      if (statusCode === 200) {
        resToken = response.id
        await createDomain(resToken)
      } else {
        console.log('Omise get token error')
      }
    })
    return resToken
  }

  const creditCardType = cc => {
    if (cc.length < 20) {
      let amex = new RegExp('^3[47][0-9]{13}$')
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
      let cup2 = new RegExp('^81[0-9]{14}[0-9]*$')

      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

      let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
      let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
      let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')

      let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
      let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
      let replaceDash = cc.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replaceAll(/\s/g, '')
      if (visa.test(replaceDash)) {
        setCardType('VISA')
      } else {
        setCardType()
      }
      if (mastercard.test(replaceDash) || mastercard2.test(replaceDash)) {
        setCardType('MASTERCARD')
      }
      if (jcb.test(replaceDash)) {
        setCardType('JCB')
      }
      // if (amex.test(cc)) {
      //   setCardType('AMEX')
      // }

      // if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      //   setCardType('DISCOVER')
      // }
      // if (diners.test(cc)) {
      //   setCardType('DINERS')
      // }

      // if (cup1.test(cc) || cup2.test(cc)) {
      //   setCardType('CHINA_UNION_PAY')
      // }

      setCardID(maskCard(cc))
      return undefined
    }
  }

  const handleChangeInvoiceType = e => {
    setInvoiceType(e.target.value)
    chargeDetail(e.target.value)
  }
  const customInputDate = text => {
    let res = text
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
    return res
  }
  const chackValidate = option => {
    let res = true
    if (!firstName) {
      setValidateFirstName(true)
    } else {
      setValidateFirstName(false)
    }
    if (!lastName) {
      setValidateLastName(true)
    } else {
      setValidateLastName(false)
    }
    if (!phoneNumber) {
      setValidatePhoneNumber(true)
    } else {
      setValidatePhoneNumber(false)
    }
    if (!address) {
      setValidateAddress(true)
    } else {
      setValidateAddress(false)
    }
    if (!subDistrict) {
      setValidateSubDistrict(true)
    } else {
      setValidateSubDistrict(false)
    }
    if (!district) {
      setValidateDistrict(true)
    } else {
      setValidateDistrict(false)
    }
    if (!province) {
      setValidateProvince(true)
    } else {
      setValidateProvince(false)
    }
    if (!zipCode) {
      setValidateZipCode(true)
    } else {
      setValidateZipCode(false)
    }
    if (option === 'newcard') {
      if (!cardID) {
        setValidateCardID(true)
      } else {
        setValidateCardID(false)
      }
      if (!cardName) {
        setValidateCardName(true)
      } else {
        setValidateCardName(false)
      }
      if (!cardDate) {
        setValidateCardDate(true)
      } else {
        setValidateCardDate(false)
      }
      if (!cardCVV) {
        setValidateCardCVV(true)
      } else {
        setValidateCardCVV(false)
      }
    }

    if (option === 'newcard') {
      if (
        firstName &&
        lastName &&
        phoneNumber &&
        address &&
        subDistrict &&
        district &&
        province &&
        zipCode &&
        cardID &&
        cardName &&
        cardDate &&
        cardCVV
      ) {
        res = true
      } else {
        res = false
      }
    } else {
      if (
        firstName &&
        lastName &&
        phoneNumber &&
        address &&
        subDistrict &&
        district &&
        province &&
        zipCode
      ) {
        res = true
      } else {
        res = false
      }
    }
    if (invoiceType === 'juristic') {
      if (!companyName) {
        setValidateCompanyName(true)
      } else {
        setValidateCompanyName(false)
      }
      if (!taxNumber) {
        setValidateTaxNumber(true)
      } else {
        setValidateTaxNumber(false)
      }
      if (companyName && taxNumber) {
        res = true
      } else {
        res = false
      }
    }

    return res
  }
  const paymentSummary = async () => {
    let obj = {
      type: page,
      consent_id: domain,
      package_id: packageId,
      package_type: type,
      invoice_type: invoiceType,
      discount_code: checkUseCode
    }
    // if (checkUseCode) {
    //   const codeValid = await checkRefCode(obj)
    //   if (!codeValid) {
    //     return
    //   }
    // }
    if (infoData?.card_detail) {
      let checkHaveCard = chackValidate('havecard')
      if (updateInfoData) {
        if (checkHaveCard) {
          await createDomain()
        }
      } else {
        await createDomain()
      }
    } else {
      // ยังไม่เคยมีข้อมูล
      let checkNewCard = chackValidate('newcard')
      if (checkNewCard) {
        await createTokenOmise()
      }
    }
  }
  const maskCard = value => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g)
    var match = (matches && matches[0]) || ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
      return parts.join('-')
    } else {
      return value
    }
  }

  const checkCode = () => {
    if (checkUseCode) {
      setOpenPopupDiscountFailed(false)
      chargeDetail(invoiceType)
    } else {
      setOpenPopupDiscountFailed(true)
      setErrorMsg(i18n[lang].codeInvalidExpired)
      setDiscountCode(false)
    }
  }
  const removeCode = () => {
    setDiscountCode(false)
    setRemoveUseCode(false)
    setCheckUseCode('')
    chargeDetail(invoiceType, 'removeCode')
  }

  const chackValidateCode = () => {
    let res = true
    if (checkUseCode) {
      setInValidateUseCode(true)
    } else {
      setInValidateUseCode(false)
      res = false
    }

    return res
  }
  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])

  const handelClickDomain = () => {
    if (page === 'adddomain') {
      history.push(`/adddomain/`)
      setOpenPopupCodeQuota(false)
    } else {
      history.push(
        `/package?activeID=${domain}&status=${statusDomain === 'suspended' ? 'suspended' : ' '}`
      )
      setOpenPopupCodeQuota(false)
    }
    setOpenPopupCodeQuota(false)
  }

  return (
    <div className="main-content">
      {alertStatusBox && (
        <div
          className={`alert alert-danger alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center`}
          role="alert"
        >
          <i className={`ri-error-warning-line label-icon`}></i>
          {errorMsg}
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      <div className="page-content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body py-2">
              <div className="row">
                <div className="col-auto">
                  <h6 className="card-title mb-0 py-2">{i18n[lang].orderSummary}</h6>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="card card-loader w-100 ">
              <div className="loader">
                <img src={`/assets/images/loadding.svg`} alt="" className="w-100" />
              </div>
            </div>
          ) : (
            <div className="row FadeInAndOut">
              <div className="col-7">
                <div className="card">
                  <div className=" card-body">
                    <p className="fs-16 font-weight-bold">{i18n[lang].taxInfo}</p>
                    <p className="fs-12 text-Gray">
                      {i18n[lang].quotationNote}
                      <a className=" link-primary">sales@cookieplus.com</a>
                    </p>
                    {!infoData || updateInfoData ? (
                      <Fragment>
                        <RadioBox>
                          <ul className=" nav">
                            <li className=" nav-item">
                              <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="WithoutinlineRadio1"
                                  value="individual"
                                  onChange={handleChangeInvoiceType}
                                  checked={invoiceType === 'individual'}
                                />
                                <label className="form-check-label" htmlFor="formCheck1">
                                  <p className="fs-14 m-0 px-2 text-dark font-weight-bold">
                                    {i18n[lang].naturalPerson}{' '}
                                  </p>
                                </label>
                              </div>
                            </li>
                            <li className=" nav-item px-3">
                              <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="WithoutinlineRadio2"
                                  value="juristic"
                                  onChange={handleChangeInvoiceType}
                                  checked={invoiceType === 'juristic'}
                                />
                                <label className="form-check-label" htmlFor="formCheck1">
                                  <p className="fs-14 m-0 px-2 text-dark font-weight-bold">
                                    {i18n[lang].legalEntity}
                                  </p>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </RadioBox>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="firstnameInput" className="form-label">
                                {i18n[lang].name} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateFirstName ? 'is-invalid' : ''
                                  }`}
                                value={firstName}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setFirstName(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateFirstName && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterFirstName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="lastnameInput" className="form-label">
                                {i18n[lang].surname} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateLastName ? 'is-invalid' : ''}`}
                                value={lastName}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setLastName(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateLastName && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterLastName}
                                </span>
                              )}
                            </div>
                          </div>
                          {invoiceType === 'juristic' ? (
                            <Fragment>
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor="lastnameInput" className="form-label">
                                    {i18n[lang].companyName} <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${inValidateCompanyName ? 'is-invalid' : ''
                                      }`}
                                    value={companyName}
                                    onKeyPress={event => {
                                      if (specialChars.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    onChange={e =>
                                      setCompanyName(
                                        !specialChars.test(e.target.value) ? e.target.value : ''
                                      )
                                    }
                                  />
                                  {inValidateCompanyName && (
                                    <span className="invalid-feedback-err">
                                      {i18n[lang].enterCompanyName}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            ''
                          )}
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].address} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateAddress ? 'is-invalid' : ''}`}
                                value={address}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setAddress(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateAddress && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterAddress}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].subdistrict} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateSubDistrict ? 'is-invalid' : ''
                                  }`}
                                value={subDistrict}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setSubDistrict(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateSubDistrict && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterSubdistrict}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].district} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateDistrict ? 'is-invalid' : ''}`}
                                value={district}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setDistrict(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateDistrict && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterDistrict}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].province} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateProvince ? 'is-invalid' : ''}`}
                                value={province}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setProvince(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateProvince && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterProvince}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].zipCode} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateZipCode ? 'is-invalid' : ''}`}
                                value={zipCode}
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setZipCode(/[0-9]/.test(e.target.value) ? e.target.value : '')
                                }
                              />
                              {inValidateZipCode && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterZipCode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].mobileNumber} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidatePhoneNumber ? 'is-invalid' : ''
                                  }`}
                                value={phoneNumber}
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setPhoneNumber(/[0-9]/.test(e.target.value) ? e.target.value : '')
                                }
                              />
                              {inValidatePhoneNumber && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterPhoneNumber}
                                </span>
                              )}
                            </div>
                          </div>
                          {invoiceType === 'juristic' ? (
                            <Fragment>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="lastnameInput" className="form-label">
                                    {i18n[lang].taxID} <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${inValidateTaxNumber ? 'is-invalid' : ''
                                      }`}
                                    value={taxNumber}
                                    onKeyPress={event => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    onChange={e => setTaxNumber(e.target.value)}
                                  />
                                  {inValidateTaxNumber && (
                                    <span className="invalid-feedback-err">
                                      {i18n[lang].enterTaxID}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            ''
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <div className="border border-1 rounded-3 p-2">
                        <Fragment>
                          <ul className="nav">
                            <li className="nav-item text-right w-100">
                              <a
                                onClick={() => setUpdateInfoData(true)}
                                className=" link-primary font-weight-bold"
                              >
                                {i18n[lang].edit}
                              </a>
                            </li>
                          </ul>
                          <ul className="nav my-2">
                            <li className="nav-item w-25 fs-14 font-weight-bold text-Gray">
                              {i18n[lang].nameSurname}
                            </li>
                            <li className="nav-item w-75 fs-14 font-weight-bold">
                              {infoData?.tax_invoice?.firstname} {infoData?.tax_invoice?.lastname}
                            </li>
                          </ul>
                          <ul className="nav my-2">
                            <li className="nav-item w-25 fs-14 font-weight-bold text-Gray">
                              {i18n[lang].type}
                            </li>
                            <li className="nav-item w-75 fs-14 font-weight-bold">
                              {infoData?.tax_invoice?.type === 'individual'
                                ? i18n[lang].naturalPerson
                                : i18n[lang].legalEntity}
                            </li>
                          </ul>
                          <ul className="nav my-2">
                            <li className="nav-item w-25 fs-14 font-weight-bold text-Gray">
                              {i18n[lang].address}
                            </li>
                            <li className="nav-item w-75 fs-14 font-weight-bold">
                              {infoData?.tax_invoice?.address} {infoData?.tax_invoice?.subDistrict}{' '}
                              {infoData?.tax_invoice?.district} {infoData?.tax_invoice?.province}{' '}
                              {infoData?.tax_invoice?.zipcode}
                            </li>
                          </ul>
                        </Fragment>
                      </div>
                    )}

                    <hr />
                    <p className=" font-weight-bold">{i18n[lang].paymentInformation}</p>
                    {!infoData?.card_detail?.brand_card ? (
                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <label htmlFor="lastnameInput" className="form-label">
                            {i18n[lang].cardNumber}
                          </label>
                          <div className="form-icon right">
                            <input
                              className={`form-control form-control-icon ${inValidateCardID ? 'is-invalid' : ''
                                }`}
                              value={cardID}
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={e => creditCardType(e.target.value)}
                            />
                            {cardType && (
                              <img
                                src={`/assets/images/${cardType}.png`}
                                alt={`${cardType}`}
                                height="20"
                              />
                            )}
                          </div>
                          {inValidateCardID && (
                            <span className="invalid-feedback-err">
                              {i18n[lang].enterCardNumber}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label htmlFor="firstnameInput" className="form-label">
                              {i18n[lang].cardholderName}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${inValidateCardName ? 'is-invalid' : ''}`}
                              value={cardName}
                              onKeyPress={event => {
                                if (specialChars.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={e => setCardName(e.target.value)}
                            />
                            {inValidateCardName && (
                              <span className="invalid-feedback-err">
                                {i18n[lang].enterCardholderName}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="numberInput" className="form-label">
                              {i18n[lang].expirationDate}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${inValidateCardDate ? 'is-invalid' : ''}`}
                              value={cardDate}
                              size="5"
                              maxLength="5"
                              onKeyPress={event => {
                                if (specialChars.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={e => setCardDate(customInputDate(e.target.value))}
                            />
                            {inValidateCardDate && (
                              <span className="invalid-feedback-err">
                                {i18n[lang].enterExpirationDate}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="numberInput" className="form-label">
                              CVV
                            </label>
                            <input
                              type="password"
                              pattern="\d*"
                              className={`form-control ${inValidateCardCVV ? 'is-invalid' : ''}`}
                              value={cardCVV}
                              size="3"
                              maxLength="3"
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={e => setCardCVV(e.target.value)}
                            />
                            {inValidateCardCVV && (
                              <span className="invalid-feedback-err">{i18n[lang].enterCVV}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="border border-1 rounded-3 p-2">
                        <ul className="nav">
                          <li className="nav-itam w-25 text-Gray fs-14 font-weight-bold">
                            {i18n[lang].cardNumberEndsWith}
                          </li>
                          <li className="nav-itam w-50">
                            {' '}
                            {infoData?.card_detail?.brand_card && (
                              <img
                                src={`/assets/images/${infoData.card_detail.brand_card.toUpperCase()}.png`}
                                alt={`${infoData?.card_detail.brand_card}`}
                                height="20"
                                className="mx-2 mb-1"
                              />
                            )}
                            {infoData?.card_detail?.last_digits_card}
                          </li>
                          <li className="nav-itam w-25 text-right fs-14 font-weight-bold text-primary">
                            <a onClick={() => history.push('/package/payment')}>
                              {i18n[lang].changeCreditCard}
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <a
                  onClick={() =>
                    history.push(
                      `/${statusDomain === 'suspended'
                        ? `${page}?activeID=${domain}&status=suspended`
                        : page
                      }`
                    )
                  }
                  className=" link-primary fs-14"
                >
                  <i className="mdi mdi-arrow-left-thin label-icon align-middle fs-20"></i>{' '}
                  {i18n[lang].backToPackage}
                </a>
              </div>
              <div className="col-5">
                <div className="card">
                  <div className="card-body">
                    <b>{i18n[lang].orderSummary}</b>

                    <ul className="nav w-100 mt-3">
                      <li className="nav-item w-50 font-weight-bold">{i18n[lang].items}</li>
                      <li className="nav-item w-50 text-right font-weight-bold">
                        {i18n[lang].totalAmount}
                      </li>
                    </ul>
                    <hr />
                    <ul className="nav w-100 mt-3 bg-light p-2">
                      <li className="nav-item w-75 font-weight-bold fs-14 ">
                        CookiePlus{' '}
                        {packageId === 1
                          ? 'Free'
                          : packageId === 2
                            ? 'Basic'
                            : packageId === 3
                              ? 'Pro'
                              : 'Ultimate'}{' '}
                        Plan ({type === 'month' ? i18n[lang].monthly : i18n[lang].yearly}) <br />{' '}
                        <p className="fs-14 text-Gray font-weight-normal mb-0">
                          {consentId ? consentId : domain}
                        </p>
                      </li>
                      <li className="nav-item w-25 text-right font-weight-bold">
                        {infoChargeDetail?.package_price &&
                          numberWithCommas(infoChargeDetail.package_price)}
                      </li>
                    </ul>
                    {discountCode && (
                      <ul className="nav w-100 mt-3">
                        <li className="nav-item w-50 font-weight-bold text-Danger">
                          {i18n[lang].discount} [ {checkUseCode} ]
                        </li>
                        <li className="nav-item w-50 text-right font-weight-bold text-Danger">
                          -{discountAmount}
                        </li>
                      </ul>
                    )}
                    <hr />
                    {infoChargeDetail?.change_package_refund &&
                      parseInt(infoChargeDetail?.change_package_refund) > 0 && (
                        <Fragment>
                          <ul className="nav w-100 mt-3">
                            <li className="nav-item w-75 font-weight-bold fs-14">
                              {i18n[lang].deductedFromPackageValue} <br />{' '}
                              <span className="text-Secondary font-weight-normal">
                                {infoChargeDetail?.change_package_refund &&
                                  parseInt(infoChargeDetail?.change_package_refund) > 0 && (
                                    <TooltipBox>
                                      ({i18n[lang].balanceFromPrevious}:{' '}
                                      {infoChargeDetail?.change_package_refund &&
                                        infoChargeDetail.change_package_refund}
                                      )
                                      <div className="tooltips">
                                        <Fragment>
                                          <i
                                            className={`mdi mdi-information label-icon fs-16 mx-2 link-edit`}
                                          ></i>
                                          <span className="tooltiptexts">
                                            <div className="text-left px-3 box_tooltips">
                                              <p className="py-2 my-2">
                                                {i18n[lang].remainingCreditStored}
                                              </p>
                                            </div>
                                          </span>
                                        </Fragment>
                                      </div>
                                    </TooltipBox>
                                  )}
                              </span>
                            </li>
                            <li className="nav-item w-25 text-right font-weight-bold text-Danger">
                              {infoChargeDetail?.pay_with_change_package_refund &&
                                '-' +
                                numberWithCommas(infoChargeDetail.pay_with_change_package_refund)}
                            </li>
                          </ul>
                          <hr />
                        </Fragment>
                      )}
                    {infoChargeDetail?.pay_with_credit_account &&
                      parseInt(infoChargeDetail?.pay_with_credit_account) > 0 && (
                        <Fragment>
                          <ul className="nav w-100 mt-3">
                            <li className="nav-item w-75 font-weight-bold fs-14">
                              {i18n[lang].deductedFromCredit}
                              <br />
                              <span className="text-Secondary font-weight-normal">
                                {infoChargeDetail?.before_credit_balance && (
                                  <span className="text-Secondary font-weight-normal">
                                    ({i18n[lang].availableCredit} :
                                    {numberWithCommas(infoChargeDetail.before_credit_balance)} )
                                  </span>
                                )}
                              </span>
                            </li>
                            <li className="nav-item w-25 text-right font-weight-bold text-Danger">
                              {infoChargeDetail?.pay_with_credit_account &&
                                '-' + numberWithCommas(infoChargeDetail.pay_with_credit_account)}
                            </li>
                          </ul>
                          <hr />
                        </Fragment>
                      )}

                    {infoChargeDetail?.excluding_vat &&
                      parseInt(infoChargeDetail?.excluding_vat) > 0 && (
                        <ul className="nav w-100 mt-3">
                          <li className="nav-item w-50 font-weight-bold fs-14">
                            {i18n[lang].priceBeforeVAT}
                          </li>
                          <li className="nav-item w-50 text-right font-weight-bold">
                            {infoChargeDetail?.excluding_vat &&
                              numberWithCommas(infoChargeDetail.excluding_vat)}
                          </li>
                        </ul>
                      )}
                    {infoChargeDetail?.vat && parseInt(infoChargeDetail?.vat) > 0 && (
                      <Fragment>
                        <ul className="nav w-100 mt-3">
                          <li className="nav-item w-50 font-weight-bold fs-14 ">VAT 7%</li>
                          <li className="nav-item w-50 text-right font-weight-bold">
                            {infoChargeDetail?.vat && numberWithCommas(infoChargeDetail.vat)}
                          </li>
                        </ul>
                        <hr />
                      </Fragment>
                    )}

                    <Fragment>
                      <ul className="nav w-100 mt-3">
                        <li className="nav-item w-100 font-weight-bold fs-14 ">
                          <a onClick={() => setUseCode(!useCode)} target="_blank">
                            <span className={`text-primary`}>{i18n[lang].haveCoupon}</span>
                          </a>
                        </li>
                      </ul>
                      {useCode && (
                        <ul className="nav w-100 mt-3 ">
                          <li className="nav-item w-75 font-weight-bold fs-14 pr-3">
                            {' '}
                            <input
                              type="text"
                              className={`form-control`}
                              value={checkUseCode}
                              onChange={e => (
                                setCheckUseCode(
                                  e.target.value.replace(/[^A-Z0-9]/gi, '').toUpperCase()
                                ),
                                e.target.value.length === 0 && setDiscountCode(false)
                              )}
                              disabled={removeUseCode === true ? true : false}
                            />
                          </li>
                          <li className="nav-item w-25 text-right font-weight-bold">
                            {removeUseCode === true ? (
                              <button
                                onClick={() => removeCode()}
                                className="btn btn-danger w-100 fs-16"
                                style={{ height: '39px', lineHeight: '1.3' }}
                              >
                                {i18n[lang].cancel}
                              </button>
                            ) : (
                              <button
                                onClick={() => checkCode()}
                                className="btn btn-primary w-100 fs-16"
                                style={{ height: '39px', lineHeight: '1.3' }}
                              >
                                {i18n[lang].redeem}
                              </button>
                            )}
                          </li>
                        </ul>
                      )}

                      <hr />
                    </Fragment>

                    {infoChargeDetail &&
                      infoChargeDetail.withholding_tax &&
                      invoiceType === 'juristic' ? (
                      <Fragment>
                        <ul className="nav w-100 mt-3">
                          <li className="nav-item w-50 font-weight-bold fs-14 ">
                            {i18n[lang].withholdingTax3}
                          </li>
                          <li className="nav-item w-50 text-right font-weight-bold">
                            {infoChargeDetail.withholding_tax
                              ? '-' + numberWithCommas(infoChargeDetail.withholding_tax)
                              : '0'}
                          </li>
                        </ul>
                        <ul className="nav w-100 mt-3">
                          <li className="nav-item w-75 fs-13">
                            {' '}
                            <b>{i18n[lang].note}:</b> {i18n[lang].withholdingTaxCertificateNote}{' '}
                            <b>{i18n[lang].companyNameNote} </b>
                            {i18n[lang].addressNote} <br></br> {i18n[lang].cityNote} <br></br> Tax
                            ID: 0105555180861
                          </li>
                        </ul>
                        <hr />
                      </Fragment>
                    ) : (
                      ''
                    )}

                    {/* <ul className="nav w-100 mt-3">
                          <li className="nav-item w-50 font-weight-bold fs-14 text-primary">
                            มีคูปองส่วนลด?
                          </li>
                        </ul>
                        <hr /> */}
                    <ul className="nav w-100 mt-3">
                      <li className="nav-item w-50 font-weight-bold fs-24">
                        {i18n[lang].netTotal}
                      </li>
                      <li className="nav-item w-50 text-right font-weight-bold fs-24">
                        {infoChargeDetail?.pay_with_card
                          ? numberWithCommas(infoChargeDetail.pay_with_card)
                          : '0'}
                      </li>
                    </ul>
                    <button
                      onClick={() => paymentSummary()}
                      className="btn btn-primary w-100 mt-3 fs-18 btn-load"
                      disabled={isWaiting}
                    >
                      {isWaiting && <span className="spinner-border flex-shrink-0" role="status">
                      </span>} {i18n[lang].pay}
                    </button>

                    <p className="text-center mt-5">
                      {i18n[lang].autoRenewalAgreement}{' '}
                      <a href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                        <span className="text-primary">{i18n[lang].termsOfService}</span>
                      </a>
                      <br />
                      {i18n[lang].and}{' '}
                      <a href={REACT_APP_PREDICTIVE + `/privacy-policy`} target="_blank">
                        <span className="text-primary">{i18n[lang].privacyPolicy}</span>
                      </a>{' '}
                      {i18n[lang].agreeToAutomaticRenewal} <br />{' '}
                      {i18n[lang].canBeCancelledOnPackagePage}
                    </p>
                    <p className="text-center mt-5">
                      <i className="mdi mdi-lock-outline label-icon align-middle fs-20"></i>{' '}
                      {i18n[lang].securePayment}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openDiscounthFailed}
        contentLabel="modal"
        style={customStylesModalsm}
        onRequestClose={() => setOpenPopupDiscountFailed(false)}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img
                src="/assets/images/coupon-fail.svg"
                alt=""
                style={{ width: '80px', height: '90px' }}
              />
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label">
                  <strong className="fs-16">{i18n[lang].promotionUnavailable}</strong>
                </label>
                <br />
                <span className="fs-14">{errorMsg}</span>
              </div>
            </div>
            <div className="modal-footer justify-content-center ">
              <button
                onClick={() => setOpenPopupDiscountFailed()}
                type="button"
                className={`btn w-lg btn-warning waves-effect waves-light  `}
              >
                {i18n[lang].close}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* --------------------------------------------------- */}
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupCodeQuota}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupCodeQuota(false)}
      >
        {/* popup 5103: 'Package - current accept consent greater than new quota package'
        popup 5102: 'Package - current permission user consent greater than new quota package' */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src="/assets/images/warning_red.png" alt="" height="70" />

              <div className="mt-3">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].unable_to_proceed}</strong>
                </label>
                <br />

                <p>{i18n[lang].payment_unable_to_process}</p>

                <p className="mt-2 text-center">
                  <b>{i18n[lang].reached_limit}</b>
                  <br />
                  <div className="box-center mt-2">
                    <div className=" text-left">
                      {i18n[lang].account_user_limit}
                      <br />
                      {i18n[lang].consent_records_limit}
                    </div>
                  </div>
                </p>
                <p className="m-0">{i18n[lang].please_select_another_plan}</p>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"
                // data-bs-dismiss="modal"
                onClick={() => handelClickDomain(false)}
              >
                {i18n[lang].select_another_plan}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupGoChangePackage}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupGoChangePackage(false)}
      >
        {/* popup 5106: 'Package - unable to change the current package', // กรณีเปลี่ยน free เป็น free ไม่ยอมให้ทำได้ */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src="/assets/images/warning_red.png" alt="" height="70" />

              <div className="mt-3">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].payment_couldnt_be_processed}</strong>
                </label>
                <br />
                <p className="mt-2 text-center">
                  {i18n[lang].already_applied_for_free_plan}
                  <br />
                  {i18n[lang].please_upgrade_to_continue}
                </p>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"
                // data-bs-dismiss="modal"
                onClick={() => setOpenPopupGoChangePackage(false)}
              >
                {i18n[lang].select_another_plan}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupGeneralSupport}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupGeneralSupport(false)}
      >
        {/* General - please contact support */}

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src="/assets/images/warning_red.png" alt="" height="70" />

              <div className="mt-3">
                <label htmlFor="basiInput" className="form-label">
                  <strong>{i18n[lang].unable_to_proceed}</strong>
                </label>
                <br />

                <p className="mt-2 mb-0">
                  {i18n[lang].unexpected_error}
                  <br />
                  {i18n[lang].contact_support}
                  <br />
                </p>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() =>
                  window.open(
                    'https://meetings.hubspot.com/hataipatt/th-cookieplus-support',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                type="button"
                className={`btn w-lg btn-outline-danger waves-effect waves-light  `}
              >
                {i18n[lang].contactUs}
              </button>
              <button
                type="button"
                className="btn w-lg btn-danger waves-effect waves-light"
                onClick={() => setOpenPopupGeneralSupport(false)}
              >
                {i18n[lang].close}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-Gray text-8">
              Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
            </div>
            <div className="col-sm-6 text-Gray text-8">
              <div className="text-sm-end d-none d-sm-block">
                <a
                  className="text-Gray"
                  href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                  target="_blank"
                >
                  Privacy Policy.
                </a>{' '}
                <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PaymentComponent
