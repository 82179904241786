import React, { Fragment, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../helper/i18n'

import { TableBox, CategoryBox } from './style'
import {
  categoryList,
  createCookieCategory,
  deleteCookieCategory,
  updateCookieCategory,
  updateCookie,
  getCookieList,
  consentById,
  mapCookieCategory,
  switchCookieCategory
} from '../../apis/management/api'
import SecondaryMenuComponent from '../menu/secondary-nav'

import Modal from 'react-modal'
import { getCookie } from '../../helper/helper'
const { REACT_APP_PREDICTIVE } = process.env
const CategoriescookieComponent = props => {
  const id = props.match.params.id
  const history = useHistory()
  const [listDomain, setListDomian] = useState()
  const [domainName, setDomainName] = useState()
  const [deleteId, setDeleteId] = useState()
  const [confirmDelete, setConfirmDelete] = useState()
  const [listCookie, setListCookie] = useState()
  const [categoryIsEnable, setCategoryIsEnable] = useState(false)
  const [openPopupCeate, setOpenPopupCeate] = useState(false)
  const [openPopupCeateCategory, setOpenPopupCeateCategory] = useState(false)
  const [openPopupEdit, setOpenPopupEdit] = useState(false)
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [openPopupScan, setOpenPopupScan] = useState(false)
  const [detailLang, setDetailLang] = useState('th')
  const [cateDisplayName, setCateDisplayName] = useState('')
  const [openPopupRemove, setOpenPopupRemove] = useState(false)
  const [openModalStopCookie, setOpenModalStopCookie] = useState(false)

  const [categoryDescription, setCategoryDescription] = useState()
  const [categoryTitle, setCategoryTitle] = useState()
  const [categoryOption, setCategoryOption] = useState()

  const [roleUser, setRoleUser] = useState('viewer')
  const [categoryName, setCategoryName] = useState()
  const [categoryDesc, setCategoryDesc] = useState()
  const [catID, setCatID] = useState()
  const [catCount, setCatCount] = useState()
  const [slugTitle, setSlugTitle] = useState()

  const [categoryId, setCategoryId] = useState()
  const [cookieDomain, setCookieDomain] = useState()
  const [activeCategoryId, setActiveCategoryId] = useState()
  const [isUpdate, setIsUpdate] = useState(false)

  const [categoryCookieList, setCategoryCookieList] = useState()
  const [listCookieCategory, setListCookieCategory] = useState()
  // let [checkedCateList, setCheckedCateList] = useState({});
  const [checkedItems, setCheckedItems] = useState({})
  const [detailCookie, setDetailCookie] = useState()
  const [inValidateDomainName, setInValidateDomainName] = useState(false)
  const [active, setActive] = useState(false)

  const [initDetail, setInitDetail] = useState({})

  const [defaultDetail, setDefaultDetail] = useState()
  const [lang, setLang] = useState(getCookie('lang'))
  const [isShowLoading, setIsShowLoading] = useState(false)

  useEffect(() => {
    setLang(props.langFn)
    if (listCookie) {
      getConsentById()
    }
  }, [props.langFn])

  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: 'auto',
      minWidth: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }

  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      }
    }
  }
  const columns = [
    {
      name: i18n[lang].cookieName,
      selector: row => row.cookie,
      sortable: true,
      width: '100px'
    },
    {
      name: i18n[lang].domain,
      selector: row => row.domain,
      width: '200px'
    },
    {
      name: i18n[lang].description,
      selector: row => row.description,
      width: '240px'
    },
    {
      name: i18n[lang].cookieDuration,
      selector: row => row.expire
    },

    {
      name: '',
      selector: row => row.options,
      width: '120px'
    }
  ]

  useEffect(() => {
    document.title = 'จัดการประเภทคุกกี้ | CookiePlus'
    getConsentById()
  }, [detailLang])

  const getConsentById = async () => {
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setRoleUser(
        data?.data?.permission_consents[0]?.role ? data.data.permission_consents[0].role : 'viewer'
      )
      getCategoryList(data.data, data.data.permission_consents[0].role)
      setCookieDomain(data.data.domain)
      setCatCount(data.data.cookie_category_manual_count)
    }
  }

  const getCategoryList = async objDetail => {
    setInitDetail(objDetail)
    setIsShowLoading(false)
    const { data, status } = await categoryList(id)
    if (data && data.data && status === 200) {
      setCategoryCookieList(data.data)

      if (!activeCategoryId) {
        setActiveCategoryId(data.data[0].id)
        setCategoryTitle(data.data[0].name[detailLang])
        setCategoryDescription(data.data[0].description[detailLang])
        cookieList(data.data[0].id, objDetail.permission_consents[0].role)
        setCategoryIsEnable(data.data[0].is_enable)
      } else {
        let selectCateDetail = data.data.filter(item => item.id === activeCategoryId)
        setActiveCategoryId(selectCateDetail[0].id)
        if (selectCateDetail[0].display_name) {
          setCateDisplayName(selectCateDetail[0].display_name[detailLang])
        }
        setCategoryTitle(selectCateDetail[0].name[detailLang])
        setCategoryDescription(selectCateDetail[0].description[detailLang])
        setCategoryOption(selectCateDetail[0].source)
        setCategoryId(selectCateDetail[0].id)
        cookieList(selectCateDetail[0].id, roleUser)
        setCatID(selectCateDetail[0].slug)
        setSlugTitle(selectCateDetail[0].slug)
      }
      setCatID(data.data[0].slug)
      setSlugTitle(data.data[0].slug)
    }
  }

  const autoCountId = obj => {
    if (catCount > 0) {
      let runNumber = catCount + 1
      if (runNumber <= 9999) {
        if (runNumber.toString().length === 1) {
          setCatID('CP000' + runNumber)
        } else if (runNumber.toString().length === 2) {
          setCatID('CP00' + runNumber)
        } else if (runNumber.toString().length === 3) {
          setCatID('CP0' + runNumber)
        } else {
          setCatID('CP' + runNumber)
        }
      }
    } else {
      setCatID('CP0001')
    }
  }

  const cookieList = async (cateId, role, option = '') => {
    let obj = {
      consent_id: id,
      cookie_category_id: cateId,
      page: 1,
      limit: 10
    }
    const { data, status } = await getCookieList(obj)
    let res = []
    if (data && data.data && data.data.rows && data.data.rows.length > 0 && status === 200) {
      let _tempData = {}
      if (cateId) {
        if (cateId !== 'none-category') {
          for (let i = 0; i < data.data.rows.length; i++) {
            _tempData = {
              cookie: data.data.rows[i].name,
              domain: data.data.rows[i].domain,
              description: data.data.rows[i].description
                ? data.data.rows[i].description[detailLang]
                : '-', //data.data.rows[i].pageviews[0].pageview,
              expire: data.data.rows[i].expire_date + ' ' + data.data.rows[i].expire_type,
              cookie_category:
                data.data.rows[i].cookie_category && data.data.rows[i].cookie_category.name
                  ? data.data.rows[i].cookie_category.name[detailLang]
                  : '-',
              options:
                role === 'viewer' ? (
                  <Fragment />
                ) : (
                  <Fragment>
                    <span
                      className={`px-2 link-edit`}
                      onClick={() => (
                        setDetailCookie(data.data.rows[i]), popupRemove(data.data.rows[i].id)
                      )}
                    >
                      {i18n[props.langFn].remove}
                    </span>
                  </Fragment>
                )
            }
            res.push(_tempData)
          }
          setListCookie(res)
        } else {
          // click non-cate
          for (let i = 0; i < data.data.rows.length; i++) {
            _tempData = {
              checked: <input type="checkbox" value={data.data.rows[i].id} />,
              id: data.data.rows[i].id,
              name: data.data.rows[i].name,
              domain: data.data.rows[i].domain,
              expire: data.data.rows[i].expire_date + ' ' + data.data.rows[i].expire_type
            }
            res.push(_tempData)
          }
          setListCookieCategory(res)
        }
      }
    } else {
      if (option !== 'addCookie') {
        setListCookie([])
      }
    }
  }

  const createCategory = async () => {
    let obj = {
      consent_id: id,
      name: categoryName,
      description: categoryDesc,
      slug: catID,
      display_name: cateDisplayName
    }
    const { data, status } = await createCookieCategory(obj)
    if (data && status === 200) {
      setOpenPopupCeateCategory(false)
      getCategoryList()
      getConsentById()
    }
  }

  const editCategory = async () => {
    let obj = {
      lang: detailLang,
      name: categoryName,
      description: categoryDesc,
      slug: isUpdate ? slugTitle : catID,
      display_name: cateDisplayName
    }
    const { data, status } = await updateCookieCategory(id, obj, activeCategoryId)
    if (data && status === 200) {
      setOpenPopupCeateCategory(false)
      getCategoryList({ permission_consents: [{ role: roleUser }] })
    }
  }

  const handleSwitchCate = async isSwitch => {
    if (!isSwitch) {
      setOpenModalStopCookie(true)
    } else {
      setCategoryIsEnable(isSwitch)
      await switchCookie()
    }
  }

  const switchCookie = async () => {
    let obj = {
      consent_id: id,
      category_id: activeCategoryId,
      is_enable: !categoryIsEnable
    }
    const { data, status } = await switchCookieCategory(obj)
    if (data && status === 200) {
      setCategoryIsEnable(!categoryIsEnable)
      setOpenModalStopCookie(false)
      getCategoryList({ permission_consents: [{ role: roleUser }] })
    }
  }

  const addCookieCategory = async () => {
    setOpenPopupCeate(true)
    setCheckedItems()
    cookieList('none-category', roleUser, 'addCookie')
  }

  const addCookie = async () => {
    setOpenPopupCeate(false)
    setIsShowLoading(false)
    if (checkedItems) {
      const asArray = Object.entries(checkedItems)
      const filtered = asArray.filter(([key, value]) => value === true)
      const resFilter = {
        cookie_id_list: Object.keys(Object.fromEntries(filtered))
      }
      const { data, status } = await mapCookieCategory(resFilter, activeCategoryId, id)
      if (data && status === 200) {
        setOpenPopupCeate(false)
        cookieList(activeCategoryId, roleUser)
        setListCookieCategory([])
        setIsShowLoading(false)
      } else {
        setOpenPopupCeate(false)
        setIsShowLoading(false)
      }
    }
  }

  const popupDelete = () => {
    setOpenPopupDelete(true)
  }
  const popupRemove = id => {
    setOpenPopupRemove(true)
    setDeleteId(id)
  }
  const removeCookieCategory = async () => {
    let obj = {
      id: detailCookie.id,
      consent_id: detailCookie.consent_id,
      name: detailCookie.name,
      domain: detailCookie.domain,
      expire_date: detailCookie.expire_date, // day , month , year
      expire_type: detailCookie.expire_type, // day , month , year
      category_cookie_id: null,
      description: detailCookie.description ? detailCookie.description[detailLang] : '-',
      lang: detailLang
    }
    const { data, status } = await updateCookie(obj)
    if (data && status === 200) {
      setOpenPopupRemove(false)
      cookieList(activeCategoryId, roleUser)
    }
  }
  const removeCategory = async () => {
    const { data, status } = await deleteCookieCategory(id, categoryId)
    if (data && status === 200) {
      setOpenPopupDelete(false)
      setCategoryOption('')
      await getCategoryList()
    } else {
      await getCategoryList()
    }
  }

  const openModalEdit = () => {
    setDomainName('')
    setOpenPopupCeateCategory(true)
    setIsUpdate(true)
    setCategoryName(categoryTitle)
    setCategoryDesc(categoryDescription)
    categoryCookieList &&
      categoryCookieList.map(item => {
        if (item.name.th === categoryTitle || item.name.en === categoryTitle) {
          setSlugTitle(item.slug)
        }
      })
  }

  const openCategoriesDescription = item => {
    setActiveCategoryId(item.id)
    setCategoryTitle(item.name[detailLang])
    setCategoryDescription(item.description[detailLang])
    setCategoryOption(item.source)
    setCategoryId(item.id)
    cookieList(item.id, roleUser)
    setCatID(item.slug)
    setSlugTitle(item.slug)
    setDefaultDetail(item)
    setCateDisplayName(
      (item?.display_name && item?.display_name[detailLang]) || item.name[detailLang]
    )
    setCategoryIsEnable(item?.is_enable || false)
  }

  const checkedCookie = (id, event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    })
  }

  return (
    <CategoryBox>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body py-2">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="card-title mb-0 py-2"> {i18n[lang].cookieCategory} </h6>
                      </div>
                      <div className="col-7">
                        <span> {i18n[lang].categoryLanguage}</span>
                        <div className="btn-group px-4">
                          <button
                            className="btn btn-light dropdown-toggle"
                            type="button"
                            id="defaultDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="true"
                          >
                            <img
                              src={`/assets/images/${detailLang}-Flag-icon.png`}
                              alt=""
                              className="img-sm-px mx-1"
                            />{' '}
                            {detailLang === 'th' ? 'ภาษาไทย' : 'English'}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            {detailLang === 'th' ? (
                              <li>
                                <a className="dropdown-item" onClick={() => setDetailLang('en')}>
                                  <img
                                    src="/assets/images/EN-Flag-icon.png"
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  English
                                </a>
                              </li>
                            ) : (
                              <li>
                                <a className="dropdown-item" onClick={() => setDetailLang('th')}>
                                  <img
                                    src="/assets/images/TH-Flag-icon.png"
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  ภาษาไทย
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => {
                        setOpenPopupCeateCategory(true)
                        setCategoryName('')
                        setCategoryDesc('')
                        autoCountId(categoryCookieList)
                        setIsUpdate(false)
                      }}
                      className="btn btn-primary btn-label  float-end"
                      disabled={roleUser === 'viewer' ? true : false}
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <i className="mdi mdi-plus-circle-outline label-icon align-middle fs-16 me-2"></i>
                        </div>
                        <div className="flex-grow-1"> {i18n[lang].addCategory}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="accordion" id="default-accordion-example">
                      {categoryCookieList &&
                        categoryCookieList.map((item, i) => {
                          return (
                            <div key={i} className={`accordion-item`}>
                              <h2 className="accordion-header" id={`headingOne_${i}`}>
                                <button
                                  className={`accordion-button transform-90 collapsed ${activeCategoryId === item.id ? '-cate-active' : ''
                                    }`}
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapseOne_${i}`}
                                  aria-expanded="false"
                                  aria-controls={`collapseOne_${i}`}
                                  onClick={() => openCategoriesDescription(item)}
                                >
                                  {item.name[detailLang]}
                                </button>
                              </h2>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="card">
                  <div className="card-body py-2">
                    {categoryOption === 'manual' ? (
                      <div>
                        <a
                          onClick={() => (roleUser !== 'viewer' ? popupDelete() : '')}
                          className={`float-end  mx-2 ${roleUser !== 'viewer' ? 'link-trash' : 'text-Gray role-disble'
                            }`}
                        >
                          <div
                            className="d-flex link-trash"
                            style={{
                              marginTop: '6px',
                              borderLeft: '1px solid #ccc',
                              paddingLeft: '10px'
                            }}
                          >
                            <div className="flex-shrink-0"></div>
                            <div className="flex-grow-1">
                              <i className="mdi mdi-trash-can label-icon align-middle fs-16 me-2"></i>
                              {i18n[lang].delete}
                            </div>
                          </div>
                        </a>
                      </div>
                    ) : roleUser === 'viewer' ? (
                      <Fragment />
                    ) : (
                      <Fragment />
                    )}
                    <div className="d-flex">
                      <h6 className="card-title mb-0 py-2 col-9">
                        {categoryTitle}
                        {categoryCookieList &&
                          categoryCookieList.map((item, i) => {
                            return item.name.th === categoryTitle ||
                              item.name.en === categoryTitle ? (
                              <span key={i} className="text-Gray fs-12 mx-2 font-weight-normal">
                                ({item.slug})
                              </span>
                            ) : (
                              ''
                            )
                          })}
                      </h6>
                      {categoryCookieList && activeCategoryId !== categoryCookieList[0].id && (
                        <div className="col-3 d-flex justify-content-end">
                          <label className="form-check-label p-2" htmlFor="flexSwitchCheckChecked">
                            {categoryIsEnable ? i18n[lang].activate : i18n[lang].deactivate}
                          </label>
                          <div className="form-check form-switch py-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              onChange={e => handleSwitchCate(e.target.checked)}
                              checked={categoryIsEnable}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <h6 className="card-title mb-0 py-2 d-flex">
                      {i18n[lang].displayName} : {cateDisplayName ? cateDisplayName : categoryTitle}
                      {roleUser !== 'viewer' && (
                        <a
                          onClick={() => openModalEdit(true)}
                          className={`float-end  mx-2 link-edit`}
                        >
                          <i className="mdi mdi-lead-pencil label-icon align-middle fs-16 me-2"></i>
                        </a>
                      )}
                    </h6>

                    <hr style={{ margin: '15px -16px 20px -16px', color: '#ccc' }} />
                    <div className="d-flex">
                      <div className="col-11">
                        <p className="f-14 text-dark">{categoryDescription}</p>
                      </div>
                      <div className="col-1">
                        {roleUser !== 'viewer' && (
                          <a
                            onClick={() => openModalEdit(true)}
                            className={`float-end  mx-2 link-edit`}
                          >
                            <i className="mdi mdi-lead-pencil label-icon align-middle fs-16 me-2"></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <div className="row">
                      <div className="col-6">
                        {' '}
                        <div className="row">
                          <div className="col-auto px-0">
                            <h6 className="card-title mb-0 py-2 px-2"> {i18n[lang].cookieList} </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        {' '}
                        <button
                          onClick={() => addCookieCategory()}
                          className="btn btn-primary btn-label  float-end"
                          disabled={roleUser === 'viewer' ? true : false}
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="mdi mdi-plus-circle-outline label-icon align-middle fs-16 me-2"></i>
                            </div>
                            <div className="flex-grow-1"> {i18n[lang].addCookieToCategory}</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body card-table-sm-fix">
                    <div id="customerList">
                      <div className="table-responsive table-card mb-1">
                        <TableBox>
                          {listCookie && listCookie.length > 0 ? (
                            <DataTable
                              pagination={false}
                              className="-fadeIn mt-0"
                              center={true}
                              columns={columns}
                              data={listCookie}
                              customStyles={tableStyles}
                            />
                          ) : (
                            <div className="zero-center">
                              <div className="zero-content w-100">
                                <img
                                  src="../assets/images/Zero-domain.png"
                                  alt=""
                                  className="img-fluid"
                                />
                                <p className="text-Secondary mt-2">
                                  {i18n[lang].addCookiesToCategory} <br />
                                  {i18n[lang].startManagingCookie}
                                </p>
                              </div>
                            </div>
                          )}
                        </TableBox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupCeateCategory}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupCeateCategory(false)}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="zoomInModalLabel">
                  {isUpdate ? i18n[lang].edit : i18n[lang].addCookieCategory}
                </h5>
                <button
                  type="button"
                  className={`btn-close ${isUpdate ? 'cmp-close-edit-popup-category' : 'cmp-close-add-popup-category'
                    }`}
                  onClick={() => setOpenPopupCeateCategory(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="col-xxl-12 col-md-12 mb-4">
                  <label htmlFor="basiInput" className="form-label">
                    <b> {i18n[lang].categoryName}</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={categoryName}
                    onChange={e => setCategoryName(e.target.value)}
                    disabled={!isUpdate ? false : categoryOption === 'manual' ? false : true}
                  />
                </div>
                <div className={`col-xxl-12 col-md-12 mb-4 `}>
                  <label htmlFor="basiInput" className="form-label">
                    <b> {i18n[lang].displayName}</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={cateDisplayName}
                    onChange={e => setCateDisplayName(e.target.value)}
                  />
                </div>
                <div className={`col-xxl-12 col-md-12 mb-4 `}>
                  <label htmlFor="basiInput" className="form-label">
                    <b> {i18n[lang].categoryId}</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={isUpdate ? slugTitle : catID}
                    onChange={e =>
                      e.target.value.length <= 20
                        ? isUpdate
                          ? setSlugTitle(e.target.value)
                          : setCatID(e.target.value)
                        : ''
                    }
                  />
                </div>
                <div className="col-xxl-12 col-md-12">
                  <label htmlFor="basiInput" className="form-label">
                    <b> {i18n[lang].categoryDescription}</b>
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea5"
                    rows="3"
                    value={categoryDesc}
                    onChange={e => setCategoryDesc(e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-outline-primary waves-effect waves-light w-25 ${isUpdate ? 'cmp-close-popup-edit-category' : 'cmp-close-popup-add-category'
                    }`}
                  onClick={() => setOpenPopupCeateCategory(false)}
                >
                  {i18n[lang].cancel}
                </button>
                <button
                  onClick={() => ((isUpdate ? editCategory() : createCategory()), setIsShowLoading(true))}
                  type="button"
                  className={`btn btn-primary w-25 btn-load ${isUpdate ? 'cmp-edit-category' : 'cmp-add-category'
                    }`}
                  disabled={isShowLoading ? isShowLoading : categoryName && categoryDesc && slugTitle ? false : true}
                >
                  <span className="d-flex align-items-center justify-content-center">
                    {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                    </span> <span className="flex-grow-1 ms-2 mr-2">
                        {i18n[lang].save}
                      </span> </Fragment> : i18n[lang].save
                    }
                  </span>

                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupCeate}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupCeate(false)}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="zoomInModalLabel">
                  {i18n[lang].addCookieToCategory}
                </h5>
                <button
                  type="button"
                  className="btn-close cmp-close-popup-cookie-category"
                  onClick={() => setOpenPopupCeate(false)}
                ></button>
              </div>
              <div className="modal-body">
                <table className="table">
                  <tbody>
                    {listCookieCategory && listCookieCategory.length > 0 ? (
                      listCookieCategory.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={item.id}
                                  onChange={e => checkedCookie(item.id, e)}
                                />
                                <label className="form-check-label" htmlFor="formCheck6">
                                  {' '}
                                  <span className="px-2">{item.name}</span>
                                </label>
                              </div>
                            </td>
                            <td>{item.domain}</td>
                            <td>{item.expire}</td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td> {i18n[lang].noCookiesInCategory}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect waves-light w-25 cmp-cancel-cookie-category"
                  // data-bs-dismiss="modal"

                  onClick={() => setOpenPopupCeate(false)}
                >
                  {i18n[lang].cancel}
                </button>
                <button
                  onClick={() => (addCookie(), setIsShowLoading(true))}
                  disabled={listCookieCategory && listCookieCategory.length > 0 ? false : true}
                  type="button"
                  className="btn btn-primary w-25 btn-load cmp-save-cookie-category"
                >

                  <span className="d-flex align-items-center justify-content-center">
                    {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                    </span> <span className="flex-grow-1 ms-2 mr-2">
                        {i18n[lang].save}
                      </span> </Fragment> : i18n[lang].save
                    }
                  </span>


                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupDelete}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupDelete(false)}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#ed3451,secondary:#c72e46"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>

                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-16"> {i18n[lang].confirmDeleteCategory}</strong>
                  </label>
                  <br />
                  <span>
                    {i18n[lang].deleteCategoryNote}
                    <br />
                    {i18n[lang].deleteCategoryNoteManage}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  onClick={() => removeCategory()}
                  type="button"
                  className={`btn w-lg btn-outline-danger waves-effect waves-light  `}
                >
                  {i18n[lang].confirmRemoveCategory}
                </button>
                <button
                  type="button"
                  className="btn w-lg btn-danger waves-effect waves-light"
                  onClick={() => setOpenPopupDelete(false)}
                >
                  {i18n[lang].cancel}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupRemove}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupRemove(false)}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/rwotyanb.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#121331,secondary:#1298be"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>
                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-16"> {i18n[lang].confirmRemoveCookie}</strong>
                  </label>
                  <br />
                  <span>
                    {i18n[lang].removeCookieNote} <br />
                    {i18n[lang].removeCookieNoteManage}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  onClick={() => removeCookieCategory()}
                  type="button"
                  className={`btn w-lg btn-outline-primary waves-effect waves-light  `}
                >
                  {i18n[lang].confirmRemoval}
                </button>
                <button
                  type="button"
                  className="btn w-lg btn-primary waves-effect waves-light"
                  onClick={() => setOpenPopupRemove(false)}
                >
                  {i18n[lang].cancel}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openModalStopCookie}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenModalStopCookie(false)}
        >
          <div className="modal-dialog">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <img src="/assets/images/warning.png" alt="predictive" />
                <div className="mt-3">
                  <label htmlFor="basiInput" className="form-label mb-3">
                    <strong className="fs-20"> {i18n[lang].confirmDeactivate}</strong>
                  </label>
                  <br />
                  <span className="fs-14"> {i18n[lang].confirmDeactivateCategory}</span>
                </div>
              </div>
              <div className="modal-footer justify-content-center ">
                <button
                  type="button"
                  className={`btn w-sm btn-outline-warning waves-effect waves-light  `}
                  onClick={() => setOpenModalStopCookie(false)}
                >
                  {i18n[lang].cancel}
                </button>
                <button
                  type="button"
                  className={`btn w-lg btn-warning waves-effect waves-light  `}
                  onClick={() => switchCookie()}
                >
                  {i18n[lang].confirmDeactivate}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </CategoryBox>
  )
}

export default CategoriescookieComponent
