import styled from 'styled-components'

const SwitchBox = styled.div`
  .form-switch-lg {
    padding-left: 2.5em;
    min-height: 28px;
    line-height: 28px;
  }
  .form-check-input {
    background-color: #e5f9ff;
  }
  .form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
  }

  .form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23afd1e5'/%3e%3c/svg%3e") !important;
  }
  .form-check-input:checked {
    background-color: #1298be;
    border-color: #1298be;
  }
`

const RadioBox = styled.div`
  .form-check .form-check-input {
    float: left;
    margin-left: 0;
  }
`
const TooltipBox = styled.div`
  .tooltips {
    display: inline-block;
  }

  .tooltips .tooltiptexts {
    // visibility: hidden;
    display: none;
    width: 100%;
    top: unset;
    left: 20%;
    margin-left: -60px;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 1;
  }

  .tooltips:hover .tooltiptexts {
    // visibility: visible;
    display: block;
  }

  .tooltiptexts {
    .box_tooltips {
      background: #000;
      position: static;
      width: 260px;
      border-radius: 5px;
      text-align: center;
    }
  }

  .float-left-mb0 {
    margin-bottom: 0;
    float: left;
  }

`;
export { SwitchBox, RadioBox ,TooltipBox}
