import React, { Fragment, useState, useEffect } from 'react'
import {
  consentById,
  getConfigBanner,
  publicConsentList,
  publishConsent,
  publicConsentListById,
  statusFile,
  packageDetailAll
} from '../../apis/management/api'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import DataTable from 'react-data-table-component'
import Modal from 'react-modal'
import styled from 'styled-components'
import { MdLock, MdRocketLaunch } from 'react-icons/md'
import { i18n } from '../../helper/i18n'
import {
  TableBox,
  PreviewBoxDefault,
  PreviewBoxPopup,
  PreviewBoxBottomLeft,
  PreviewBoxTopLeft,
  PreviewBoxBottomRight,
  PreviewBoxTopRight,
  PreviewBoxFullTop
} from './style'
import { getCookie } from '../../helper/helper'

const { REACT_APP_CDN_SERVER, REACT_APP_PREDICTIVE } = process.env
const PublishCookieComponent = props => {
  const id = props.match.params.id
  const [listScriptCookie, setListScriptCookie] = useState()
  const [openPopupPreview, setOpenPopupPreview] = useState(false)
  const [openPopupReconsentPreview, setOpenPopupReconsentPreview] = useState(false)
  const [cookieDomain, setCookieDomain] = useState()
  const [openPopupNewVersion, setOpenPopupNewVersion] = useState(false)
  const [openPopupPublishSuccess, setOpenPopupPublishSuccess] = useState(false)
  const [openPopupPublishFailed, setOpenPopupPublishFailed] = useState(false)

  const [colorBackground, setColorBackground] = useState('')
  const [colorFont, setColorFont] = useState('')
  const [colorDesc, setColorDesc] = useState('')
  const [fontSize, setFontSize] = useState('M')
  const [titleFontSize, setTitleFontSize] = useState('M')
  const [showLogo, setShowLogo] = useState()

  const [rangeOpacity, setRangeOpacity] = useState(100)
  let [showTitle, setShowTitle] = useState(true)
  let [titleTH, setTitleTH] = useState('')
  let [titleEN, setTitleEN] = useState('')
  let [descTH, setDescTH] = useState('')
  let [descEN, setDescEN] = useState('')

  let [acceptTH, setAcceptTH] = useState('')
  let [acceptEN, setAcceptEN] = useState('')

  let [declineTH, setDeclineTH] = useState('')
  let [declineEN, setDeclineEN] = useState('')

  let [customPolicy, setCustomPolicy] = useState(true)
  let [textPolicyTH, setTextPolicyTH] = useState('')
  let [textPolicyEN, setTextPolicyEN] = useState('')
  let [position, setPosition] = useState()

  let [logoBanner, setLogoBanner] = useState()

  let [showReject, setShowReject] = useState(true)
  let [showCloseBtn, setShowCloseBtn] = useState(true)

  let [checkedReConsent, setCheckedReConsent] = useState(false)
  let [checkedBlockCookie, setCheckedBlockCookie] = useState(false)

  let [bannerStatus, setBannerStatus] = useState(false)
  let [bannerPublishStatus, setBannerPublishStatus] = useState(false)
  let [bannerVersion, setBannerVersion] = useState('-')
  let [bannerPublishDate, setBannerPublishDate] = useState()
  let [bannerCreateBy, setBannerCreateBy] = useState('-')
  let [bannerNewVersion, setBannerNewVersion] = useState(1)
  const [acceptButtonBG, setAcceptButtonBG] = useState('')
  const [acceptTextColor, setAcceptTextColor] = useState('')

  const [declineButtonBorder, setDeclineButtonBorder] = useState('')
  const [declineTextColor, setDeclineTextColor] = useState('')

  const [linkTextColor, setLinkTextColor] = useState('')
  const [settingTextColor, setSettingTextColor] = useState('#1298BE')
  const [settingButtonBorder, setSettingButtonBorder] = useState('#1298BE')
  const [settingCustomButton, setSettingCustomButton] = useState('')

  const [initDetail, setInitDetail] = useState({})
  const [packageDetail, setPackageDetail] = useState(0)
  const [isClickPubilsh, setIsClickPubilsh] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))

  const [isShowLoading, setIsShowLoading] = useState(false)

  useEffect(() => {
    setLang(props.langFn)
    if (listScriptCookie) {
      setListScriptCookie([])
      getConsentPublishList()
    }
  }, [props.langFn])

  let scriptString = `<!-- CookiePlus Consent Notice start for ${cookieDomain} -->
<script src="${REACT_APP_CDN_SERVER}/cmp.js" charset="UTF-8"></script>
<script src="${REACT_APP_CDN_SERVER}/${id}/init.js" charset="UTF-8"></script>
<!-- CookiePlus Consent Notice end for ${cookieDomain} -->`

  const customStyles = {
    content: {
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '1055',
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      outline: '0',
      padding: '0'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const customStylesModalsm = {
    content: {
      background: 'none',
      border: 0
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const detailLang = 'th'

  useEffect(() => {
    document.title = 'เผยแพร่แบนเนอร์ | CookiePlus'
    getConfig()
    getConsentById()
    getConsentPublishList()
    getStatusFile()
    getPackageDetailAll()
  }, [])

  const getStatusFile = async () => {
    const { data, status } = await statusFile(id)
    if (data && status === 200) {
      setBannerStatus(data.status.active)
    }
  }
  const getPackageDetailAll = async () => {
    let obj = {
      consent_id: id,
      payment_detail: false
    }
    const { data, status } = await packageDetailAll(obj)
    if (status === 200) {
      setPackageDetail(data.data.packageDetail)
    }
  }
  const getConsentPublishList = async () => {
    const { data, status } = await publicConsentList(id)
    if (data && status === 200) {
      setBannerVersion(data?.data[0]?.version || '-')
      setBannerPublishDate(data?.data[0]?.created_at || '')
      setBannerCreateBy(data?.data[0]?.user.email || '-')
      consentList(data.data)
      setBannerNewVersion(data.data[0].version)
    }
  }

  const getReConsentPublishList = async (consent_id, publish_id) => {
    let random = Math.floor(100000 + Math.random() * 900000)
    setOpenPopupReconsentPreview(true)
    const { data, status } = await publicConsentListById(consent_id, publish_id)
    if (data && status === 200) {
      let { decorate, policy, showButton, texting, logo, position } =
        data.data.config_script.configBanner

      setColorBackground(decorate.backgroundColor)
      setColorFont(decorate.titleColor)
      setColorDesc(decorate.textColor)
      setFontSize(decorate.fontSize)
      setTitleFontSize(decorate?.titleFontSize ? decorate.titleFontSize : 'M')
      setRangeOpacity(parseInt(decorate.backgroundOpacity))
      setShowTitle(texting.showTitle)
      setTitleTH(texting.title.th)
      setTitleEN(texting.title.en)
      setDescTH(texting.text.th)
      setDescEN(texting.text.en)
      setCustomPolicy(policy.policyDefault)
      setTextPolicyTH(policy.textPolicy.th)
      setTextPolicyEN(policy.textPolicy.en)
      let url = policy.linkPolicy
      if (!url.match(/^https?:\/\//i)) {
        url = 'https://' + url
      }
      setShowReject(showButton.showRejectAll)
      setShowCloseBtn(showButton.showCloseBanner)
      setShowLogo(logo.showLogo)
      if (logo?.logoUrl) {
        setLogoBanner(data.data.config_script.configBanner.logo.logoUrl + `?cache=${random}`)
      } else {
        setLogoBanner('../assets/images/Presented.svg')
      }
      setPosition(position ? position : 'default')

      setAcceptTH(
        decorate?.buttonAccept?.text?.th ? decorate.buttonAccept.text.th : 'ยินยอมทั้งหมด'
      )
      setAcceptEN(decorate?.buttonAccept?.text?.en ? decorate.buttonAccept.text.en : 'Accept All')

      setDeclineTH(
        decorate?.buttonDecline?.text?.th ? decorate.buttonDecline.text.th : 'ปฏิเสธทั้งหมด'
      )
      setDeclineEN(
        decorate?.buttonDecline?.text?.en ? decorate.buttonDecline.text.en : 'Decline all'
      )

      setSettingCustomButton(
        decorate?.buttonSetting?.borderDefault ? decorate.buttonSetting.borderDefault : false
      )
      setSettingButtonBorder(
        decorate?.buttonSetting?.borderColor ? decorate.buttonSetting.borderColor : '#1298be'
      )

      setAcceptButtonBG(
        decorate?.buttonAccept?.backgroundColor ? decorate.buttonAccept.backgroundColor : '#1298be'
      )
      setAcceptTextColor(
        decorate?.buttonAccept?.textColor ? decorate.buttonAccept.textColor : '#ffffff'
      )
      setDeclineButtonBorder(
        decorate?.buttonDecline?.borderColor ? decorate.buttonDecline.borderColor : '#1298be'
      )
      setDeclineTextColor(
        decorate?.buttonDecline?.textColor ? decorate.buttonDecline.textColor : '#1298be'
      )
      setLinkTextColor(policy?.buttonLink?.textColor ? policy.buttonLink.textColor : '#1298be')
      setSettingTextColor(
        decorate?.buttonSetting?.textColor ? decorate.buttonSetting.textColor : '#1298be'
      )
    }
  }
  const consentList = data => {
    let res = []
    let _tempData = {}
    for (let i = 0; i < data.length; i++) {
      _tempData = {
        version: data[i].version,
        scriptBlock: data[i].block_cookie ? i18n[props.langFn].yes : i18n[props.langFn].no,
        reConsent: data[i].reconsent ? i18n[props.langFn].yes : i18n[props.langFn].no,
        publishBy: data[i].user.email,
        publishDate: convertDate(data[i].created_at),
        options: (
          <Fragment>
            <span
              className="btn btn-outline-primary waves-effect waves-light float-end"
              onClick={() => getReConsentPublishList(id, data[i].id)}
            >
              {i18n[props.langFn].display}
            </span>
          </Fragment>
        )
      }
      res.push(_tempData)
    }
    setListScriptCookie(res)
  }

  const getConsentById = async () => {
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setInitDetail(data.data)
      setCookieDomain(data.data.domain)
      if (data.data.status === 'publish') {
        setBannerPublishStatus(true)
      }
    }
  }

  const coppyScript = () => {
    var copyText = document.getElementById('textScript')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(copyText.value)
  }
  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    }
  }

  const columns = [
    {
      name: i18n[lang].bannerVersion,
      selector: row => row.version,
      width: '250px',
      sortable: true
    },
    // {
    //   name: 'บล็อกสคริปต์อัตโนมัติ',
    //   selector: row => row.scriptBlock
    // },
    {
      name: i18n[lang].requestForNewConsent,
      selector: row => row.reConsent
    },
    {
      name: i18n[lang].publishedBy,
      selector: row => row.publishBy
    },
    {
      name: i18n[lang].publicationDate,
      selector: row => row.publishDate
    },
    {
      name: '',
      selector: row => row.options,
      width: '140px'
    }
  ]
  const popupPreview = () => {
    getConfig()
    setOpenPopupPreview(true)
  }

  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }

  const getConfig = async () => {
    const { data, status } = await getConfigBanner(id)
    if (data && data.data && status === 200) {
      let random = Math.floor(100000 + Math.random() * 900000)
      let { decorate, policy, showButton, texting, logo, position } = data.data

      setColorBackground(decorate.backgroundColor)
      setColorFont(decorate.titleColor)
      setColorDesc(decorate.textColor)
      setFontSize(decorate.fontSize)
      setTitleFontSize(decorate?.titleFontSize ? decorate.titleFontSize : 'M')
      setRangeOpacity(parseInt(decorate.backgroundOpacity))
      setShowTitle(texting.showTitle)
      setTitleTH(texting.title.th)
      setTitleEN(texting.title.en)
      setDescTH(texting.text.th)
      setDescEN(texting.text.en)
      setCustomPolicy(policy.policyDefault)
      setTextPolicyTH(policy.textPolicy.th)
      setTextPolicyEN(policy.textPolicy.en)
      let url = policy.linkPolicy
      if (!url.match(/^https?:\/\//i)) {
        url = 'https://' + url
      }
      setShowReject(showButton.showRejectAll)
      setShowCloseBtn(showButton.showCloseBanner)
      setShowLogo(logo.showLogo)
      if (logo?.logoUrl) {
        setLogoBanner(logo?.logoUrl ? logo.logoUrl + `?cache=${random}` : '')
      } else {
        setLogoBanner('../assets/images/Presented.svg')
      }

      setPosition(position ? position : 'default')

      setAcceptTH(
        decorate?.buttonAccept?.text?.th ? decorate.buttonAccept.text.th : 'ยินยอมทั้งหมด'
      )
      setAcceptEN(decorate?.buttonAccept?.text?.en ? decorate.buttonAccept.text.en : 'Accept All')

      setDeclineTH(
        decorate?.buttonDecline?.text?.th ? decorate.buttonDecline.text.th : 'ปฏิเสธทั้งหมด'
      )
      setDeclineEN(
        decorate?.buttonDecline?.text?.en ? decorate.buttonDecline.text.en : 'Decline all'
      )

      setSettingCustomButton(
        decorate?.buttonSetting?.borderDefault ? decorate.buttonSetting.borderDefault : false
      )
      setSettingButtonBorder(
        decorate?.buttonSetting?.borderColor ? decorate.buttonSetting.borderColor : '#1298be'
      )

      setAcceptButtonBG(
        decorate?.buttonAccept?.backgroundColor ? decorate.buttonAccept.backgroundColor : '#1298be'
      )
      setAcceptTextColor(
        decorate?.buttonAccept?.textColor ? decorate.buttonAccept.textColor : '#ffffff'
      )
      setDeclineButtonBorder(
        decorate?.buttonDecline?.borderColor ? decorate.buttonDecline.borderColor : '#1298be'
      )
      setDeclineTextColor(
        decorate?.buttonDecline?.textColor ? decorate.buttonDecline.textColor : '#1298be'
      )
      setLinkTextColor(policy?.buttonLink?.textColor ? policy.buttonLink.textColor : '#1298be')
      setSettingTextColor(
        decorate?.buttonSetting?.textColor ? decorate.buttonSetting.textColor : '#1298be'
      )
    }
  }
  const BannerBox = styled.div`
  .link-setting {
    color: ${settingTextColor}!important;
    border: ${settingCustomButton === true ? '1px' : '0px'} solid ${settingButtonBorder}!important;
    background-color: transparent !important;

  }
  .btn-link-policy {
    color: ${linkTextColor}!important;
  }
  .btn-accept {
    background-color: ${acceptButtonBG}!important;
    color: ${acceptTextColor}!important;
    border: 1px solid ${acceptButtonBG}!important;
  }
  .btn-reject {
    background-color: transparent !important;
    color: ${declineTextColor}!important;
    border: 1px solid ${declineButtonBorder}!important;
  }
    .bg-modal {
      background: ${colorBackground}
    }
    .color-header {
      color: ${colorFont}!important;
    }
    .color-content {
      color: ${colorDesc}!important;
    }
    .background-opacity {
      background-size: calc(${rangeOpacity} / 100))
    }
    .title-font-size-text {
      font-size: ${titleFontSize ? titleFontSize + 'px' : '16px'};
    }
    .font-size-text {
      font-size: ${fontSize ? fontSize + 'px' : '16px'};
    }

    .show-button {
      display: ${showCloseBtn === true ? '' : 'none'};
    }
    .show-button-reject {
      display: ${showReject === true ? '' : 'none'};
    }

    .position-default {
      max-width: 1014px !important;
      margin: 1.75rem auto !important;
      top: 40px !important ;
    }
    .position-bottom {
      max-width: 100% !important;
      margin: 0 !important;
      top: 85px !important ;
    }
    .position-popup {
      width: 500px;
      position: absolute;
      bottom: 0%;
      max-width: 100%;
    }
  `
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }
  const publishBanner = async () => {
    setIsClickPubilsh(true)
    let obj = {
      consent_id: id,
      reconsent: checkedReConsent,
      block_cookie: checkedBlockCookie
    }
    const { data, status } = await publishConsent(obj)
    if (data && data.data && status === 200) {
      setOpenPopupNewVersion(false)
      setOpenPopupPublishSuccess(true)
      getConsentPublishList()
      getConsentById()
      setCheckedBlockCookie(false)
      getStatusFile()
      setIsClickPubilsh(false)
      setIsShowLoading(false)
    } else {
      setOpenPopupPublishFailed(true)
      setIsClickPubilsh(false)
      setIsShowLoading(false)
    }
  }

  const ExampleBannerDefault = () => {
    return (
      <BannerBox>
        <PreviewBoxDefault>
          <div className="cookie-full-preview">
            <div className={`modal-dialog modal-lg modal-dialog-centered position-default`}>
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div className="modal-body text-left">
                  <div className="row">
                    <div
                      className={`color-content font-size-text break-word ${
                        showReject === true ? (showReject ? 'col-5' : 'col-7') : 'col-7'
                      }`}
                    >
                      {detailLang === 'th' ? descTH : descEN}
                      <a
                        className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        {customPolicy ? (
                          <span>{i18n[lang].cookiePolicy}</span>
                        ) : (
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                        )}
                      </a>
                    </div>
                    <div
                      className={`p-5per ${
                        showReject === true
                          ? showReject === true
                            ? 'col-7 text-center'
                            : 'col-5 text-center'
                          : 'col-5 text-right'
                      } `}
                    >
                      <button
                        type="button"
                        className={`btn link-setting btn-outline-primary waves-effect waves-light mx-2 font-size-text`}
                      >
                        {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                      </button>
                      {showReject && (
                        <button
                          type="button"
                          className={`btn btn-reject btn-outline-primary waves-effect waves-light mx-2 font-size-text`}
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      )}
                      <button
                        type="button"
                        className={`btn btn-accept btn-primary mx-2 font-size-text`}
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>
                {showLogo === true ? (
                  <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src={logoBanner} alt="" height="14" />
                  </div>
                ) : packageDetail.package_id === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </PreviewBoxDefault>
      </BannerBox>
    )
  }
  const ExampleBannerBottom = () => {
    return (
      <BannerBox>
        <div className="cookie-full-preview">
          <div className={`modal-dialog modal-lg modal-dialog-centered position-bottom`}>
            <div className="modal-content bg-modal background-opacity">
              <div className="modal-header">
                <h5 className="modal-title color-header title-font-size-text">
                  {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                </h5>
                <button type="button" className="btn-close show-button"></button>
              </div>
              <div className="modal-body text-left pt-0">
                <div className="row d-flex align-items-center">
                  <div
                    className={`color-content font-size-text break-word ${
                      showReject === true ? (showReject ? 'col-5' : 'col-7') : 'col-7'
                    }`}
                  >
                    {detailLang === 'th' ? descTH : descEN}
                    <a className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}>
                      {customPolicy ? (
                        <span>{i18n[lang].cookiePolicy}</span>
                      ) : (
                        <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                      )}
                    </a>
                  </div>
                  <div
                    className={`p-5per ${
                      showReject === true
                        ? showReject === true
                          ? 'col-7 text-right'
                          : 'col-5 text-center'
                        : 'col-5 text-right'
                    } `}
                  >
                    <button
                      type="button"
                      className={`btn link-setting btn-outline-primary waves-effect waves-light mx-2 font-size-text`}
                    >
                      {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                    </button>
                    {showReject && (
                      <button
                        type="button"
                        className={`btn btn-reject btn-outline-primary waves-effect waves-light mx-2 font-size-text`}
                      >
                        {detailLang === 'th' ? declineTH : declineEN}
                      </button>
                    )}
                    <button
                      type="button"
                      className={`btn btn-accept btn-primary mx-2 font-size-text`}
                    >
                      {detailLang === 'th' ? acceptTH : acceptEN}
                    </button>
                  </div>
                </div>
              </div>
              {showLogo === true ? (
                <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                  <img src={logoBanner} alt="" height="14" />
                </div>
              ) : packageDetail.package_id === 1 ? (
                <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                  <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </BannerBox>
    )
  }
  const ExampleBannerPopup = () => {
    return (
      <BannerBox>
        <PreviewBoxPopup>
          <div className="backdrop">
            <div className="cookie-full-preview-popup">
              <div className={`modal-dialog modal-lg modal-dialog-centered position-popup`}>
                <div className="modal-content bg-modal background-opacity">
                  <div className="modal-header">
                    <h5 className="modal-title color-header title-font-size-text">
                      {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                    </h5>
                    <button type="button" className="btn-close show-button"></button>
                  </div>
                  <div className="modal-body text-left">
                    <div className="row">
                      <div className={`color-content font-size-text col-12 break-word`}>
                        {detailLang === 'th' ? descTH : descEN}
                        <a
                          className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                        >
                          {customPolicy ? (
                            <span>{i18n[lang].cookiePolicy}</span>
                          ) : (
                            <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                          )}
                        </a>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className={` ${showReject === true ? 'col-12 px-1' : 'col-6 px-1'}`}>
                        {' '}
                        <button
                          type="button"
                          className={`btn link-setting btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2 w-100`}
                        >
                          {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                        </button>
                      </div>
                      {showReject && (
                        <div className="col-6 px-1">
                          <button
                            type="button"
                            className={`w-100 btn btn-reject btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2`}
                          >
                            {detailLang === 'th' ? declineTH : declineEN}
                          </button>
                        </div>
                      )}
                      <div className="col-6 px-1">
                        {' '}
                        <button
                          type="button"
                          className={`w-100 btn btn-accept btn-primary mx-1 font-size-text mt-2`}
                        >
                          {detailLang === 'th' ? acceptTH : acceptEN}
                        </button>
                      </div>
                    </div>
                  </div>

                  {showLogo === true ? (
                    <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img src={logoBanner} alt="" height="14" />
                    </div>
                  ) : packageDetail.package_id === 1 ? (
                    <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                      <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </PreviewBoxPopup>
      </BannerBox>
    )
  }
  const ExampleBannerBottomLeft = () => {
    return (
      <BannerBox>
        <PreviewBoxBottomLeft>
          <div className="cookie-Bottom-Left">
            <div className={`modal-dialog modal-lg modal-dialog-centered position-BottomLeft`}>
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div className="modal-body text-left">
                  <div className="row">
                    <div className={`color-content font-size-text col-12 break-word`}>
                      {detailLang === 'th' ? descTH : descEN}
                      <a
                        className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        {customPolicy ? (
                          <span>{i18n[lang].cookiePolicy}</span>
                        ) : (
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={` col-12 px-1`}>
                      {' '}
                      <button
                        type="button"
                        className={`btn link-setting btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2 w-100`}
                      >
                        {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                      </button>
                    </div>
                    {showReject && (
                      <div className="col-12 px-1">
                        <button
                          type="button"
                          className={`w-100 btn btn-reject btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2`}
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className="col-12 px-1">
                      {' '}
                      <button
                        type="button"
                        className={`w-100 btn btn-accept btn-primary mx-1 font-size-text mt-2`}
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo === true ? (
                  <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src={logoBanner} alt="" height="14" />
                  </div>
                ) : packageDetail.package_id === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </PreviewBoxBottomLeft>
      </BannerBox>
    )
  }
  const ExampleBannerTopLeft = () => {
    return (
      <BannerBox>
        <PreviewBoxTopLeft>
          <div className="cookie-Top-Left">
            <div className={`modal-dialog modal-lg modal-dialog-centered position-TopLeft`}>
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div className="modal-body text-left">
                  <div className="row">
                    <div className={`color-content font-size-text col-12 break-word`}>
                      {detailLang === 'th' ? descTH : descEN}
                      <a
                        className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        {customPolicy ? (
                          <span>{i18n[lang].cookiePolicy}</span>
                        ) : (
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={` col-12 px-1`}>
                      {' '}
                      <button
                        type="button"
                        className={`btn link-setting btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2 w-100`}
                      >
                        {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                      </button>
                    </div>
                    {showReject && (
                      <div className="col-12 px-1">
                        <button
                          type="button"
                          className={`w-100 btn btn-reject btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2`}
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className="col-12 px-1">
                      {' '}
                      <button
                        type="button"
                        className={`w-100 btn btn-accept btn-primary mx-1 font-size-text mt-2`}
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo === true ? (
                  <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src={logoBanner} alt="" height="14" />
                  </div>
                ) : packageDetail.package_id === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </PreviewBoxTopLeft>
      </BannerBox>
    )
  }
  const ExampleBannerBottomRight = () => {
    return (
      <BannerBox>
        <PreviewBoxBottomRight>
          <div className="cookie-Bottom-Right">
            <div className={`modal-dialog modal-lg modal-dialog-centered position-BottomRight`}>
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div className="modal-body text-left">
                  <div className="row">
                    <div className={`color-content font-size-text col-12 break-word`}>
                      {detailLang === 'th' ? descTH : descEN}
                      <a
                        className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        {customPolicy ? (
                          <span>{i18n[lang].cookiePolicy}</span>
                        ) : (
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={` col-12 px-1`}>
                      {' '}
                      <button
                        type="button"
                        className={`btn link-setting btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2 w-100`}
                      >
                        {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                      </button>
                    </div>
                    {showReject && (
                      <div className="col-12 px-1">
                        <button
                          type="button"
                          className={`w-100 btn btn-reject btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2`}
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className="col-12 px-1">
                      {' '}
                      <button
                        type="button"
                        className={`w-100 btn btn-accept btn-primary mx-1 font-size-text mt-2`}
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo === true ? (
                  <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src={logoBanner} alt="" height="14" />
                  </div>
                ) : packageDetail.package_id === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </PreviewBoxBottomRight>
      </BannerBox>
    )
  }
  const ExampleBannerTopRight = () => {
    return (
      <BannerBox>
        <PreviewBoxTopRight>
          <div className="cookie-Top-Right">
            <div className={`modal-dialog modal-lg modal-dialog-centered position-TopRight`}>
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div className="modal-body text-left">
                  <div className="row">
                    <div className={`color-content font-size-text col-12 break-word`}>
                      {detailLang === 'th' ? descTH : descEN}
                      <a
                        className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        {customPolicy ? (
                          <span>{i18n[lang].cookiePolicy}</span>
                        ) : (
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className={` col-12 px-1`}>
                      {' '}
                      <button
                        type="button"
                        className={`btn link-setting btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2 w-100`}
                      >
                        {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                      </button>
                    </div>
                    {showReject && (
                      <div className="col-12 px-1">
                        <button
                          type="button"
                          className={`w-100 btn btn-reject btn-outline-primary waves-effect waves-light mx-1 font-size-text mt-2`}
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      </div>
                    )}
                    <div className="col-12 px-1">
                      {' '}
                      <button
                        type="button"
                        className={`w-100 btn btn-accept btn-primary mx-1 font-size-text mt-2`}
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>

                {showLogo === true ? (
                  <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src={logoBanner} alt="" height="14" />
                  </div>
                ) : packageDetail.package_id === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </PreviewBoxTopRight>
      </BannerBox>
    )
  }
  const ExampleBannerFullTop = () => {
    return (
      <BannerBox>
        <PreviewBoxFullTop>
          <div className="cookie-full-top">
            <div className={`modal-dialog modal-lg modal-dialog-centered position-bottom`}>
              <div className="modal-content bg-modal background-opacity">
                <div className="modal-header">
                  <h5 className="modal-title color-header title-font-size-text">
                    {showTitle ? (detailLang === 'th' ? titleTH : titleEN) : ''}
                  </h5>
                  <button type="button" className="btn-close show-button"></button>
                </div>
                <div className="modal-body text-left pt-0">
                  <div className="row d-flex align-items-center">
                    <div
                      className={`color-content font-size-text break-word ${
                        showReject === true ? (showReject ? 'col-5' : 'col-7') : 'col-7'
                      }`}
                    >
                      {detailLang === 'th' ? descTH : descEN}
                      <a
                        className={`btn-link-policy text-decoration-underline mx-2 font-size-text`}
                      >
                        {customPolicy ? (
                          <span>{i18n[lang].cookiePolicy}</span>
                        ) : (
                          <span>{detailLang === 'th' ? textPolicyTH : textPolicyEN}</span>
                        )}
                      </a>
                    </div>
                    <div
                      className={`p-5per ${
                        showReject === true
                          ? showReject === true
                            ? 'col-7 text-right'
                            : 'col-5 text-center'
                          : 'col-5 text-right'
                      } `}
                    >
                      <button
                        type="button"
                        className={`btn link-setting btn-outline-primary waves-effect waves-light mx-2 font-size-text`}
                      >
                        {detailLang === 'th' ? 'การตั้งค่าคุกกี้' : 'Change Preferences'}
                      </button>
                      {showReject && (
                        <button
                          type="button"
                          className={`btn btn-reject btn-outline-primary waves-effect waves-light mx-2 font-size-text`}
                        >
                          {detailLang === 'th' ? declineTH : declineEN}
                        </button>
                      )}
                      <button
                        type="button"
                        className={`btn btn-accept btn-primary mx-2 font-size-text`}
                      >
                        {detailLang === 'th' ? acceptTH : acceptEN}
                      </button>
                    </div>
                  </div>
                </div>
                {showLogo === true ? (
                  <div className=" modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src={logoBanner} alt="" height="14" />
                  </div>
                ) : packageDetail.package_id === 1 ? (
                  <div className="modal-footer leaflet-bar text-left footer-banner pt-1 fs-12">
                    <img src="../assets/images/Presented.svg" alt="predictive" height="14" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </PreviewBoxFullTop>
      </BannerBox>
    )
  }
  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body py-2">
                <div className="row">
                  <div className="col-6">
                    {' '}
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="card-title mb-0 py-2">{i18n[lang].publication} </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => setOpenPopupNewVersion(true)}
                      className={`btn btn-primary btn-label float-end mx-2 `}
                      disabled={
                        (initDetail &&
                          initDetail.permission_consents &&
                          initDetail.permission_consents[0] &&
                          initDetail.permission_consents[0].role &&
                          initDetail.permission_consents[0].role === 'viewer') ||
                        (initDetail &&
                          initDetail.permission_consents &&
                          initDetail.permission_consents[0] &&
                          initDetail.permission_consents[0].role &&
                          initDetail.permission_consents[0].role === 'editor')
                          ? true
                          : packageDetail.is_limit_exceed
                          ? true
                          : false
                      }
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          {packageDetail.is_limit_exceed ? (
                            <i className="label-icon align-middle fs-16 me-2">
                              <MdLock />
                            </i>
                          ) : (
                            <i className="label-icon align-middle fs-16 me-2">
                              <MdRocketLaunch />
                            </i>
                          )}
                        </div>
                        <div className="flex-grow-1">{i18n[lang].publishBanner}</div>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-primary waves-effect waves-light float-end"
                      onClick={() => popupPreview()}
                    >
                      <div className="flex-grow-1">{i18n[lang].preview}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="card-body p-4">
                    <div className=" border-bottom-dotted-gray">
                      <p className="py-1 m-0 fs-14 font-weight-bold">{i18n[lang].bannerStatus}</p>
                      <p className="p-0">
                        <i
                          className={`mdi mdi-checkbox-blank-circle label-icon align-middle fs-12 me-1 text-${
                            bannerStatus ? 'success' : 'warning'
                          }`}
                        ></i>
                        {bannerStatus ? i18n[lang].bannerActive : i18n[lang].bannerInactive}
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="py-1 m-0 fs-14 font-weight-bold">{i18n[lang].publication}</p>
                      <p className="p-0">
                        <i
                          className={`mdi mdi-checkbox-blank-circle label-icon align-middle fs-12 me-1 text-${
                            bannerPublishStatus ? 'success' : 'warning'
                          }`}
                        ></i>
                        {bannerPublishStatus
                          ? i18n[lang].updatedToLatest
                          : i18n[lang].unpublishedUpdates}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-body p-4 text-center">
                    <div className=" border-bottom-dotted-gray">
                      <p className="py-2 my-3 fs-16 font-weight-bold">
                        {i18n[lang].currentBannerVersion} : {bannerVersion}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="m-0 py-1 fs-14">
                        <span className="font-weight-bold">{i18n[lang].lastPublishedOn} :</span>{' '}
                        {bannerPublishDate ? convertDate(bannerPublishDate) : '-'}
                      </p>
                      <p className="m-0 py-0 fs-14">
                        <span className="font-weight-bold">{i18n[lang].publishedBy} :</span>{' '}
                        {bannerCreateBy}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body p-4">
                <div>
                  <p className="py-1 m-0 fs-14 font-weight-bold">{i18n[lang].cookieBannerScript}</p>
                  <p className="py-1 m-0 fs-14">{`${i18n[lang].copyScriptInstructions}`}</p>
                </div>
                <div className="mt-2">
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      type="text"
                      id="textScript"
                      aria-label="With textarea"
                      rows="8"
                      value={scriptString}
                      disabled
                    ></textarea>
                  </div>

                  <button
                    onClick={() => coppyScript()}
                    className="btn btn-outline-primary waves-effect waves-light float-end mt-3"
                  >
                    <div className="flex-grow-1">{i18n[lang].copyScript}</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body card-table-fix">
                <div id="customerList">
                  <div className="table-responsive table-card mb-1">
                    <TableBox>
                      {listScriptCookie ? (
                        <DataTable
                          pagination={true}
                          className="-fadeIn mt-0"
                          center={true}
                          columns={columns}
                          data={listScriptCookie}
                          customStyles={tableStyles}
                        />
                      ) : (
                        <div className="zero-center">
                          <div className="zero-content">
                            <img
                              src="../assets/images/Zero-domain.png"
                              alt=""
                              className="img-fluid"
                            />
                            <p className="text-Secondary mt-2">{i18n[lang].clickPublishBanner}</p>
                          </div>
                        </div>
                      )}
                    </TableBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupPreview}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupPreview(false)}
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content bg-device">
              <div className={`modal-header backdrop-header-${position}`}>
                <button
                  onClick={() => setOpenPopupPreview(false)}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                {position === 'default' && <ExampleBannerDefault />}
                {position === 'bottom' && <ExampleBannerBottom />}
                {position === 'popup' && <ExampleBannerPopup />}
                {position === 'bottomLeft' && <ExampleBannerBottomLeft />}
                {position === 'topLeft' && <ExampleBannerTopLeft />}
                {position === 'bottomRight' && <ExampleBannerBottomRight />}
                {position === 'topRight' && <ExampleBannerTopRight />}
                {position === 'fullTop' && <ExampleBannerFullTop />}
              </div>
              {/* <div className="modal-footer justify-content-center"></div> */}
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupReconsentPreview}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupReconsentPreview(false)}
        >
          <div className="modal-dialog modal-fullscreen ">
            <div className="modal-content bg-device">
              <div className={`modal-header backdrop-header-${position}`}>
                <button
                  onClick={() => setOpenPopupReconsentPreview(false)}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                {position === 'default' && <ExampleBannerDefault />}
                {position === 'bottom' && <ExampleBannerBottom />}
                {position === 'popup' && <ExampleBannerPopup />}
                {position === 'bottomLeft' && <ExampleBannerBottomLeft />}
                {position === 'topLeft' && <ExampleBannerTopLeft />}
                {position === 'bottomRight' && <ExampleBannerBottomRight />}
                {position === 'topRight' && <ExampleBannerTopRight />}
                {position === 'fullTop' && <ExampleBannerFullTop />}
              </div>
              {/* <div className="modal-footer justify-content-center"></div> */}
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupNewVersion}
          contentLabel="modal"
          style={customStylesModalsm}
          onRequestClose={() => setOpenPopupNewVersion(false)}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content p-1">
              <div className="modal-body text-center" style={{ paddingBottom: 0 }}>
                <lord-icon
                  src="https://cdn.lordicon.com/lupuorrc.json"
                  trigger="loop"
                  colors="primary:#121331,secondary:#1298be"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>

                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-16">
                      {i18n[lang].publishBannerVersion} {bannerNewVersion}
                    </strong>
                  </label>
                  <br />
                  <div className="row my-3">
                    <div className="col-md-10">
                      <p className="f-14 text-dark text-left">
                        {i18n[lang].requestNewConsent} <br />
                        <span className="fs-12 text-Secondary">
                          {' '}
                          {i18n[lang].bannerDisplayedAgain}
                        </span>
                      </p>
                    </div>

                    <div className="col-md-2  text-right">
                      <div
                        className="form-check form-switch form-switch-md"
                        style={{ marginLeft: 20 }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          onChange={() => setCheckedReConsent(!checkedReConsent)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row my-3 d-none">
                    <div className="col-md-10">
                      <p className="f-14 text-dark text-left">
                        บล็อกคุกกี้อัตโนมัติ (แนะนำ) <br />
                        <span className="fs-12 text-Secondary">
                          {' '}
                          ระบบจะบล็กคุกกี้จนกว่าจะได้รับความยินยอมจากผู้ใช้งาน
                        </span>
                      </p>
                    </div>

                    <div className="col-md-2  text-right">
                      <div className="form-check form-switch form-switch-md">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          onChange={() => setCheckedBlockCookie(!checkedBlockCookie)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  onClick={() => setOpenPopupNewVersion()}
                  type="button"
                  className={`btn w-lg btn-outline-primary waves-effect waves-light  `}
                >
                  {i18n[lang].cancel}
                </button>
                <button
                  type="button"
                  className="btn w-lg btn-primary waves-effect waves-light btn-load"
                  onClick={() => (publishBanner(), setIsShowLoading(true))}
                  disabled={isClickPubilsh || isShowLoading}
                >
                  <span className="d-flex align-items-center">
                    {isShowLoading ? (
                      <Fragment>
                        {' '}
                        <span className="spinner-border flex-shrink-0" role="status"></span>{' '}
                        <span className="flex-grow-1 ms-2 mr-2">{i18n[lang].confirmPublish}</span>{' '}
                      </Fragment>
                    ) : (
                      i18n[lang].confirmPublish
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupPublishSuccess}
          contentLabel="modal"
          style={customStylesModalsm}
          onRequestClose={() => setOpenPopupPublishSuccess(false)}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/lupuorrc.json"
                  trigger="loop"
                  colors="primary:#121331,secondary:#1298be"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>
                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-16">{i18n[lang].yayPublished}</strong>
                  </label>
                  <br />
                  <span>
                    {i18n[lang].bannerPublished} <br />
                    {i18n[lang].displayUpdate}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn w-lg btn-primary waves-effect waves-light"
                  onClick={() => setOpenPopupPublishSuccess()}
                >
                  {i18n[lang].close}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupPublishFailed}
          contentLabel="modal"
          style={customStylesModalsm}
          onRequestClose={() => setOpenPopupPublishFailed(false)}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/tdrtiskw.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#ed3451,secondary:#ed3451"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>

                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-16">{i18n[lang].oopsSomethingWrong}</strong>
                  </label>
                  <br />
                  <span>
                    {i18n[lang].bannerPublishingFailed}
                    <br />
                    {i18n[lang].tryAgainPlease}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  onClick={() => setOpenPopupPublishFailed()}
                  type="button"
                  className={`btn w-lg btn-outline-danger waves-effect waves-light  `}
                >
                  {i18n[lang].cancel}
                </button>
                <button
                  type="button"
                  className="btn w-lg btn-danger waves-effect waves-light"
                  onClick={() => setOpenPopupPublishFailed()}
                >
                  {i18n[lang].tryAgain}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default PublishCookieComponent
