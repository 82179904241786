import styled from "styled-components";

const TableBox = styled.div`
.baner-zero-center{
  width: 100%;
  padding: 0% 33%;
  text-align: center;
  position: absolute;
  top: 5%;
}
.sc-hHLeRK{
  min-height: 450px; 
}
`

const PreviewBoxDefault = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    padding:  0px 11%;
    width: 100%;
    position: absolute;
    bottom: 3%;
    max-width: 100%;
  }

  .right-8{
    right: 8px;
  }
  .break-word{
    word-wrap: break-word;
  }
  
`
const PreviewBoxBottom = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    max-width: 100% !important;
    margin: 0 !important;
    top: 70px !important ;
  }

  .right-8{
    right: 8px;
  }
  
  .break-word{
    word-wrap: break-word;
  }
  
`
  const PreviewBoxPopup = styled.div`
  .cookie-full-preview-popup {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }

  .banner-preview {
    padding: 13% 23%;
    width: 100%;
    position: absolute;
    bottom: 0%;
    max-width: 100%;
  }
  
  .right-8{
    right: 8px;
  }
  
  .backdrop{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0%;
    background-color: #06060696;
    left: 0px;
    top: 0px;
  }

  .h-419{
    height: 419px !important;
  } 
  .h-450 {
    height: 450px !important;
}
.break-word{
  word-wrap: break-word;
}
  `
  const PreviewBoxBottomLeft = styled.div`

  .break-word{
    word-wrap: break-word;
  }
  button.btn {
    height: 36px !important;
    line-height: 1 !important;
  }
  .cookie-Bottom-Left{
    position: fixed;
    left: 13%;
    width: 350px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    bottom: -14%;
  }
  .position-BottomLeft{
    max-width: 1014px !important;
    margin: 0 !important;
   
    bottom: 0px;
    display: flex;
  }
  `
  const PreviewBoxTopLeft = styled.div`
  .break-word{
    word-wrap: break-word;
  }
  button.btn {
    height: 36px !important;
    line-height: 1 !important;
  }
  .cookie-Top-Left{
    position: fixed;
    left: 13%;
    width: 350px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    top: 20%;
  }
  .position-TopLeft{
    max-width: 1014px !important;
    margin: 0 !important;
   
    bottom: 0px;
    display: flex;
  }
  `
  const PreviewBoxBottomRight = styled.div`
 
  .break-word{
    word-wrap: break-word;
  }
  button.btn {
    height: 36px !important;
    line-height: 1 !important;
  }
  .cookie-Bottom-Right{
    position: fixed;
    right: -10%;
    width: 350px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    bottom: -14%;
  }
  .position-BottomRight{
    max-width: 1014px !important;
    margin: 0 !important;
    bottom: 0px;
    display: flex;
  }
  `
  const PreviewBoxTopRight = styled.div`
  
  .break-word{
    word-wrap: break-word;
  }
  button.btn {
    height: 36px !important;
    line-height: 1 !important;
  }
  .cookie-Top-Right{
    position: fixed;
    right: -9%;
    width: 350px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    top: 23%;
  }
  .position-TopRight{
    max-width: 1014px !important;
    margin: 0 !important;
    bottom: 0px;
    display: flex;
  }
  `
  const PreviewBoxFullTop = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    max-width: 100% !important;
    margin: 0 !important;
    top: 70px !important ;
  }

  .right-8{
    right: 8px;
  }
  
  .break-word{
    word-wrap: break-word;
  }
  .cookie-full-top {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}
`

export { TableBox ,PreviewBoxDefault , PreviewBoxBottom ,PreviewBoxPopup,PreviewBoxBottomLeft,PreviewBoxTopLeft,PreviewBoxBottomRight,PreviewBoxTopRight,PreviewBoxFullTop};
