import React, { Fragment, useState, useEffect, useRef } from 'react'
import { TermBox } from './style'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { acceptTerm } from '../../apis/authenication/api'
import { getCookie, saveCookie } from '../../helper/helper'
import { i18n } from '../../helper/i18n'
import DropdownLangComponent from '../layout/dropdownLang'

const TermConditionComponent = props => {
  const history = useHistory()

  const [bottom, setBottom] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    document.title = 'เงื่อนไขกาาใช้งานระบบ | CookiePlus'
  }, [])

  const handleScroll = e => {
    let setHeight = lang === 'th' ? 4300 : 4100
    const scrollBottom = e.target.scrollTop > setHeight
    setBottom(scrollBottom)
  }

  const acceptCon = async () => {
    const { data, status } = await acceptTerm()

    if (data && data.access_token) {
      localStorage.setItem('token', data.access_token)
      window.location.href = '/domain'
    } else {
      window.location.href = '/domain'
    }
  }

  return (
    <TermBox>
      <div className="auth-page-wrapper  py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="auth-page-content overflow-hidden p-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row justify-content-center mb-3">
                  <img src="/assets/images/logo-dark.svg" alt="" className="img-fluid w-250px" />
                </div>
                <div className="card overflow-hidden">
                  <div className="row g-0">
                    <div className="col-lg-12">
                      {lang === 'th' ? (
                        <div className="term-box th">
                          <div className="term-box-header text-center">
                            <div className="absolute-40">
                              <DropdownLangComponent changeLangFn={setLang} valueLang={lang} />
                            </div>
                            <div className=" text-center">
                              <p>ข้อกำหนดและเงื่อนไขการใช้งาน</p>
                            </div>
                          </div>
                          <div id="scrolled" className="term-box-body" onScroll={handleScroll}>
                            <p className="fs-14 text-Secondary term-text-content">
                              ข้อกำหนดและเงื่อนไขการใช้งานนี้ ("<b>ข้อกำหนด</b>
                              ”) เป็นข้อตกลงระหว่าง <b>CookiePlus</b> โดย{' '}
                              <b>บริษัท พรีดิกทิฟ จำกัด (“เรา”)</b> และผู้ใช้งาน ("<b>ท่าน</b>")
                              โดยข้อกำหนดนี้ได้กำหนดเงื่อนไขทั่วไปสำหรับการใช้งานผลิตภัณฑ์หรือบริการเว็บไซต์
                              CookiePlus และการเข้าร่วมกิจกรรมที่เกี่ยวข้องกับ CookiePlus ของท่าน ("
                              <b></b>เว็บไซต์ CookiePlus" หรือ "<b>บริการ</b>") <br />
                              <br /> <b>บัญชีผู้ใช้และสมาชิก</b>
                              <br />
                              เมื่อท่านได้ทำการสร้างบัญชีผู้ใช้บนเว็บไซต์ CookiePlus
                              ท่านมีหน้าที่ในรับผิดชอบต่อกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้การใช้งานบัญชี
                              หรือกิจกรรมอื่นใดที่เกี่ยวข้อง ซึ่งรวมถึง
                              การรักษาความปลอดภัยของบัญชีของท่านด้วย
                              เราไม่มีหน้าที่ในการตรวจสอบบัญชีใหม่ก่อนที่ท่านจะลงชื่อเข้าใช้หรือใช้บริการ
                              และในกรณีที่ท่านให้ข้อมูลเท็จอันส่งผลให้บัญชีของท่านถูกยุติการใช้งานนั้น
                              ท่านมีหน้าที่แจ้งให้เราทราบทันทีเมื่อบัญชีดังกล่าวถูกนำไปใช้โดยไม่ได้รับอนุญาต
                              หรือละเมิดต่อความปลอดภัยอื่น ๆ ซึ่งเราจะไม่รับผิดชอบต่อความเสียหายใด ๆ
                              ที่เกิดขึ้นจากการกระทำหรือการละเว้นการกระทำของท่าน และอาจทำการระงับ
                              ปิดการใช้งาน หรือลบบัญชีผู้ใช้ของท่านได้ (แล้วแต่กรณี)
                              ในกรณีที่ท่านละเมิดข้อกำหนดนี้
                              หรือกระทำการอื่นใดอันเป็นการทำลายชื่อเสียงเรานั้น
                              เรามีสิทธิลบบัญชีของท่านได้ทันที
                              และท่านจะไม่สามารถลงทะเบียนใหม่เพื่อใช้บริการของเราได้
                              ซึ่งเราอาจมีความจำเป็นที่จะต้องทำการปิดกั้นที่อยู่อีเมล (Email
                              Address) และที่อยู่อินเทอร์เน็ตโพรโทคอล (Internet Protocol)
                              ของท่านเพื่อป้องกันการลงทะเบียนอีกครั้ง
                              <br /> <br /> <b>เนื้อหาของผู้ใช้งาน</b> <br /> เพื่อการใช้งาน
                              หรือการใช้บริการ ท่านต้องรับผิดชอบในความถูกต้อง ความสมบูรณ์
                              ความน่าเชื่อถือ
                              ความเหมาะสมและความเป็นเจ้าของทรัพย์สินทางปัญญาหรือสิทธิในการใช้เนื้อหา
                              (“<b>เนื้อหา</b>”) ที่ท่านได้ให้ไว้บนเว็บไซต์ CookiePlus
                              ทั้งหมดแต่เพียงผู้เดียว เราอาจตรวจสอบ
                              หรือสังเกตการณ์เนื้อหาบนเว็บไซต์ที่ท่านนำส่งหรือสร้างขึ้นจากการให้บริการ
                              โดยเราจะไม่ใช้ ทำซ้ำ ดัดแปลง แก้ไข เผยแพร่
                              หรือแจกจ่ายเนื้อหาที่ท่านสร้างขึ้นหรือจัดเก็บไว้ในบัญชีผู้ใช้ของท่านเพื่อการค้า
                              การตลาดหรือวัตถุประสงค์อื่นใด เว้นแต่ ได้รับอนุญาตจากท่านเป็นการเฉพาะ
                              เราขอสงวนสิทธิ์ในการปฏิเสธหรือลบเนื้อหาใด ๆ ที่ไม่เหมาะสม
                              หรือละเมิดนโยบาย หรือเป็นอันตรายในทางใดทางหนึ่งต่อข้อกำหนด
                              <br /> <br /> <b>ความถูกต้องหรือความสมบูรณ์ของข้อมูล</b> <br />
                              ในกรณีที่ข้อมูลของเว็บไซต์ CookiePlus
                              นั้นมีข้อผิดพลาดในความถูกต้องหรือความสมบูรณ์ เช่น การสะกดผิด
                              หรือการละเว้นที่มีความเกี่ยวข้องกับการส่งเสริมการขาย หรือข้อเสนอ
                              เราขอสงวนสิทธิ์ในการแก้ไขข้อผิดพลาด หรือความไม่ถูกต้องดังกล่าว
                              ซึ่งรวมถึง การเปลี่ยนแปลง ทำให้ข้อมูลเป็นปัจจุบันหรือยกเลิกคำสั่งซื้อ
                              รวมถึง คำสั่งซื้อหลังจากที่ท่านส่งไปแล้ว
                              โดยไม่จำเป็นต้องแจ้งให้ท่านทราบล่วงหน้า
                              และเราไม่มีหน้าที่ในการทำให้ข้อมูลเป็นปัจจุบัน แก้ไข
                              หรือชี้แจงในข้อมูลบนเว็บไซต์ CookiePlus และเราจะไม่มีกำหนดวันที่
                              หรือวันที่มีเปลี่ยนแปลงบนเว็บไซต์ CookiePlus หรือบริการอื่น ๆ
                              ที่เกี่ยวข้องภายหลังการแก้ไขหรือทำให้เป็นปัจจุบัน เว้นแต่
                              กฎหมายกำหนดให้ต้องดำเนินการ
                              <br /> <br /> <b>การชำระและการเรียกเก็บเงิน</b> <br />{' '}
                              เมื่อครบกำหนดชำระ
                              ท่านจะต้องชำระค่าธรรมเนียมหรือเงินเพิ่มของบัญชีการใช้งานของท่านตามที่กำหนด
                              และในกรณีที่ท่านได้ใช้บริการทดลองแบบไม่เสียค่าใช้จ่ายนั้น
                              ท่านอาจต้องชำระเงินภายหลังช่วงระยะเวลาการทดลองได้สิ้นสุดลงทันที
                              และหากท่านมีการต่ออายุการใช้งานโดยอัตโนมัติ
                              ท่านจะถูกเรียกเก็บเงินโดยอัตโนมัติจากการเปิดใช้งานสำหรับการบริการโดยอัตโนมัติตามเงื่อนไขที่ท่านได้เลือกไว้
                              ในกรณีที่เราเห็นว่าการทำธุรกรรมของท่านนั้น
                              มีแนวโน้มเป็นธุรกรรมที่มีความเสี่ยงสูง
                              เราจะขอสำเนาบัตรที่มีรูปยืนยันตัวตนซึ่งออกโดยหน่วยงานราชการ
                              หรือสำเนาใบแจ้งยอดบัญชีธนาคารล่าสุดสำหรับบัตรเครดิตหรือบัตรเดบิตที่ใช้สำหรับการซื้อในครั้งนั้น
                              ๆ เราขอสงวนสิทธิ์ในการเปลี่ยนแปลงผลิตภัณฑ์ บริการ
                              ราคาผลิตภัณฑ์หรือบริการ และกิจกรรมที่เกี่ยวข้องกับเว็บไซต์ CookiePlus
                              รวมถึงปฏิเสธคำสั่งซื้อ หรือจำกัดคำสั่งซื้อได้ตลอดเวลา
                              ในกรณีที่เราทำการเปลี่ยนแปลงหรือยกเลิกคำสั่งซื้อของท่าน
                              เราจะแจ้งให้ท่านทราบผ่านทางอีเมล (Email) ที่อยู่สำหรับการเรียกชำระ
                              หรือหมายเลขโทรศัพท์
                              เมื่อเราได้ตรวจสอบและรับคำสั่งซื้อและการชำระเงินของท่านเรียบร้อยแล้วนั้น
                              เราจะให้สิทธิเข้าถึงผลิตภัณฑ์ หรือบริการให้แก่ท่านโดยเร็วที่สุด
                              ทั้งนี้ ค่าธรรมเนียมทั้งหมดนั้นไม่สามารถขอคืนได้
                              <br /> <br /> <b>การคืนเงิน</b> <br />
                              ท่านรับทราบก่อนที่จะซื้อผลิตภัณฑ์และบริการใด ๆ บนเว็บไซต์ CookiePlus
                              ว่าเราขอสงวนสิทธิ์ในการคืนเงินหลังจากการชำระค่าผลิตภัณฑ์หรือบริการใด ๆ
                              ดังนั้น
                              โปรดอ่านรายละเอียดการบริการอย่างละเอียดก่อนตัดสินใจทำการสั่งซื้อบริการ
                              <br /> <br /> <b>การใช้บริการของบุคคลที่สาม</b> <br />
                              ในกรณีที่ท่านเลือกที่จะเปิดใช้ เข้าถึง หรือใช้บริการของบุคคลที่สาม
                              ท่านรับทราบว่าการเข้าใช้หรือใช้งานบริการดังกล่าวนั้นกำหนดโดยเงื่อนไขและข้อกำหนดการให้บริการของบุคคลที่สามนั้นแต่เพียงผู้เดียว
                              เราไม่มีส่วนร่วม
                              หรือมีหน้าที่รับผิดชอบต่อเงื่อนไขและข้อกำหนดการให้บริการดังกล่าว
                              รวมถึง ไม่ให้คำรับรองต่อบริการอื่นดังกล่าว ซึ่งรวมถึงแต่ไม่จำกัดเพียง
                              เนื้อหาหรือวิธีที่บุคคลเหล่านั้นจัดการกับข้อมูล
                              รวมถึงข้อมูลส่วนบุคคลของท่าน และท่านสละสิทธิ์ ไม่เรียกร้องจาก บริษัท
                              พรีดิกทิฟ จำกัด ในเรื่องที่เกี่ยวข้องกับการบริการอื่นดังกล่าว บริษัท
                              พรีดิกทิฟ จำกัด
                              จะไม่รับผิดชอบต่อความเสียหายหรือความสูญหายที่ได้เกิดขึ้นหรือถูกกล่าวหาว่าได้เกิดขึ้นโดยหรือเกี่ยวข้องกับการที่ท่านเลือกใช้
                              เข้าถึง หรือใช้บริการอื่นดังกล่าว
                              หรือจากการที่ท่านได้ไว้วางใจในการปฏิบัติต่อความเป็นส่วนตัว
                              ความปลอดภัยของการประมวลผลข้อมูล หรือนโยบายอื่นใดของบริการดังกล่าว
                              ท่านอาจจำเป็นจะต้องสมัครหรือลงชื่อเข้าใช้การบริการอื่นดังกล่าวกับเว็บไซต์อื่นนั้น
                              ๆ การที่ท่านได้เลือกที่จะเปิดใช้การบริการอื่นดังกล่าวนั้น
                              ท่านได้อนุญาต บริษัท พรีดิกทิฟ จำกัด จำกัด
                              เปิดเผยข้อมูลของท่านเท่าที่จำเป็นในการช่วยให้ใช้งานหรือเปิดการใช้งานบริการอื่นดังกล่าวได้
                              <br /> <br /> <b>การเชื่อมต่อเว็บไซต์อื่น</b> <br /> เว็บไซต์
                              CookiePlus อาจมีการเชื่อมต่อกับเว็บไซต์อื่น ทั้งนี้
                              เราไม่ได้ให้คำรับรอง ร่วมกัน สนับสนุน ช่วยเหลือ
                              หรือเป็นเครือเดียวกันกับเว็บไซต์อื่น ไม่ว่าโดยทางตรงหรือทางอ้อม
                              เว้นแต่ ได้กำหนดเป็นการเฉพาะไว้ เราไม่มีหน้าที่ในการตรวจสอบหรือประเมิน
                              และเราไม่รับรอง รับผิดชอบ หรือรับผิดในข้อเสนอของธุรกิจหรือบุคคลใด ๆ
                              เนื้อหาในเว็บไซต์ หรือการกระทำใด ๆ เกี่ยวกับผลิตภัณฑ์
                              บริการและเนื้อหาของบุคคลอื่น ๆ
                              ท่านเป็นผู้รับความเสี่ยงที่อาจเกิดขึ้นทั้งหมดในการเชื่อมต่อไปยังเว็บไซต์อื่น
                              ๆ ดังนั้น
                              ท่านต้องตรวจสอบการชี้แจงทางกฎหมายและเงื่อนไขการใช้งานของเว็บไซต์นั้น ๆ
                              ที่ท่านได้เชื่อมต่อผ่านเว็บไซต์ CookiePlus
                              <br /> <br /> <b>การสำรองข้อมูล</b> <br />{' '}
                              เราทำการสำรองเนื้อหาและข้อมูลเว็บไซต์ CookiePlus
                              โดยเราจะกระทำอย่างสุดความสามารถ
                              เพื่อให้มั่นใจได้ว่าข้อมูลที่ทำการสำรองนั้นมีความครบถ้วนและถูกต้อง
                              และเราจะทำการกู้คืนข้อมูลที่สำรองไว้โดยอัตโนมัติเพื่อทำให้ผลกระทบหรือช่วงเวลาที่ไม่สามารถทำงานได้
                              (downtime) มีอัตราลดลง
                              <br /> <br /> <b>ข้อห้ามการใช้งาน</b> <br />
                              ท่านไม่สามารถแก้ไข พิมพ์ หรือทำสำเนาส่วนใดของเว็บไซต์ CookiePlus
                              หรือนำบางส่วนของเว็บไซต์ CookiePlus ไปรวมกับงานอื่น
                              ไม่ว่าจะเป็นในรูปแบบสื่อสิ่งพิมพ์ หรือสื่ออิเล็กทรอนิกส์
                              หรือในรูปแบบอื่นใดก็ตาม หรือนำบางส่วนของเว็บไซต์ CookiePlus
                              ไปรวมไว้ในเว็บไซต์อื่นในรูปแบบการฝังเว็บไซต์ (embedding)
                              แบ่งไว้เป็นส่วนในหน้าเว็บไซต์ (framing)
                              หรือวิธีการอื่นใดโดยไม่ได้รับอนุญาตที่ชัดแจ้งจาก บริษัท พรีดิกทิฟ
                              จำกัด และท่านห้ามใช้ใช้งานเว็บไซต์ CookiePlus
                              หรือเนื้อหาในลักษณะดังนี้
                              <br />
                              (1) เพื่อวัตถุประสงค์ที่มิชอบด้วยกฎหมาย
                              <br />
                              (2)
                              ชักชวนหรือสนับสนุนผู้อื่นให้ดำเนินการหรือมีส่วนร่วมในการกระทำที่ผิดกฎหมาย
                              <br />
                              (3) ละเมิดกฎระเบียบ ข้อบังคับ กฎหมาย
                              <br />
                              (4) ละเมิดทรัพย์สินทางปัญญาของเรา
                              หรือทรัพย์สินทางปัญญาอื่นใดของผู้อื่น
                              <br />
                              (5) คุกคาม ล่วงละเมิด หมิ่นประมาท ดูหมิ่น ใส่ร้าย ทำให้เสียชื่อเสียง
                              ข่มขู่หรือเลือกปฏิบัติ รสนิยมทางเพศ ศาสนา ชาติพันธุ์ เชื้อชาติ อายุ
                              ชาติกำเนิดหรือความทุพพลภาพ หรือเพื่อวัตถุประสงค์ในทางอนาจาร
                              หรือผิดศีลธรรม
                              <br />
                              (6) นำเข้าหรือส่งต่อข้อมูลเท็จหรือข้อมูลที่บิดเบือน
                              <br />
                              (7) อัปโหลด หรือส่งไวรัส
                              หรือรหัสที่เป็นอันตรายประเภทอื่นที่อาจใช้ในลักษณะใด ๆ
                              ที่จะส่งผลกระทบต่อการทำงาน หรือการให้บริการ หรือเว็บไซต์ที่เกี่ยวข้อง
                              เว็บไซต์อื่น ๆ หรืออินเทอร์เน็ต
                              <br />
                              (8) เก็บรวบรวมหรือติดตามข้อมูลส่วนบุคคลของผู้อื่น
                              <br />
                              (9) ส่งข้อความที่ผู้รับไม่ได้ร้องขอ (spam)
                              หรือหลอกลวงผู้อื่นโดยใช้อีเมลหรือหน้าเว็บไซต์ปลอม (phish)
                              หรือเปลี่ยนแปลงเส้นทางการเข้าชมเว็บไซต์ไปยังเว็บไซต์ปลอมเพื่อโจรกรรมข้อมูลผู้ใช้
                              (pharm) หรือแอบอ้างเป็นผู้มีสิทธิเข้าถึงข้อมูล (pretext)
                              หรือใช้โปรแกรมเก็บรวบรวมข้อมูลบนเว็บไซต์ (spider)
                              หรือเก็บรวบรวมข้อมูลเว็บไซต์ (crawl) หรือดึงข้อมูลจากเว็บไซต์อื่น ๆ
                              เพื่อนำมาใช้งาน (scrape)
                              <br />
                              (10)
                              เพื่อแทรกแซงหรือหลีกเลี่ยงคุณลักษณะทางด้านความปลอดภัยของบริการหรือเว็บไซต์ที่เกี่ยวข้อง
                              เว็บไซต์อื่น ๆ หรืออินเทอร์เน็ต
                              เราขอสงวนสิทธิ์ในการยกเลิกการใช้บริการหรือใช้เว็บไซต์ใด ๆ
                              ของท่านจากการฝ่าฝืนการใช้งานที่ต้องห้าม
                              <br /> <br /> <b>ทรัพย์สินทางปัญญา</b> <br />
                              ข้อกำหนดนี้ไม่ถือเป็นการโอนสิทธิในทรัพย์สินทางปัญญาใด ๆ ของ บริษัท
                              พรีดิกทิฟ จำกัด โดยสิทธิ กรรมสิทธิ์
                              และผลประโยชน์ทั้งหมดของทรัพย์สินดังกล่าว รวมถึง แต่ไม่จำกัดเพียง
                              ลิขสิทธิ์ สิทธิบัตร ความลับทางการค้า
                              และสิทธิในทรัพย์สินทางปัญญาอื่นใดจะยังคงอยู่ในระหว่างคู่สัญญา กับ
                              บริษัท พรีดิกทิฟ จำกัด แต่เพียงผู้เดียว และการใช้งานเว็บไซต์
                              CookiePlus และบริการของท่านไม่ถือเป็นการให้สิทธิหรืออนุญาตให้ท่านทำซ้ำ
                              ดัดแปลง แปล หรือใช้เครื่องหมายการค้าใดของ บริษัท พรีดิกทิฟ จำกัด
                              หรือของบุคคลอื่นที่เกี่ยวข้องทั้งสิ้น
                              <br /> <br /> <b>การสงวนสิทธิ์ในการรับประกัน</b> <br />
                              ท่านยอมรับความเสี่ยงเองแต่เพียงผู้เดียวสำหรับการใช้บริการ
                              ใช้งานเว็บไซต์ CookiePlus
                              หรือการเข้าร่วมในกิจกรรมที่เกี่ยวข้องกับบริการของ CookiePlus
                              และท่านยอมรับว่าบริการดังกล่าวได้จัดเตรียมในรูปแบบ "ตามที่เป็นอยู่"
                              และ "ตามที่มีอยู่" และทางเราปฏิเสธในการรับประกันใด ๆ
                              ไม่ว่าโดยชัดแจ้งหรือโดยนัย ซึ่งรวมถึงแต่ไม่จำกัดเพียง
                              การรับประกันโดยนัยเกี่ยวกับความสามารถทางการค้า
                              ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ การไม่เกิดเหตุละเมิดการใช้งาน
                              และเราไม่รับประกันว่าบริการตรงตามความต้องการของท่าน
                              หรือบริการจะไม่ขัดข้อง ใช้ได้ตลอดเวลา หรือปราศจากข้อผิดพลาด รวมถึง
                              เราจะไม่รับประกันใด ๆ เกี่ยวกับผลลัพธ์ที่อาจได้รับจากการใช้บริการ
                              หรือความน่าเชื่อถือของข้อมูลใด ๆ ที่ได้รับผ่านบริการ
                              หรือข้อบกพร่องที่เกิดขึ้นในบริการจะได้รับการแก้ไข
                              ท่านเข้าใจและยอมรับว่าเนื้อหา และ/หรือข้อมูลใด ๆ ที่ได้ดาวน์โหลด
                              หรือได้รับจากการใช้บริการจะได้กระทำขึ้นและอยู่ในดุลพินิจและความเสี่ยงของท่านเอง
                              และท่านจะต้องรับผิดชอบแต่เพียงผู้เดียวสำหรับความเสียหายที่เกิดขึ้นกับระบบคอมพิวเตอร์ของท่านหรือการสูญเสียข้อมูลอันเป็นผลมาจากดาวน์โหลดเนื้อหา
                              และ/หรือข้อมูลนั้น ท่านรับทราบว่า บริษัท พรีดิกทิฟ จำกัด
                              มิใช่สำนักงานกฎหมาย ที่ปรึกษากฎหมาย ทนายความ
                              หรือให้บริการคำปรึกษาทางกฎหมาย แต่อย่างใด ดังนั้น
                              เราไม่รับรองในความถูกต้องทางกฎหมายหรือการบังคับใช้ได้ของเอกสารต่าง ๆ
                              สำหรับความต้องการที่เกี่ยวข้องกับข้อกฎหมาย รวมถึง สัญญา แบบฟอร์ม
                              และเอกสารอื่น ๆ บนเว็บไซต์ <a>[CookiePlus: Link]</a>
                              การใช้บริการข้อมูลแบบฟอร์มหรือเอกสารของท่านถือเป็นความเสี่ยงของท่านเอง
                              บริษัท พรีดิกทิฟ จำกัด และพนักงาน ที่ปรึกษา
                              หรือทนายความที่มีส่วนในการให้บริการและข้อมูลขอสงวนสิทธิ์ในการรับประกันใด
                              ๆ โดยชัดแจ้ง
                              อีกทั้งไม่ก่อให้เกิดความสัมพันธ์ระหว่างทนายความกับลูกค้าโดยการให้บริการและข้อมูลแก่ท่าน
                              อย่างไรก็ตาม ท่านทราบดีว่า บริการให้คำปรึกษานี้
                              ท่านจำเป็นต้องพิจารณาจากองค์ประกอบภายในองค์กรของท่านเป็นหลักและควรได้รับคำปรึกษาจากที่ปรึกษากฎหมายภายในบริษัทของท่านเองเพื่อให้ท่านมั่นใจว่าคำปรึกษาที่ได้รับจะสอดคล้องกับการดำเนินธุรกิจขององค์กรท่าน
                              <br /> <br /> <b>ข้อจำกัดความรับผิด</b> <br /> บริษัท พรีดิกทิฟ จำกัด
                              สงวนสิทธิ์ไม่รับผิดชอบต่อบุคคลใด ๆ ในกรณีดังนี้
                              <br /> (1) ความเสียหายทางอ้อมใด ๆ ความเสียหายพิเศษ
                              ค่าเสียหายในเชิงลงโทษ ความเสียหายในการชดใช้
                              หรือความเสียหายที่เป็นผลสืบเนื่อง ซึ่งรวมถึงแต่ไม่จำกัดเพียง
                              ความเสียหายจากผลกำไร รายได้ การค้าขาย ค่าความนิยม การใช้เนื้อหา
                              การสูญเสียโอกาสทางธุรกิจ
                              <br /> (2) ความเสียหาย หรือสูญหายที่เกิดจากเหตุสุดวิสัย
                              เกินความสามารถของเราในการป้องกัน หรือควบคุม เช่น การจลาจล
                              การขาดแคลนไฟฟ้า รวมถึง คำสั่งของรัฐบาล อย่างไรก็ตาม
                              ความรับผิดที่เกิดขึ้น ซึ่งรวมถึงแต่ไม่จำกัดเพียง ในทางสัญญา ละเมิด
                              การรับรอง ความประมาทเลินเล่อ หรือการกระทำอื่นใดที่ บริษัท พรีดิกทิฟ
                              จำกัด ได้รับแจ้งมาแล้วว่าเกิดขึ้นได้
                              หรือคาดหมายได้ว่าความเสียหายดังกล่าวอาจจะเกิดขึ้นซึ่งบริษัท พรีดิกทิฟ
                              จำกัด ต้องรับผิด
                              จะถูกจำกัดไว้เพียงเท่ากับจำนวนจริงที่ท่านได้ชำระให้แก่ บริษัท
                              พรีดิกทิฟ จำกัด
                              <br /> <br /> <b>การชดใช้ค่าเสียหาย</b> <br />
                              ท่านจะชดใช้ค่าเสียหายแก่ บริษัท พรีดิกทิฟ จำกัด ในความสูญเสีย
                              ความเสียหายหรือค่าใช้จ่าย รวมถึงค่าทนายความที่เหมาะสม
                              ที่เกิดขึ้นอันเกี่ยวเนื่องหรือเกิดจากข้อกล่าวหา ข้อเรียกร้อง การกระทำ
                              ข้อพิพาท หรือการดำเนินการข้อพิพาท หรือข้อเรียกร้องใด ๆ
                              จากบุคคลที่สามอันเป็นผลมาจาก หรือเกี่ยวกับเนื้อหาของท่าน
                              การใช้งานเว็บไซต์ CookiePlus หรือบริการที่เกี่ยวข้องโดยท่าน
                              และการเข้าร่วมกิจกรรมที่จัดขึ้นโดยบริษัท พรีดิกทิฟ จำกัด
                              หรือการกระทำอันมิชอบโดยเจตนาใด ๆ ของท่าน
                              <br /> <br /> <b>การโอนสิทธิ</b> <br /> ท่านไม่มีสิทธิโอนสิทธิใด ๆ
                              ตามข้อกำหนดนี้ ไม่ว่าทั้งหมด หรือแต่บางส่วนแก่บุคคลอื่น
                              โดยไม่ได้รับความยินยอมจากเรา ทั้งนี้
                              เรามีอิสระที่จะโอนสิทธิหรือภาระผูกพันใด ๆ
                              ไม่ว่าทั้งหมดหรือบางส่วนให้กับบุคคลที่สามซึ่งเป็นส่วนหนึ่งของการขายทรัพย์สิน
                              หรือหุ้นทั้งหมด หรือเป็นส่วนหนึ่งของการควบรวมกิจการ
                              <br /> <br /> <b>การแก้ไขและเปลี่ยนแปลงข้อกำหนด</b> <br />
                              เราสงวนสิทธิ์ในการแก้ไขข้อกำหนดนี้หรือนโยบายที่เกี่ยวข้องกับเว็บไซต์
                              CookiePlus
                              โดยจะมีผลทันทีเมื่อได้ทำการเผยแพร่ข้อกำหนดฉบับล่าสุดลงบนเว็บไซต์
                              CookiePlus โดยเราจะทำการระบุวันที่มีการแก้ไขลงไว้ข้างใต้ของข้อกำหนด
                              การเข้าใช้งานเว็บไซต์ CookiePlus
                              หลังจากที่มีการแก้ไขดังกล่าวจะถือเป็นการที่ท่านได้ยินยอมในการแก้ไขนั้น
                              <br /> <br /> <b>
                                การแยกส่วนที่เป็นโมฆะออกจากส่วนที่ไม่เป็นโมฆะ
                              </b>{' '}
                              <br />
                              หากข้อกำหนดหรือเงื่อนไขส่วนใดของข้อกำหนดนี้ตกเป็นโมฆะ ไม่ชอบด้วยกฎหมาย
                              หรือไม่สามารถบังคับใช้ได้
                              คู่สัญญาตกลงว่าให้ข้อกำหนดอื่นที่เหลืออยู่ยังคงมีผลบังคับใช้ได้และชอบด้วยกฎหมายต่อไป
                              <br /> <br /> <b>กฎหมายที่ใช้บังคับและการระงับข้อพิพาท</b> <br />
                              ข้อตกลงนี้ตลอดจนข้อตกลงอื่น ๆ
                              ที่เป็นผลมาจากข้อตกลงนี้อยู่ใต้บังคับและตีความตามกฎหมายไทย
                              และให้ศาลไทยเป็นศาลที่มีเขตอำนาจแต่เพียงผู้เดียวในการในการนั่งพิจารณาตัดสินคดีความ
                              <br /> <br /> <b>การยอมรับข้อกำหนด </b> <br /> ท่านรับทราบและยอมรับว่า
                              ท่านได้อ่านข้อกำหนดนี้และยินยอมในข้อตกลงและเงื่อนไขทั้งหมด
                              ในการใช้งานเว็บไซต์ CookiePlus หรือบริการอื่นของ CookiePlus
                              ถือว่าท่านได้ยินยอมที่จะผูกพันกับข้อกำหนดนี้
                              หากท่านไม่ยินยอมในข้อตกลงของข้อกำหนดนี้ ท่านจะไม่มีสิทธิในการใช้งาน
                              หรือเข้าถึงเว็บไซต์ CookiePlus หรือบริการดังกล่าว
                              <br /> <br /> <b>ช่องทางการติดต่อ</b> <br />
                              หากท่านมีข้อสงสัยหรือต้องการสอบถามเพิ่มเติมเกี่ยวกับข้อกำหนดและเงื่อนไขของเรา
                              ท่านสามารถติดต่อเราได้ที่ dpo@predictive.co.th หรือ
                              <br /> <b>บริษัท พรีดิกทิฟ จำกัด </b> เลขที่ 10/35 อาคารเดอะเทรนดี้
                              ชั้นที่ 1A ซอยสุขุมวิท 13 (แสงจันทร์) แขวงคลองเตยเหนือ เขตวัฒนา
                              กรุงเทพมหานคร 10110
                            </p>
                          </div>
                          <div className="col-lg-12 term-box-footer">
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                onClick={() => history.push('/login')}
                                type="button"
                                className="btn btn-outline-primary waves-effect waves-light px-5"
                              >
                                ไม่ยอมรับ
                              </button>
                              <button
                                onClick={() => acceptCon()}
                                type="submit"
                                className="btn btn-primary px-5"
                                disabled={bottom ? false : true}
                              >
                                ยอมรับ
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="term-box en">
                          <div className="absolute-40">
                            <DropdownLangComponent changeLangFn={setLang} valueLang={lang} />
                          </div>
                          <div className="term-box-header text-center">
                            <p>Terms and Conditions of Use</p>
                          </div>
                          <div className="term-box-body" onScroll={handleScroll}>
                            <p className="fs-14 text-Secondary term-text-content">
                              These terms and conditions of use (the "<b>Terms</b>
                              ") are an agreement between CookiePlus by Predictive Company Limited
                              ("<b>We</b>") and users ("<b>You</b>
                              "). These terms govern your acquisition and use of products or
                              services on the CookiePlus website and any activity related to
                              CookiePlus (the "<b>CookiePlus website</b>" or the "<b>Service</b>
                              ")
                              <br />
                              <br /> <b>Accounts and membership</b> <br />
                              When you create an account on the CookiePlus website, you are
                              responsible for all activities that occur as part of your account
                              usage or any other related activities, including the security of your
                              account. We will not undertake the obligation of new account
                              verification before you log in or use the services. In case that you
                              provide false information, which causes the termination of your
                              account, it is your responsibility to notify us immediately of any
                              unauthorized use of such accounts or any other breach of security. We
                              are not responsible for any damages arising out of your actions or
                              omissions and may suspend, disable or delete your account (as the case
                              may be). In the event that you violate these terms or act
                              inconsistently to defame our reputation, we have the right to delete
                              your account immediately and you will not be able to re-register to
                              use our services. We may also need to block your email address and IP
                              address to prevent your re-registration.
                              <br />
                              <br /> <b>User content</b> <br />
                              You are solely responsible for the accuracy, completeness,
                              reliability, suitability and ownership of all intellectual property or
                              rights to use the content (“<b></b>Content”) that you have provided on
                              the CookiePlus website at your own discretion. We may check or monitor
                              your content that you submit to or create from the service on the
                              website; We will not use, reproduce, modify, publish or distribute
                              your content for commercial or marketing purposes or for any other
                              purposes, unless specifically authorized by you. We reserve the right
                              to reject or remove any inappropriate content or violate the policy or
                              harm in any way to the terms
                              <br />
                              <br /> <b>The accuracy of information</b> <br />
                              In case that information of the CookiePlus website contains errors in
                              accuracy or completeness, such as Typo mistake or omissions in
                              connection with a promotion or offer, we reserve the right to correct
                              the errors or such inaccuracies, including up to date information,
                              cancel a current order or submitted order without the need to notify
                              you in advance. We are not obliged to update, amend or clarify
                              information on the CookiePlus website or even set the date of change
                              on the CookiePlus website or other related services after such
                              revision or update. Unless required by law.
                              <br /> <br />
                              <b>Payment and Invoicing</b> <br />
                              When the payment is due, you are obliged to pay any applicable fees to
                              your account. In the event that you have used the free trial service,
                              you must pay immediately after the trial period has ended. If you have
                              an automatic renewal, you will automatically be billed for your
                              automatic activation of the service that you have chosen. If your
                              transactions are considered a high-risk transaction, we will ask for a
                              government-issued document with a photo or a copy of the recent bank
                              statement for the credit or debit card used for such a purchase. We
                              reserve the right to change our products, services, and prices. and
                              activities related to the CookiePlus website, including cancel or
                              limit orders at any time. In case that we change or cancel your order,
                              we will notify you via email, billing address or phone number. Once we
                              have checked and received your order and payment, we will give you
                              access to the products or services to you as soon as possible.
                              However, all fees are non-refundable.
                              <br /> <br />
                              <b>Refund</b> <br />
                              You acknowledge that before purchasing any products and services on
                              the CookiePlus website, we reserve the right to refund all products or
                              services after payment. Please carefully read the service details
                              before deciding purchase.
                              <br /> <br />
                              <b>Third-Party Services</b> <br />
                              In case that you choose to enable, access or use third-party services,
                              you acknowledge that your access or use of such services is solely
                              determined by the terms and conditions of that third-party services.
                              We are not liable for such terms and conditions and we do not warrant
                              such services including but not limited to the content or how they
                              handle information (including your personal data). You waive any claim
                              from Predictive Company Limited in relation to such services.
                              Predictive Company Limited shall not be liable for any damage or loss
                              caused or alleged by or in connection with your choosing, accessing,
                              using or your belief in privacy policy or data processing security or
                              any other policies of such services. You may be required to apply or
                              sign in to such services’ websites. You have authorized Predictive
                              Company to disclose your information to enable use of such services.
                              <br /> <br />
                              <b>Links to other websites</b> <br />
                              The CookiePlus website may contain links to other websites. We do not
                              directly or indirectly endorse, join, support, assist, or affiliate
                              with any other website, unless otherwise expressed. We are not obliged
                              to review or evaluate, and we do not endorse or assume responsibility
                              or liability for the offerings of any business or individual. Any
                              content or otherwise obtained through the use of third-party services
                              is at your own discretion and risk; therefore, you need to review the
                              legal terms and terms of use of those websites that you have connected
                              with the CookiePlus website.
                              <br />
                              <br /> <b>Data backup</b> <br />
                              We back-up all CookiePlus website content and data. To ensure that the
                              backup data is complete and accurate, we will automatically restore
                              the backed-up data to minimize the impact or downtime.
                              <br />
                              <br /> <b>Restricted Matters</b> <br />
                              You cannot edit, print, or copy any share of the CookiePlus website,
                              or merge any parts of the CookiePlus website with other works (whether
                              in publishing or electronic form or in any other form) or gather any
                              parts of the CookiePlus website into another website in the form of
                              embedding, framing or any other means without the permission of
                              Predictive Company Limited. You are prohibited from using the
                              CookiePlus website as the following:
                              <br />
                              1. Unlawful purpose
                              <br />
                              2. Solicit or encourage others to violate laws and regulations
                              <br />
                              3. Acts that violate laws and regulations
                              <br />
                              4. Acts that infringe our intellectual property or others
                              <br />
                              5. Threat, harassment, defamation, or expressions that lead to
                              discrimination by race, national origin, creed, gender, and
                              disability, or for indecent or immoral purposes.
                              <br />
                              6. Import or transmit false or misleading information.
                              <br />
                              7. Upload or transmit viruses or other types of malicious code that
                              may be used in any manner and affect its operation or service or
                              related websites or the Internet
                              <br />
                              8. Collect or track other personal data
                              <br />
                              9. Sending unsolicited messages (spam) or deceiving others by using
                              phony email or phish pages, or redirecting website traffic to steal
                              users data (pharm) or impersonating the right owner to pretext or use
                              spiders or crawl to extract data from other websites (scrape).
                              <br />
                              10. To interfere or circumvent with the security features of the
                              service or related websites, other websites or the Internet. We
                              reserve the right to terminate your use of our service or website from
                              any violation of any prohibited use.
                              <br />
                              <br /> <b>Intellectual Property</b> <br />
                              This term does not constitute an assignment of any intellectual
                              property rights by Predictive Company Limited. All rights, ownership,
                              and interests, including but not limited to copyrights, patents, trade
                              secrets, and any other intellectual property rights that remain solely
                              between the parties and Predictive Company Limited. Your use of the
                              CookiePlus website and service does not constitute a license or
                              authorization for you to copy, modify, translate or use any trademarks
                              of Predictive Company Limited or any other stakeholders.
                              <br /> <br />
                              <b>No Warranty</b> <br />
                              By using our website or services, you understand and agree that any
                              activities related to CookiePlus, you are at your own discretion and
                              risk; All Resources we provide are “as is” and “as available”. This
                              means that we do not represent or warrant to you, including but not
                              limited to: warranties of merchantability, suitability for a specific
                              purpose, infringement; the use that meet your needs or requirements,
                              or the use that will be uninterrupted, timely, secure, or free from
                              errors. Furthermore, you understand and agree that any content
                              downloaded or otherwise obtained through the use of our service is
                              downloaded or obtained at your own discretion and risk; that you are
                              solely responsible for any damage to your computer or other devices
                              for any loss of data that may result from the download of such
                              content. You acknowledge that Predictive Company Limited is not a law
                              firm, attorney, or lawyer. We do not warrant the legality or
                              enforceability of the documents for legal requirements, including
                              contracts, forms and other documents on the website{' '}
                              <a>[CookiePlus: Link]</a> Your use of the service, information, forms
                              or documents is at your own risk. Predictive Company Limited and its
                              employees, consultants or attorneys who are involved in providing
                              services reserve the right to make warranty and it expressly does not
                              create any attorney-client relationship by providing you services or
                              information. You need to seek advice from your own company's legal
                              counsel to assure you that the counseling is consistent with your
                              organization's business practices.
                              <br />
                              <br /> <b>Limitation of Liability</b> <br />
                              Predictive Company Limited reserves the right not to be liable to any
                              person as the following:
                              <br />
                              1. Any indirect damage, special damage, punitive damages, indemnity
                              damage or consequential damages including but not limited to damage
                              from profits, revenue, commerce, goodwill, loss of business
                              opportunity.
                              <br />
                              2. Damage or loss caused by force majeure beyond our ability to
                              prevent or control such as riots, shortages, as well as government
                              orders. However, the liability incurred includes but is not limited to
                              contract, violation, representation, negligence or any other claim
                              against us will be limited to the amount you have paid for the
                              services.
                              <br />
                              <br /> <b>Indemnification</b> <br />
                              You agree to indemnify Predictive Company Limited for and against all
                              losses, damages or costs, including reasonable attorneys’ fees
                              resulting from any violation, disputes or any claim from a third-party
                              resulting from your use, content or related services, or joining in
                              activities organized by Predictive Company Limited with intentional
                              misconduct.
                              <br />
                              <br /> <b>Assignability</b> <br />
                              You are not entitled to assign any rights, all or any part of these
                              terms, to any other person. However, we shall be free to assign part
                              or all of its rights under these terms to third parties as part of our
                              properties, shares or as part of a merger.
                              <br /> <br />
                              <b>Modification to the terms</b> <br />
                              We reserve the right to change these terms or related CookiePlus
                              website policies. We will indicate the date of revision under these
                              terms and the modified version shall immediately become effective.
                              Your continued use of our website or services after such changes will
                              constitute acknowledgment and acceptance of the modified terms.
                              <br /> <br />
                              <b>Severability</b> <br />
                              Any provision of these terms which is determined to be void, invalid,
                              illegal or unenforceable for any reason shall not affect the remainder
                              of these terms, which shall remain in effect.
                              <br />
                              <br /> <b>Applicable Law</b> <br />
                              These terms shall be governed by, construed, interpreted and enforced
                              in accordance with the laws of Thailand and submitted to the
                              jurisdiction of Thai courts.
                              <br /> <br />
                              <b>Acceptance of the terms</b> <br />
                              You acknowledge and agree that you have read these terms and agree to
                              all terms and conditions. By using the CookiePlus website or other
                              CookiePlus services, you agree to be bound by these terms. If you do
                              not agree to the terms, you will not have the right to use or access
                              the CookiePlus website or other related services.
                              <br /> <br />
                              <b>Contact Information</b> <br />
                              If you have any questions or concerns about these terms as outlined
                              above, you can contact us at: dpo@predictive.co.th or{' '}
                              <b>Predictive Company Limited</b> 10/35 The Trendy Office Building (1A
                              floor), Sukhumvit Soi13 Klongtoey-Nua, Wattana, Bangkok 10110
                            </p>
                          </div>
                          <div className="col-lg-12 term-box-footer">
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                onClick={() => history.push('/login')}
                                type="button"
                                className="btn btn-outline-primary waves-effect waves-light px-5"
                              >
                                Reject
                              </button>
                              <button
                                onClick={() => acceptCon()}
                                type="submit"
                                className="btn btn-primary px-5"
                                disabled={bottom ? false : true}
                              >
                                Accept
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer-login start-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-8 text-Gray">
                    Copyright © 2023 by Predictive Co., Ltd. All Right Reserved. Privacy Policy.
                    Terms and conditions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </TermBox>
  )
}

export default TermConditionComponent
