import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { consentList, packageDetailAll, consentOwner } from '../../apis/management/api'
import { getCookie, numberWithCommas } from '../../helper/helper'
import DataTable from 'react-data-table-component'
import { i18n } from '../../helper/i18n'

import {
  getPaymentAccount,
  addTaxInvoice,
  addNewCard,
  cancelPackageSubscribe,
  getAllReceipt,
  getAccountCredit
} from '../../apis/Payment/api'
import { TableBox, SwitchBox, RadioBox, Button, TooltipBox, FooterBox } from './style'
import Modal from 'react-modal'

const { REACT_APP_PREDICTIVE, REACT_APP_OMISE_SECRET_KEY } = process.env

const PackageComponent = props => {
  const history = useHistory()
  const id = props.match.params.id

  const type = props.match.params.type
  const [domainName, setDomainName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [selectDomain, setSelectDomain] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [address, setAddress] = useState('')
  const [subDistrict, setSubDistrict] = useState('')
  const [district, setDistrict] = useState('')
  const [province, setProvince] = useState('')
  const [zipCode, setZipCode] = useState('')

  const [cardID, setCardID] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardCVV, setCardCVV] = useState('')
  const [cardType, setCardType] = useState('')

  const [showPackage, setShowPackage] = useState(true)

  const [infoData, setInfoData] = useState(false)

  const [listReceipt, setListReceipt] = useState('')

  const [checkoutPay, setCheckoutPay] = useState(false)
  const [infoform, setInfoform] = useState(false)
  const [invoiceType, setInvoiceType] = useState('individual')
  const [changeCard, setChangeCard] = useState(false)
  const [openCanclePopup, setCancelPackagePopup] = useState(false)
  const [openCancelSuccess, setCancelSuccess] = useState(false)
  const [openCancleFail, setCancelFail] = useState(false)
  const [inValidateDomainName, setInValidateDomainName] = useState(false)

  const [switchPackageType, setSwitchPackageType] = useState(false)
  const [packageDetail, setPackageDetail] = useState('')
  const [packageName, setPackageName] = useState(false)
  const [packageExpiredAt, setPackageExpiredAt] = useState('')
  const [packageReStartAt, setPackageReStartAt] = useState('')

  const [packagePaymentDetail, setPaymentDetail] = useState('')
  const [packageUserDetail, setPackageUserDetail] = useState('')
  const [packageSessionDetail, setPackageSessionDetail] = useState('')
  const [packageScanUrlsDetail, setPackageScanUrlsDetail] = useState('')

  const [domainList, setDomainList] = useState([])

  const [tabActive, setTabActive] = useState('package')
  const [inValidateFirstName, setValidateFirstName] = useState(false)
  const [inValidateLastName, setValidateLastName] = useState(false)
  const [inValidatePhoneNumber, setValidatePhoneNumber] = useState(false)
  const [inValidateAddress, setValidateAddress] = useState(false)
  const [inValidateSubDistrict, setValidateSubDistrict] = useState(false)
  const [inValidateDistrict, setValidateDistrict] = useState(false)
  const [inValidateProvince, setValidateProvince] = useState(false)
  const [inValidateZipCode, setValidateZipCode] = useState(false)
  const [inValidateCardID, setValidateCardID] = useState(false)
  const [inValidateCardName, setValidateCardName] = useState(false)
  const [inValidateCardDate, setValidateCardDate] = useState(false)
  const [inValidateCardCVV, setValidateCardCVV] = useState(false)
  const [inValidateCompanyName, setValidateCompanyName] = useState(false)
  const [inValidateTaxNumber, setValidateTaxNumber] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [statusUpdateInfodata, setStatusUpdateInfodata] = useState()
  const [alertStatusBox, setAlertStatusBox] = useState(false)
  const [checkPackageType, setCheckPackageType] = useState(false)
  const [packageId, setPackageId] = useState()
  const [lang, setLang] = useState(getCookie('lang'))
  const [creditTotal, setCreditTotal] = useState(0)
  const [unsubscribePackage, setUnsubscribePackage] = useState(0)
  const specialChars = /[||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/
  const [ischeckFreePackage, setIsCheckFreePackage] = useState({
    show: false,
    status: true,
    msg: ''
  })
  const search = useLocation().search
  const activeID = new URLSearchParams(search).get('activeID')
  const statusDomain = new URLSearchParams(search).get('status')
  const [packagePeriod, setPackagePeriod] = useState()
  const [isDisable, setIsDisable] = useState(true)

  useEffect(() => {
    setLang(props.langFn)
    if (listReceipt) {
      allReceipt()
    }
    if (activeID) {
      setSelectDomain(activeID)
      getPackageDetail(activeID)
    }
  }, [props.langFn])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: 'auto',
      minWidth: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      }
    }
  }
  const columns = [
    {
      name: i18n[lang].orderNumberLabel,
      selector: row => row.charge_id,
      width: '250px',
      sortable: true
    },
    {
      name: i18n[lang].paymentDate,
      selector: row => row.created_at,
      sortable: true
    },
    {
      name: i18n[lang].paymentMethod,
      selector: row => row.charge_type
    },
    {
      name: i18n[lang].status,
      selector: row => row.status
    },
    {
      name: i18n[lang].amount,
      selector: row => row.amount
    }
    // {
    //   name: 'ใบเสร็จรับเงิน',
    //   selector: row => row.options
    // }
  ]
  const addTaxInvoiceFn = async () => {
    let obj = {
      tax_invoice: {
        type: invoiceType,
        address: address,
        firstname: firstName,
        lastname: lastName,
        district: district,
        subDistrict: subDistrict,
        province: province,
        country: 'thai',
        zipcode: zipCode,
        phone: phoneNumber,
        companyName: invoiceType !== 'individual' ? companyName : '',
        taxNumber: invoiceType !== 'individual' ? taxNumber : ''
      }
    }

    const { data, status } = await addTaxInvoice(obj)
    if (status === 200) {
      setAlertStatusBox(true)
      setStatusUpdateInfodata(true)
      setErrorMsg(i18n[lang].updateSuccessful)
    } else {
      setAlertStatusBox(true)
      setStatusUpdateInfodata(false)
      setErrorMsg(i18n[lang].updateFailed)
    }
  }

  const chackValidate = () => {
    let res = true
    if (!firstName) {
      setValidateFirstName(true)
    } else {
      setValidateFirstName(false)
    }
    if (!lastName) {
      setValidateLastName(true)
    } else {
      setValidateLastName(false)
    }
    if (!phoneNumber) {
      setValidatePhoneNumber(true)
    } else {
      setValidatePhoneNumber(false)
    }
    if (!address) {
      setValidateAddress(true)
    } else {
      setValidateAddress(false)
    }
    if (!subDistrict) {
      setValidateSubDistrict(true)
    } else {
      setValidateSubDistrict(false)
    }
    if (!district) {
      setValidateDistrict(true)
    } else {
      setValidateDistrict(false)
    }
    if (!province) {
      setValidateProvince(true)
    } else {
      setValidateProvince(false)
    }
    if (!zipCode) {
      setValidateZipCode(true)
    } else {
      setValidateZipCode(false)
    }

    if (
      firstName &&
      lastName &&
      phoneNumber &&
      address &&
      subDistrict &&
      district &&
      province &&
      zipCode
    ) {
      res = true
    } else {
      res = false
    }
    if (invoiceType === 'juristic') {
      if (!companyName) {
        setValidateCompanyName(true)
      } else {
        setValidateCompanyName(false)
      }
      if (!taxNumber) {
        setValidateTaxNumber(true)
      } else {
        setValidateTaxNumber(false)
      }
      if (companyName && taxNumber) {
        res = true
      } else {
        res = false
      }
    }

    return res
  }
  const updateInfodata = async () => {
    let check = chackValidate()

    if (check) {
      addTaxInvoiceFn()
    } else {
      setAlertStatusBox(true)
      setStatusUpdateInfodata(false)
      setErrorMsg(i18n[props.langFn].updateFailed)
    }
  }
  const chackValidateCard = () => {
    let res = true
    if (!cardID) {
      setValidateCardID(true)
    } else {
      setValidateCardID(false)
    }
    if (!cardName) {
      setValidateCardName(true)
    } else {
      setValidateCardName(false)
    }
    if (!cardDate) {
      setValidateCardDate(true)
    } else {
      setValidateCardDate(false)
    }
    if (!cardCVV) {
      setValidateCardCVV(true)
    } else {
      setValidateCardCVV(false)
    }
    if (cardID && cardName && cardDate && cardCVV) {
      res = true
    } else {
      res = false
    }
    return res
  }
  const updateCreditCard = async () => {
    let checkCreditCard = chackValidateCard()
    if (checkCreditCard) {
      createTokenOmise()
    } else {
      setStatusUpdateInfodata(false)
      setErrorMsg(i18n[lang].updateFailed)
      setAlertStatusBox(false)
    }
  }
  const createTokenOmise = async () => {
    let resToken = ''
    window.OmiseCard.configure({
      publicKey: REACT_APP_OMISE_SECRET_KEY
    })
    let exMount = cardDate.split('/')[0]
    let exYear = cardDate.split('/')[1]
    var cardInformation = {
      name: cardName,
      number: cardID,
      expiration_month: exMount,
      expiration_year: exYear,
      security_code: cardCVV
    }
    window.Omise.createToken('card', cardInformation, function (statusCode, response) {
      if (statusCode === 200) {
        resToken = response.id
        addNewCard(resToken).then(response => {
          if (response.status === 200) {
            window.location.href = response.data.authorize_uri_omise
          } else {
            setStatusUpdateInfodata(false)
            setErrorMsg(i18n[lang].updateFailed)
            setAlertStatusBox(false)
          }
        })
      } else {
        setAlertStatusBox(true)
        setErrorMsg(i18n[lang].paymentFailed)
        // setAlertStatusBox(true)
        // setStatusUpdateInfodata(false)
        // setErrorMsg(response.message)
        // if (response.message === 'expiration year is invalid') {
        //   setValidateCardDate(true)
        // } else if (response.message === 'expiration date cannot be in the past') {
        //   setValidateCardDate(true)
        // } else {
        //   setValidateCardDate(false)
        //   setErrorMsg('')
        //   setAlertStatusBox(false)
        //   setStatusUpdateInfodata(true)
        // }
        // if (
        //   response.message ===
        //   'expiration year is invalid and brand not supported (american express)'
        // ) {
        //   setValidateCardID(true)
        // } else {
        //   setValidateCardID(false)
        //   setErrorMsg('')
        //   setAlertStatusBox(false)
        //   setStatusUpdateInfodata(true)
        // }
      }
    })
    return resToken
  }

  const getConsentOwnerList = async () => {
    const { data, status } = await consentOwner()
    if (data && data.data && data.data.length > 0 && status === 200) {
      let wrap = data.data.filter(
        item => item.permission_consents[0].role === 'owner' && item.status !== 'pending'
      )
      let filterById = data.data.filter(item => item.id === activeID)
      if (activeID && filterById) {
        setSelectDomain(filterById[0].id)
        setDomainName(filterById[0].domain)
        if (filterById[0].id) {
          getPackageDetail(filterById[0].id)
        } else {
          setPackageName('')
        }
      }
      setDomainList(wrap)
    }
  }

  useEffect(() => {
    document.title = 'แพ็กเกจ | CookiePlus'
    accountCredit()
    window.scrollTo(0, 0)
    if (window) {
      window.Omise.setPublicKey(REACT_APP_OMISE_SECRET_KEY)
    }
    getConsentOwnerList()
  }, [])

  useEffect(() => {
    setTabActive(type ? type : 'package')
    if (type === 'payment') {
      paymentAccount()
    }
    if (type === 'invoice') {
      allReceipt()
    }
  }, [type])

  const paymentAccount = async () => {
    const { data, status } = await getPaymentAccount()
    if (data?.data?.tax_invoice && status === 200) {
      const { tax_invoice } = data.data
      setFirstName(tax_invoice.firstname)
      setLastName(tax_invoice.lastname)
      setAddress(tax_invoice.address)
      setSubDistrict(tax_invoice.subDistrict)
      setDistrict(tax_invoice.district)
      setProvince(tax_invoice.province)
      setZipCode(tax_invoice.zipcode)
      setInvoiceType(tax_invoice.type)
      setPhoneNumber(tax_invoice.phone)
      setCompanyName(tax_invoice.companyName)
      setTaxNumber(tax_invoice.taxNumber)
      setInfoData(data.data)
      setChangeCard(data?.data?.card_detail ? false : true)
    } else if (data?.data?.card_detail && status === 200) {
      setInfoData(data.data)
    }
  }

  const customDate = d => {
    let exDate = new Date(d)
    let day = exDate.getDate() + ''
    let month = exDate.getMonth() + 1 + ''
    let year = exDate.getFullYear() + ''
    const monthLocal = exDate.toLocaleString(props.langFn, { month: 'long' })
    let restartDate = day + ' ' + monthLocal + ' ' + year
    return restartDate
  }
  const customDateOneDay = d => {
    if (d) {
      const date = new Date(d)
      date.setDate(date.getDate() + 1)

      let exDate = new Date(date.toISOString())
      let day = exDate.getDate() + ''
      let month = exDate.getMonth() + 1 + ''
      let year = exDate.getFullYear() + ''
      const monthLocal = exDate.toLocaleString(props.langFn, { month: 'long' })
      let restartDate = day + ' ' + monthLocal + ' ' + year
      return restartDate
    }
  }

  const getPackageDetail = async id => {
    let obj = {
      consent_id: id,
      payment_detail: true
    }
    setInValidateDomainName(false)
    const { data, status } = await packageDetailAll(obj)
    if (data?.data && status === 200) {
      setPackagePeriod(data.data.package_period.package_end_date)
      setPackageDetail(data.data.packageDetail)
      setPackageName(data.data.packageDetail.package.package_name)

      setPaymentDetail(data.data.payment_detail)
      setPackageUserDetail(data.data.user)
      setPackageSessionDetail(data.data.session)
      setPackageScanUrlsDetail(data.data.scan_urls)
      setPackageId(data.data.packageDetail.package.id)
      let exDate = new Date(data.data.payment_detail.next_payment)
      let day = exDate.getDate() + ''
      let month = exDate.getMonth() + 1 + ''
      let year = exDate.getFullYear() + ''
      let expireDate = day + '/' + month + '/' + year
      setPackageExpiredAt(expireDate)
      const monthLocal = exDate.toLocaleString('th', { month: 'long' })

      let restartDate = day + ' ' + monthLocal + ' ' + year
      setPackageReStartAt(restartDate)
      saveDomain(id, data.data.packageDetail.is_suspend)

      if (data.data.payment_detail.payment_type === 'รายเดือน') {
        setCheckPackageType(false)
        setSwitchPackageType(false)
      } else {
        setCheckPackageType(true)
        setSwitchPackageType(true)
      }
    } else {
      setPackageName()
    }
  }

  const customInputDate = text => {
    let res = text
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
    return res
  }

  const paymentPackage = async () => {
    setShowPackage(false)
    setInfoform(false)
    setCheckoutPay(true)
  }

  const handleChangeInvoiceType = e => {
    setInvoiceType(e.target.value)
  }

  const handleUnsubscribePackage = e => {
    setUnsubscribePackage(e.target.value)
  }
  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }

  const accountCredit = async () => {
    const { data, status } = await getAccountCredit()
    if (data?.data && status === 200) {
      setCreditTotal(data.data.credit)
    }
  }

  const checkPackageDomain = packageDomain => {
    if (statusDomain && statusDomain === 'suspended') {
      if (packageDomain !== packageName) {
        return true
      } else {
        return false
      }
    } else {
      if (packageDetail.is_suspend) {
        if (packageDomain !== packageName) {
          return true
        } else {
          return false
        }
      }
    }
  }

  const allReceipt = async () => {
    const { data, status } = await getAllReceipt()
    let res = []
    if (status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.length; i++) {
        _tempData = {
          charge_id: <span>#{data.data[i].ref}</span>,
          created_at: convertDate(data.data[i].created_at),
          charge_type: (
            <TooltipBox>
              <p className="float-left-mb0">{i18n[props.langFn].creditCard}</p>
              <div className="tooltips">
                {(data.data[i]?.payment_detail?.change_package_refund &&
                  parseInt(data.data[i].payment_detail?.change_package_refund) > 0) ||
                (data.data[i]?.payment_detail?.pay_with_credit_account &&
                  parseInt(data.data[i].payment_detail?.pay_with_credit_account) > 0) ? (
                  <Fragment>
                    <i className={`mdi mdi-information label-icon fs-16 mx-2 link-edit`}></i>
                    <span className="tooltiptexts">
                      <div className="text-left p-3 box_tooltips">
                        <ul className="nav w-100 my-1">
                          <li className="nav-item w-60">{i18n[props.langFn].packagePrice} : </li>
                          <li className="nav-item w-40 text-right">
                            {data.data[i].payment_detail.package_price
                              ? numberWithCommas(data.data[i].payment_detail.package_price) +
                                i18n[props.langFn].thb
                              : '0' + i18n[props.langFn].thb}
                          </li>
                        </ul>

                        {data.data[i]?.payment_detail?.change_package_refund &&
                          parseInt(data.data[i].payment_detail.change_package_refund) > 0 && (
                            <ul className="nav w-100 my-1 ">
                              <li className="nav-item w-60">
                                {i18n[props.langFn].remainingPackageValue} :{' '}
                              </li>
                              <li className="nav-item w-40 text-right">
                                {data.data[i].payment_detail.change_package_refund
                                  ? numberWithCommas(
                                      data.data[i].payment_detail.change_package_refund
                                    ) + i18n[props.langFn].thb
                                  : '0' + i18n[props.langFn].thb}
                              </li>
                            </ul>
                          )}

                        {data.data[i]?.payment_detail?.pay_with_credit_account &&
                          parseInt(data.data[i].payment_detail.pay_with_credit_account) > 0 && (
                            <ul className="nav w-100 my-1 ">
                              <li className="nav-item w-60">
                                {i18n[props.langFn].deductedFromCredit} :{' '}
                              </li>
                              <li className="nav-item w-40 text-right">
                                {data.data[i].payment_detail.pay_with_credit_account
                                  ? numberWithCommas(
                                      data.data[i].payment_detail.pay_with_credit_account
                                    ) + i18n[props.langFn].thb
                                  : '0' + i18n[props.langFn].thb}
                              </li>
                            </ul>
                          )}
                        {data.data[i]?.payment_detail?.withholding_tax &&
                          parseInt(data.data[i].payment_detail.withholding_tax) > 0 && (
                            <ul className="nav w-100 my-1">
                              <li className="nav-item w-60">
                                {i18n[props.langFn].withholdingTax} :{' '}
                              </li>
                              <li className="nav-item w-40 text-right">
                                {data.data[i].payment_detail.withholding_tax
                                  ? ' -' +
                                    numberWithCommas(data.data[i].payment_detail.withholding_tax) +
                                    i18n[props.langFn].thb
                                  : '0' + i18n[props.langFn].thb}{' '}
                              </li>
                            </ul>
                          )}
                        <ul className="nav w-100 my-1 border-top">
                          <li className="nav-item w-60">{i18n[props.langFn].total} : </li>
                          <li className="nav-item w-40 text-right">
                            {data.data[i].payment_detail.pay_with_card
                              ? numberWithCommas(data.data[i].payment_detail.pay_with_card) +
                                i18n[props.langFn].thb
                              : '0' + i18n[props.langFn].thb}
                          </li>
                        </ul>
                      </div>
                    </span>
                  </Fragment>
                ) : (
                  ''
                )}
              </div>
            </TooltipBox>
          ),
          status: (
            <span
              className={`badge ${
                data.data[i].status === 'successful'
                  ? 'badge-soft-success'
                  : data.data[i].status === 'pending'
                  ? 'badge-Pending-Ultimate'
                  : 'badge-soft-danger'
              }  font-weight-bold`}
            >
              {data.data[i].status === 'successful'
                ? i18n[props.langFn].paymentSuccessful
                : data.data[i].status === 'pending'
                ? i18n[props.langFn].processing
                : i18n[props.langFn].paymentFailureMessage}
            </span>
          ),
          amount: data.data[i].payment_detail.pay_with_card
            ? numberWithCommas(data.data[i].payment_detail.pay_with_card) + i18n[props.langFn].thb
            : '0' + i18n[props.langFn].thb,
          options: (
            <a className=" text-primary font-weight-bold">
              <i className="mdi mdi-tray-arrow-down fs-16 text-primary px-2"></i>{' '}
              {i18n[props.langFn].download}
            </a>
          )
        }
        res.push(_tempData)
      }
      setListReceipt(res)
    }
  }

  const cancelPackage = async () => {
    const { data, status } = await cancelPackageSubscribe(selectDomain)
    if (status === 200) {
      setCancelPackagePopup(false)
      setCancelSuccess(true)
      getPackageDetail(selectDomain)
    } else {
      setCancelPackagePopup(false)
      setCancelFail(true)
    }
  }
  const maskCard = value => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g)
    var match = (matches && matches[0]) || ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
      return parts.join('-')
    } else {
      return value
    }
  }

  const selectedPackage = packageId => {
    if (packageId === 1) {
      let allPlan = domainList.map(item => item.package_transactions[0].package.package_name)
      let filterFreePlan = allPlan.filter(item => item === 'Free Plan')

      if (filterFreePlan.length > 0) {
        setIsCheckFreePackage({
          show: true,
          status: true,
          msg: i18n[lang].freePlanExists
        })
        setTimeout(() => {
          setIsCheckFreePackage({
            show: false,
            status: true,
            msg: ''
          })
        }, 5000)
      } else {
        if (domainName) {
          history.push(
            `/payment/${selectDomain}/${packageId}/${
              switchPackageType ? 'year' : 'month'
            }/package?status=${
              (selectDomain === activeID && statusDomain) || packageDetail.is_suspend
                ? 'suspended'
                : ''
            }`
          )
        } else {
          setInValidateDomainName(true)
          window.scrollTo(0, 0)
        }
      }
    } else {
      if (!domainName) {
        setInValidateDomainName(true)
        window.scrollTo(0, 0)
      } else {
        setInValidateDomainName(false)
        history.push(
          `/payment/${selectDomain}/${packageId}/${
            switchPackageType ? 'year' : 'month'
          }/package?status=${
            (selectDomain === activeID && statusDomain) || packageDetail.is_suspend
              ? 'suspended'
              : ''
          }`
        )
      }
    }
  }

  const creditCardType = cc => {
    if (cc.length < 20) {
      let amex = new RegExp('^3[47][0-9]{13}$')
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
      let cup2 = new RegExp('^81[0-9]{14}[0-9]*$')

      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

      let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
      let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
      let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')

      let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
      let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
      let replaceDash = cc.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replaceAll(/\s/g, '')
      if (visa.test(replaceDash)) {
        setCardType('VISA')
      } else {
        setCardType()
      }
      if (mastercard.test(replaceDash) || mastercard2.test(replaceDash)) {
        setCardType('MASTERCARD')
      }
      if (jcb.test(replaceDash)) {
        setCardType('JCB')
      }
      // if (amex.test(cc)) {
      //   setCardType('AMEX')
      // }

      // if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      //   setCardType('DISCOVER')
      // }
      // if (diners.test(cc)) {
      //   setCardType('DINERS')
      // }

      // if (cup1.test(cc) || cup2.test(cc)) {
      //   setCardType('CHINA_UNION_PAY')
      // }

      setCardID(maskCard(cc))
      return undefined
    }
  }
  const handleChangeDomain = e => {
    setSelectDomain(e.target.value)
    setDomainName(
      e.target.value !== '0' ? e.target.options[e.target.selectedIndex].text : undefined
    )
    if (e.target.value !== '0') {
      getPackageDetail(e.target.value)
    } else {
      setPackageName('')
    }
  }

  const customLang = text => {
    if (text === 'รายเดือน') {
      return i18n[lang].monthly
    }
    if (text === 'รายปี') {
      return i18n[lang].yearly
    }
    if (text === 'บัตรเครดิต') {
      return i18n[lang].creditCard
    }
  }
  const saveDomain = (id, status) => {
    if (id) {
      history.push(`/package?activeID=${id}&status=${status ? 'suspended' : ''}`)
    }
  }

  return (
    <div className="main-content">
      {ischeckFreePackage.show && (
        <div
          className={`alert alert-danger  alert-dismissible alert-solid alert-label-icon fade show xs-alert-center`}
          role="alert"
        >
          <i className="ri-error-warning-line label-icon"></i>

          {ischeckFreePackage.msg}
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={() => setIsCheckFreePackage({ show: false, status: true, msg: '' })}
          ></button>
        </div>
      )}
      {alertStatusBox && (
        <div
          className={`alert ${
            statusUpdateInfodata ? 'alert-success' : ' alert-danger'
          }  alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center`}
          role="alert"
        >
          <i
            className={`${
              statusUpdateInfodata ? 'ri-check-double-line' : 'ri-error-warning-line'
            } label-icon`}
          ></i>
          {errorMsg}
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      <div className="page-content">
        <div className="container-fluid">
          {showPackage && (
            <Fragment>
              <div className="card">
                <div className="card-body py-2">
                  <div className="row">
                    <div className="col-6">
                      <h6 className="card-title mb-0 py-2">{i18n[lang].package} </h6>
                    </div>
                    <div className="col-6 text-right">
                      <h6 className="card-title mb-0 py-2">
                        {' '}
                        {creditTotal > 0 && (
                          <Fragment>
                            {' '}
                            <img
                              src={`/assets/images/coin.svg`}
                              alt=""
                              height={17}
                              className="px-2"
                            />
                            {i18n[lang].remainingCredit} :{' '}
                            <span className=" text-warning">{numberWithCommas(creditTotal)}</span>
                          </Fragment>
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <ul
                    className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={`nav-link font-weight-bold ${
                          tabActive === 'package' ? 'active' : ''
                        }`}
                        data-bs-toggle="tab"
                        href="#packageDetails"
                        role="tab"
                        onClick={() =>
                          history.push(
                            activeID ? `/package?activeID=${activeID}&status=suspended` : '/package'
                          )
                        }
                      >
                        {i18n[lang].package}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link font-weight-bold ${
                          tabActive === 'payment' ? 'active' : ''
                        }`}
                        onClick={() =>
                          history.push(
                            activeID
                              ? `/package/payment?activeID=${activeID}&status=suspended`
                              : '/package/payment'
                          )
                        }
                        data-bs-toggle="tab"
                        href="#formInvoice"
                        role="tab"
                      >
                        {i18n[lang].paymentInformation}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link font-weight-bold ${
                          tabActive === 'invoice' ? 'active' : ''
                        }`}
                        onClick={() =>
                          history.push(
                            activeID
                              ? `/package/invoice?activeID=${activeID}&status=suspended`
                              : '/package/invoice'
                          )
                        }
                        data-bs-toggle="tab"
                        href="#taxInvoice"
                        role="tab"
                      >
                        {i18n[lang].receipt}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body p-0">
                  <div className="tab-content">
                    <div
                      className={`tab-pane p-4 ${tabActive === 'package' ? 'active' : ''}`}
                      id="packageDetails"
                      role="tabpanel"
                    >
                      <div className="card-table-fix overflow-hidden">
                        <div className="container">
                          <div className="row justify-content-md-center border-dashed-pk mb-5 pb-5">
                            <Fragment>
                              <div className="col-md-5 text-center">
                                <b className="fs-30">{i18n[lang].chooseDomain}</b>
                                <select
                                  className={`form-select my-3 ${
                                    inValidateDomainName ? 'is-invalid' : ''
                                  }`}
                                  aria-label="Default select example"
                                  onChange={e => handleChangeDomain(e)}
                                  value={selectDomain}
                                >
                                  <option value="0">{i18n[lang].chooseDomain}</option>
                                  {/* <option value="0">
                                    {activeID ? domainName : i18n[lang].chooseDomain}
                                  </option> */}
                                  {domainList &&
                                    domainList.map((item, i) => {
                                      return (
                                        <option key={i} value={item.id} name={item.domain}>
                                          {item.domain}
                                        </option>
                                      )
                                    })}
                                </select>
                              </div>

                              {packageName ? (
                                <div className={`row justify-content-md-center-pk mb-5 pb-5 mt-3`}>
                                  <div className="col-6">
                                    <div className="package-box ">
                                      <div className="row">
                                        <div className="col-12 text-right">
                                          <span className="fs-12 text-right">
                                            {(packageDetail && packageDetail.is_cancel) ||
                                            packageName === 'Free Plan' ||
                                            packageName === 'Enterprise Plan' ? (
                                              ''
                                            ) : (
                                              <div>
                                                {statusDomain == 'suspended' ? null : (
                                                  <a onClick={() => setCancelPackagePopup(true)}>
                                                    {i18n[lang].cancelPackageTitle}
                                                  </a>
                                                )}
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 font-weight-bold fs-14 py-1">
                                          {i18n[lang].currentPackage}
                                        </div>
                                        <div className="col-6">
                                          <span
                                            className={`badge px-3 badge-${
                                              packageName === 'Ultimate Plan'
                                                ? 'Ultimate'
                                                : packageName === 'Free Plan'
                                                ? 'Free'
                                                : packageName === 'Basic Plan'
                                                ? 'Basic'
                                                : packageName === 'Suspended Plan'
                                                ? 'Suspended'
                                                : packageName === 'Pro Plan'
                                                ? 'Pro'
                                                : packageName === 'Complimentary Plan'
                                                ? 'Complimentary'
                                                : packageName === 'Enterprise Plan'
                                                ? 'Enterprise'
                                                : 'Suspended'
                                            } `}
                                          >
                                            {packageName ? packageName : 'Pending'}{' '}
                                          </span>{' '}
                                          {packageDetail && packageDetail.is_cancel ? (
                                            <span className="invalid-feedback-err">
                                              {i18n[lang].expiryDate} {packageExpiredAt}
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 font-weight-bold fs-14 py-1">
                                          {i18n[lang].payment}
                                        </div>
                                        <div className="col-6">
                                          {packagePaymentDetail && packagePaymentDetail.payment_type
                                            ? customLang(packagePaymentDetail.payment_type)
                                            : '-'}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 font-weight-bold fs-14 py-1">
                                          {i18n[lang].paymentMethod}
                                        </div>
                                        <div className="col-6">
                                          {packagePaymentDetail &&
                                          packagePaymentDetail.paymant_method
                                            ? customLang(packagePaymentDetail.paymant_method)
                                            : '-'}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 font-weight-bold fs-14 py-1">
                                          {i18n[lang].nextPaymentDue}
                                        </div>
                                        <div className="col-6">
                                          {packageName !== 'Free Plan'
                                            ? packagePaymentDetail &&
                                              packagePaymentDetail.next_payment
                                              ? packageDetail && packageDetail.is_cancel
                                                ? '-'
                                                : customDate(packagePaymentDetail.next_payment)
                                              : '-'
                                            : '-'}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 font-weight-bold fs-14 py-1">
                                          {i18n[lang].users}
                                        </div>
                                        <div className="col-6">
                                          {packageUserDetail && packageUserDetail.user_limit_used
                                            ? packageUserDetail.user_limit_used.toLocaleString('en')
                                            : '0'}
                                          /
                                          {packageUserDetail && packageUserDetail.package_user_quota
                                            ? packageUserDetail.package_user_quota
                                            : '0'}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 font-weight-bold fs-14 py-1">
                                          {i18n[lang].dailyScans}
                                        </div>
                                        <div className="col-6">
                                          {packageScanUrlsDetail &&
                                          packageScanUrlsDetail.scan_url_used
                                            ? packageScanUrlsDetail.scan_url_used.toLocaleString(
                                                'en'
                                              )
                                            : '0'}
                                          /
                                          {packageScanUrlsDetail &&
                                          packageScanUrlsDetail.package_scan_url_quota
                                            ? packageScanUrlsDetail.package_scan_url_quota.toLocaleString(
                                                'en'
                                              )
                                            : '0'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="package-box text-center box-flex-center">
                                      <p className="fs-14 font-weight-bold  mt-3">
                                        {i18n[lang].numberOfSessions}{' '}
                                      </p>
                                      <p className="fs-24">
                                        <b>
                                          {packageSessionDetail && packageSessionDetail.session_used
                                            ? packageSessionDetail.session_used.toLocaleString('en')
                                            : '0'}{' '}
                                        </b>
                                        /
                                        {packageSessionDetail &&
                                        packageSessionDetail.package_session_quota
                                          ? packageSessionDetail.package_session_quota.toLocaleString(
                                              'en'
                                            )
                                          : '0'}
                                      </p>
                                      <p className="fs-14 my-2">
                                        {packageDetail && packageDetail.is_cancel
                                          ? '-'
                                          : `${i18n[lang].resetDate}  ${customDateOneDay(
                                              packagePeriod
                                            )} `}
                                      </p>
                                      {/* <button className="btn btn-primary px-5 btn-sm">
                                        {' '}
                                        <img
                                          src="/assets/images/growth.svg"
                                          alt=""
                                          height="15"
                                        />{' '}
                                        อัพเกรดแพ็กเกจ
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </Fragment>
                          </div>
                        </div>

                        <div className="text-center">
                          <h1>{i18n[lang].pricesAndPackages}</h1>
                          <p className="fs-16">{i18n[lang].save20Percent}</p>
                          <div className="row justify-content-center mt-3">
                            <div className="col-7 text-center">
                              <SwitchBox>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic radio toggle button group"
                                >
                                  <label className=" fs-24 font-weight-bold mx-3">
                                    {i18n[lang].monthly}
                                  </label>
                                  <div className="form-check form-switch form-switch-lg ">
                                    <input
                                      className="form-check-input left-0"
                                      type="checkbox"
                                      role="switch"
                                      checked={switchPackageType}
                                      onChange={() => {
                                        setSwitchPackageType(!switchPackageType)
                                      }}
                                    />
                                  </div>

                                  <label className=" fs-24 font-weight-bold mx-3 ">
                                    {i18n[lang].yearly}
                                  </label>
                                </div>
                                <span className="badge  bg-success mx-3 badge-pk text-white">
                                  {i18n[lang].discount20Percent}
                                </span>
                              </SwitchBox>
                            </div>
                          </div>
                          <div className="row justify-content-center mt-3">
                            <div className="col-12">
                              <div className="row justify-content-md-center">
                                <div className="col-md-3">
                                  <div className="card card-pk card-height-100">
                                    <div className="card-header">
                                      <span className="float-end text-secondary">
                                        <img src="/assets/images/CP-Free.svg" alt="" height="30" />
                                      </span>
                                      <h6 className="card-title text-left fs-16"> Free Plan</h6>
                                      <p className="card-subtitle text-muted mb-4 text-left fs-12">
                                        {i18n[lang].smallBusinesses}
                                      </p>
                                      <p className="text-left">
                                        <span className="h1">Free </span> {i18n[lang].thb} /{' '}
                                        {i18n[lang].domain} /{' '}
                                        {!switchPackageType ? i18n[lang].month : i18n[lang].year}
                                      </p>
                                    </div>
                                    <div className="card-body px-2 text-left">
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimited} Pageviews
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].compatibleWith} Subdomains
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        2,000 Sessions {i18n[lang].perMonth}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].upToUser} 1 {i18n[lang].user}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].scan} 50 URLs{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        100,000 {i18n[lang].consentRecords}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].storeDataFor} 3 {i18n[lang].months}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-close-circle align-middle text-Gray"></i>{' '}
                                        {i18n[lang].customizeDetailedBanner}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-close-circle align-middle text-Gray"></i>{' '}
                                        {i18n[lang].customizeLogoAndIcons}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-close-circle align-middle text-Gray"></i>{' '}
                                        Google Consent Mode v2{' '}
                                      </p>
                                    </div>
                                    <div className="card-footer border-0">
                                      <Button>
                                        <button
                                          onClick={() => selectedPackage(1)}
                                          className={`btn w-100 ${
                                            (packageName === 'Free Plan' &&
                                              !packageDetail.package_expired) ||
                                            packageId > 4
                                              ? 'btn-gray'
                                              : 'btn-primary'
                                          }`}
                                          disabled={
                                            (packageName === 'Free Plan' &&
                                              !packageDetail.package_expired) ||
                                            packageId > 4 ||
                                            (checkPackageDomain('Free Plan') && packageId > 1)
                                              ? true
                                              : false
                                          }
                                        >
                                          {packageName === 'Free Plan'
                                            ? i18n[lang].currentPackage
                                            : i18n[lang].selectPackage}
                                        </button>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card card-pk card-height-100">
                                    <div className="card-header">
                                      <span className="float-end text-secondary">
                                        <img src="/assets/images/CP-Basic.svg" alt="" height="35" />
                                      </span>
                                      <h6 className="card-title text-left fs-16"> Basic Plan</h6>
                                      <p className="card-subtitle text-muted mb-4 text-left fs-12">
                                        {i18n[lang].smallBusinesses}
                                      </p>
                                      {!switchPackageType ? (
                                        ''
                                      ) : (
                                        <p className="text-left card-subtitle text-muted text-decoration-line-through">
                                          2,388
                                        </p>
                                      )}
                                      <p className="text-left ">
                                        {!switchPackageType ? (
                                          <span className="h1">199 </span>
                                        ) : (
                                          <span className="h1 text-success">1,900 </span>
                                        )}
                                        {i18n[lang].thb} / {i18n[lang].domain} /{' '}
                                        {!switchPackageType ? i18n[lang].month : i18n[lang].year}
                                      </p>
                                    </div>
                                    <div className="card-body px-2 text-left">
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimited} Pageviews
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].compatibleWith} Subdomains
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        20,000 Sessions {i18n[lang].perMonth}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].upToUser} 1 {i18n[lang].user}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].scan} 500 URLs{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        6,000,000 {i18n[lang].consentRecords}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].storeDataFor} 12 {i18n[lang].months}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].customizeDetailedBanner}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].customizeLogoAndIcons}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        Google Consent Mode v2{' '}
                                      </p>
                                    </div>
                                    <div className="card-footer  border-0">
                                      <Button>
                                        <button
                                          onClick={() => selectedPackage(2)}
                                          className={`btn w-100 ${
                                            (packageName === 'Basic Plan' &&
                                              !packageDetail.package_expired &&
                                              switchPackageType === checkPackageType) ||
                                            packageId > 4
                                              ? 'btn-gray'
                                              : 'btn-primary'
                                          }`}
                                          disabled={
                                            (packageName === 'Basic Plan' &&
                                              !packageDetail.package_expired &&
                                              switchPackageType === checkPackageType) ||
                                            packageId > 4 ||
                                            (checkPackageDomain('Basic Plan') && packageId > 2)
                                              ? true
                                              : false
                                          }
                                        >
                                          {packageName === 'Basic Plan' &&
                                          !packageDetail.package_expired &&
                                          switchPackageType === checkPackageType
                                            ? i18n[lang].currentPackage
                                            : i18n[lang].selectPackage}
                                        </button>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card card-pk card-height-100">
                                    <div className="card-header">
                                      <span className="float-end text-secondary">
                                        <img src="/assets/images/CP-Pro.svg" alt="" height="50" />
                                      </span>
                                      <h6 className="card-title text-left fs-16"> Pro Plan</h6>
                                      <p className="card-subtitle text-muted mb-4 text-left fs-12">
                                        {i18n[lang].mediumBusinesses}
                                      </p>
                                      {!switchPackageType ? (
                                        ''
                                      ) : (
                                        <p className="text-left card-subtitle text-muted text-decoration-line-through">
                                          14,988
                                        </p>
                                      )}
                                      <p className="text-left">
                                        {!switchPackageType ? (
                                          <span className="h1">1,249 </span>
                                        ) : (
                                          <span className="h1 text-success">11,990 </span>
                                        )}
                                        {i18n[lang].thb} / {i18n[lang].domain} /{' '}
                                        {!switchPackageType ? i18n[lang].month : i18n[lang].year}
                                      </p>
                                    </div>
                                    <div className="card-body px-2 text-left">
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimited} Pageviews
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].compatibleWith} Subdomains
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        100,000 Sessions {i18n[lang].perMonth}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].upToUser} 10 {i18n[lang].user}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].scan} 10,000 URLs{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimitedConsentRecords}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].storeDataFor} 24 {i18n[lang].months}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].customizeDetailedBanner}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].customizeLogoAndIcons}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        Google Consent Mode v2{' '}
                                      </p>
                                    </div>
                                    <div className="card-footer  border-0">
                                      <Button>
                                        <button
                                          onClick={() => selectedPackage(3)}
                                          className={`btn w-100 ${
                                            (packageName === 'Pro Plan' &&
                                              !packageDetail.package_expired &&
                                              switchPackageType === checkPackageType) ||
                                            packageId > 4
                                              ? 'btn-gray'
                                              : 'btn-primary'
                                          }`}
                                          disabled={
                                            (packageName === 'Pro Plan' &&
                                              !packageDetail.package_expired &&
                                              packageId >= 2 &&
                                              switchPackageType === checkPackageType) ||
                                            packageId > 4 ||
                                            (checkPackageDomain('Pro Plan') && packageId > 3)
                                              ? true
                                              : false
                                          }
                                        >
                                          {packageName === 'Pro Plan' &&
                                          !packageDetail.package_expired &&
                                          switchPackageType === checkPackageType
                                            ? i18n[lang].currentPackage
                                            : i18n[lang].selectPackage}
                                        </button>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card card-pk card-height-100">
                                    <div className="card-header">
                                      <span className="float-end text-secondary">
                                        <img
                                          src="/assets/images/CP-Ultimate.svg"
                                          alt=""
                                          height="55"
                                        />
                                      </span>
                                      <h6 className="card-title text-left fs-16"> Ultimate Plan</h6>
                                      <p className="card-subtitle text-muted mb-4 text-left fs-12">
                                        {i18n[lang].largeBusinesses}
                                      </p>
                                      {!switchPackageType ? (
                                        ''
                                      ) : (
                                        <p className="text-left card-subtitle text-muted text-decoration-line-through">
                                          31,788
                                        </p>
                                      )}
                                      <p className="text-left">
                                        {!switchPackageType ? (
                                          <span className="h1">2,649 </span>
                                        ) : (
                                          <span className="h1 text-success">25,400 </span>
                                        )}
                                        {i18n[lang].thb} / {i18n[lang].domain} /{' '}
                                        {!switchPackageType ? i18n[lang].month : i18n[lang].year}
                                      </p>
                                    </div>
                                    <div className="card-body px-2 text-left">
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimited} Pageviews
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].compatibleWith} Subdomains
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        500,000 Sessions {i18n[lang].perMonth}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimitedNumberOfUsers}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].scan} 50,000 URLs{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].unlimitedConsentRecords}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].storeDataFor} 24 {i18n[lang].months}
                                      </p>

                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].customizeDetailedBanner}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        {i18n[lang].customizeLogoAndIcons}{' '}
                                      </p>
                                      <p>
                                        <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                                        Google Consent Mode v2{' '}
                                      </p>
                                    </div>
                                    <div className="card-footer  border-0">
                                      <Button>
                                        <button
                                          onClick={() => selectedPackage(4)}
                                          className={`btn w-100 ${
                                            (packageName === 'Ultimate Plan' &&
                                              !packageDetail.package_expired &&
                                              switchPackageType === checkPackageType) ||
                                            packageId > 4
                                              ? 'btn-gray'
                                              : 'btn-primary'
                                          }`}
                                          disabled={
                                            (packageName === 'Ultimate Plan' &&
                                              !packageDetail.package_expired &&
                                              switchPackageType === checkPackageType) ||
                                            packageId > 4 ||
                                            (checkPackageDomain('Ultimate Plan') && packageId > 4)
                                              ? true
                                              : false
                                          }
                                        >
                                          {packageName === 'Ultimate Plan' &&
                                          !packageDetail.package_expired &&
                                          switchPackageType === checkPackageType
                                            ? i18n[lang].currentPackage
                                            : i18n[lang].selectPackage}
                                        </button>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="card card-pk">
                                    <div className=" card-body">
                                      <div className="row">
                                        <div className="col-2 m-auto">
                                          <img
                                            src="/assets/images/CP-Enterpise.svg"
                                            alt=""
                                            height="70"
                                          />
                                        </div>
                                        <div className="col-8">
                                          <h6 className="card-title text-left fs-16">
                                            Enterprise Plan
                                          </h6>
                                          <p className="card-subtitle text-muted mb-4 text-left fs-12">
                                            {i18n[lang].largeUsage}
                                          </p>
                                          <div className="row">
                                            <div className="col-12">
                                              <p className="text-left">
                                                <i className="mdi mdi-check-circle text-gold fs-16 mr-2"></i>
                                                {i18n[lang].customizeUsages}{' '}
                                              </p>
                                            </div>
                                            <div className="col-12">
                                              <p className="text-left">
                                                <i className="mdi mdi-check-circle text-gold fs-16 mr-2"></i>
                                                {i18n[lang].dedicatedSupport}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 m-auto">
                                          <button
                                            onClick={() =>
                                              window.open(
                                                'https://meetings.hubspot.com/hataipatt/th-cookieplus-support',
                                                '_blank',
                                                'noopener,noreferrer'
                                              )
                                            }
                                            className="btn btn-primary w-100"
                                            target="_blank"
                                          >
                                            {i18n[lang].contactUs}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <p className=" text-left text-Gray">
                                  <u>{i18n[lang].note}</u>: {i18n[lang].noteSessions}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane p-4 ${tabActive === 'payment' ? 'active' : ''}`}
                      id="formInvoice"
                      role="tabpanel"
                    >
                      <Fragment>
                        <p className="fs-16">{i18n[lang].taxInfo} </p>
                        <RadioBox>
                          <ul className=" nav">
                            <li className=" nav-item">
                              <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="WithoutinlineRadio1"
                                  value="individual"
                                  onChange={handleChangeInvoiceType}
                                  checked={invoiceType === 'individual'}
                                />
                                <label className="form-check-label" htmlFor="formCheck1">
                                  <p className="fs-14 m-0 px-2 text-dark font-weight-bold">
                                    {i18n[lang].naturalPerson}{' '}
                                  </p>
                                </label>
                              </div>
                            </li>
                            <li className=" nav-item px-3">
                              <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="WithoutinlineRadio2"
                                  value="juristic"
                                  onChange={handleChangeInvoiceType}
                                  checked={invoiceType === 'juristic'}
                                />
                                <label className="form-check-label" htmlFor="formCheck1">
                                  <p className="fs-14 m-0 px-2 text-dark font-weight-bold">
                                    {i18n[lang].legalEntity}
                                  </p>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </RadioBox>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="lastnameInput" className="form-label">
                                {i18n[lang].name} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidateFirstName ? 'is-invalid' : ''
                                }`}
                                value={firstName}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setFirstName(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateFirstName && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterFirstName}{' '}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="lastnameInput" className="form-label">
                                {i18n[lang].surname} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateLastName ? 'is-invalid' : ''}`}
                                value={lastName}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setLastName(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateLastName && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterLastName}{' '}
                                </span>
                              )}
                            </div>
                          </div>
                          {invoiceType === 'juristic' ? (
                            <Fragment>
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor="lastnameInput" className="form-label">
                                    {i18n[lang].companyName} <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      inValidateCompanyName ? 'is-invalid' : ''
                                    }`}
                                    value={companyName}
                                    onKeyPress={event => {
                                      if (specialChars.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    onChange={e =>
                                      setCompanyName(
                                        !specialChars.test(e.target.value) ? e.target.value : ''
                                      )
                                    }
                                  />
                                  {inValidateCompanyName && (
                                    <span className="invalid-feedback-err">
                                      {i18n[lang].enterCompanyName}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            ''
                          )}
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].address} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateAddress ? 'is-invalid' : ''}`}
                                value={address}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setAddress(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateAddress && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterAddress}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].subdistrict} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidateSubDistrict ? 'is-invalid' : ''
                                }`}
                                value={subDistrict}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setSubDistrict(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateSubDistrict && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterSubdistrict}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].district} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateDistrict ? 'is-invalid' : ''}`}
                                value={district}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setDistrict(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateDistrict && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterDistrict}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].province} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateProvince ? 'is-invalid' : ''}`}
                                value={province}
                                onKeyPress={event => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setProvince(
                                    !specialChars.test(e.target.value) ? e.target.value : ''
                                  )
                                }
                              />
                              {inValidateProvince && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterProvince}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].zipCode} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${inValidateZipCode ? 'is-invalid' : ''}`}
                                value={zipCode}
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setZipCode(/[0-9]/.test(e.target.value) ? e.target.value : '')
                                }
                              />
                              {inValidateZipCode && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterZipCode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="numberInput" className="form-label">
                                {i18n[lang].mobileNumber} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidatePhoneNumber ? 'is-invalid' : ''
                                }`}
                                value={phoneNumber}
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  setPhoneNumber(/[0-9]/.test(e.target.value) ? e.target.value : '')
                                }
                              />
                              {inValidatePhoneNumber && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterPhoneNumber}
                                </span>
                              )}
                            </div>
                          </div>
                          {invoiceType === 'juristic' ? (
                            <Fragment>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="lastnameInput" className="form-label">
                                    {i18n[lang].taxID} <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      inValidateTaxNumber ? 'is-invalid' : ''
                                    }`}
                                    value={taxNumber}
                                    onKeyPress={event => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    onChange={e =>
                                      setTaxNumber(
                                        /[0-9]/.test(e.target.value) ? e.target.value : ''
                                      )
                                    }
                                  />
                                  {inValidateTaxNumber && (
                                    <span className="invalid-feedback-err">
                                      {i18n[lang].enterTaxID}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            ''
                          )}
                        </div>
                      </Fragment>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end py-3">
                          <b>{i18n[lang].noteTaxInfo}</b>
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            onClick={() => updateInfodata()}
                            type="submit"
                            className="btn btn-primary px-5"
                          >
                            {i18n[lang].update}
                          </button>
                        </div>
                      </div>
                      <b>{i18n[lang].payment}</b>
                      <hr />
                      <div className="col-lg-12">
                        {!infoData || changeCard ? (
                          <div className="row">
                            <div className="col-lg-6 mb-3  ">
                              <label htmlFor="lastnameInput" className="form-label">
                                {i18n[lang].cardNumber}
                              </label>
                              <div className="form-icon right">
                                <input
                                  className={`form-control form-control-icon ${
                                    inValidateCardID ? 'is-invalid' : ''
                                  }`}
                                  value={cardID}
                                  onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  onChange={e =>
                                    creditCardType(
                                      /[0-9]/.test(e.target.value) ? e.target.value : ''
                                    )
                                  }
                                />
                                {cardType && (
                                  <img
                                    src={`/assets/images/${cardType}.png`}
                                    alt={`${cardType}`}
                                    height="20"
                                  />
                                )}
                              </div>
                              {inValidateCardID && (
                                <span className="invalid-feedback-err">
                                  {i18n[lang].enterCardNumber}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="firstnameInput" className="form-label">
                                  {i18n[lang].cardholderName}
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    inValidateCardName ? 'is-invalid' : ''
                                  }`}
                                  value={cardName}
                                  onKeyPress={event => {
                                    if (specialChars.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  onChange={e => setCardName(e.target.value)}
                                />
                                {inValidateCardName && (
                                  <span className="invalid-feedback-err">
                                    {i18n[lang].enterCardholderName}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="numberInput" className="form-label">
                                  {i18n[lang].expirationDate}
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    inValidateCardDate ? 'is-invalid' : ''
                                  }`}
                                  value={cardDate}
                                  size="5"
                                  maxLength="5"
                                  onChange={e => setCardDate(customInputDate(e.target.value))}
                                />
                                {inValidateCardDate && (
                                  <span className="invalid-feedback-err">
                                    {i18n[lang].enterExpirationDate}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="numberInput" className="form-label">
                                  CVV
                                </label>
                                <input
                                  type="password"
                                  pattern="\d*"
                                  className={`form-control ${
                                    inValidateCardCVV ? 'is-invalid' : ''
                                  }`}
                                  value={cardCVV}
                                  size="3"
                                  maxLength="3"
                                  onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  onChange={e =>
                                    setCardCVV(/[0-9]/.test(e.target.value) ? e.target.value : '')
                                  }
                                />
                                {inValidateCardCVV && (
                                  <span className="invalid-feedback-err">
                                    {i18n[lang].enterCVV}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="border border-1 rounded-3 p-2 col-lg-6">
                            <ul className="nav">
                              <li className="nav-itam w-25 text-Gray fs-14 font-weight-bold">
                                {i18n[lang].cardNumberEndsWith}
                              </li>
                              <li className="nav-itam w-50">
                                {' '}
                                {infoData?.card_detail?.brand_card && (
                                  <img
                                    src={`/assets/images/${infoData.card_detail.brand_card.toUpperCase()}.png`}
                                    alt={`${infoData?.card_detail.brand_card}`}
                                    height="20"
                                    className="mx-2 mb-1"
                                  />
                                )}
                                {infoData?.card_detail?.last_digits_card}
                              </li>
                              <li className="nav-itam w-25 text-right fs-14 font-weight-bold text-primary">
                                <a onClick={() => setChangeCard(true)}>
                                  {i18n[lang].changeCreditCard}
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="text-Gray mb-0">{i18n[lang].note}</p>
                          <ul>
                            <li className="text-Gray">{i18n[lang].verificationCharge}</li>
                            <li className="text-Gray">{i18n[lang].primaryPaymentCard}</li>
                            <li className="text-Gray">
                              {i18n[lang].agreeToTerms}{' '}
                              <a href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                                <span className=" text-primary">{i18n[lang].termsOfService}</span>
                              </a>{' '}
                              {i18n[lang].and}{' '}
                              <a href={REACT_APP_PREDICTIVE + `/privacy-policy`} target="_blank">
                                <span className="text-primary">{i18n[lang].privacyPolicy}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-6">
                          <p className="text-right font-weight-bold">
                            <i className="mdi mdi-lock-outline label-icon align-middle fs-20"></i>{' '}
                            {i18n[lang].securePayment}
                          </p>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              className="btn btn-primary px-5"
                              onClick={() => updateCreditCard()}
                            >
                              {i18n[lang].update}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane p-0 ${tabActive === 'invoice' ? 'active' : ''}`}
                      id="taxInvoice"
                      role="tabpanel"
                    >
                      <div className="card-table-fix ">
                        <div id="customerList">
                          <div className="table-responsive  mb-1">
                            <TableBox>
                              <DataTable
                                pagination={true}
                                className="-fadeIn mt-0 none-overflow"
                                center={true}
                                columns={columns}
                                data={listReceipt}
                                customStyles={tableStyles}
                              />
                            </TableBox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{' '}
            </Fragment>
          )}
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openCanclePopup}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setCancelPackagePopup(false)}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-1">
            <div className="modal-body ">
              <div className="text-center">
                <img src={`/assets/images/canclepackage.svg`} alt="" height="90" />
              </div>
              <div className="mt-1 ">
                <div htmlFor="basiInput" className="form-label fs-20 text-center">
                  <p className="fs-16 my-3">
                    <b>{i18n[lang].cancelPackageTitle}</b>
                    <br />
                    <span className="fs-14">
                      {i18n[lang].cancelPackage}{' '}
                      <b>
                        {packageName} (
                        {packagePaymentDetail?.payment_type
                          ? customLang(packagePaymentDetail.payment_type)
                          : '-'}
                        )
                      </b>{' '}
                      {i18n[lang].ofDomain} <br />
                      <b>{domainName} </b>
                      {i18n[lang].questionMark}
                    </span>
                  </p>
                  <p className="fs-14">
                    {i18n[lang].packageActiveUntil} <b> {packageExpiredAt}</b>
                  </p>
                </div>
                <br />
                <span className=" text-Danger text-left font-weight-bold">
                  {i18n[lang].pleaseNote} <br />
                  {i18n[lang].domainSuspensionNote}
                  <br />
                  {i18n[lang].reactivationOptionNote}
                  <br />
                  {i18n[lang].permanentDeletionNote}
                </span>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className={`btn w-lg btn-outline-danger waves-effect waves-light `}
                onClick={() => cancelPackage()}
              >
                {i18n[lang].confirmCancelPackageBtn}
              </button>
              <button
                type="button"
                className={`btn w-lg btn-danger  waves-effect waves-light`}
                onClick={() => setCancelPackagePopup(false)}
              >
                {i18n[lang].cancel}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openCancelSuccess}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setCancelSuccess(false)}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-1">
            <div className="modal-body ">
              <div className="text-center">
                <img src={`/assets/images/cf-canclepackage.svg`} alt="" height="90" />
              </div>
              <div className="mt-1 text-center">
                <label htmlFor="basiInput" className="form-label fs-20 text-center">
                  <p className="fs-16 my-3">
                    <b>{i18n[lang].packageCanceled}</b>
                    <br />
                    <span className="fs-14">
                      {i18n[lang].sorryToSeeYouGo}
                      <br />
                      {/* เพื่อช่วยให้เราพัฒนาได้ดียิ่งขึ้น กรุณาระบุสาเหตุที่คุณยกเลิกการใช้งาน */}
                    </span>
                  </p>
                </label>
                <br />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className={`btn w-lg btn-primary  waves-effect waves-light`}
                onClick={() => setCancelSuccess(false)}
              >
                {i18n[lang].close}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openCancleFail}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setCancelFail(false)}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-1">
            <div className="modal-body ">
              <div className="text-center">
                <img src={`/assets/images/canclefail.svg`} alt="" height="90" />
              </div>
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label fs-20 text-center">
                  <p className="fs-16 my-3">
                    <b>{i18n[lang].failedToCancelPackage}</b>
                    <br />
                    <span className="fs-14">
                      {i18n[lang].somethingWentWrong}
                      <br />
                      {i18n[lang].ifYouNeedAssistance}
                    </span>
                  </p>
                </label>
                <br />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className={`btn w-lg btn-danger  waves-effect waves-light`}
                onClick={() => setCancelFail(false)}
              >
                {i18n[lang].close}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <FooterBox>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </FooterBox>
    </div>
  )
}

export default PackageComponent
