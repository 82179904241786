import styled from 'styled-components'

const TableBox = styled.div`
  div:first-child {
    white-space: unset;
    text-overflow: ellipsis;
  }
  // .ifOHjV{
  //   position: fixed;
  // }

  .sc-dmRaPn {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: auto;
    min-height: 0;
  }
  .sc-hHLeRK {
    min-height: 450px;
  }

  .rdt_TableCell:nth-of-type(2) {
    text-transform: capitalize;
  }
`
const RadioBox = styled.div`
  .form-check .form-check-input {
    float: left;
    margin-left: 0;

  }
`

export { TableBox, RadioBox }
