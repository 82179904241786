import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { connectLogin, confirmEmail, acceptTerm } from '../../apis/authenication/api'
import { confirmInviteUser } from '../../apis/management/api'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from './recaptcha'
import { Login } from './style'
import { i18n } from '../../helper/i18n'
import DropdownLangComponent from '../layout/dropdownLang'
import { getCookie } from '../../helper/helper'

const { REACT_APP_CAPTCHA, REACT_APP_PREDICTIVE } = process.env
var round = 0
const LoginComponent = props => {
  const [lang, setLang] = useState(getCookie('lang'))
  const [loginError, setLoginError] = useState(false)
  const [shown, setShown] = useState(false)
  const [isLoginFail, setIsLoginFail] = useState(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [verifyToken, setVerifyToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)

  const [isConfirmEmail, setIsConfirmEmail] = useState({
    display: false,
    status: '',
    msg: ''
  })
  const search = useLocation().search
  const resetPassword = new URLSearchParams(search).get('reset')
  const checkToken = new URLSearchParams(search).get('token')
  const tokenInvite = new URLSearchParams(search).get('invite')
  const token = window.location.search
  const confirmDetail = {
    token: encodeURIComponent(token).slice(11)
  }
  const confirmInvite = {
    token: encodeURIComponent(token).slice(12)
  }
  const [isShowLoading, setIsShowLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    document.title = 'เข้าสู่ระบบ | CookiePlus'
  }, [])

  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])

  useEffect(() => {
    localStorage.removeItem('token')
    if (checkToken) {
      callbackConfirm()
    }
    if (resetPassword) {
      setIsConfirmEmail({
        display: false,
        status: '',
        msg: ''
      })
      setIsResetPassword(true)
    }

    if (tokenInvite) {
      checkConfirmInvite(tokenInvite)
    }

    refreshRecaptcha()
  }, [])

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const checkConfirmInvite = async () => {
    const { data, status } = await confirmInviteUser(confirmInvite)
    if (data && status === 200) {
      setIsConfirmEmail({
        display: true,
        status: true,
        msg: i18n[lang].invitedSuccess
      })
    } else {
      setIsConfirmEmail({
        display: true,
        status: false,
        msg: i18n[lang].invitedFailed
      })
    }
  }

  const callbackConfirm = async () => {
    const { data, status } = await confirmEmail(confirmDetail)
    if (data && status === 200) {
      setIsConfirmEmail({
        display: true,
        status: true,
        msg: i18n[lang].identitySuccess
      })
    } else if (data === 'Token expired.') {
      setIsConfirmEmail({
        display: true,
        status: false,
        msg: i18n[lang].identityFailContact
      })
    } else {
      setIsConfirmEmail({
        display: true,
        status: false,
        msg: i18n[lang].identityFailTry
      })
    }
  }

  const onSubmit = async obj => {

    setIsShowLoading(true)
    let params = {
      email: obj.email.toLowerCase(),
      password: obj.password,
      token: verifyToken
    }
    const { data, status } = await connectLogin(params)
    if (data && status === 200) {

      setIsShowLoading(false)
      localStorage.setItem('token', data.access_token)
      if (data.accept_term) {
        window.location.href = '/domain'
      } else {
        window.location.href = '/term'
      }
      setIsLoginFail(false)
    } else {
      setIsShowLoading(false)
      console.log('aaaaa')

      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
      setIsLoginFail(true)
      setIsResetPassword(false)
      setIsConfirmEmail({
        display: false,
        status: '',
        msg: ''
      })
    }
  }

  const keepTokenFn = token => {
    setVerifyToken(token)
  }

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  return (
    <Login>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
          <div className="bg-overlay">
            {isLoginFail && (
              <div
                className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].loginFailed}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}

            {isResetPassword && (
              <div
                className="alert alert-success alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
                role="alert"
              >
                <i className="ri-check-double-line label-icon"></i>
                {i18n[lang].passwordSuccess}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}

            {isConfirmEmail.display && (
              <div
                className={`alert ${isConfirmEmail.status ? 'alert-success' : 'alert-danger'
                  } alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center`}
                role="alert"
              >
                {isConfirmEmail.status ? (
                  <i className="ri-check-double-line label-icon"></i>
                ) : (
                  <i className="ri-error-warning-line label-icon"></i>
                )}

                {isConfirmEmail.msg}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
          </div>
          <div className="auth-page-content overflow-hidden pb-0">
            <div className="container ">
              <div className="row ">
                <div className="col-lg-12">
                  <div className="card overflow-hidden">
                    <div className="row g-0 ">
                      <div className="col-lg-6">
                        <div className="p-lg-5 p-4 auth-one-bg h-100">
                          <div className="position-relative h-100 d-flex flex-column">
                            <div className="mb-4"></div>
                            <div className="my-auto row">
                              <div className="col-12 text-center">
                                <img
                                  src="assets/images/logo-dark.svg"
                                  alt="cookieplus"
                                  height="20"
                                />

                                <p className="fs-36 text-Dark font-weight-bold mt-2">
                                  {i18n[lang].welcome}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 pr-1">
                        <div className="p-lg-5 p-4 scroll-content-500">
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="text-dark big-subtitle">{i18n[lang].signin}</p>
                              <DropdownLangComponent changeLangFn={setLang} valueLang={lang} />
                            </div>
                            <p className="text-muted text-email">
                              {i18n[lang].notHaveAcc}
                              <span>
                                <a href="/register" className="text-primary text-email">
                                  {' '}
                                  {i18n[lang].signup}
                                  {lang === 'en' ? '.' : ''}
                                </a>
                              </span>
                            </p>
                          </div>

                          <div className="mt-3">
                            <form action="/domain" onSubmit={handleSubmit(onSubmit)}>
                              <div className="mb-3">
                                <label htmlFor="username" className="form-label text-16 ">
                                  {i18n[lang].email}
                                </label>
                                <input
                                  id="email"
                                  placeholder={i18n[lang].email}
                                  className={`form-control ${errors.email || loginError ? 'is-invalid' : ''
                                    }`}
                                  {...register('email', {
                                    required: i18n[lang].enterEmail,
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: i18n[lang].emailWrongFormat
                                    }
                                  })}
                                />
                                <div className="invalid-feedback">
                                  {loginError ? i18n[lang].emailOrPasswordFailed : ''}
                                </div>
                                <label className="invalid-feedback-err">
                                  {errors.email?.message}
                                </label>
                              </div>

                              <div className="mb-3">
                                <div className="float-end">
                                  <a
                                    id="forgotpassword"
                                    name="forgotpassword"
                                    href="/forgotpassword"
                                    className="text-primary cursor-pointer text-email"
                                  >
                                    {i18n[lang].forgotPassword}?
                                  </a>
                                </div>
                                <label htmlFor="password" className="form-label text-16 ">
                                  {i18n[lang].password}
                                </label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <input
                                    id="password"
                                    name="password"
                                    placeholder={i18n[lang].password}
                                    className={`form-control ${errors.password || loginError ? 'is-invalid' : ''
                                      }`}
                                    type={shown ? 'text' : 'password'}
                                    {...register('password', {
                                      required: i18n[lang].enterPassword
                                    })}
                                  />
                                  <button
                                    className="btn position-absolute end-0 top-0  text-muted show_password"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setShown(!shown)}
                                  >
                                    <span>
                                      {shown ? (
                                        <i className="ri-eye-fill align-middle"></i>
                                      ) : (
                                        <i className="ri-eye-fill align-middle"></i>
                                      )}
                                    </span>
                                  </button>

                                  <label className="invalid-feedback-err">
                                    {errors.password?.message}
                                  </label>
                                </div>
                              </div>

                              {/* <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label text-muted"
                              htmlFor="auth-remember-check"
                            >
                              จำฉันอยู่ในระบบ
                            </label>
                          </div> */}

                              <div className="mt-1">
                                <button
                                  id="btn-login"
                                  name="btn-login"
                                  className="btn btn-primary w-100 text-16 btn-load"
                                  type="submit"
                                  // onClick={() => setIsShowLoading(true)}
                                  disabled={isShowLoading}

                                >
                                  <span className="d-flex align-items-center justify-content-center">

                                    {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                                    </span> <span className=" ms-2 mr-2">
                                        {i18n[lang].signin}
                                      </span> </Fragment> : i18n[lang].signin
                                    }

                                  </span>

                                </button>
                              </div>
                              <div className="mt-2">
                                <a
                                  href="/resend"
                                  id="btn-resend"
                                  name="btn-resend"
                                  className="text-email text-primary"
                                >
                                  {i18n[lang].resendEmail}
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer-page start-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div className="mb-0 text-10 xx-sm d-inline">
                      Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
                    </div>
                    <div className="d-inline">
                      <a
                        className="text-white text-10 xx-sm"
                        href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                        target="_blank"
                      >
                        &nbsp; Privacy Policy
                      </a>{' '}
                      <span className="text-10 xx-sm">●</span>{' '}
                      <a
                        className="text-white text-10 xx-sm"
                        href={REACT_APP_PREDICTIVE + `/terms`}
                        target="_blank"
                      >
                        Terms and conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <ReCaptchaComponent keepTokenFn={keepTokenFn} refreshToken={refreshReCaptcha} />
        </GoogleReCaptchaProvider>
      </div>
    </Login>
  )
}

export default LoginComponent
