import styled from 'styled-components'

const Login = styled.div`
@media (max-width: 821px){
  
    .container {
        width: 100% !important; 
    }
    p , a {
        font-size: small;
    }

    .big-subtitle{
        font-size: 24px;
    }
    .xx-sm{
        font-size: 8px;
    }
    .fw-semibold{
        font-weight: 400; 
    }
    .text-email{
        font-size: 16px;
    }
    .scroll-content-500 {
        height: 575px;
        overflow-y: hidden;
        max-height: 60vh;
      }

     .d-inline{
        display: block !important;  
     }
     .footer-page {
        bottom: 25px;
        padding: 20px calc(1.5rem / 2);
        position: absolute;
        right: 0;
        color: var(--css-Light);
        left: 250px;
        height: 40px;
        background-color: transparent;
    }
  }
`
export { Login }
