import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { changePassword } from '../../apis/authenication/api'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
import { getCookie } from '../../helper/helper'
import DropdownLangComponent from '../layout/dropdownLang'
import { i18n } from '../../helper/i18n'

import { RegisterBox } from './style'
const { REACT_APP_CAPTCHA, REACT_APP_PREDICTIVE } = process.env

const ResetPasswordComponent = props => {
  const [shownCF, setShownCF] = useState(false)
  const [shownPass, setShownPass] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))

  const [invalidPassword, setInvalidPassword] = useState(false)

  const [checkedLetter, setCheckedLetter] = useState(false)
  const [checkedCapital, setCheckedCapital] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState(false)
  const [checkedSpecial, setCheckedSpecial] = useState(false)
  const [checkedLength, setCheckedLength] = useState(false)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [verifyToken, setVerifyToken] = useState()

  const [verifyCFPassword, setVerifyCFPassword] = useState(false)

  const [passwordInput, setPasswordInput] = useState('')
  const [CFPassword, setConfirmPassword] = useState('')
  const [checkContain, setCheckContain] = useState(false)

  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)

  const [isResetPasswordFail, setResetPasswordFail] = useState(false)
  const search = useLocation().search
  const token = window.location.search
  const confirmDetail = {
    token: encodeURIComponent(token).slice(11)
  }
  useEffect(() => {
    document.title = 'ตั้งรหัสผ่านใหม่ | CookiePlus'
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])
  const onSubmit = async () => {
    if (checkedLetter && checkedCapital && checkedNumber && checkedSpecial && checkedLength) {
      setInvalidPassword(false)

      if (passwordInput !== CFPassword) {
        setVerifyCFPassword(true)
      } else {
        if (!CFPassword) {
          setVerifyCFPassword(true)
        } else {
          setVerifyCFPassword(false)
          let params = {
            password: passwordInput,
            token: confirmDetail.token,
            tokenReCaptcha: verifyToken
          }
          const { data, status } = await changePassword(params)
          if (data && status === 200) {
            window.location.href = '/login?reset=y'
          } else {
            setResetPasswordFail(true)
          }
        }
      }
    } else {
      setInvalidPassword(true)
    }
  }

  const keepTokenFn = token => {
    setVerifyToken(token)
  }

  const checkPassword = e => {
    const letter = document.getElementById('pass-lower')
    const capital = document.getElementById('pass-upper')
    const number = document.getElementById('pass-number')
    const special = document.getElementById('pass-special')
    const length = document.getElementById('pass-length')

    setPasswordInput(e.target.value)
    if (e.target.value) {
      setInvalidPassword(false)
    }
    const lowerCaseLetters = /[a-z]/g
    if (e.target.value.match(lowerCaseLetters)) {
      letter.classList.remove('invalid')
      letter.classList.add('valid')
      setCheckedLetter(true)
    } else {
      letter.classList.remove('valid')
      letter.classList.add('invalid')
      setCheckedLetter(false)
    }

    const upperCaseLetters = /[A-Z]/g
    if (e.target.value.match(upperCaseLetters)) {
      capital.classList.remove('invalid')
      capital.classList.add('valid')
      setCheckedCapital(true)
    } else {
      capital.classList.remove('valid')
      capital.classList.add('invalid')
      setCheckedCapital(false)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if (e.target.value.match(numbers)) {
      number.classList.remove('invalid')
      number.classList.add('valid')
      setCheckedNumber(true)
    } else {
      number.classList.remove('valid')
      number.classList.add('invalid')
      setCheckedNumber(false)
    }

    const specials = /[!@#$%^*_&=]/g
    if (e.target.value.match(specials)) {
      special.classList.remove('invalid')
      special.classList.add('valid')
      setCheckedSpecial(true)
    } else {
      special.classList.remove('valid')
      special.classList.add('invalid')
      setCheckedSpecial(false)
    }

    // Validate length
    if (e.target.value.length >= 8) {
      length.classList.remove('invalid')
      length.classList.add('valid')
      setCheckedLength(true)
    } else {
      length.classList.remove('valid')
      length.classList.add('invalid')
      setCheckedLength(false)
    }
  }

  return (
    <RegisterBox>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
          <div className="bg-overlay">
            {isResetPasswordFail && (
              <div
                className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].emailNotFound}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            {isRegisterSuccess && (
              <div
                className="alert alert-success alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].passwordSuccess}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
          </div>
          <div className="auth-page-content overflow-hidden pt-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card overflow-hidden">
                    <div className="row g-0">
                      <div className="col-lg-6">
                        <div className="p-lg-5 p-4 auth-one-bg h-100">
                          <div className="position-relative h-100 d-flex flex-column">
                            <div className="mb-4"></div>
                            <div className="my-auto row">
                              <div className="col-12 text-center">
                                <img
                                  src="assets/images/logo-dark.svg"
                                  alt="cookieplus"
                                  height="20"
                                />

                                <p className="fs-36 text-Dark font-weight-bold mt-2">
                                  {i18n[lang].welcome}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 pr-1">
                        <div className="p-5 scroll-content-500">
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="text-dark big-subtitle">{i18n[lang].addNewPassword}</p>
                              <DropdownLangComponent changeLangFn={setLang} valueLang={lang} />
                            </div>
                            <p className="text-muted">{i18n[lang].pleaseEnterNewPassword}</p>
                          </div>

                          <div className="mt-4">
                            <div>
                              <label className="form-label text-16 ">{i18n[lang].password} *</label>
                              <div className={`position-relative auth-pass-inputgroup`}>
                                <input
                                  placeholder={i18n[lang].password}
                                  onFocus={e => setCheckContain(true)}
                                  onBlur={e => setCheckContain(false)}
                                  onChange={e => checkPassword(e)}
                                  value={passwordInput}
                                  className={`form-control ${invalidPassword ? 'is-invalid' : ''}`}
                                  type={shownPass ? 'text' : 'password'}
                                  id="new-password"
                                />

                                <button
                                  className="btn position-absolute end-0 top-0 text-decoration-none text-muted show_password"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setShownPass(!shownPass)}
                                >
                                  <span>
                                    {shownPass ? (
                                      <i className="ri-eye-fill align-middle"></i>
                                    ) : (
                                      <i className="ri-eye-fill align-middle"></i>
                                    )}
                                  </span>
                                </button>
                                <label className="invalid-feedback-err">
                                  {invalidPassword && i18n[lang].enterPassword}
                                </label>
                                <div
                                  id="password-contain"
                                  className={`p-3 pt-0 bg-white mb-2 rounded ${
                                    checkContain ? 'd-block' : 'd-none'
                                  }`}
                                >
                                  <h5 className="fs-14">{i18n[lang].passwordMustContain}</h5>
                                  <p id="pass-length" className="invalid mb-2">
                                    {i18n[lang].atLeast8Characters}
                                  </p>
                                  <p id="pass-lower" className="invalid mb-2">
                                    {i18n[lang].atLeastOneLowercase}
                                  </p>
                                  <p id="pass-upper" className="invalid mb-2">
                                    {i18n[lang].atLeastOneUppercase}
                                  </p>
                                  <p id="pass-number" className="invalid mb-2">
                                    {i18n[lang].atLeastOneNumber}
                                  </p>
                                  <p id="pass-special" className="invalid mb-0">
                                    {i18n[lang].atLeastOneSpecialChar}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <label className="form-label text-16 ">
                                {i18n[lang].ConfirmPassword} *
                              </label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <input
                                  placeholder={i18n[lang].password}
                                  className={`form-control ${verifyCFPassword ? 'is-invalid' : ''}`}
                                  type={shownCF ? 'text' : 'password'}
                                  onChange={e => setConfirmPassword(e.target.value)}
                                  id="cf-password"
                                />

                                <button
                                  className="btn position-absolute end-0 top-0 text-decoration-none text-muted show_password"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setShownCF(!shownCF)}
                                >
                                  <span>
                                    {shownCF ? (
                                      <i className="ri-eye-fill align-middle"></i>
                                    ) : (
                                      <i className="ri-eye-fill align-middle"></i>
                                    )}
                                  </span>
                                </button>
                                <label className="invalid-feedback-err">
                                  {verifyCFPassword && i18n[lang].passwordNotMatch}
                                </label>
                              </div>
                            </div>

                            <div className="mt-4">
                              <button
                                className="btn btn-primary w-100 text-16"
                                // type="submit"
                                onClick={() => onSubmit()}
                                id="btn-cf-password"
                              >
                                {i18n[lang].ConfirmPassword}
                              </button>
                              <footer className="footer-login start-0 text-right px-5">
                                <a
                                  className="text-right fs-14 cursor-pointer"
                                  href="/login"
                                  id="btn-to-login"
                                >
                                  <i className="mdi mdi-arrow-left-thin"></i>
                                  {i18n[lang].backToLogin}
                                </a>
                              </footer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer-page start-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div className="mb-0 text-10 xx-sm d-inline">
                      Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
                    </div>
                    <div className="d-inline">
                      <a
                        className="text-white text-10 xx-sm"
                        href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                        target="_blank"
                      >
                        &nbsp;Privacy Policy
                      </a>{' '}
                      <span className="text-10 xx-sm">●</span>{' '}
                      <a
                        className="text-white text-10 xx-sm"
                        href={REACT_APP_PREDICTIVE + `/terms`}
                        target="_blank"
                      >
                        Terms and conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <ReCaptchaComponent keepTokenFn={keepTokenFn} refreshToken={refreshReCaptcha} />
        </GoogleReCaptchaProvider>
      </div>
    </RegisterBox>
  )
}

export default ResetPasswordComponent
