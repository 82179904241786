import styled from "styled-components";
const TermBox = styled.div`
.term-box{
    padding: 40px;
}

.term-box-header{
    font-size: 24px;
    font-weight: normal;
    color: #212529;
}
.term-box-body{
    height: 450px;
    overflow-y: auto;
    padding: 20px;
}
.term-box-footer{
padding-top: 20px;
}
.term-text-content{
    line-height: 2.3;
}
`

export { TermBox };
