import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { consentList, packageDetailAll, consentOwner, periodUsage } from '../../apis/management/api'
import { getCookie, numberWithCommas } from '../../helper/helper'
import DataTable from 'react-data-table-component'
import { i18n } from '../../helper/i18n'

import {
  getPaymentAccount,
  addTaxInvoice,
  addNewCard,
  cancelPackageSubscribe,
  getAllReceipt,
  getAccountCredit
} from '../../apis/Payment/api'
import { TableBox, SwitchBox, RadioBox, Button, TooltipBox, FooterBox } from './style'
import Modal from 'react-modal'
import { ContentBox } from '../recordsAcceptCookie/style'
import SecondaryMenuComponent from '../menu/secondary-nav'

const { REACT_APP_PREDICTIVE, REACT_APP_OMISE_SECRET_KEY } = process.env

const PackageDetailsComponent = props => {
  const history = useHistory()
  const id = props.match.params.id

  const type = props.match.params.type

  const [packageDetail, setPackageDetail] = useState('')
  const [packageName, setPackageName] = useState(false)
  const [packageExpiredAt, setPackageExpiredAt] = useState('')

  const [packagePaymentDetail, setPaymentDetail] = useState('')
  const [packageUserDetail, setPackageUserDetail] = useState('')
  const [packageSessionDetail, setPackageSessionDetail] = useState('')
  const [packageScanUrlsDetail, setPackageScanUrlsDetail] = useState('')
  const [packagePeriod, setPackagePeriod] = useState()


  const [lang, setLang] = useState(getCookie('lang'))
  const search = useLocation().search
  const [packageList, setPackageList] = useState('')

  const [infoData, setInfoData] = useState(false)

  useEffect(() => {
    setLang(props.langFn)
    getConsentList()

  }, [props.langFn])

  const tableStyles = {
    // borderRadius: '60px',
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500',
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px',

      }
    },
    cells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for data cells
        // paddingRight: "8px",
      }
    }
  }

  useEffect(() => {
    document.title = 'แพ็กเกจ | CookiePlus'

    window.scrollTo(0, 0)
    if (window) {
      window.Omise.setPublicKey(REACT_APP_OMISE_SECRET_KEY)
    }
    getPackageDetail(id)
    paymentAccount()
  }, [])



  const customDate = d => {
    let exDate = new Date(d)
    let day = exDate.getDate() + ''
    let month = exDate.getMonth() + 1 + ''
    let year = exDate.getFullYear() + ''
    const monthLocal = exDate.toLocaleString(props.langFn, { month: 'long' })
    let restartDate = day + ' ' + monthLocal + ' ' + year
    return restartDate
  }
  const customDateOneDay = d => {
    if (d) {
      const date = new Date(d);
      date.setDate(date.getDate() + 1);

      let exDate = new Date(date.toISOString())
      let day = exDate.getDate() + ''
      let month = exDate.getMonth() + 1 + ''
      let year = exDate.getFullYear() + ''
      const monthLocal = exDate.toLocaleString(props.langFn, { month: 'long' })
      let restartDate = day + ' ' + monthLocal + ' ' + year
      return restartDate
    }
  }

  const customLang = text => {
    if (text === 'รายเดือน') {
      return i18n[lang].monthly
    }
    if (text === 'รายปี') {
      return i18n[lang].yearly
    }
    if (text === 'บัตรเครดิต') {
      return i18n[lang].creditCard
    }
  }
  const columns = [
    {
      name: `${i18n[lang].billingCycle}`,
      selector: row => row.billingCycle,
      width: ''
    },
    {
      name: `${i18n[lang].numberOfSessions}`,
      selector: row => row.sessions
    },
    {
      name: `${i18n[lang].package}`,
      selector: row => row.package
    },


  ]

  const getConsentList = async () => {
    const { data, status } = await periodUsage(id)
    let res = []
    if (data && data.period_usage && data.period_usage.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.period_usage.length; i++) {
        _tempData = {
          billingCycle: customDate(data.period_usage[i]?.package_start_period),
          sessions: data.period_usage[i]?.total_session.toLocaleString('en'),
          package: (
            <span
              className={`badge px-3 badge-${formatTextPlan(data.period_usage[i].package_name)} `}
            >
              {formatTextPlan(data.period_usage[i].package_name)}
            </span>
          ),

        }
        res.push(_tempData)
      }

      setPackageList(res)

    }
  }

  const getPackageDetail = async id => {
    let obj = {
      consent_id: id,
      payment_detail: true
    }
    const { data, status } = await packageDetailAll(obj)
    if (data?.data && status === 200) {
      setPackagePeriod(data.data.package_period.package_end_date)
      setPackageDetail(data.data.packageDetail)
      setPackageName(data.data.packageDetail.package.package_name)

      setPaymentDetail(data.data.payment_detail)
      setPackageUserDetail(data.data.user)
      setPackageSessionDetail(data.data.session)
      setPackageScanUrlsDetail(data.data.scan_urls)
      let exDate = new Date(data.data.payment_detail.next_payment)
      let day = exDate.getDate() + ''
      let month = exDate.getMonth() + 1 + ''
      let year = exDate.getFullYear() + ''
      let expireDate = day + '/' + month + '/' + year
      setPackageExpiredAt(expireDate)
    } else {
      setPackageName()
    }
  }

  const formatTextPlan = (text) => {
    if (text === 'Free Plan') {
      return 'Free'
    } else if (text === 'Basic Plan') {
      return 'Basic'

    } else if (text === 'Pro Plan') {
      return 'Pro'

    } else if (text === 'Ultimate Plan') {
      return 'Ultimate'

    } else if (text === 'Enterprise Plan') {
      return 'Enterprise'

    } else if (text === 'Complimentary Plan') {
      return 'Complimentary'

    } else if (text === 'Suspended') {
      return 'Suspended'

    }
  }
  const paymentAccount = async () => {
    const { data, status } = await getPaymentAccount()

    if (data?.data?.tax_invoice && status === 200) {
      setInfoData(data.data)
    }
  }
  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />
      <div className="main-content">
        <ContentBox>
          <div className="page-content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header py-2">
                  <div className="row m-0">
                    <div className="col-12">
                      <h6 className="card-title mb-0 py-2">{i18n[lang].packageDetails} </h6>
                    </div>

                  </div>
                </div>

              </div>

              <div className="card ">
                <div className="card-body">
                  <div className={`row justify-content-md-center-pk mb-4 mt-3`}>
                    <div className="col-6">
                      <div className="package-box box-flex-center">
                        <div className="row">
                          <div className="col-4 font-weight-bold fs-14 py-1">
                            {i18n[lang].currentPackage}
                          </div>
                          <div className="col-6">
                            <span
                              className={`badge px-3 badge-${formatTextPlan(packageName)} `}
                            >
                              {packageName ? packageName : 'Pending'}{' '}
                            </span>{' '}
                            {packageDetail && packageDetail.is_cancel ? (
                              <span className="invalid-feedback-err">
                                {i18n[lang].expiryDate}  {packageExpiredAt}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 font-weight-bold fs-14 py-1">
                            {i18n[lang].payment}
                          </div>
                          <div className="col-6  py-1">

                            {packagePaymentDetail && packagePaymentDetail.payment_type
                              ? customLang(packagePaymentDetail.payment_type)
                              : '-'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 font-weight-bold fs-14 py-1">
                            {i18n[lang].paymentMethod}
                          </div>
                          <div className="col-6  py-1">
                            {packageName === 'Free Plan' ? '-' : <Fragment>
                              {infoData?.card_detail?.brand_card && (
                                <img
                                  src={`/assets/images/${infoData.card_detail.brand_card.toUpperCase()}.png`}
                                  alt={`${infoData?.card_detail.brand_card}`}
                                  height="20"
                                  className="mb-1"
                                />
                              )}
                              {infoData?.card_detail?.last_digits_card ? '*********' + infoData?.card_detail?.last_digits_card : '-'}

                            </Fragment>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 font-weight-bold fs-14 py-1">
                            {i18n[lang].nextPaymentDue}
                          </div>
                          <div className="col-6  py-1">
                            {packageName !== 'Free Plan' ? packagePaymentDetail && packagePaymentDetail.next_payment
                              ? packageDetail && packageDetail.is_cancel
                                ? '-'
                                : customDate(packagePaymentDetail.next_payment)
                              : '-' : '-'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 font-weight-bold fs-14 py-1">
                            {i18n[lang].users}
                          </div>
                          <div className="col-6  py-1">
                            {packageUserDetail && packageUserDetail.user_limit_used
                              ? packageUserDetail.user_limit_used.toLocaleString('en')
                              : '0'}
                            /
                            {packageUserDetail && packageUserDetail.package_user_quota
                              ? packageUserDetail.package_user_quota
                              : '0'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 font-weight-bold fs-14 py-1">
                            {i18n[lang].dailyScans}
                          </div>
                          <div className="col-6  py-1">
                            {packageScanUrlsDetail &&
                              packageScanUrlsDetail.scan_url_used
                              ? packageScanUrlsDetail.scan_url_used.toLocaleString(
                                'en'
                              )
                              : '0'}
                            /
                            {packageScanUrlsDetail &&
                              packageScanUrlsDetail.package_scan_url_quota
                              ? packageScanUrlsDetail.package_scan_url_quota.toLocaleString(
                                'en'
                              )
                              : '0'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6  py-1">
                      <div className="package-box text-center box-flex-center">
                        <p className="fs-14 font-weight-bold  mt-3">{i18n[lang].numberOfSessions}</p>
                        <p className="fs-24">
                          <b>
                            {packageSessionDetail && packageSessionDetail.session_used
                              ? packageSessionDetail.session_used.toLocaleString('en')
                              : '0'}{' '}
                          </b>
                          /
                          {packageSessionDetail &&
                            packageSessionDetail.package_session_quota
                            ? packageSessionDetail.package_session_quota.toLocaleString(
                              'en'
                            )
                            : '0'}
                        </p>
                        <p className="fs-14 my-2">
                          {packageDetail && packageDetail.is_cancel
                            ? '-'
                            : `${i18n[lang].resetDate}  ${customDateOneDay(packagePeriod)} `}
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-5'>
                <DataTable
                  pagination={true}
                  className="-fadeIn mt-0 none-overflow"
                  center={true}
                  columns={columns}
                  data={packageList}
                  customStyles={tableStyles}
                />
              </div>
            </div>
          </div>
        </ContentBox>


        <FooterBox>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 text-Gray text-8">
                  Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
                </div>
                <div className="col-sm-6 text-Gray text-8">
                  <div className="text-sm-end d-none d-sm-block">
                    <a
                      className="text-Gray"
                      href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                      target="_blank"
                    >
                      Privacy Policy.
                    </a>{' '}
                    <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                      Terms and conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </FooterBox>
      </div>
    </Fragment>
  )
}

export default PackageDetailsComponent
