import React, { Fragment, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { useHistory, useLocation } from 'react-router-dom'
import { checkEmailList } from '../../apis/authenication/api'
import { consentById } from '../../apis/management/api'
import {
  acceptConsentList,
  permissionUser,
  inviteRoleUser,
  resendInviteRoleUser,
  deleteUserPermission,
  updateUserPermission
} from '../../apis/management/api'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import { TableBox, RadioBox } from './style'
import Modal from 'react-modal'
import { TagsInput } from 'react-tag-input-component'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env

const UserManagementComponent = props => {
  const id = props.match.params.id

  const history = useHistory()
  const [listUserPermission, setListUserPermission] = useState()
  const [textSearch, setTextSearch] = useState('')
  const [paginationPage, setPaginationPage] = useState(1)
  const [deleteId, setDeleteId] = useState()
  const [userDetail, setUserDetail] = useState({})
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [emailValidateFail, setEmailValidateFail] = useState(false)
  const [showNotiSuccess, setShowNotiSuccess] = useState({
    message: 'เพิ่มผู้ใช้งานเรียบร้อย',
    show: false
  })
  const [showNotiFail, setShowNotiFail] = useState(false)
  const [isClickInvite, setIsClickInvite] = useState(false)
  const [addRole, setAddRole] = useState()
  const [isUpdate, setIsUpdate] = useState()
  const [userRole, setUserRole] = useState()
  const [getEmail, setGetEmail] = useState()
  const [selected, setSelected] = useState([])

  const [accountRole, setAccountRole] = useState('')
  const [lang, setLang] = useState(getCookie('lang'))

  const [isShowLoading, setIsShowLoading] = useState(false)

  useEffect(() => {
    setLang(props.langFn)
    if (listUserPermission) {
      getUserPermissionList()
    }

  }, [props.langFn])
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: 'auto',
      minWidth: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    }
  }

  const columns = [
    {
      name: i18n[lang].email,
      selector: row => row.email,
      width: '350px',
      sortable: true
    },
    {
      name: i18n[lang].accessLevel,
      selector: row => row.role
    },
    {
      name: i18n[lang].status,
      selector: row => row.status
    },
    {
      name: '',
      selector: row => row.option,
      width: '100px'
    }
  ]

  useEffect(() => {
    document.title = 'ผู้ใช้งาน | CookiePlus'
    getConsentById()
  }, [])
  useEffect(() => {
    getUserPermissionList()
  }, [accountRole])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [addRole])

  const getConsentById = async () => {
    const { data, status } = await consentById(id)
    if (data && data.data && status === 200) {
      setAccountRole(data.data.permission_consents[0].role)
    }
  }

  const inviteUser = async option => {
    setIsClickInvite(true)
    if (option === 'add') {
      let obj = {
        consent_id: id,
        emails: selected,
        role: userRole
      }
      const { data, status } = await inviteRoleUser(obj)
      if (status === 200) {
        getUserPermissionList()
        setShowNotiFail(false)
        setAddRole(false)
        setShowNotiSuccess({
          message: i18n[lang].userAdded,
          show: true
        })
        window.scrollTo(0, 0)
        setTimeout(() => {
          setShowNotiSuccess({
            message: i18n[lang].userAdded,
            show: false
          })
        }, 3000)
        setIsShowLoading(false)

      } else {
        setShowNotiFail(true)
        setIsShowLoading(false)
      }
    } else {
      let obj = {
        consent_id: id,
        permission_id: userDetail.id,
        role: userRole
      }
      const { data, status } = await updateUserPermission(obj)
      if (data) {
        getUserPermissionList()
        setShowNotiFail(false)
        setAddRole(false)
        setShowNotiSuccess({
          message: i18n[lang].userEdited,
          show: true
        })
        window.scrollTo(0, 0)
        setTimeout(() => {
          setShowNotiSuccess({
            message: '',
            show: false
          })
        }, 3000)
      } else {
        setShowNotiFail(true)
      }
    }
    setIsClickInvite(false)
  }

  const getUserPermissionList = async () => {
    const { data, status } = await permissionUser(id)
    let res = []
    if (data && data.data && data.data.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.length; i++) {
        _tempData = {
          email: data.data[i].user.email,
          role: (
            <p className="fs-14 m-0">
              {data.data[i].role === 'owner' ? (
                <Fragment>
                  <span>Admin</span> <span className="text-Secondary">(Owner)</span>
                </Fragment>
              ) : (
                <span> {data.data[i].role}</span>
              )}
            </p>
          ),
          status: data.data[i].verified ? (
            <p className="fs-14 m-0">{i18n[props.langFn].normal}</p>
          ) : (
            <p
              className="fs-14 m-0"
              onClick={() => resendInvite(data.data[i].id, data.data[i].user.email)}
            >
              {i18n[props.langFn].waitForEmailConfirmation}{' '}
              <a className="btn-link">
                <img
                  src="../assets/images/paperplane.png"
                  alt="cookieplus"
                  style={{ width: 11, marginLeft: 10, marginRight: 3 }}
                />{' '}
                {i18n[props.langFn].sendAgain}
              </a>
            </p>
          ),
          option: data.data[i].role !== 'owner' && (
            <Fragment>
              {(accountRole === 'admin' || accountRole === 'owner') && (
                <Fragment>
                  {' '}
                  <span
                    className="link-edit px-2"
                    onClick={() => {
                      setAddRole(true)
                      roleFn('UPDATE')
                      setUserDetail(data.data[i])
                      setUserRole(data.data[i].role)
                    }}
                  >
                    <i className="mdi mdi-lead-pencil"></i>
                  </span>
                  <span
                    className="link-trash px-2"
                    onClick={() => {
                      popupDelete(data.data[i].id, data.data[i].user.email)
                    }}
                  >
                    <i className="mdi mdi-trash-can"></i>
                  </span>
                </Fragment>
              )}
            </Fragment>
          )
        }

        res.push(_tempData)
      }
      setListUserPermission(res)
    } else {
      setListUserPermission()
    }
  }

  const handleChangePage = async e => {
    if (e * 10 === listUserPermission.length) {
      let page = paginationPage + 1
      setPaginationPage(page)
      await getUserPermissionList(page, textSearch)
    }
  }

  const popupDelete = async (permission_id, email) => {
    setDeleteId(permission_id)
    setOpenPopupDelete(true)
    setGetEmail(email)
  }

  const deleteUser = async () => {
    let obj = {
      consent_id: id,
      permission_id: deleteId
    }
    const { data, status } = await deleteUserPermission(obj)
    if (status === 200) {
      setOpenPopupDelete(false)
      setShowNotiFail(false)
      setShowNotiSuccess({
        message: i18n[lang].userDeletedSuccessfully,
        show: true
      })
      getUserPermissionList()
      setTimeout(() => {
        setShowNotiSuccess({
          message: '',
          show: false
        })
      }, 3000)
    } else {
      setOpenPopupDelete(false)
      setShowNotiFail(true)
    }
  }

  const roleFn = option => {
    if (option === 'ADD') {
      setIsUpdate(false)
    } else {
      setIsUpdate(true)
    }
  }

  const checkEmail = async value => {
    setSelected(value)
    let email = value[value.length - 1]
    if (value.length !== 0) {
      const { data } = await checkEmailList(id, [email])
      if (data && data.message && data.message !== 'success') {
        var allSelects = document.getElementsByClassName('rti--tag')
        var lastSelect = allSelects[allSelects.length - 1]
        setEmailValidateFail(true)
        lastSelect.classList.add('tag-red')
      } else {
        setEmailValidateFail(false)
      }
    }
  }

  const handleChangeRole = e => {
    setUserRole(e.target.value)
  }

  const resendInvite = async (permission_id, email) => {
    let obj = {
      consent_id: id,
      permission_id: permission_id
    }
    const { status } = await resendInviteRoleUser(obj)
    if (status === 200) {
      setShowNotiSuccess({
        message: `${i18n[lang].emailSent} ${email}`,
        show: true
      })
      setTimeout(() => {
        setShowNotiSuccess({
          message: ``,
          show: false
        })
      }, 3000)
    }
  }

  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />

      <div className="main-content">
        {showNotiSuccess.show && (
          <div
            className="alert alert-success alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
            role="alert"
          >
            <i className="ri-check-double-line label-icon"></i>
            {showNotiSuccess.message}
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}

        {showNotiFail && (
          <div
            className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
            role="alert"
          >
            <i className="ri-error-warning-line label-icon"></i>
            {i18n[lang].errorOccurred}
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}

        <div className="page-content">
          {!addRole ? (
            <Fragment>
              <div className="container-fluid">
                <div className="card">
                  <div className="card-body py-2">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="card-title mb-0 py-2">{i18n[lang].users} </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        {' '}
                        {(accountRole === 'admin' || accountRole === 'owner') && (
                          <button
                            onClick={() => {
                              setAddRole(true)
                              roleFn('ADD')
                              setSelected([])
                              setUserRole()

                            }}

                            className="btn btn-primary btn-label float-end mx-2 btn-load"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <i className="mdi mdi-plus-circle-outline label-icon align-middle fs-16 me-2"></i>

                              </div>
                            </div>
                            <div className="flex-grow-1">{i18n[lang].addUser}</div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body card-table-fix">
                    <div id="customerList">
                      <div className="table-responsive table-card  mb-1">
                        <TableBox>
                          {listUserPermission ? (
                            <DataTable
                              pagination={true}
                              onChangePage={e => handleChangePage(e)}
                              className="-fadeIn mt-0"
                              center={true}
                              columns={columns}
                              data={listUserPermission}
                              customStyles={tableStyles}
                            />
                          ) : (
                            <div className="zero-center">
                              <div className="zero-content">
                                <img
                                  src="../assets/images/Zero-domain.png"
                                  alt="cookieplus"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          )}
                        </TableBox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="container-fluid">
                <div className="card">
                  <div className="card-body py-2">
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="card-title mb-0 py-2">
                          {isUpdate ? i18n[lang].editUserAccess : i18n[lang].addUser}{' '}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body card-table-fix">
                    <div className="card-table-fix overflow-hidden">
                      <div className="col-lg-12">
                        <div className="mb-2">
                          <label htmlFor="firstnameInput" className="form-label font-weight-bold">
                            {i18n[lang].emailLabel} <span className="text-danger">*</span>{' '}
                            <span className="fs-14 text-Secondary m-0 font-weight-normal">
                              {i18n[lang].pleaseEmail}
                            </span>
                          </label>
                          {isUpdate ? (
                            <input
                              className="form-control"
                              value={userDetail.user.email}
                              type="text"
                              disabled={true}
                            />
                          ) : (
                            <TagsInput
                              value={selected}
                              onChange={e => checkEmail(e)}
                              name="email"
                            />
                          )}
                          {emailValidateFail && (
                            <label className="text-red">
                              {i18n[lang].emailNotRegistered}
                            </label>
                          )}
                        </div>
                      </div>
                      <h6 className="card-title mb-0 py-2">{i18n[lang].accessLevel} </h6>
                      <RadioBox>
                        <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                          <input
                            className="form-check-input mr-2"
                            type="radio"
                            name="inlineRadioOptions"
                            id="WithoutinlineRadio1"
                            value="admin"
                            onChange={handleChangeRole}
                            checked={userRole === 'owner' || userRole === 'admin'}
                          />
                          <label className="form-check-label" htmlFor="formCheck1">
                            <p className="fs-14 m-0">Admin</p>
                            <p className="fs-14 text-Secondary m-0">
                              {i18n[lang].adminDescription}
                            </p>
                          </label>
                        </div>
                        <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                          <input
                            className="form-check-input mr-2"
                            type="radio"
                            name="inlineRadioOptions"
                            id="WithoutinlineRadio2"
                            value="editor"
                            onChange={handleChangeRole}
                            checked={userRole === 'editor'}
                          />
                          <label className="form-check-label" htmlFor="formCheck1">
                            <p className="fs-14 m-0">Editor</p>
                            <p className="fs-14 text-Secondary m-0">
                              {i18n[lang].editorDescription}
                            </p>
                          </label>
                        </div>
                        <div className="form-check form-radio-outline form-radio-primary my-3 p-0">
                          <input
                            className="form-check-input mr-2"
                            type="radio"
                            name="inlineRadioOptions"
                            id="WithoutinlineRadio3"
                            value="viewer"
                            onChange={handleChangeRole}
                            checked={userRole === 'viewer'}
                          />
                          <label className="form-check-label" htmlFor="formCheck1">
                            <p className="fs-14 m-0">Viewer</p>
                            <p className="fs-14 text-Secondary m-0">
                              {i18n[lang].viewerDescription}
                            </p>
                          </label>
                        </div>
                      </RadioBox>
                    </div>

                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          onClick={() => setAddRole(false)}
                          type="button"
                          className="btn btn-outline-primary waves-effect waves-light px-5"
                        >
                          {i18n[lang].cancel}
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary px-5 btn-load"
                          disabled={
                            isUpdate
                              ? false
                              : userRole && selected.length > 0 && !emailValidateFail
                                ? isShowLoading
                                : isShowLoading && isShowLoading
                          }
                          onClick={() => (
                            !isClickInvite ? inviteUser(isUpdate ? 'update' : 'add') : ''
                            , setIsShowLoading(true))}
                        >
                          {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                          </span><span className="flex-grow-1 ms-2 mr-2">
                              {isUpdate ? i18n[lang].save : i18n[lang].addUser}
                            </span>   </Fragment> : <div className="flex-grow-1">{isUpdate ? i18n[lang].save : i18n[lang].addUser}</div>
                          }

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupDelete}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupDelete(false)}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#ed3451,secondary:#c72e46"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>
                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong>{i18n[lang].deleteUser}</strong>
                  </label>
                  <br />
                  <span>
                    <b>{getEmail}</b>
                    <br />
                    {i18n[lang].confirmDeleteUser}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className={`btn w-lg btn-outline-danger waves-effect waves-light  `}
                  onClick={() => deleteUser()}
                >
                  {i18n[lang].confirmUserDeletion}
                </button>
                <button
                  type="button"
                  className="btn w-lg btn-danger waves-effect waves-light"
                  onClick={() => setOpenPopupDelete(false)}
                >
                  {i18n[lang].cancel}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default UserManagementComponent
