const enumCode = {
  en: {
    1002: 'invalid input',
    5001: 'Free package quota exceeded',
    6001: 'Some value is undefined',
    6002: 'Some value is invalid',
    6101: 'Can`t create customer omise',
    6102: 'Can`t charge order omise',
    6103: 'Payment transaction not found',
    6104: 'Can`t get status charge omise',
    6105: 'Can`t update payment account',
    6106: 'Can`t delete card customer omise',
    6107: 'Payment account not found',
    6108: 'Can`t add default card customer omise',
    6109: 'Can`t refund omise',
    6110: 'Can`t redeem code'
  },
  th: {
    1002: 'ข้อมูลไม่ถูกต้อง',
    5001: 'เกินกำหนดแพ็กเกจ',
    6001: 'Some value is undefined',
    6002: 'Some value is invalid',
    6101: 'Can`t create customer omise',
    6102: 'Can`t charge order omise',
    6103: 'Payment transaction not found',
    6104: 'Can`t get status charge omise',
    6105: 'Can`t update payment account',
    6106: 'Can`t delete card customer omise',
    6107: 'Payment account not found',
    6108: 'Can`t add default card customer omise',
    6109: 'Can`t refund omise',
    6110: 'Can`t redeem code'
  }
}

export { enumCode }
