const i18n = {
  en: {
    signin: 'Sign in',
    haveAccount: 'Already have an account with us?',
    signup: 'Sign Up',
    notHaveAcc: `Don't have an account with us yet?`,
    email: 'Email',
    resendEmail: 'Resend verification email',
    password: 'Password',
    ConfirmPassword: 'Confirm password',
    forgotPassword: 'Forgot your password',
    welcome: 'Welcome',
    acceptTerm: 'I accept',
    acceptService: 'the Terms of Service',
    privacyPolicy: 'Privacy Policy.',
    backToLogin: 'Return to the login page',
    sendEmailDone: 'Email sent successfully.',
    sendEmailFail: 'Failed to send email.',
    registerFail: 'Unable to register.',
    pleaseAcceptTerm: 'Please accept the terms of service and privacy policy to register.',
    pleaseTryAgian: 'Please try again',
    sendAgian: 'Send again',
    enterEmailResetPassword: 'Enter your email address to receive a password reset link',
    emailForLogin: 'Email used for sign in',
    requestResetPassword1: 'A password reset request has been sent to the specified email address.',
    requestResetPassword2: 'Please click the link in the email to reset your password.',
    enterEmail: 'Please enter an email',
    emailWrongFormat: 'Email format is incorrect',
    domainName: 'Domain name',
    addDomain: 'Add domain',
    manageDomain: 'Domain Management',
    domain: 'Domain',
    numberOfCookies: 'Number of cookies',
    status: 'Status',
    bannerVersion: 'Banner version',
    publicationDate: 'Publication date',

    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    add: 'Add',
    cookie: 'Cookie',

    published: 'Published',
    notYetPublished: 'Not yet published',
    updatesNotPublished: 'There are updates that have not yet been published.',

    package: 'Package',
    paymentInformation: 'Payment information',
    receipt: 'Receipt',
    chooseDomain: 'Choose a domain',
    pricesAndPackages: 'Prices and packages',
    save20Percent: 'Save 20% when paying for the annual package',
    monthly: 'Monthly',
    yearly: 'Yearly',
    discount20Percent: '20% discount',
    noteSessions:
      ' Sessions are counted by the number of website visitors. It will expire after 24 hours and begin counting as a new session.',

    forBeginners: 'For Beginners',
    smallBusinesses: 'Small businesses or websites',
    mediumBusinesses: 'Medium businesses or websites',
    largeBusinesses: 'Large businesses or websites',
    unlimited: 'Unlimited',

    compatibleWith: 'Compatible with',
    perMonth: 'per month',
    upToUser: 'Up to',
    user: 'user',
    scan: 'Scan',
    consentRecords: 'Consent records',
    storeDataFor: 'Store data for up to',
    months: 'Months',
    month: 'Month',
    year: 'Year',
    day: 'Day',
    thb: 'THB',
    customizeDetailedBanner: 'Customize detailed banner',
    customizeLogoAndIcons: 'Customize logo and icons',
    unlimitedConsentRecords: 'Unlimited consent records',
    unlimitedNumberOfUsers: 'Unlimited number of users',
    selectPackage: 'Select package',

    contactUs: 'Contact Us',
    largeUsage:
      'Websites or businesses that need to handle large amounts of traffic, you can customize the usage plan as needed.',
    customizeUsages: 'Customize the appropriate number of usages.',
    dedicatedSupport: 'Dedicated support by Account Manager​',

    urlFormatError: 'URL format is incorrect. Please specify http:// or https://',
    remainingCredit: 'Remaining credit',

    taxInfo: 'Information for issuing tax invoices/ receipts',
    naturalPerson: 'Personal',
    legalEntity: 'Company',
    name: 'Name',
    surname: 'Surname',
    address: 'Address',
    subdistrict: 'Subdistrict',
    district: 'District',
    province: 'Province',
    zipCode: 'Zip code',
    mobileNumber: 'Mobile Number',
    noteTaxInfo:
      '(Note: Data updates do not affect tax invoices/receipts that have already been processed.)',
    update: 'Update',
    payment: 'Payment',
    cardNumber: 'Card number',
    cardholderName: 'Cardholder name',
    expirationDate: 'Expiration date (month/year)',
    cvv: 'CVV',
    securePayment: 'Secure payment system by Opn Payments',
    note: 'Note',
    verificationCharge:
      'The system will try to deduct money to verify the credit card and will refund within 24 hours.',
    primaryPaymentCard:
      'The credit card you enter will be the primary payment card for all domains.',
    agreeToTerms: "By clicking “Update”, you agree to CookiePlus'",
    termsOfService: 'Terms of Service',
    and: 'and',
    confirmDeleteDomain: 'Do you want to delete the domain?',
    confirmDeleteDomainNote:
      'Deleting the domain will delete all data and cannot be recovered. Type "Delete" in the field below to confirm domain deletion.',
    confirmDomainDeletion: 'Confirm deletion',
    freePlanExists: 'Free Plan package already exists.',
    cancelPackageTitle: 'Cancel package',
    cancelPackage: 'Do you want to cancel the package',
    ofDomain: 'for domain',
    questionMark: '?',
    packageActiveUntil:
      'Your current package will remain active until the billing cycle end date on',

    pleaseNote: 'Please note!',
    domainSuspensionNote:
      'At the end of the billing cycle, the domain will be suspended and the banner will stop working.',
    reactivationOptionNote:
      'You can choose a package at any time to reactivate the domain and banner within 30 days. After 30 days,',
    permanentDeletionNote: 'the domain will be permanently deleted and data cannot be recovered.',

    packageCanceled: 'Package canceled.',
    sorryToSeeYouGo: "We're sorry to see you go.",
    close: 'Close',
    somethingWentWrong: 'Something went wrong. Please try again. ',
    ifYouNeedAssistance: 'If you need assistance, email us at support@cookieplus.com',
    failedToCancelPackage: 'Failed to cancel package.',
    promotionUnavailable: 'Unable to use promotion.',
    promotionExpired: 'Discount coupon code or promotion code may be fully redeemed or expired.',
    paymentFailed: 'Unable to process payment. Please check the information is correct.',
    codeInvalidExpired: 'Code is invalid or expired.',
    profileUpdated: 'Profile information updated successfully.',
    profileUpdateFailed: 'Failed to update profile information.',
    privacyUpdated: 'Privacy information updated successfully',
    privacyUpdateFailed: 'Failed to update privacy information.',
    passwordChanged: 'New password changed successfully.',
    passwordChangeFailed: 'Failed to change new password.',
    userAdded: 'User added successfully.',
    userEdited: 'User edited successfully.',
    emailSent: 'Confirmation email sent to ',
    errorOccurred: 'Error occurred. Please try again.',
    deleteUser: 'Delete user?',
    confirmDeleteUser: 'Do you want to remove this user from your domain?',
    confirmUserDeletion: 'Confirm deletion',
    addCookie: 'Add cookie',
    editCookie: 'Edit cookie',
    cookieName: 'Cookie name',
    cookieDuration: 'Cookie duration',
    yearMonthDay: 'year month day',
    cookieCategory: 'Cookie category',
    description: 'Description',
    confirmDeleteCookie: 'Do you want to delete cookie?',
    deleteCookieNote:
      'Once deleted, it cannot be recovered. To recover cookie to the list, You need to scan cookie again.',
    confirmCookieDeletion: 'Confirm deletion of cookie',
    publishBannerVersion: 'Publish banner version ',
    requestConsentAgain:
      'Do you want to request consent from website users again? The cookie banner will be displayed again to request consent from new website users.',
    confirmPublish: 'Confirm publishing',
    yayPublished: 'Yay! Successfully published.',
    bannerPublished: 'Your banner has been successfully published.',
    displayUpdate: 'The display will be updated shortly.',
    oopsSomethingWrong: 'Oops! Something went wrong',
    bannerPublishingFailed: 'Banner publishing failed.',
    tryAgainPlease: 'Please try again.',
    tryAgain: 'Try again',
    fileTooLarge:
      'This file exceeds the maximum download size. Please contact support for information.',
    serverConnectionError:
      'Sorry! Server connection error. Please confirm you have an internet connection, then try again in a moment.',
    sorry: 'Sorry!',
    settingsSaved: 'Settings saved successfully.',
    settingsSaveFailed: 'Failed to save settings.',
    confirmOverQuota: 'Do you want to enable usage beyond the package ',
    confirmOverQuotaSession: 'session quota (additional charges apply)?',

    overQuotaCharge:
      'Charges will be billed each usage month at 5,000 baht per 100,000 additional sessions',
    iUnderstand: 'I understand',
    letMeThink: 'Let me think about it',
    editCategory: 'Edit category',
    addCategory: 'Add category',
    categoryName: 'Category name',
    displayName: 'Display name',
    categoryId: 'Category ID',
    categoryDescription: 'Category description',
    addCookieToCategory: 'Add cookie to the category',
    noCookiesInCategory: 'No cookies found in category',
    confirmDeleteCategory: 'Do you want to delete this cookie category?',
    deleteCategoryNote: 'Cookies in this category will remain in the "Uncategorized" cookie list. ',
    deleteCategoryNoteManage:
      'You can manage cookie categories or add cookies to other categories.',
    confirmRemoveCategory: 'Confirm removal',
    confirmRemoveCookie: 'Do you want to remove this cookie from the category?',
    removeCookieNote: 'This Cookie will be in the "Uncategorized" cookie list.',
    removeCookieNoteManage: 'You can manage cookie categories or add cookies to categories again.',
    confirmDeactivate: 'Confirm deactivation',
    confirmDeactivateCategory: 'Confirm deactivation of this cookie category',

    userSettings: 'User settings',
    profile: 'Profile',
    changePassword: 'Change password',
    originalPassword: 'Original password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    pleaseEnterEmail: 'Please enter an email',
    incorrectPassword: 'Incorrect password. Please try again',
    passwordConfirmationIncorrect: 'Password confirmation is incorrect',
    passwordIncorrect: 'Password is incorrect',
    passwordMustContain: 'Password must contain:',
    atLeast8Characters: 'At least 8 characters',
    atLeastOneLowercase: 'At least one lowercase letter (a-z)',
    atLeastOneUppercase: 'At least one uppercase letter (A-Z)',
    atLeastOneNumber: 'At least one number (0-9)',
    atLeastOneSpecialChar: 'At least one special character (!@#$%^*-_=)',
    registrationCompleted: 'Registration completed successfully',
    confirmationLinkSent: 'CookiePlus has sent a confirmation link to your email',
    clickLinkToVerify:
      'Please click the link in the email to verify your identity before logging in',
    ifNoEmailReceived: "If you don't receive the email within 5 minutes",
    emailFormatIncorrect: 'Email format is incorrect',
    submitPasswordResetRequest: 'Submit a password reset request',

    consentRecord: 'Consent records',
    search: 'Search',
    date: 'Date',
    consentID: 'Consent ID',
    ipAddress: 'IP Address',
    page: 'Page',
    consent: 'Consent',
    consented: 'Consent',

    acceptAll: 'Accept all',
    acceptSome: 'Accept some',
    closeBanner: 'Close banner',

    necessaryCookies: 'Necessary cookies',
    performanceCookies: 'Performance cookies',
    marketingCookies: 'Marketing cookies',
    functionalityCookies: 'Functionality cookies',
    noSearchResults: "We couldn't find any results matching your search.",

    deleteCookieConfirmation: 'Delete cookie',
    deleteCookieMessage: 'Do you want to delete cookie?',
    deleteWarning: 'Once deleted, it cannot be recovered',
    deleteRecovery: 'To recover cookie to the list, You need to scan cookie again.',
    confirmDeletion: 'Confirm deletion of cookie',

    scanCookies: 'Scan cookies',
    language: 'Language',
    thai: 'Thai',
    english: 'English',
    latestScan: 'Latest scan',
    stopScanning: 'Stop scanning',
    startManagingCookies: 'Press the “Scan cookies” button to start managing cookies.',
    scanningInProgress:
      'We are performing a cookie scan on the website. This may take several minutes.',
    scanComplete: 'The cookie will be displayed when the scan is complete.',

    bannerManagement: 'Banner management',
    bannerLanguage: 'Language',
    languageEn: 'English',
    desktop: 'Desktop',
    mobilePhone: 'Mobile',
    mobileAndTablet: 'Mobile/Tablet',
    bannerDefaultLanguage: 'Banner default language',
    automaticLanguageDetection: 'Automatic language detection',
    detectLanguageFromHtml: 'Detect language ',
    detectLanguageFromHtmlTH: 'from HTML lang attribute',
    specifyLanguageInTag: 'Specifying the language in the html tag, such as <html lang="en">',
    detectLanguageFromCookies: 'Detect language from cookies',
    enterCookieNameValue: "Enter the name of the cookie that sets the website's language. ",
    enterCookieName: 'The value must be set with a 2-letter language code, such as en, th',
    bannerFormat: 'Banner format',
    default: 'Default',
    fullBottom: 'Full Bottom',
    fullTop: 'Full Top',
    popup: 'Pop-up',
    bottomLeft: 'Bottom Left',
    topLeft: 'Top Left',
    bottomRight: 'Bottom Right',
    topRight: 'Top Right',
    text: 'Text',
    displayTitle: 'Display title',
    titleText: 'Title text',
    bodyText: 'Body text',
    titleFontSize: 'Title font size',
    bodyFontSize: 'Body font size',
    cookiePolicy: 'Cookie Policy',
    customize: 'Customize',
    cookiePolicyText: 'Cookie policy text',
    policyName: 'Policy name',
    cookiePolicyLink: 'Cookie policy link',
    colorsAndButtonsSettings: 'Colors and buttons settings',
    basicBannerStyleSettings: 'Basic banner style settings',
    backgroundColor: 'Background color',
    fontColorTitle: 'Font color (title)',
    fontColorText: 'Font color (text)',
    cookiePolicyButtonSettings: "'Cookie Policy' button settings",
    cookiePolicyButtonColor: "'Cookie Policy' button color",
    cookieSettingsButtonSettings: "'Cookie Settings' button settings",
    displayCookieSettingsButton: "Display 'Cookie Settings' button in button format",
    cookieSettingsButtonColor: "'Cookie Settings' button color",
    cookieSettingsButtonTextColor: "'Cookie Settings' button text color",
    cookieSettingsButtonText: "'Cookie Settings' button text",
    confirmMySettings: "'Confirm my settings' button text",
    confirmSettings: 'Confirm my settings',
    acceptButtonSettings: "'Accept' button settings",
    acceptButtonBackgroundColor: "'Accept' button background color",
    acceptButtonTextColor: "'Accept' button text color",
    acceptButtonText: "'Accept' button text",
    declineButtonSettings: "'Decline' button settings",
    displayButton: 'Display button',
    declineButtonColor: "'Decline' button color",
    declineButtonTextColor: "'Decline' button text color",
    declineButtonText: "'Decline' button text",
    declineAll: 'Decline all',
    closeBannerButtonX: "Close banner button 'x'",
    bannerLogo: 'Banner logo',
    displayLogo: 'Display logo',
    cookiePlusLogo: 'CookiePlus logo',
    uploadLogo: 'Upload logo',
    logoDisplayNote: 'The logo is displayed at the bottom of banner and cookie settings button',
    clickToSelectLogoFile: 'Click to select logo file.',
    logoFileSupport: 'Support files up to 1MB, file types .PNG, .JPG.',
    logoFileSupportRecommended: '(Recommended size 32 x 32 px or 1:1)',
    cookieSettingsIcon: 'Cookie settings icon',
    displayIconOnWebsite: 'Display icon on website',
    preview: 'Preview',
    cookieSettingsIconNote:
      'Cookie settings icon is displayed on the website to allow users to change their cookie settings',
    defaultSettings: 'Default settings',
    uploadImage: 'Upload image',
    clickToSelectIconFile: 'Click to select icon file',
    iconFileSupport:
      'Support files up to 1MB, file types .PNG, .JPG. (Recommended size 32 x 32 px or 1:1)',
    customizeCss: 'Customize CSS',
    addCss: 'Add the CSS you want to customize',
    cssNote: 'Note: Font Family will not be displayed in Live Preview.',
    cookieSettingsManagement: "'Cookie Settings' management",
    collapseCookieDetails: 'Collapse cookie details',
    bannerButtonCode:
      "This is the code for setting a button or link on a website to display 'Cookie settings' banner. Copy the code below and place it on the button or text link you want.",

    addCookieCategory: 'Add cookie category',
    cookieList: 'Cookie list',
    remove: 'Remove',

    activate: 'Activate',
    confirmDeactivation: 'Confirm deactivation',
    confirmCategoryDeactivation: 'Confirm deactivation of this cookie category',
    deactivate: 'Deactivate',

    removeFromCategoryPrompt:
      "Do you want to remove this cookie from the category? This Cookie will be in the 'Uncategorized' cookie list. You can manage cookie categories or add cookies to categories again.",
    confirmRemoval: 'Confirm removal',
    categoryLanguage: 'Language',
    delete: 'Delete',
    category: 'Category',

    addDomainButton: "Click the 'Add Domain' button to start managing domains",
    deleteDomainPrompt: 'Do you want to delete the domain?',
    deleteDomainWarning: 'Deleting the domain will delete all data and cannot be recovered. Type ',
    deleteDomainWarningInput: ' in the field below to confirm domain deletion.',
    confirmDeletionDomain: 'Confirm deletion',

    usageCustomization:
      'Websites or businesses that need to handle large amounts of traffic, you can customize the usage plan as needed.',
    appropriateUsage: 'Customize the appropriate number of usages.',
    currentPackage: 'Current package',
    paymentMethod: 'Payment method',
    nextPaymentDue: 'Next payment due',
    dailyScans: 'Daily website scans',
    numberOfSessions: 'Number of Sessions',
    resetDate: 'Reset date',
    companyName: 'Company name',
    taxID: 'Tax ID number',
    packageEndDate:
      'Your current package will remain active until the billing cycle end date on [date]',
    enterFirstName: 'Please enter first name',
    enterLastName: 'Please enter last name',
    enterCompanyName: 'Please enter company name',
    enterAddress: 'Please enter address',
    enterSubdistrict: 'Please enter subdistrict',
    enterDistrict: 'Please enter district',
    enterProvince: 'Please enter province',
    enterZipCode: 'Please enter zip code',
    enterPhoneNumber: 'Please enter mobile number',
    enterTaxID: 'Please enter tax ID number',
    enterCardNumber: 'Please enter card number',
    enterCardholderName: 'Please enter cardholder name',
    enterExpirationDate: 'Please enter expiration date',
    enterCVV: 'Please enter CVV number',
    creditCard: 'Credit card',
    cardNumberEndsWith: 'Card number ends with',
    changeCreditCard: 'Change credit card',
    orderNumber: 'Order number',
    paymentDate: 'Payment date',
    updateSuccessful: 'Update successful',
    updateFailed: 'Update failed',

    packagePrice: 'Package price',
    remainingPackageValue: 'Remaining package value',
    deductedFromCredit: 'Deducted from remaining credit',
    withholdingTax: 'Withholding tax 3%',
    total: 'Total',
    paymentSuccessful: 'Payment successful',
    processing: 'Processing',
    download: 'Download',
    expiryDate: 'Expiry date',
    nameSurname: 'Name-Surname',
    type: 'Type',
    backToPackage: 'Back to package page',
    orderSummary: 'Order summary',
    items: 'Items',
    totalAmount: 'Total amount (Baht)',
    discount: 'Discount',
    deductedFromPackageValue: 'Deducted from remaining package value',
    balanceFromPrevious: 'Balance from previous package',
    remainingCreditStored: 'Remaining value after discount deduction will be stored as credit',
    availableCredit: 'Available credit',
    priceBeforeVAT: 'Price before Value Added Tax (VAT)',
    haveCoupon: 'Do you have a discount coupon or referral code?',
    redeem: 'Redeem',
    withholdingTax3: 'Withholding tax 3%',
    netTotal: 'Net total',
    pay: 'Pay',
    autoRenewalAgreement:
      'By clicking "Pay", you accept and agree to automatic renewal which can be cancelled on the package page',
    paymentSuccessMessage: 'Payment successful',
    paymentFailureMessage: 'Payment failed',
    orderNumberLabel: 'Order number',
    paymentThankYou: 'Thank you for your CookiePlus payment',
    orderEmailNotification: 'We will send the order details to your email',
    needAssistance: 'If you need assistance, email us at',
    backToWebsite: 'Back to website',
    withholdingTaxCertificateNote: 'Please send the withholding tax certificate within 15 days to:',
    companyNameNote: 'Predictive Company Limited (Head Office)',
    addressNote: '10/35 The Trendy Building, 1A Floor, Soi Sukhumvit 13 (Sang Chan)',
    cityNote: 'Klongtoey Nua, Wattana, Bangkok 10110',
    creditCardVerificationNote:
      "Note: The system will try to deduct money to verify the credit card and will refund within 24 hours. The credit card you enter will be the primary payment card for all domains. By clicking “Update”, you agree to CookiePlus' Terms of Service and Privacy Policy.",
    domainSuspensionNotice:
      'Please note! At the end of the billing cycle, the domain will be suspended and the banner will stop working. You can choose a package at any time to reactivate the domain and banner within 30 days. After 30 days, the domain will be permanently deleted and data cannot be recovered.',
    cancelPackageConfirmation: 'Do you want to cancel the package for domain XXX?',
    searchTerm: 'Search term',
    ok: 'OK',
    agreeToAutomaticRenewal: 'and agree to automatic renewal',
    canBeCancelledOnPackagePage: 'which can be cancelled on the package page',

    test: 'test',
    enterEmailVerify: 'Enter your email to receive an account verification link',
    emailUsedRegister: 'Email used for account registration',
    uableSendPassword: 'Unable to send password reset request.',
    uableSendVerify: 'Unable to send account verification email.',
    emailNotFound: 'Email not found. Please check if you entered the correct email.',
    passwordSuccess: 'Password set successfully.',
    pleaseEnterNewPassword: 'Please enter the new password you want to use to log in',
    addNewPassword: 'Set new password',
    identitySuccess: 'Identity verification successful.',
    identityFailContact: 'Identity verification failed. Contact support at support@cookieplus.com',
    identityFailTry:
      'Identity verification failed. Please try again or contact support at support@cookieplus.com',
    loginFailed: 'Login failed. Please try again.',
    invitedSuccess: 'Invitation accepted successfully.',
    invitedFailed: 'Failed to accept invitation.',
    emailOrPasswordFailed: 'The email address or password you entered is incorrect.',
    enterPassword: 'Please enter password.',
    OpsCantFindPage: `Oops! We can't find the page you're looking for`,
    error: 'ERROR',
    forbiden: 'Forbiden',
    noAccess: `It looks like you don't have access to this section`,
    amount: 'Amount',
    quotationNote: 'Note: If you need a quotation, please contact us via Live Chat or email',
    users: 'User',
    cannotScanDomain: 'Cannot scan this domain',
    scanEvery: 'Scan every',
    listPagesWithCookies: 'List of pages where cookies were found',
    enterCookieNames: 'Please enter cookie name',
    addCookiesToCategory: 'Click the "Add cookies to category" button',
    startManagingCookie: 'to start managing cookies in the category',
    publication: 'Publication',
    publishBanner: 'Publish banner',
    requestNewConsent: 'Do you want to request consent from website users again?',
    bannerDisplayedAgain:
      'The cookie banner will be displayed again to request consent from new website users.',
    confirmPublishing: 'Confirm publishing',
    bannerPublishedSuccess:
      'Your banner has been successfully published. The display will be updated shortly.',
    bannerStatus: 'Banner status',
    unpublishedUpdates: 'You have unpublished updates',
    clickPublishBanner: 'Click "Publish Banner" to start managing banners',
    bannerInactive: 'Banner inactive',
    bannerActive: 'Banner active',
    updatedToLatest: 'Updated to the latest version',
    currentBannerVersion: 'Current banner version',
    lastPublishedOn: 'Last published on',
    cookieBannerScript: 'Cookie Banner Installation Script',
    copyScriptInstructions:
      'Copy the script below and paste it at the top of your website, between the <head> and </head> tags on every page. If you customize the banner, cookies, or cookie categories, simply publish the banner again. The banner will update automatically without needing to re-copy and install the script',
    copyScript: 'Copy script',
    requestForNewConsent: 'Request for new consent',
    yes: 'Yes',
    no: 'No',
    display: 'Display',
    passwordNotMatch: `Password doesn't match.`,
    settings: 'Settings',
    ipAddressStorage: 'IP Address storage',
    storeIpAddressFullConsent: 'Store IP Address when user gives full consent',
    storeIpAddressPartialConsent: 'Store IP Address when user gives partial consent',
    storeIpAddressDeclinesConsent: 'Store IP Address when user declines consent',
    displayIpAddressInConsentRecord: 'Display IP Address in Consent record',
    dataRetentionPeriod: 'Data retention period',
    dataRetentionInfo:
      'Maximum data retention period depends on your chosen package. Data exceeding the specified period will be deleted from the system',
    automaticCookieScan: 'Automatic cookie scan',
    automaticCookieScanInfo:
      "System will automatically scan cookies based on settings. If you don't want automatic scanning, select 'No schedule'",
    noSchedule: 'No schedule',
    every7Days: 'Every 7 days',
    every15Days: 'Every 15 days',
    every30Days: 'Every 30 days',
    days: ' Days',
    usageQuota: 'Usage quota',
    enableUsageBeyondQuota: 'Enable usage beyond package session quota (additional charges apply)',
    usageQuotaInfo:
      'If enabled, the banner will continue to function normally after reaching the package session limit, with additional costs.\n\nUsage quota increases by 100,000 sessions at a price of 5,000 baht per 100,000 additional sessions',
    enableUsageBeyondQuotaPrompt:
      'Do you want to enable usage beyond the package session quota (additional charges apply)?',
    usageChargesInfo: 'Charges will be billed each usage month ',
    usageChargesInfoSessions: 'at 5,000 baht per 100,000 additional sessions',
    callBackFunction: 'CallBack function (JavaScript)',
    enableCallBackFunction: 'Enable CallBack function',
    callBackFunctionInfo:
      'When enabled, CookiePlus will send acceptance or rejection values to the specified function',
    specifyFunctionName: 'Specify function name',
    errorPage: 'Error page',
    backToHome: 'Back to home',
    pageNotFound: "Oops! We can't find the page you're looking for",
    saveSettings: 'Save settings',
    activity: 'Activity',
    settingsUpdatedSuccessfully: 'Settings updated successfully.',
    settingsUpdateFailed: 'Settings update failed.',
    activityLog: 'Activity log',

    addUser: 'Add user',
    pleaseEmail: ' (Please press "Enter" after adding user email)',
    emailNotRegistered:
      'This email does not have an account in the system yet. Please make sure the email is registered before adding to user.',
    accessLevel: 'Access Level',
    admin: 'Admin',
    adminDescription:
      'Can manage all aspects of the account including adding/ deleting domains and users',
    editor: 'Editor',
    editorDescription: 'Can manage Cookies, Cookie Category, and Cookie Banner',
    viewer: 'Viewer',
    viewerDescription: 'Can only view dashboard and settings menu',
    addUserButton: 'Add user',
    emailLabel: 'Email',
    accessLevelLabel: 'Access Level',
    normal: 'Normal',
    waitForEmailConfirmation: 'Wait for email confirmation',
    sendAgain: 'Send again',
    editUserAccess: 'Edit user access',
    confirmDeletionUser: 'Confirm deletion',
    userDeletedSuccessfully: 'User deleted successfully',

    dashboard: 'Dashboard',
    all: 'All',
    today: 'Today',
    thisMonth: 'This month',
    previousMonth: 'Previous month',

    allConsentLog: 'All consent',
    allConsent: 'All consent',

    consentProportion: 'Consent proportion',
    rejectAll: 'Reject all',

    consentByCategory: 'Consent by Category',

    consentOverview: 'Consent overview',

    pageRank: 'Consent ratio by page rank (Top 10)',
    deviceRatio: 'Consent ratio by device category',
    proportion: 'Proportion',
    noResultsFound: 'No results found',
    website: 'Website',
    management: 'Management',
    domainSuspended: 'Domain temporarily suspended!',
    domainCurrentlySuspendedDomain: 'The domain ',
    domainCurrentlySuspended: ' is currently suspended',
    packageExpired: 'Your package has expired. ',
    packageExpiredContact: 'Please contact CookiePlus team to renew ',
    packageExpiredUpgrade: 'or upgrade your package to continue using the service.',
    contactCookiePlusTeam: 'Contact CookiePlus team',
    packageExpiredAccountOwnerPlease: 'Please contact your ',
    packageExpiredAccountOwnerAccount: ' Account Owner ',
    packageExpiredAccountOwnerUpgrade: ' to renew ',
    renewOrUpgradePackage:
      "Please renew or upgrade your package to continue using the service. You can do this on the 'Package' page.",
    renewPackage: 'Renew package',
    lastSevenDay: 'Last 7 days',
    closeBannerX: "Close banner 'x'",
    devices: 'Device',
    computer: 'Desktop',
    mobile: 'Mobile',
    tablet: 'Tablet',
    smartTv: 'Smart TV',
    others: 'Others',
    historicalData2year: '*Data up to 2 years back',
    logOut: 'Log out',
    confirmCancelPackageBtn: 'Confirm, cancel package',
    alert: 'Alert!',
    sessionsCountApproachingLimit: 'Sessions count approaching limit',
    domainSessionsCount: "This domain's sessions count",
    nearingPackageLimit: 'is nearing the package limit.',
    preventBannerDeactivation:
      'To prevent banner deactivation, please contact us at admin@predictive.co.th',
    upgradePackageBeforeLimit: 'to upgrade your package before reaching the limit',
    enableExceedQuota: "You can enable 'Allow Exceeding Session Quota' in Settings.",
    setting: 'Settings',
    sessionsLimitReached: 'Sessions limit reached',
    bannerOnWebsite: 'The banner on ',
    bannerOnWebsiteWebsite: 'website',
    hasReachedSessionLimit: 'has reached the session limit',
    forYourPackage: 'for your package.',
    overQuotaUsageEnabled: 'Overquota usage is enabled.',
    additionalUsageCharged: 'Additional usage will be charged',
    ratePerSession: 'at 5000 baht per 100,000 sessions.',
    keepBannerActive: 'To keep the banner active, please upgrade your package.',
    temporarilySuspended: 'Temporarily suspended',
    reachedPackageLimit: "Your domain's sessions count has reached the package limit.",
    upgradeToContinue: 'Please upgrade your package to continue using the service.',
    upgradeNow: 'Upgrade now',
    later: 'Later',
    deleteDomain: 'Delete domain',
    saveBannerSettings: 'Save banner settings',
    deleteCookieCategory: 'Delete cookie category',
    editCookieCategory: 'Edit cookie category',
    deleteCookie: 'Delete cookie',
    editCookies: 'Edit cookies',
    addCookieToCategorys: 'Add cookie to category',
    grantPermissions: 'Grant permissions to other users',
    removeUserPermissions: 'Remove user permissions',
    editUserPermissions: 'Edit user permissions',
    addLogo: 'Add logo',
    changeDomainPackage: 'Change domain package',
    modifyContentSettings: 'Modify content settings',
    stopCookieScan: 'Stop cookie scan',
    addCookieCategorys: 'Add cookie category',
    packageDetails: 'Package details',
    dailyWebsiteScans: 'Daily website scans',
    billingCycle: 'Billing cycle',
    or: 'or',
    sessionId: 'Session Id',
    filterByCate: 'Seach by category',
    typingSearch: 'Typing search',
    consentId: 'Consent ID',
    unable_to_proceed: 'Unable to Proceed',
    payment_unable_to_process: "Sorry, we're unable to process your payment right now.",
    reached_limit: "You've reached either:",
    account_user_limit: '1. Account User limit',
    consent_records_limit: '2. Consent records limit',
    please_select_another_plan: 'Please select another plan to continue.',
    select_another_plan: 'Select another Plan',
    payment_couldnt_be_processed: "Payment couldn't be processed",
    already_applied_for_free_plan: "It looks like you've already applied for our free plan.",
    please_upgrade_to_continue: 'Please upgrade to another plan to continue.',
    unexpected_error: 'Unexpected error. Please try again or',
    contact_support: 'contact support.',
    chooseForSearch: 'Select to search'
  },
  th: {
    signin: 'ลงชื่อเข้าใช้',
    haveAccount: 'มีบัญชีกับเราเรียบร้อยแล้ว',
    signup: 'สมัครสมาชิก',
    notHaveAcc: 'ยังไม่มีบัญชีกับเรา',
    email: 'อีเมล',
    resendEmail: 'ส่งอีเมลยืนยันบัญชีอีกครั้ง',
    password: 'รหัสผ่าน',
    ConfirmPassword: 'ยืนยันรหัสผ่าน',
    forgotPassword: 'ลืมรหัสผ่าน',
    welcome: 'ยินดีต้อนรับ',
    acceptTerm: 'ฉันยอมรับ',
    acceptService: 'ข้อตกลงในการใช้บริการ',
    privacyPolicy: 'นโยบายวามเป็นส่วนตัว',
    backToLogin: 'กลับสู่หน้าลงชื่อเข้าใช้',
    sendEmailDone: 'ส่งอีเมลเรียบร้อยแล้ว',
    sendEmailFail: 'ส่งอีเมลไม่สำเร็จ',
    registerFail: 'ไม่สามารถสมัครสมาชิก',
    pleaseAcceptTerm: 'กรุณายอมรับข้อตกลงการใช้บริการ และนโยบายความเป็นส่วนตัว เพื่อสมัครสมาชิก',
    pleaseTryAgian: 'กรุณาลองใหม่อีกครั้ง',
    sendAgian: 'ส่งอีกครั้ง',
    enterEmailResetPassword: 'กรอกอีเมลเพื่อรับลิงก์การรีเซตรหัสผ่าน',
    emailForLogin: 'อีเมลที่ใช้สำหรับลงชื่อเข้าใช้',
    requestResetPassword1: 'ส่งคำขอรีเซตรหัสผ่านไปที่อีเมลที่ระบุเรียบร้อยแล้ว',
    requestResetPassword2: 'โปรดคลิกลิงก์ในอีเมลเพื่อตั้งรหัสผ่านใหม่',
    enterEmail: 'กรุณาใส่อีเมล',
    emailWrongFormat: 'รูปแบบอีเมลไม่ถูกต้อง',
    domainName: 'ชื่อโดเมน',
    addDomain: 'เพิ่มโดเมน',
    manageDomain: 'จัดการโดเมน',
    domain: 'โดเมน',
    numberOfCookies: 'จำนวนคุกกี้',
    status: 'สถานะ',
    bannerVersion: 'แบนเนอร์เวอร์ชัน',
    publicationDate: 'วันที่เผยแพร่',

    cancel: 'ยกเลิก',
    save: 'บันทึก',
    edit: 'แก้ไข',
    add: 'เพิ่ม',
    cookie: 'คุกกี้',

    published: 'เผยแพร่',
    notYetPublished: 'ยังไม่เผยแพร่',
    updatesNotPublished: 'มีการอัพเดทที่ยังไม่ถูกเผยแพร่',

    package: 'แพ็กเกจ',
    paymentInformation: 'ข้อมูลการชำระเงิน',
    receipt: 'ใบเสร็จรับเงิน',

    chooseDomain: 'เลือกโดเมน',

    forBeginners: 'สำหรับผู้เริ่มต้น',
    smallBusinesses: 'สำหรับเว็บไซต์หรือธุรกิจขนาดเล็ก',
    mediumBusinesses: 'สำหรับเว็บไซต์หรือธุรกิจขนาดกลาง',
    largeBusinesses: 'สำหรับเว็บไซต์หรือธุรกิจขนาดใหญ่',

    pricesAndPackages: 'ราคาและแพ็กเกจ',
    save20Percent: 'ประหยัดทันที 20% เมื่อชำระแพ็กเกจแบบรายปี',
    monthly: 'รายเดือน',
    yearly: 'รายปี',
    discount20Percent: 'ส่วนลด 20%',
    noteSessions:
      ' Sessions นับจากจำนวนผู้เข้าชมเว็บไซต์ และจะหมดอายุหลังจากผ่านไป 24 ชั่วโมง และเริ่มนับเป็น Session ใหม่',
    unlimited: 'ไม่จำกัด',
    compatibleWith: 'ใช้งานได้กับ',
    perMonth: 'ต่อเดือน',
    upToUser: 'จำนวนผู้ใช้งาน ',
    user: 'บัญชี',
    scan: 'สแกนเว็บไซต์',
    consentRecords: 'บันทึกความยินยอม',
    storeDataFor: 'จัดเก็บข้อมูลสูงสุด ',
    month: 'เดือน',
    months: 'เดือน',
    year: 'ปี',
    day: 'วัน',
    thb: 'บาท',
    customizeDetailedBanner: 'ปรับแต่งแบนเนอร์แบบละเอียด',
    customizeLogoAndIcons: 'ปรับแต่งโลโก้และไอคอน',
    unlimitedConsentRecords: 'ไม่จำกัดบันทึกความยินยอม',
    unlimitedNumberOfUsers: 'ไม่จำกัดจำนวนผู้ใช้งาน',
    selectPackage: 'เลือกแพ็กเกจ',

    contactUs: 'ติดต่อเรา',
    largeUsage:
      'สำหรับเว็บไซต์หรือธุรกิจที่ต้องการใช้งานจำนวนมาก สามารถเลือกปรับแผนการใช้งานได้ตามต้องการ',
    customizeUsages: 'ปรับแต่งจำนวนการใช้งานที่เหมาะสม',
    dedicatedSupport: 'ดูแลโดย Account Manager​',

    urlFormatError: 'รูปแบบ ​URL ไม่ถูกต้อง กรุณาระบุ http:// หรือ https://',
    remainingCredit: 'เครดิตคงเหลือ',

    taxInfo: 'ข้อมูลสำหรับออกใบกำกับภาษี/ใบเสร็จรับเงิน',
    naturalPerson: 'บุคคลธรรมดา',
    legalEntity: 'นิติบุคคล',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    address: 'ที่อยู่',
    subdistrict: 'แขวง/ตำบล',
    district: 'เขต/อำเภอ',
    province: 'จังหวัด',
    zipCode: 'รหัสไปรษณีย์',
    mobileNumber: 'โทรศัพท์',
    noteTaxInfo:
      '(หมายเหตุ: การอัปเดตข้อมูลไม่มีผลกับใบกำกับภาษี/ใบเสร็จรับเงินที่ดำเนินการเสร็จสิ้นแล้ว)',
    update: 'อัปเดต',
    payment: 'การชำระเงิน',
    cardNumber: 'หมายเลขบัตร',
    cardholderName: 'ชื่อผู้ถือบัตร',
    expirationDate: 'วันหมดอายุ (เดือน/ปี)',
    cvv: 'CVV',
    securePayment: 'ระบบชำระเงินปลอดภัยโดย Opn Payments',
    note: 'หมายเหตุ',
    verificationCharge: 'ระบบจะทดลองตัดเงินเพื่อตรวจสอบบัตรเครดิต และจะคืนเงินภายใน 24 ชั่วโมง',
    primaryPaymentCard: 'บัตรเครดิตที่คุณกรอกจะถูกใช้เป็นบัตรหลักในการชำระเงินของทุกโดเมน',
    agreeToTerms: 'เมื่อคลิก “อัปเดต” หมายถึงคุณยอมรับ',
    termsOfService: 'นโยบายการใช้งาน',
    and: 'และ',
    confirmDeleteDomain: 'คุณต้องการลบโดเมนใช่หรือไม่',
    confirmDeleteDomainNote:
      'การลบโดเมนจะเป็นการลบข้อมูลทั้งหมด และไม่สามารถกู้คืนได้ พิมพ์ "Delete" ในช่องด้านล่างเพื่อยืนยันการลบโดเมน',
    confirmDomainDeletion: 'ยืนยันการลบโดเมน',
    freePlanExists: 'มีแพ็กเกจ Free Plan แล้ว',
    cancelPackageTitle: 'ยกเลิกแพ็กเกจ',
    cancelPackage: 'คุณต้องการยกเลิกแพ็กเกจ',
    ofDomain: 'ของโดเมน',
    questionMark: 'ใช่หรือไม่?',
    packageActiveUntil: 'แพ็กเกจปัจจุบันของคุณจะยังคงใช้งานได้จนถึงวันสิ้นสุดรอบบิลในวันที่',

    pleaseNote: 'โปรดทราบว่า',
    domainSuspensionNote:
      'เมื่อสิ้นสุดรอบบิล โดเมนจะถูกระงับการใช้งาน (Suspend) และแบนเนอร์จะหยุดการทำงาน',
    reactivationOptionNote:
      'คุณสามารถเลือกแพ็กเกจได้ตลอดเวลาเพื่อให้โดเมนและแบนเนอร์กลับมาใช้งานได้อีกครั้ง',
    permanentDeletionNote:
      'ภายใน 30 วัน หากครบกำหนด 30 วัน โดเมนจะถูกลบอย่างถาวรและไม่สามารถกู้คืนข้อมูลได้',

    confirmCancelPackageBtn: 'ยืนยัน, ยกเลิกแพ็กเกจ',
    packageCanceled: 'ยกเลิกแพ็กเกจแล้ว',
    sorryToSeeYouGo: 'เราเสียใจที่คุณยกเลิกจากเรา',
    close: 'ปิด',
    somethingWentWrong: 'เกิดข้อขัดข้องบางอย่างขึ้น กรุณาลองใหม่อีกครั้ง ',
    ifYouNeedAssistance: 'หากต้องการความช่วยเหลือ ส่งอีเมลถึงเราที่ support@cookieplus.com',

    failedToCancelPackage: 'ยกเลิกแพ็กเกจไม่สำเร็จ',
    promotionUnavailable: 'ไม่สามารถใช้โปรโมชั่นได้',
    promotionExpired: 'คูปองส่วนลด หรือ โปรโมชั่น อาจมีผู้ใช้งานเต็มสิทธิ์แล้ว หรือหมดอายุ',
    paymentFailed: 'ไม่สามารถชำระเงินได้ กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
    codeInvalidExpired: 'รหัสไม่ถูกต้องหรือหมดอายุแล้ว',
    profileUpdated: 'อัปเดตข้อมูลโปรไฟล์เรียบร้อยแล้ว',
    profileUpdateFailed: 'อัปเดตข้อมูลโปรไฟล์ไม่สำเร็จ',
    privacyUpdated: 'อัปเดตข้อมูลความเป็นส่วนตัวเรียบร้อยแล้ว',
    privacyUpdateFailed: 'อัปเดตข้อมูลความเป็นส่วนตัวไม่สำเร็จ',
    passwordChanged: 'เปลี่ยนรหัสผ่านใหม่เรียบร้อย',
    passwordChangeFailed: 'เปลี่ยนรหัสผ่านใหม่ไม่สำเร็จ',
    userAdded: 'เพิ่มผู้ใช้งานเรียบร้อย',
    userEdited: 'แก้ไขผู้ใช้งานสำเร็จ',
    emailSent: 'ส่งอีเมลยืนยันไปยัง',
    errorOccurred: 'เกิดข้อผิดพลาด กรุณาลองใหม่',
    deleteUser: 'ลบผู้ใช้งาน?',
    confirmDeleteUser: 'คุณต้องการลบผู้ใช้นี้ออกจากโดเมน',
    confirmUserDeletion: 'ยืนยันลบผู้ใช้งาน',
    addCookie: 'เพิ่มคุกกี้',
    editCookie: 'แก้ไขคุกกี้',
    cookieName: 'ชื่อคุกกี้',
    cookieDuration: 'อายุการใช้งาน',
    yearMonthDay: 'ปี เดือน วัน',
    cookieCategory: 'หมวดหมู่คุกกี้',
    description: 'คำอธิบาย',
    confirmDeleteCookie: 'คุณต้องการลบคุกกี้',
    deleteCookieNote:
      'เมื่อทำการลบแล้วจะไม่สามารถกู้คืนได้ หากต้องการให้คุกกี้กลับมาในรายการ ต้องทำการสแกนคุกกี้อีกครั้ง',
    confirmCookieDeletion: 'ยืนยันการลบคุกกี้',
    publishBannerVersion: 'เผยแพร่แบนเนอร์เวอร์ชัน',
    requestConsentAgain:
      'ต้องการขอความยินยอมผู้ใช้งานเว็บไซต์ใหม่อีกครั้ง คุกกี้แบนเนอร์จะแสดงเพื่อขอความยินยอมจากผู้ใช้งานเว็บไซต์ใหม่อีกครั้ง',
    confirmPublish: 'ยืนยันการเผยแพร่',
    yayPublished: 'เย้! เผยแพร่สำเร็จ',
    bannerPublished: 'แบนเนอร์ของคุณได้รับการเผยแพร่เรียบร้อย',
    displayUpdate: 'การแสดงผลจะได้รับการปรับเปลี่ยนในไม่ช้า',
    oopsSomethingWrong: 'อุ๊ปส์! มีบางอย่างผิดพลาด',
    bannerPublishingFailed: 'การเผยแพร่แบนเนอร์ไม่สำเร็จ',
    tryAgainPlease: 'กรุณาลองใหม่อีกครั้ง',
    tryAgain: 'ลองอีกครั้ง',
    fileTooLarge: 'ไฟล์นี้มีขนาดใหญ่เกินขนาดดาวน์โหลดสูงสุด โปรดติดต่อ support เพื่อขอข้อมูล',
    serverConnectionError:
      'ขออภัย! การเชื่อมต่อเซิร์ฟเวอร์ผิดพลาด โปรดยืนยันว่าคุณมีการเชื่อมต่ออินเทอร์เน็ต แล้วลองอีกครั้งในอีกสักครู่',
    sorry: 'ขออภัย!',
    settingsSaved: 'บันทึกการตั้งค่าเรียบร้อย',
    settingsSaveFailed: 'บันทึกการตั้งค่าไม่สำเร็จ',
    confirmOverQuota: 'คุณต้องการเปิดให้ใช้งานเกินโควตา Session ',
    confirmOverQuotaSession: 'ตามแพ็กเกจ (มีค่าใช้จ่าย)?',
    overQuotaCharge:
      'ค่าใช้จ่ายจะถูกคิดในแต่ละรอบเดือนการใช้งาน ในราคา 5,000 บาท ต่อทุก 100,000 sessions ที่เพิ่มขึ้น',

    iUnderstand: 'ฉันเข้าใจ',
    editCategory: 'แก้ไขหมวดหมู่',
    addCategory: 'เพิ่มหมวดหมู่',
    categoryName: 'ชื่อหมวดหมู่',
    displayName: 'ชื่อที่แสดง',
    categoryId: 'ID หมวดหมู่',
    categoryDescription: 'คำอธิบายหมวดหมู่',
    addCookieToCategory: 'เพิ่มคุกกี้ในหมวดหมู่',
    noCookiesInCategory: 'ไม่พบคุกกี้ในหมวดหมู่',
    confirmDeleteCategory: 'คุณต้องการลบหมวดหมู่คุกกี้',
    deleteCategoryNote: 'คุกกี้ในหมวดหมู่นี้จะคงอยู่ในรายการคุกกี้ “ไม่มีหมวดหมู่” ',
    deleteCategoryNoteManage: 'คุณสามารถจัดการหมวดหมู่คุกกี้หรือเพิ่มคุกกี้ในหมวดหมู่อื่นๆ',
    confirmRemoveCategory: 'ยืนยันการนำออก',
    confirmRemoveCookie: 'คุณต้องการนำคุกกี้ออกจากหมวดหมู่',
    removeCookieNote: 'คุกกี้จะอยู่ในรายการคุกกี้ “ไม่มีหมวดหมู่” ',
    removeCookieNoteManage: 'คุณสามารถจัดการหมวดหมู่คุกกี้หรือเพิ่มคุกกี้ในหมวดหมู่ได้อีกครั้ง',
    confirmDeactivate: 'ยืนยันปิดการทำงาน',
    confirmDeactivateCategory: 'ยืนยันปิดการทำงานของคุกกี้หมวดหมู่นี้',

    userSettings: 'ตั้งค่าผู้ใช้งาน',
    profile: 'โปรไฟล์',
    changePassword: 'เปลี่ยนรหัสผ่าน',
    originalPassword: 'รหัสผ่านเดิม',
    newPassword: 'รหัสผ่านใหม่',
    confirmNewPassword: 'ยืนยันรหัสผ่านใหม่',

    pleaseEnterEmail: 'กรุณาใส่อีเมล',
    incorrectPassword: 'รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
    passwordConfirmationIncorrect: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
    passwordIncorrect: 'รหัสผ่านไม่ถูกต้อง',
    passwordMustContain: 'รหัสผ่านต้องประกอบด้วย:',
    atLeast8Characters: 'อย่างน้อย 8 ตัวอักษร',
    atLeastOneLowercase: 'อักษรตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว (a-z)',
    atLeastOneUppercase: 'อักษรตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว (A-Z)',
    atLeastOneNumber: 'ตัวเลขอย่างน้อยหนึ่งตัว (0-9)',
    atLeastOneSpecialChar: 'อักขระพิเศษอย่างน้อยหนึ่งตัว (!@#$%^*-_=)',
    registrationCompleted: 'สมัครสมาชิกเรียบร้อยแล้ว',
    confirmationLinkSent: 'เราได้ส่งลิงก์การยืนยันไปที่อีเมล',
    clickLinkToVerify: 'โปรดกดลิงในอีเมลเพื่อยืนยันตัวตนก่อนเข้าใช้งาน',
    ifNoEmailReceived: 'หากไม่ได้รับอีเมลภายใน 5 นาที',
    emailFormatIncorrect: 'รูปแบบอีเมลไม่ถูกต้อง',
    submitPasswordResetRequest: 'ส่งคำขอรีเซตรหัสผ่าน',

    consentRecord: 'บันทึกความยินยอม',
    search: 'ค้นหา',
    date: 'วันที่',
    consentID: 'Consent ID',
    ipAddress: 'IP Address',
    page: 'เพจ',
    consent: 'การยินยอม',
    consented: 'ความยินยอม',

    acceptAll: 'ยอมรับทั้งหมด',
    acceptSome: 'ยอมรับบางส่วน',
    closeBanner: 'ปิดแบนเนอร์',

    necessaryCookies: 'คุกกี้ที่จำเป็น',
    performanceCookies: 'คุกกี้เพื่อการวิเคราะห์',
    marketingCookies: 'คุกกี้เพื่อการโฆษณา',
    functionalityCookies: 'คุกกี้เพื่อการใช้งาน',
    noSearchResults: 'เราไม่พบการค้นหา ที่ตรงกับการค้นหาของคุณ',

    deleteCookieConfirmation: 'ลบคุกกี้',
    deleteCookieMessage: 'คุณต้องการลบคุกกี้',
    deleteWarning: 'เมื่อทำการลบแล้วจะไม่สามารถกู้คืนได้',
    deleteRecovery: 'หากต้องการให้คุกกี้กลับมาในรายการ ต้องทำการสแกนคุกกี้อีกครั้ง',
    confirmDeletion: 'ยืนยันการลบคุกกี้',

    scanCookies: 'สแกนคุกกี้',
    language: 'ภาษาคำอธิบาย',
    thai: 'ภาษาไทย',
    english: 'English',
    latestScan: 'สแกนล่าสุด',
    stopScanning: 'หยุดสแกน',
    startManagingCookies: 'กดปุ่ม “สแกนคุกกี้” เพื่อเริ่มต้นจัดการคุกกี้',
    scanningInProgress: 'เรากำลังดำเนินการสแกนคุกกี้ในเว็บไซต์ อาจใช้เวลาหลายนาที',
    scanComplete: 'คุกกี้จะแสดงเมื่อการสแกนเสร็จสิ้น',

    bannerManagement: 'จัดการแบนเนอร์',
    bannerLanguage: 'ภาษาแบนเนอร์',
    languageEn: 'English',
    saveSettings: 'บันทึกการตั้งค่า',
    desktop: 'เดสก์ทอป',
    mobilePhone: 'มือถือ',
    bannerDefaultLanguage: 'ภาษาเริ่มต้นของแบนเนอร์',
    automaticLanguageDetection: 'ตรวจจับภาษาอัตโนมัติ',
    detectLanguageFromHtml: 'ตรวจจับภาษาจาก HTML lang attribute',
    detectLanguageFromHtmlTH: '',
    specifyLanguageInTag: 'การกำหนดภาษาใน tag html เช่น <html lang="en">',
    detectLanguageFromCookies: 'ตรวจจับภาษาจากคุกกี้',
    enterCookieNameValue: 'กรอกชื่อคุกกี้ที่กำหนดการตั้งค่าภาษาของเว็บไซต์ โดย value',
    enterCookieName: ' ต้องกำหนดค่าภาษาด้วยตัวอักษร 2 หลัก เช่น en, th',
    bannerFormat: 'รูปแบบแบนเนอร์',
    default: 'ค่าเริ่มต้น',
    fullBottom: 'เต็มด้านล่าง',
    fullTop: 'เต็มด้านบน',
    popup: 'ป๊อปอัพ',
    bottomLeft: 'ล่างซ้าย',
    topLeft: 'บนซ้าย',
    bottomRight: 'ล่างขวา',
    topRight: 'บนขวา',
    text: 'ข้อความ',
    displayTitle: 'แสดงหัวข้อ',
    titleText: 'ชื่อหัวข้อ',
    bodyText: 'ข้อความ',
    titleFontSize: 'ขนาดตัวอักษรหัวข้อ',
    bodyFontSize: 'ขนาดตัวอักษรข้อความ',
    cookiePolicy: 'นโยบายคุกกี้',
    customize: 'กำหนดเอง',
    cookiePolicyText: 'ข้อความนโยบายคุกกี้',
    policyName: 'ชื่อนโยบาย',
    cookiePolicyLink: 'ลิงก์นโยบายคุกกี้',
    colorsAndButtonsSettings: 'ตั้งค่าสีและปุ่ม',
    basicBannerStyleSettings: 'ตั้งค่าสไตล์พื้นฐานแบนเนอร์',
    backgroundColor: 'สีพื้นหลัง',
    fontColorTitle: 'สีตัวอักษร (หัวข้อ)',
    fontColorText: 'สีตัวอักษร (ข้อความ)',
    cookiePolicyButtonSettings: 'ตั้งค่าปุ่ม ‘นโยบายคุกกี้’',
    cookiePolicyButtonColor: 'สีปุ่ม ‘นโยบายคุกกี้’',
    cookieSettingsButtonSettings: 'ตั้งค่าปุ่ม ‘การตั้งค่าคุกกี้’',
    displayCookieSettingsButton: 'แสดงรูปแบบ ‘การตั้งค่าคุกกี้’ แบบปุ่ม',
    cookieSettingsButtonColor: 'สีปุ่ม ‘การตั้งค่าคุกกี้’',
    cookieSettingsButtonTextColor: 'สีข้อความปุ่ม ‘การตั้งค่าคุกกี้’',
    cookieSettingsButtonText: 'ข้อความปุ่ม ‘การตั้งค่าคุกกี้’',
    confirmMySettings: 'ข้อความปุ่ม ‘ยืนยันตัวเลือกของฉัน’',
    confirmSettings: 'ตกลงการตั้งค่า',
    acceptButtonSettings: 'ตั้งค่าปุ่ม ‘ยอมรับ’',
    acceptButtonBackgroundColor: 'สีพื้นปุ่ม ‘ยอมรับ’',
    acceptButtonTextColor: 'สีข้อความปุ่ม ‘ยอมรับ’',
    acceptButtonText: 'ข้อความปุ่ม ‘ยอมรับ’',
    declineButtonSettings: 'ตั้งค่าปุ่ม ‘ปฏิเสธ’',
    displayButton: 'แสดงปุ่ม',
    declineButtonColor: 'สีปุ่ม ‘ปฏิเสธ’',
    declineButtonTextColor: 'สีข้อความปุ่ม ‘ปฏิเสธ’',
    declineButtonText: 'ข้อความปุ่ม ‘ปฏิเสธ’',
    declineAll: 'ปฏิเสธทั้งหมด',
    closeBannerButtonX: "ปุ่มปิดแบนเนอร์ 'x'",
    bannerLogo: 'โลโก้แบนเนอร์',
    displayLogo: 'แสดงโลโก้',
    cookiePlusLogo: 'โลโก้ CookiePlus',
    uploadLogo: 'อัปโหลดโลโก้',
    logoDisplayNote: 'โลโก้จะแสดงด้านล่างของแบนเนอร์และการตั้งค่าคุกกี้',
    clickToSelectLogoFile: 'คลิกเลือกไฟล์โลโก้',
    logoFileSupport: 'รองรับไฟล์ขนาดไม่เกิน 1MB ประเภทไฟล์ .PNG, .JPG',
    logoFileSupportRecommended: '(ขนาดแนะนำ 32 x 32 px หรือ 1:1)',
    cookieSettingsIcon: 'ไอคอนการตั้งค่าคุกกี้',
    displayIconOnWebsite: 'แสดงไอคอนบนเว็บไซต์',
    preview: 'ดูตัวอย่าง',
    cookieSettingsIconNote:
      'ไอคอนรูปคุกกี้จะอยู่บนหน้าเว็บไซต์เพื่อให้ผู้ใช้งานสามารถเปลี่ยนแปลงการตั้งค่าคุกกี้ใหม่ได้',
    defaultSettings: 'ค่าเริ่มต้น',
    uploadImage: 'อัปโหลดรูป',
    clickToSelectIconFile: 'คลิกเลือกไฟล์ไอคอน',
    iconFileSupport:
      'รองรับไฟล์ขนาดไม่เกิน 1MB ประเภทไฟล์ .PNG, .JPG (ขนาดแนะนำ 32 x 32 px หรือ 1:1)',
    customizeCss: 'ปรับแต่ง CSS',
    addCss: 'เพิ่ม CSS ที่ต้องการปรับแต่ง',
    cssNote: 'หมายเหตุ : Font Family จะไม่แสดงผลใน Live Preview',
    cookieSettingsManagement: 'จัดการการตั้งค่าคุกกี้',
    collapseCookieDetails: 'ย่อรายละเอียดคุกกี้',
    bannerButtonCode:
      'โค้ดสำหรับกำหนดปุ่มหรือลิงก์บนเว็บไซต์เพื่อแสดงการตั้งค่าคุกกี้ คัดลอกโค้ดด้านล่างแล้วนำไปกำหนดที่ปุ่มหรือลิงก์ข้อความที่คุณต้องการ',
    addCookieCategory: 'เพิ่มหมวดหมู่',
    cookieList: 'รายการคุกกี้',
    remove: 'นำออก',
    activate: 'เปิดการทำงาน',
    confirmDeactivation: 'ยืนยันปิดการทำงาน',
    confirmCategoryDeactivation: 'ยืนยันปิดการทำงานของคุกกี้หมวดหมู่นี้',
    deactivate: 'ปิดการทำงาน',

    removeFromCategoryPrompt:
      'คุณต้องการนำคุกกี้ออกจากหมวดหมู่ คุกกี้จะอยู่ในรายการคุกกี้ “ไม่มีหมวดหมู่” คุณสามารถจัดการหมวดหมู่คุกกี้หรือเพิ่มคุกกี้ในหมวดหมู่ได้อีกครั้ง',
    confirmRemoval: 'ยืนยันการนำออก',
    categoryLanguage: 'ภาษาหมวดหมู่',
    delete: 'ลบ',
    category: 'หมวดหมู่',
    addDomainButton: 'กดปุ่ม “เพิ่มโดเมน” เพื่อเริ่มต้นจัดการโดเมน',
    deleteDomainPrompt: 'คุณต้องการลบโดเมนใช่หรือไม่',
    deleteDomainWarning: 'การลบโดเมนจะเป็นการลบข้อมูลทั้งหมด และไม่สามารถกู้คืนได้ พิมพ์',
    deleteDomainWarningInput: 'ในช่องด้านล่างเพื่อยืนยันการลบโดเมน',
    confirmDeletionDomain: 'ยืนยันการลบโดเมน',

    usageCustomization:
      'สำหรับเว็บไซต์หรือธุรกิจที่ต้องการใช้งานจำนวนมาก สามารถเลือกปรับแผนการใช้งานได้ตามต้องการ',
    appropriateUsage: 'ปรับแต่งจำนวนการใช้งานที่เหมาะสม',
    currentPackage: 'แพ็กเกจปัจจุบัน',
    paymentMethod: 'วิธีการชำระเงิน',
    nextPaymentDue: 'ชำระเงินครั้งต่อไป',
    dailyScans: 'สแกนเว็บไซต์ต่อวัน',
    numberOfSessions: 'จำนวน Sessions',
    resetDate: 'เริ่มนับใหม่วันที่',
    companyName: 'ชื่อบริษัท',
    taxID: 'เลขประจำตัวผู้เสียภาษี (Tax ID)',
    enterFirstName: 'กรุณาระบุชื่อ',
    enterLastName: 'กรุณาระบุนามสกุล',
    enterCompanyName: 'กรุณาระบุชื่อบริษัท',
    enterAddress: 'กรุณาระบุที่อยู่',
    enterSubdistrict: 'กรุณาระบุแขวง/ตำบล',
    enterDistrict: 'กรุณาระบุเขต/อำเภอ',
    enterProvince: 'กรุณาระบุจังหวัด',
    enterZipCode: 'กรุณาระบุรหัสไปรษณีย์',
    enterPhoneNumber: 'กรุณาระบุหมายเลขโทรศัพท์',
    enterTaxID: 'กรุณาระบุเลขประจำตัวผู้เสียภาษี',
    enterCardNumber: 'กรุณาระบุหมายเลขบัตร',
    enterCardholderName: 'กรุณาระบุชื่อผู้ถือบัตร',
    enterExpirationDate: 'กรุณาระบุวันหมดอายุ',
    enterCVV: 'กรุณาระบุหมายเลขCVV',
    creditCard: 'บัตรเครดิต',
    cardNumberEndsWith: 'หมายเลขบัตรลงท้าย',
    changeCreditCard: 'เปลี่ยนบัตรเครดิต',
    orderNumber: 'หมายเลขคำสั่งซื้อ',
    paymentDate: 'วันที่ชำระเงิน',
    updateSuccessful: 'อัปเดตสำเร็จ',
    updateFailed: 'อัปเดตไม่สำเร็จ',

    packagePrice: 'ราคาแพ็กเกจ',
    remainingPackageValue: 'มูลค่าแพ็กเกจคงเหลือ',
    deductedFromCredit: 'หักจากเครดิตคงเหลือ',
    withholdingTax: 'หักภาษี ณ ที่จ่าย 3%',
    total: 'รวมชำระ',
    paymentSuccessful: 'ชำระเงินสำเร็จ',
    processing: 'กำลังดำเนินการ',
    download: 'ดาวน์โหลด',
    expiryDate: 'สิ้นสุดวันที่',
    nameSurname: 'ชื่อ-นามสกุล',
    type: 'ประเภท',
    backToPackage: 'ย้อนกลับไปหน้าแพ็กเกจ',
    orderSummary: 'สรุปรายการสั่งซื้อ',
    items: 'รายการ',
    totalAmount: 'ยอดรวม (บาท)',
    discount: 'ส่วนลด',
    deductedFromPackageValue: 'หักจากมูลค่าแพ็กเกจคงเหลือ',
    balanceFromPrevious: 'ยอดจากแพ็กเกจเดิม',
    remainingCreditStored: 'มูลค่าคงเหลือหลังจากหักเป็นส่วนลดแล้วจะถูกเก็บเป็นเครดิต',
    availableCredit: 'เครดิตที่ใช้ได้',
    priceBeforeVAT: 'ราคาก่อนภาษีมูลค่าเพิ่ม',
    haveCoupon: 'มีคูปองส่วนลด หรือ รหัสผู้แนะนำ (Referral Code) ?',
    redeem: 'ใช้',
    withholdingTax3: 'ภาษีหัก ณ ที่จ่าย 3%',
    netTotal: 'ยอดรวมสุทธิ',
    pay: 'ชำระเงิน',
    autoRenewalAgreement:
      'เมื่อคลิก "ชำระเงิน" หมายถึงคุณยอมรับ และยินยอมการต่ออายุอัตโนมัติ ซึ่งสามารถยกเลิกได้ผ่านหน้าแพ็กเกจ',
    paymentSuccessMessage: 'ชำระเงินสำเร็จ',
    paymentFailureMessage: 'ชำระเงินไม่สำเร็จ',
    orderNumberLabel: 'หมายเลขคำสั่งซื้อ',
    paymentThankYou: 'ขอบคุณสำหรับการชำระเงิน CookiePlus',
    orderEmailNotification: 'เราจะจัดส่งรายละเอียดคำสั่งซื้อไปยังอีเมลของคุณ',
    needAssistance: 'หากต้องการความช่วยเหลือส่งอีเมลถึงเราที่',
    backToWebsite: 'กลับหน้าเว็บไซต์',
    withholdingTaxCertificateNote: 'ดำเนินการส่งหนังสือรับรองภาษีหัก ณ ที่จ่าย ภายใน 15 วัน',
    companyNameNote: 'บริษัท พรีดิกทิฟ จำกัด (สำนักงานใหญ่)',
    addressNote: 'เลขที่ 10/35 อาคารเดอะเทรนดี้ ชั้น 1A ซอยสุขุมวิท 13 (แสงจันทร์)',
    cityNote: 'แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 10110',
    creditCardVerificationNote:
      'หมายเหตุ: ระบบจะทดลองตัดเงินเพื่อตรวจสอบบัตรเครดิต และจะคืนเงินภายใน 24 ชั่วโมง บัตรเครดิตที่คุณกรอกจะถูกใช้เป็นบัตรหลักในการชำระเงินของทุกโดเมน เมื่อคลิก “อัปเดต” หมายถึงคุณยอมรับ นโยบายการใช้งาน และ นโยบายความเป็นส่วนตัว',
    domainSuspensionNotice:
      'โปรดทราบว่า! เมื่อสิ้นสุดรอบบิล โดเมนจะถูกระงับการใช้งาน (Suspend) และแบนเนอร์จะหยุดการทำงาน คุณสามารถเลือกแพ็กเกจได้ตลอดเวลาเพื่อให้โดเมนและแบนเนอร์กลับมาใช้งานได้อีกครั้ง ภายใน 30 วัน หากครบกำหนด 30 วัน โดเมนจะถูกลบอย่างถาวรและไม่สามารถกู้คืนข้อมูลได้',
    cancelPackageConfirmation: 'คุณต้องการยกเลิกแพ็กเกจ ของโดเมน XXX ใช่หรือไม่?',
    searchTerm: 'คำค้นหา',
    ok: 'ตกลง',
    agreeToAutomaticRenewal: 'และยินยอมการต่ออายุอัตโนมัติ',
    canBeCancelledOnPackagePage: 'ซึ่งสามารถยกเลิกได้ผ่านหน้าแพ็กเกจ',

    test: 'เทส',
    enterEmailVerify: 'กรอกอีเมลเพื่อรับลิงก์ยืนยันบัญชี',
    emailUsedRegister: 'อีเมลที่ใช้สมัครสมาชิก',
    uableSendPassword: 'ไม่สามารถส่งคำขอรีเซตรหัสผ่านได้',
    uableSendVerify: 'ไม่สามารถส่งอีเมลยืนยันบัญชีได้',
    emailNotFound: 'ไม่พบอีเมลนี้ กรุณาตรวจสอบว่าคุณระบุอีเมลถูกต้อง',
    passwordSuccess: 'ตั้งรหัสผ่านเรียบร้อย',
    pleaseEnterNewPassword: 'กรุณากรอกรหัสผ่านใหม่ที่คุณต้องการใช้งานเพื่อเข้าสู่ระบบ',
    addNewPassword: 'ตั้งรหัสผ่านใหม่',
    identitySuccess: 'ยืนยันตัวตนสำเร็จ',
    identityFailContact: 'ยืนยันตัวตนไม่สำเร็จ ติดต่อทีมสนับสนุน support@cookieplus.com',
    identityFailTry:
      'ยืนยันตัวตนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง หรือติดต่อทีมสนับสนุน support@cookieplus.com',
    loginFailed: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่',
    invitedSuccess: 'ตอบรับคำเชิญสำเร็จ',
    invitedFailed: 'ตอบรับคำเชิญไม่สำเร็จ',
    emailOrPasswordFailed: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
    enterPassword: 'กรุณาใส่รหัสผ่าน',
    OpsCantFindPage: 'อุ๊บส์! เราไม่พบหน้าที่คุณต้องการ',
    error: 'ข้อผิดพลาด',
    forbiden: 'ต้องห้าม',
    noAccess: 'ดูเหมือนว่าคุณไม่มีสิทธิ์เข้าถึงในส่วนนี้',
    amount: 'จำนวนเงิน',
    quotationNote: 'หมายเหตุ: หากต้องการใบเสนอราคา ติดต่อเราได้ทาง Live Chat หรืออีเมล',
    users: 'ผู้ใช้งาน',
    cannotScanDomain: 'ไม่สามารถสแกนโดเมนนี้ได้',
    scanEvery: 'สแกนทุก',
    listPagesWithCookies: 'รายการเพจที่พบคุกกี้',
    enterCookieNames: 'โปรดกรอกชื่อคุกกี้',
    addCookiesToCategory: 'กดปุ่ม “เพิ่มคุกกี้ในหมวดหมู่”',
    startManagingCookie: 'เพื่อเริ่มต้นจัดการคุกกี้ในหมวดหมู่',

    publication: 'การเผยแพร่',
    publishBanner: 'เผยแพร่แบนเนอร์',
    requestNewConsent: 'ต้องการขอความยินยอมผู้ใช้งานเว็บไซต์ใหม่อีกครั้ง',
    bannerDisplayedAgain: 'คุกกี้แบนเนอร์จะแสดงเพื่อขอความยินยอมจากผู้ใช้งานเว็บไซต์ใหม่อีกครั้ง',
    confirmPublishing: 'ยืนยันการเผยแพร่',
    bannerPublishedSuccess:
      '"แบนเนอร์ของคุณได้รับการเผยแพร่เรียบร้อย การแสดงผลจะได้รับการปรับเปลี่ยนในไม่ช้า"',
    bannerStatus: 'สถานะแบนเนอร์',
    unpublishedUpdates: 'มีรายการอัปเดตที่ยังไม่ถูกเผยแพร่',
    clickPublishBanner: 'กดปุ่ม “เผยแพร่แบนเนอร์” เพื่อเริ่มต้นจัดการแบนเนอร์',
    bannerInactive: 'ไม่ทำงาน',
    bannerActive: 'ทำงานปกติ',
    updatedToLatest: 'อัปเดตเป็นเวอร์ชันล่าสุดแล้ว',
    currentBannerVersion: 'แบนเนอร์เวอร์ชันปัจจุบัน',
    lastPublishedOn: 'เผยแพร่ล่าสุดเมื่อ',
    cookieBannerScript: 'สคริปต์สำหรับติดตั้งแบนเนอร์คุกกี้',
    copyScriptInstructions:
      'คัดลอกสคริปต์ด้านล่างแล้ววางด้านบนสุดระหว่างแท็ก <head> และ </head> ทุกหน้าของเว็บไซต์ของคุณ หากปรับแต่งแบนเนอร์, คุกกี้ หรือหมวดหมู่คุกกี้ ให้คุณกดเผยแพร่แบนเนอร์ แบนเนอร์จะถูกอัปเดตอัตโนมัติโดยไม่ต้องคัดลอกสคริปต์เพื่อติดตั้งอีกครั้ง',
    copyScript: 'คัดลอกสคริปต์',
    requestForNewConsent: 'ขอความยินยอมอีกครั้ง',
    yes: 'ใช่',
    no: 'ไม่ใช่',
    display: 'แสดง',
    passwordNotMatch: 'การยืนยันรหัสผ่านไม่ตรงกัน',
    settings: 'การตั้งค่า',
    ipAddressStorage: 'การจัดเก็บ IP Address',
    storeIpAddressFullConsent: 'เก็บบันทึกข้อมูล IP Address เมื่อผู้ใช้งานให้ความยินยอม',
    storeIpAddressPartialConsent: 'เก็บบันทึกข้อมูล IP Address เมื่อผู้ใช้งานให้ความยินยอมบางส่วน',
    storeIpAddressDeclinesConsent: 'เก็บบันทึกข้อมูล IP Address เมื่อผู้ใช้งานปฏิเสธให้ความยินยอม',
    displayIpAddressInConsentRecord: 'แสดง IP Address ในบันทึกความยินยอม',
    dataRetentionPeriod: 'ระยะเวลาการจัดเก็บข้อมูล',
    dataRetentionInfo:
      'ระยะเวลาจัดเก็บข้อมูลสูงสุดขึ้นอยู่กับแพ็กเกจที่คุณเลือก ข้อมูลที่เกินจากระยะเวลาที่กำหนดจะถูกลบออกจากระบบ',
    automaticCookieScan: 'รอบการสแกนคุกกี้อัตโนมัติ',
    automaticCookieScanInfo:
      'ระบบจะทำการสแกนคุกกี้อัตโนมัติตามการตั้งค่า หากไม่ต้องการการสแกนอัตโนมัติ สามารถเลือก ‘ไม่ตั้งเวลา’',
    noSchedule: 'ไม่ตั้งเวลา',
    every7Days: 'ทุก 7 วัน',
    every15Days: 'ทุก 15 วัน',
    every30Days: 'ทุก 30 วัน',
    usageQuota: 'โควตาการใช้งาน',
    enableUsageBeyondQuota: 'เปิดให้ใช้งานเกินโควตา Session ตามแพ็กเกจ (มีค่าใช้จ่าย)',
    usageQuotaInfo:
      'หากคุณเปิดการใช้งาน แบนเนอร์จะทำงานต่อตามปกติเมื่อใช้งานจนครบ sessions ตามแพ็กเกจ ซึ่งมีค่าใช้จ่ายเพิ่มเติม\n\nโควตาการใช้งานจะเพิ่มทีละ 100,000 sessions ในราคา 5,000 บาท ต่อทุก 100,000 sessions ที่เพิ่มขึ้น',
    enableUsageBeyondQuotaPrompt:
      'คุณต้องการเปิดให้ใช้งานเกินโควตา Session ตามแพ็กเกจ (มีค่าใช้จ่าย)?',
    usageChargesInfo: 'ค่าใช้จ่ายจะถูกคิดในแต่ละรอบเดือนการใช้งาน ',
    usageChargesInfoSessions: 'ในราคา 5,000 บาท ต่อทุก 100,000 sessions ที่เพิ่มขึ้น',
    letMeThink: 'ขอคิดดูก่อน',
    callBackFunction: 'CallBack Function (JavaScript)',
    enableCallBackFunction: 'เปิดใช้งาน CallBack Function',
    callBackFunctionInfo: 'เมื่อเปิดใช้งาน ระบบจะส่งค่าการยอมรับหรือปฏิเสธไปยังฟังก์ชันที่ระบุไว้',
    specifyFunctionName: 'ระบุชื่อ Function',
    errorPage: 'error page',
    backToHome: 'กลับหน้าหลัก',
    pageNotFound: 'อุ๊บส์! เราไม่พบหน้าที่คุณต้องการ',
    activity: 'กิจกรรม',
    settingsUpdatedSuccessfully: 'อัปเดตการตั้งค่าเรียบร้อยแล้ว',
    settingsUpdateFailed: 'อัปเดตการตั้งค่าไม่สำเร็จ',
    activityLog: 'บันทึกกิจกรรม',

    addUser: 'เพิ่มผู้ใช้งาน',
    pleaseEmail: '(กรุณากด "Enter" หลังจากเพิ่มอีเมลผู้ใช้งาน)',
    emailNotRegistered: 'อีเมลยังไม่มีบัญชีในระบบ กรุณาสมัครสมาชิกก่อนทำการเพิ่มผู้ใช้งาน',
    accessLevel: 'สิทธิ์การใช้งาน',
    admin: 'Admin',
    adminDescription: 'สามารถจัดการทุกอย่างในบัญชี เพิ่ม/ลบโดเมน และผู้ใช้งาน',
    editor: 'Editor',
    editorDescription: 'สามารถจัดการคุกกี้ หมวดหมู่คุกกี้ แบนเนอร์',
    viewer: 'Viewer',
    viewerDescription: 'สามารถดูแดชบอร์ดและการตั้งค่าเท่านั้น',
    addUserButton: 'เพิ่มผู้ใช้งาน',
    emailLabel: 'อีเมล',
    accessLevelLabel: 'สิทธิ์การใช้งาน',
    normal: 'ปกติ',
    waitForEmailConfirmation: 'รอการยืนยันอีเมล',
    sendAgain: 'ส่งอีกครั้ง',
    editUserAccess: 'แก้ไขสิทธิ์ผู้ใช้งาน',
    confirmDeletionUser: 'ยืนยันลบผู้ใช้งาน',
    userDeletedSuccessfully: 'ลบผู้ใช้งานเรียบร้อย',

    dashboard: 'แดชบอร์ด',
    all: 'ทั้งหมด',
    today: 'วันนี้',
    thisMonth: 'เดือนนี้',
    previousMonth: 'เดือนก่อนหน้า',

    allConsentLog: 'บันทึกความยินยอมทั้งหมด',
    allConsent: 'ความยินยอมทั้งหมด',

    consentProportion: 'สัดส่วนความยินยอม',
    rejectAll: 'ปฏิเสธทั้งหมด',

    consentByCategory: 'ความยินยอมตามหมวดหมู่',

    consentOverview: 'ภาพรวมความยินยอม',

    pageRank: 'สัดส่วนการยินยอมตามอันดับเพจ (10 ลำดับ)',
    deviceRatio: 'สัดส่วนการยินยอมตามหมวดหมู่อุปกรณ์',
    proportion: 'สัดส่วน',
    noResultsFound: 'ไม่พบข้อมูล',

    website: 'เว็บไซต์',
    management: 'การจัดการ',
    domainSuspended: 'โดเมนหยุดทำงานชั่วคราว!',
    domainCurrentlySuspendedDomain: 'ขณะนี้โดเมน ',
    domainCurrentlySuspended: ' หยุดทำงานชั่วคราว',
    packageExpired: 'เนื่องจากแพ็กเกจของคุณหมดอายุแล้ว ',
    packageExpiredContact: 'กรุณาติดต่อทีม CookiePlus เพื่อต่ออายุแพ็กเกจ ',
    packageExpiredUpgrade: 'หรืออัปเกรดแพ็กเกจเพื่อใช้งานต่อ',
    contactCookiePlusTeam: 'ติดต่อทีม CookiePlus',
    packageExpiredAccountOwnerPlease: ' กรุณาติดต่อ  ',
    packageExpiredAccountOwnerAccount: ' Account Owner  ',
    packageExpiredAccountOwnerUpgrade: ' ของคุณเพื่อต่ออายุแพ็กเกจ ',
    renewOrUpgradePackage:
      ' กรุณาต่ออายุแพ็กเกจหรืออัปเกรดแพ็กเกจเพื่อใช้งานต่อ ได้ที่หน้า ‘แพ็กเกจ’',
    renewPackage: 'ต่ออายุแพ็กเกจ',
    lastSevenDay: '7 วันล่าสุด',
    closeBannerX: 'กดปิดแบนเนอร์ ‘x’',
    devices: 'อุปกรณ์',
    computer: 'คอมพิวเตอร์',
    mobile: 'โทรศัพท์มือถือ',
    mobileAndTablet: 'โทรศัพท์มือถือ/แท็บเล็ต',
    tablet: 'แท็บเล็ต',
    smartTv: 'สมาร์ททีวี',
    others: 'อื่นๆ',
    historicalData2year: '*ข้อมูลย้อนหลังไม่เกิน 2 ปี',
    logOut: 'ออกจากระบบ',
    days: 'วัน',
    alert: 'แจ้งเตือน!',
    sessionsCountApproachingLimit: 'จำนวน Sessions ใกล้ถึงขีดสูงสุด',
    domainSessionsCount: 'จำนวน Sessions ของโดเมน',
    nearingPackageLimit: 'ใกล้ถึงขีดสูงสุดตามแพ็กเกจแล้ว',
    preventBannerDeactivation: 'เพื่อไม่ให้แบนเนอร์หยุดทำงาน กรุณาติดต่อเรา admin@predictive.co.th',
    upgradePackageBeforeLimit: 'เพื่ออัปเกรดแพ็กเกจก่อนถึงขีดสูงสุด',
    enableExceedQuota:
      'คุณสามารถตั้งค่า “เปิดใช้งานเกินโควตา Session” ได้เองที่ปุ่ม ตั้งค่าการใช้งาน',
    setting: 'ตั้งค่าการใช้งาน',
    sessionsLimitReached: 'จำนวน Sessions ถึงขีดสูงสุดแล้ว',
    bannerOnWebsite: 'ขณะนี้แบนเนอร์บนหน้าเว็บไซต์',
    hasReachedSessionLimit: 'มีจำนวน Sessions',
    forYourPackage: 'ถึงขีดสูงสุดตามแพ็กเกจแล้ว',
    overQuotaUsageEnabled: 'มีการเปิดให้ใช้งานเกินโควตา Session ตามแพ็กเกจ',
    additionalUsageCharged: 'หลังจากนี้จะถูกคิด',
    ratePerSession: 'ในราคา 5000 บาท ต่อทุก 100,000 sessions ที่เพิ่มขึ้น',
    keepBannerActive: 'เพื่อไม่ให้แบนเนอร์หยุดทำงาน กรุณาอัปเกรดแพ็กเกจก่อนถึงขีดสูงสุด',
    temporarilySuspended: 'หยุดทำงานชั่วคราว',
    reachedPackageLimit: 'เนื่องจากจำนวน Sessions ถึงขีดสูงสุดตามแพ็กเกจแล้ว',
    upgradeToContinue: 'กรุณาอัปเกรดแพ็กเกจเพื่อใช้งานต่อ',
    upgradeNow: 'อัปเกรดตอนนี้',
    later: 'เอาไว้ทีหลัง',

    deleteDomain: 'ลบโดเมน',
    saveBannerSettings: 'บันทึกการตั้งค่าแบนเนอร์',
    deleteCookieCategory: 'ลบหมวดหมู่คุกกี้',
    editCookieCategory: 'แก้ไขหมวดหมู่คุกกี้',
    deleteCookie: 'ลบคุกกี้',
    editCookies: 'แก้ไขคุกกี้',
    addCookieToCategorys: 'ทำการเพิ่มคุกกี้เข้าสู่หมวดหมู่คุกกี้',
    grantPermissions: 'ให้สิทธิ์แก่ผู้ใช้อื่น',
    removeUserPermissions: 'ลบสิทธิ์ผู้ใช้',
    editUserPermissions: 'แก้ไขสิทธิ์ผู้ใช้',
    addLogo: 'เพิ่มโลโก้',
    changeDomainPackage: 'ปรับเปลี่ยนแพ็คเกจของโดเมน',
    modifyContentSettings: 'ปรับเปลี่ยนการตั้งค่าคอนเซนท์',
    stopCookieScan: 'หยุดสแกนคุกกี้',
    addCookieCategorys: 'เพิ่มหมวดหมู่คุกกี้',
    packageDetails: 'ข้อมูลการใช้งานแพ็กเกจ',
    dailyWebsiteScans: 'สแกนเว็บไซต์ต่อวัน',
    billingCycle: 'รอบการใช้งาน',
    or: 'หรือ',
    sessionId: 'รหัสความยินยอม',
    filterByCate: 'ค้นหาตามหมวดหมู่',
    typingSearch: 'พิมพ์เพื่อค้นหา',
    consentId: 'รหัสความยินยอม',
    unable_to_proceed: 'ไม่สามารถดำเนินการต่อ',
    payment_unable_to_process: 'ขออภัย เราไม่สามารถดำเนินการชำระเงินของคุณได้ เนื่องจาก',
    reached_limit: 'คุณได้ถึงขีดจำกัดใน:',
    account_user_limit: '1. จำนวนผู้ใช้งาน',
    consent_records_limit: '2. จำนวนบันทึกความยินยอม',
    please_select_another_plan: 'กรุณาเลือกแพ็กเกจอื่นเพื่อดำเนินการต่อ',
    select_another_plan: 'เลือกแพ็กเกจอื่น',
    payment_couldnt_be_processed: 'ไม่สามารถชำระเงินได้',
    already_applied_for_free_plan: 'ดูเหมือนว่าคุณได้สมัครแพ็กเกจฟรีของเราไปแล้ว',
    please_upgrade_to_continue: 'กรุณาอัปเกรดเป็นแพ็กเกจอื่นเพื่อดำเนินการต่อ',
    unexpected_error: 'เกิดข้อผิดพลาดที่ไม่คาดคิด กรุณาลองใหม่อีกครั้ง',
    contact_support: 'หรือติดต่อทีม CookiePlus',
    chooseForSearch: 'เลือกเพื่อค้นหา'
  },
  cn: {
    signin: 'ข้อมูลไม่ถูกต้อง'
  }
}

export { i18n }
