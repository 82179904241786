import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import { getCookie, saveCookie } from '../../helper/helper'

const DropdownLangComponent = props => {
  const [openLang, setOpenLang] = useState(false)

  const onChangeLang = lang => {
    saveCookie('lang', lang, 365) // บันทึกค่าลงในคุกกี้
    props.changeLangFn(lang)
  }

  return (
    <div className="btn-group">
      {/* btn change Lang */}
      <button
        className={`btn dropdown-toggle `}
        type="button"
        id="defaultDropdown"
        data-bs-toggle="dropdown"
        data-bs-auto-close="true"
        aria-expanded="true"
      >
        <img src={`/assets/images/language.svg`} alt="" className="img-sm-px mx-1" />{' '}
        {props.valueLang === 'th' ? 'ภาษาไทย' : 'English'}
      </button>

      <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
        {props.valueLang === 'th' ? (
          <li>
            <a className="dropdown-item" onClick={() => onChangeLang('en')}>
              English
            </a>
          </li>
        ) : (
          <li>
            <a className="dropdown-item" onClick={() => onChangeLang('th')}>
              ภาษาไทย
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default DropdownLangComponent
