import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import { getChargeConsentStatus, getChargeNewCardStatus } from '../../apis/Payment/api'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const CheckPaymentComponent = props => {
  const history = useHistory()
  const consentId = props.match.params.id

  const [paySuccess, setPaySuccess] = useState(true)
  const [chargeId, setChargeId] = useState('')
  const [refID, setrefID] = useState('')
  const [loading, setLoading] = useState(true)
  const { REACT_APP_PREDICTIVE, REACT_APP_OMISE_SECRET_KEY } = process.env
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])
  useEffect(() => {
    document.title = 'ชำระเงิน | CookiePlus'
    if (consentId !== 'add-new-card') {
      getStatusCharge(consentId)
    } else {
      getStatusNewCard()
    }
  }, [])

  const getStatusCharge = async id => {
    const { data, status } = await getChargeConsentStatus(id)
    if (status === 200) {
      if (data.data.status === 'successful') {
        setChargeId(data.data.charge_id)
        setrefID(data.data.ref)
        setLoading(false)
        setPaySuccess(true)
      } else if (data.data.status === 'pending') {
        setTimeout(() => {
          getStatusCharge(consentId)
        }, 5000)
      } else {
        setChargeId(data.data.charge_id)
        setrefID(data.data.ref)
        setLoading(false)
        setPaySuccess(false)
      }
    } else {
      setChargeId(data.data.charge_id)
      setrefID(data.data.ref)
      setLoading(false)
      setPaySuccess(false)
    }
  }

  const getStatusNewCard = async () => {
    const { data, status } = await getChargeNewCardStatus()
    if (status === 200) {
      if (data.data.status === 'successful') {
        setChargeId(data.data.charge_id)
        setrefID(data.data.ref)
        setLoading(false)
        setPaySuccess(true)
      } else if (data.data.status === 'pending') {
        setTimeout(() => {
          getStatusNewCard()
        }, 5000)
      } else {
        //failed expired, reversed
        setChargeId(data.data.charge_id)
        setrefID(data.data.ref)
        setLoading(false)
        setPaySuccess(false)
      }
    } else {
      setChargeId(data.data.charge_id)
      setrefID(data.data.ref)
      setLoading(false)
      setPaySuccess(false)
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {loading ? (
            <div className="card card-loader w-100 ">
              <div className="loader">
                <img src={`/assets/images/loadding.svg`} alt="" className="w-100" />
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body py-5 text-center">
                <img
                  src={`/assets/images/${paySuccess ? 'PaySuccess' : 'PayFail'}.svg`}
                  alt=""
                  className=""
                />
                <p className="fs-24 font-weight-bold m-0 mt-5">
                  {paySuccess ? i18n[lang].paymentSuccessMessage : i18n[lang].paymentFailureMessage}
                </p>
                {refID && (
                  <p className="fs-18 font-weight-bold text-Gray">{i18n[lang].orderNumberLabel} : #{refID}</p>
                )}
                <p className=" fs-14 text-Gray font-weight-bold">
                  {paySuccess ? (
                    <span>
                      {i18n[lang].paymentThankYou} <br />
                      {i18n[lang].orderEmailNotification}
                    </span>
                  ) : (
                    <span>
                      {i18n[lang].somethingWentWrong}
                      <br />
                      {i18n[lang].needAssistance}{' '}
                      <span className="text-primary text-uppercase">support@cookieplus.com</span>
                    </span>
                  )}
                </p>
                <button
                  onClick={() => history.push('/domain')}
                  className="btn btn-primary mt-3 px-5"
                >
                  {i18n[lang].backToWebsite}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-Gray text-8">
              Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
            </div>
            <div className="col-sm-6 text-Gray text-8">
              <div className="text-sm-end d-none d-sm-block">
                <a
                  className="text-Gray"
                  href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                  target="_blank"
                >
                  Privacy Policy.
                </a>{' '}
                <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default CheckPaymentComponent
