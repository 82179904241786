import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import HeaderComponent from '../header/header'
import { getCookie } from '../../helper/helper'
import { i18n } from '../../helper/i18n'
const PrimaryMenuComponent = props => {
  const history = useHistory()
  const path = useLocation().pathname

  const [active, setActive] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])

  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <a onClick={() => history.push('/domain')} className="logo logo-light">
          <span className="logo-sm">
            <img src="/assets/images/logo-16.svg" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo.svg" alt="" height="26" />
          </span>
        </a>
        <button
          onClick={e => props.pinMenuFn()}
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/domain') ? 'active' : ''} `}
                onClick={() => history.push(`/domain`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-application-outline"></i>
                <span data-key="t-authentication">{i18n[lang].website}</span>
              </a>
            </li>
            <div className="py-2 px-4">
              <p className="text-white mb-0" style={{ border: '0.2px solid rgb(30 67 94)' }}></p>
            </div>
            <li className="menu-title ">
              <i className="ri-more-fill"></i> <span data-key="t-pages">{i18n[lang].management}</span>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/usersetting') ? 'active' : ''} `}
                onClick={() => history.push(`/usersetting`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-account-cog"></i>
                <span data-key="t-authentication">{i18n[lang].userSettings}</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link menu-link ${path.includes('/package') ? 'active' : ''} `}
                onClick={() => history.push(`/package`)}
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="mdi mdi-gift"></i> <span data-key="t-pages">{i18n[lang].package}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PrimaryMenuComponent
