import * as axios from 'axios'
import { redirectLogin, redirectPermission, refreshToken } from '../../helper/helper'

const { REACT_APP_API_MANAGEMENT } = process.env

let token = localStorage.getItem('token')
const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const summaryConsentCategory = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/dashboard/get-summary-consent-category?consent_id=${obj.consent_id}&start_date=${obj.start_date}&end_date=${obj.end_date}&lang=${obj.lang}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const summaryConsent = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/dashboard/get-summary-consent?consent_id=${obj.consent_id}&start_date=${obj.start_date}&end_date=${obj.end_date}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const summaryPage = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/dashboard/get-summary-page?consent_id=${obj.consent_id}&start_date=${obj.start_date}&end_date=${obj.end_date}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const summaryConsentGraph = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/dashboard/get-summary-consent-graph?consent_id=${obj.consent_id}&start_date=${obj.start_date}&end_date=${obj.end_date}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const summaryDevices = async obj => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: obj.consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_MANAGEMENT}/dashboard/get-summary-device?start_date=${obj.start_date}&end_date=${obj.end_date}`
    const response = await axios.get(URL, configHeader)

    if (response?.status && response.status === 200) {
      refreshToken(response.headers.access_token)
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export { summaryConsentCategory, summaryConsent, summaryPage, summaryConsentGraph, summaryDevices }
