import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { redirectLogin } from '../../helper/helper'
import { verifyToken } from '../../apis/authenication/api'
import { packageDetail, consentById } from '../../apis/management/api'
import Modal from 'react-modal'
import DropdownLangComponent from '../layout/dropdownLang'
import { i18n } from '../../helper/i18n'

const HeaderComponent = props => {
  const id = props && props.match && props.match.params ? props.match.params.id : ''
  const [userDetail, setUserDetail] = useState()
  const [sessionQuota, setSessionQuota] = useState()
  const [sessionUsed, setSessionUsed] = useState()
  const [sessionRemaining, setSessionRemaining] = useState()
  const [sessionBox, setSessionBox] = useState()
  const [sumSession, setSumSession] = useState()
  const [cookieDomain, setCookieDomain] = useState()

  const history = useHistory()
  const [openPopupLimitSessions, setPopupLimitSessions] = useState(false)
  const [openPopupLimitSessionsEnterprise, setPopupLimitSessionsEnterprise] = useState(false)
  const [limitSessions, setSessions] = useState()
  const [sessionAddon, setSessionAddon] = useState(false)
  const [openPopupLimitSessionsAddon, setPopupLimitSessionsAddon] = useState(false)
  const [accountRole, setAccountRole] = useState('')
  // const [lang, setLang] = useState('th')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: 'auto',
      minWidth: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }

  useEffect(() => {
    checkLogin()
  }, [])

  useEffect(() => {
    const consentId = props && props.match && props.match.params ? props.match.params.id : ''
    if (consentId) {
      getConsentById(consentId)
      getPackageDetail()
      setSessionBox(true)
    } else {
      setSessionBox(false)
    }
  }, [props])

  const getConsentById = async id => {
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setCookieDomain(data.data.domain)
      setAccountRole(data.data.permission_consents[0].role)
    }
  }

  const getPackageDetail = async () => {
    const { data, status } = await packageDetail(id)
    if (data.data.packageDetail.package_expired) {
      history.push(`/domain`)
    }
    setSessionQuota(
      data.data.session.package_session_quota
        ? (
          data.data.session.package_session_quota + data.data.session.session_addon_quota
        ).toLocaleString('en')
        : 0
    )

    setSessionUsed(
      data.data.session.session_used ? data.data.session.session_used.toLocaleString('en') : 0
    )
    setSessionRemaining(
      data.data.session.remaining ? data.data.session.remaining.toLocaleString('en') : 0
    )
    setSessionAddon(
      data.data.packageDetail.package.is_session_addon
        ? data.data.packageDetail.package.is_session_addon
        : false
    )
    let sessionPercent =
      (data.data.session.session_used /
        (data.data.session.package_session_quota + data.data.session.session_addon_quota)) *
      100

    setSumSession(sessionPercent + '%')
    setSessions(sessionPercent)

    if (data.data.packageDetail.type === 'credit') {
      if (sessionPercent >= 70 && sessionPercent <= 100) {
        if (!getCookie('notiSession')) {
          setPopupLimitSessions(true)
        }
      }
    } else {
      if (data.data.packageDetail.package.is_session_addon) {
        if (sessionPercent >= 70 && sessionPercent <= 99) {
          if (!getCookie('notiSession')) {
            setPopupLimitSessionsEnterprise(true)
          }
        } else {
          if (sessionPercent === 100) {
            if (!getCookie('notiSession')) {
              setPopupLimitSessionsAddon(true)
            }
          }
        }
      }
    }
  }

  const checkLogin = async () => {
    const { data, status } = await verifyToken()
    if (data && status === 200) {
      setUserDetail(data)
      if (window.location.pathname === '/') {
        history.push('/domain')
      }
    } else {
      redirectLogin()
    }
  }

  const cookieSession = () => {
    if (!/(^|;)\s*notiSession=/.test(document.cookie)) {
      var date = new Date()
      date.setDate(date.getDate() + 1)
      document.cookie = `notiSession=${limitSessions};expires=${date.toUTCString()};path=/`
    }
  }
  function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  return (
    <header id="page-topbar">
      {userDetail && (
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <a href="index.html" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="17" />
                  </span>
                </a>

                <a href="index.html" className="logo logo-light">
                  <span className="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src="assets/images/logo-light.png" alt="" height="17" />
                  </span>
                </a>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown d-md-none topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-search-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-search fs-22"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {sessionBox && (accountRole === 'admin' || accountRole === 'owner') && (
                <Fragment>
                  <div className="w-230px mr-3">
                    <div className="row w-100 mt-3 mx-0">
                      <ul className="nav w-100">
                        <li className="nav-item w-25">Sessions</li>
                        <li className="nav-item w-75 text-right">
                          <b>{sessionUsed ? sessionUsed : '0'}</b> /{' '}
                          {sessionQuota ? sessionQuota : '0'}
                        </li>
                      </ul>
                    </div>
                    <div className="progress animated-progress custom-progress mb-4">
                      <div
                        className="progress-bar bg-Primary"
                        role="progressbar"
                        style={{ width: sumSession }}
                        // aria-valuenow={sumSession}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="border-left"></div>
                </Fragment>
              )}
              <DropdownLangComponent changeLangFn={props.setLangFn} valueLang={props.langFn} />

              <div className="dropdown header-item ml-3">
                <button
                  type="button"
                  className="btn"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center ">
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {userDetail && userDetail.email}
                      </span>
                      <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                        {userDetail && userDetail.verified ? 'Verified' : 'UnVerified'}
                      </span>
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" onClick={() => redirectLogin()}>
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
                    <span
                      className="align-middle"
                      onClick={() => localStorage.removeItem('token')}
                      data-key="t-logout"
                    >
                      {i18n[props.langFn].logOut}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupLimitSessions}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setPopupLimitSessions(false)}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img
                src={`/assets/images/limit-${limitSessions >= 70 && limitSessions <= 99 ? '70' : '100'
                  }.png`}
                alt=""
                height="60"
              />
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label fs-20">
                  <strong>
                    {i18n[props.langFn].alert}
                    <br />
                    <span className="fs-16">
                      {limitSessions >= 70 && limitSessions <= 99
                        ? i18n[props.langFn].sessionsCountApproachingLimit
                        : i18n[props.langFn].sessionsLimitReached}
                    </span>
                  </strong>
                </label>
                <br />
                {limitSessions >= 70 && limitSessions <= 99 ? (
                  <span>
                    {i18n[props.langFn].domainSessionsCount} <b> {cookieDomain} </b>
                    {i18n[props.langFn].nearingPackageLimit} <br />
                    {i18n[props.langFn].keepBannerActive}
                  </span>
                ) : (
                  <span>
                    {i18n[props.langFn].bannerOnWebsite} <b> {cookieDomain} </b> {i18n[props.langFn].bannerOnWebsiteWebsite} {i18n[props.langFn].domainCurrentlySuspended}
                    <br />
                    {i18n[props.langFn].reachedPackageLimit}
                    <br />
                    {i18n[props.langFn].upgradeToContinue}
                  </span>
                )}
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className={`btn w-lg ${limitSessions === 100 ? 'btn-outline-danger' : 'btn-outline-warning'
                  } waves-effect waves-light  `}
                onClick={() => (
                  setPopupLimitSessions(false), cookieSession(), history.push(`/package`)
                )}
              >
                {i18n[props.langFn].upgradeNow}
              </button>
              <button
                type="button"
                className={`btn w-lg ${limitSessions === 100 ? 'btn-danger' : 'btn-warning'
                  }  waves-effect waves-light`}
                onClick={() => (setPopupLimitSessions(false), cookieSession())}
              >
                {i18n[props.langFn].later}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupLimitSessionsEnterprise}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setPopupLimitSessionsEnterprise(false)}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src={`/assets/images/limit_inv_yellow.png`} alt="" height="133" />
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label fs-20">
                  <strong>
                    {i18n[props.langFn].alert}
                    <br />
                    <span className="fs-16">{i18n[props.langFn].sessionsCountApproachingLimit}</span>
                  </strong>
                </label>
                <br />
                <span>
                  {i18n[props.langFn].domainSessionsCount} <b> {cookieDomain} </b> {i18n[props.langFn].nearingPackageLimit}{' '}
                  <br />
                  {i18n[props.langFn].preventBannerDeactivation} <br />
                  {i18n[props.langFn].upgradePackageBeforeLimit}
                  <br />
                  {i18n[props.langFn].or}
                  <br />
                  {i18n[props.langFn].enableExceedQuota}
                </span>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className={`btn w-lg btn-warning  waves-effect waves-light`}
                onClick={() => (setPopupLimitSessionsEnterprise(false), cookieSession())}
              >
                {i18n[props.langFn].ok}
              </button>
              <button
                type="button"
                className={`btn w-lg btn-outline-warning  waves-effect waves-light`}
                onClick={() => (
                  setPopupLimitSessionsEnterprise(false),
                  cookieSession(),
                  history.push(`/ipsetting/${id}`)
                )}
              >{i18n[props.langFn].setting}

              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupLimitSessionsAddon}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setPopupLimitSessionsAddon(false)}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-1">
            <div className="modal-body text-center">
              <img src={`/assets/images/limit_inv_yellow.png`} alt="" height="133" />
              <div className="mt-1">
                <label htmlFor="basiInput" className="form-label fs-20">
                  <strong>
                    {i18n[props.langFn].alert}
                    <br />
                    <span className="fs-16">{i18n[props.langFn].sessionsLimitReached}</span>
                  </strong>
                </label>
                <br />
                <span>
                  {i18n[props.langFn].bannerOnWebsite} <b> {cookieDomain} </b> {i18n[props.langFn].hasReachedSessionLimit} <br />
                  {i18n[props.langFn].forYourPackage}
                  <br />
                  <br />
                  {i18n[props.langFn].overQuotaUsageEnabled}
                  <br />
                  {i18n[props.langFn].additionalUsageCharged} <b> {i18n[props.langFn].ratePerSession}</b>
                </span>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className={`btn w-lg btn-warning  waves-effect waves-light`}
                onClick={() => (setPopupLimitSessionsAddon(false), cookieSession())}
              >
                {i18n[props.langFn].ok}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </header>
  )
}

export default HeaderComponent
