import styled from "styled-components";

const TableBox = styled.div`
  div:first-child {
    white-space: unset;
    text-overflow: ellipsis;
  }
  

  .scan-cookie{
    padding: 13% 0%;
    text-align: center;
    position: absolute;
    top: 10%;
    width: 100%;
  } 
  .sc-dmRaPn{
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: auto;
    min-height: 0;
  }
`;

export { TableBox };
