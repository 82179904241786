import styled from 'styled-components'

const UserSettingBox = styled.div`
.card-table-fix {
    min-height: unset;
    height: auto;

}
`
export { UserSettingBox }
