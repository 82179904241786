import styled from 'styled-components'

const TableBox = styled.div`
  div:first-child {
    white-space: unset;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .zero-center {
    width: 100%;
    padding: 0% 30%;
    text-align: center;
    position: absolute;
    top: 15%;
  }
`
const CategoryBox = styled.div`
  .-cate-active {
    background: #1298be;
    color: #fff;
  }
`
export { TableBox, CategoryBox }
