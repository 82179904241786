import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { resendConfirmEmail } from '../../apis/authenication/api'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
import { RegisterBox } from './style'
import DropdownLangComponent from '../layout/dropdownLang'
import { getCookie } from '../../helper/helper'
import { i18n } from '../../helper/i18n'

const { REACT_APP_CAPTCHA, REACT_APP_PREDICTIVE } = process.env

var round = 0

const ResendComponent = props => {
  const [forgotPasswordError, setForgotPasswordError] = useState(false)
  const [verifyToken, setVerifyToken] = useState()
  const [isResendFail, setResendFail] = useState(false)
  const [isResendSuccess, setResendSuccess] = useState(false)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [lang, setLang] = useState(getCookie('lang'))
  const [isShowLoading, setIsShowLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    document.title = 'ยืนยันตัวตน | CookiePlus'
    refreshRecaptcha()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 10000)
    return () => clearInterval(interval)
  }

  const onSubmit = async obj => {
    setIsShowLoading(true)
    let params = {
      email: obj.email.toLowerCase(),
      token: verifyToken
    }
    const { data, status } = await resendConfirmEmail(params)
    if (data && status === 200) {
      setResendFail(false)
      setResendSuccess(true)
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
      setIsShowLoading(false)
    } else {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
      setResendFail(true)
      setIsShowLoading(false)
    }
  }

  const keepTokenFn = token => {
    setVerifyToken(token)
  }

  return (
    <RegisterBox>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
          <div className="bg-overlay">
            {isResendFail && (
              <div
                className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].uableSendVerify}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            {isResendSuccess && (
              <div
                className="alert alert-success alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].sendEmailDone}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
          </div>
          <div className="auth-page-content overflow-hidden">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card overflow-hidden">
                    <div className="row g-0">
                      <div className="col-lg-6">
                        <div className="p-lg-5 py-4 auth-one-bg h-100">
                          <div className="position-relative h-100 d-flex flex-column">
                            <div className="mb-4"></div>
                            <div className="my-auto row">
                              <div className="col-12 text-center">
                                <img
                                  src="assets/images/logo-dark.svg"
                                  alt="cookieplus"
                                  height="20"
                                />

                                <p className="fs-36 text-Dark font-weight-bold mt-2">
                                  {i18n[lang].welcome}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 pr-1">
                        <div className="p-25-center scroll-content-500 my-auto">
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="text-dark big-subtitle m-0 ">{i18n[lang].resendEmail}</p>
                              <DropdownLangComponent changeLangFn={setLang} valueLang={lang} />
                            </div>
                            <p className="text-muted text-email">{i18n[lang].enterEmailVerify}</p>
                          </div>

                          <div className="mt-4">
                            <form action="/domain" onSubmit={handleSubmit(onSubmit)}>
                              <div className="mb-3">
                                <label htmlFor="username" className="form-label text-16 ">
                                  {i18n[lang].emailUsedRegister}
                                </label>
                                <input
                                  id="email"
                                  placeholder={i18n[lang].email}
                                  className={`form-control ${errors.email || forgotPasswordError ? 'is-invalid' : ''
                                    }`}
                                  {...register('email', {
                                    required: i18n[lang].enterEmail,
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: i18n[lang].emailFormatIncorrect
                                    }
                                  })}
                                />
                                <div className="invalid-feedback">
                                  {forgotPasswordError
                                    ? 'The email address or password is incorrect'
                                    : ''}
                                </div>
                                <label className="invalid-feedback-err">
                                  {errors.email?.message}
                                </label>
                              </div>

                              <div className="mt-4">
                                <button
                                  className="btn btn-primary w-100 text-16 mb-5 btn-load"
                                  type="submit"
                                  id="btn-resend-email"
                                  disabled={isShowLoading}
                                >
                                  <span className="d-flex align-items-center justify-content-center">

                                    {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                                    </span> <span className=" ms-2 mr-2">
                                        {i18n[lang].resendEmail}
                                      </span> </Fragment> : i18n[lang].resendEmail
                                    }

                                  </span>

                                </button>

                                <footer className="footer-login start-0 text-right px-5">
                                  <a
                                    className="text-right fs-14 cursor-pointer"
                                    href="/login"
                                    id="btn-to-login"
                                  >
                                    <i className="mdi mdi-arrow-left-thin"></i>
                                    {i18n[lang].backToLogin}
                                  </a>
                                </footer>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer-page start-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div className="mb-0 text-10 xx-sm d-inline">
                      Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
                    </div>
                    <div className="d-inline">
                      <a
                        className="text-white text-10 xx-sm"
                        href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                        target="_blank"
                      >
                        &nbsp;Privacy Policy
                      </a>{' '}
                      <span className="text-10 xx-sm">●</span>{' '}
                      <a
                        className="text-white text-10 xx-sm"
                        href={REACT_APP_PREDICTIVE + `/terms`}
                        target="_blank"
                      >
                        Terms and conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <ReCaptchaComponent keepTokenFn={keepTokenFn} refreshToken={refreshReCaptcha} />
        </GoogleReCaptchaProvider>
      </div>
    </RegisterBox>
  )
}

export default ResendComponent
