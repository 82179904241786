import React, { Fragment, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { useHistory, useLocation } from 'react-router-dom'
import { TableBox } from './style'
import {
  createConsent,
  deleteConsent,
  getCookieList,
  scanCookie,
  categoryList,
  deleteCookie,
  createCookie,
  consentById,
  updateCookie,
  cookieDetail,
  stopScanCookie
} from '../../apis/management/api'
import { LuHistory } from 'react-icons/lu'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'

import List from 'list.js'
import Modal from 'react-modal'
import { i18n } from '../../helper/i18n'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env

const ScanCookieComponent = props => {
  const id = props.match.params.id

  const history = useHistory()
  const [listCookie, setListCookie] = useState()
  const [domainName, setDomainName] = useState()
  const [deleteId, setDeleteId] = useState()
  const [cookieId, setCookieId] = useState()
  const [openPopupCeate, setOpenPopupCeate] = useState(false)
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [openPopupScan, setOpenPopupScan] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [categoryCookieList, setCategoryCookieList] = useState()

  const [countCookies, setCountCookies] = useState(0)
  const [cookieName, setCookieName] = useState('')
  const [cookieExpireDate, setCookieExpireDate] = useState('')
  const [cookieExpireType, setCookieExpireType] = useState('Year')
  const [descCookie, setDescCookie] = useState('')
  const [detailCookie, setDetailCookie] = useState({})
  const [initDetail, setInitDetail] = useState({})
  const [categoryId, setCategoryId] = useState(0)
  const [detailLang, setDetailLang] = useState('th')
  const [paginationPage, setPaginationPage] = useState(1)

  const [scanlistCookie, setscanlistCookie] = useState(true)
  const [scanURL, setscanURL] = useState(false)
  const [listCookieURL, setListCookieURL] = useState()
  const [nameCookie, setNameCookie] = useState()

  const [inValidateDomainName, setInValidateDomainName] = useState(false)
  const [isScanFail, setIsScanFail] = useState(false)
  const [active, setActive] = useState(false)
  const [isShowLoading, setIsShowLoading] = useState(false)

  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
    if (listCookie) {
      getCookieListFn()
      // getConsentPublishList()
    }
  }, [props.langFn])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-45%, -50%)',
      width: 'auto',
      minWidth: '400px',
      height: 'auto',
      borderRadius: '10px',
      padding: '0px',
      border: 'unset',
      background: 'unset'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }

  const tableStyles = {
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '500'
      }
    },
    headCells: {
      style: {
        backgroundColor: '#f3f6f9',
        fontSize: '14px'
      }
    }
  }
  const paginationComponentOptions = {
    rowsPerPageText: '', // Remove the label
    noRowsPerPage: true, // Disable the rows per page dropdown
    selectAllRowsItem: false,
    selectAllRowsItemText: ''
  }
  const columns = [
    {
      name: i18n[lang].cookieName,
      selector: row => row.cookie,
      width: '300px',
      sortable: true
    },
    {
      name: i18n[lang].domain,
      selector: row => row.domain
    },
    {
      name: i18n[lang].description,
      selector: row => row.description,
      width: '300px'
    },
    {
      name: i18n[lang].cookieDuration,
      selector: row => row.expire
    },
    {
      name: i18n[lang].cookieCategory,
      selector: row => row.cookie_category
    },
    {
      name: '',
      selector: row => row.options,
      width: '100px'
    }
  ]

  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let res = day + '/' + month + '/' + year + ' ' + time
    return res
  }

  useEffect(() => {
    document.title = 'สแกนคุกกี้ | CookiePlus'
    getConsentById()
    getCategoryList()
  }, [])

  useEffect(() => {
    getCookieListFn()
  }, [detailLang])

  const getConsentById = async () => {
    const { data, status } = await consentById(id)
    if (data && status === 200) {
      setInitDetail(data.data)
      if (data.data.is_scanning) {
        setOpenPopupScan(true)
        const interval = setInterval(async () => {
          const res = await consentById(id)
          if (res && res.data && res.data.data && !res.data.data.is_scanning) {
            await getCookieListFn()
            setOpenPopupScan(false)
            clearInterval(interval)
          }
        }, 10000)
        return () => clearInterval(interval)
      } else {
        setOpenPopupScan(false)
      }
    }
  }

  const scanCookieBtn = async () => {
    let obj = {
      consent_id: id,
      url: initDetail.domain
    }
    const { data, status } = await scanCookie(obj)
    if (data && status === 200) {
      setOpenPopupScan(true)
      setIsScanFail(false)
      getConsentById()
    } else {
      setOpenPopupScan(false)
      setIsScanFail(true)
    }
  }

  const getCookieListFn = async (page = 1) => {
    const result = await consentById(id)
    let role = result.data.data

    let obj = {
      consent_id: id,
      cookie_category_id: '',
      page: page,
      limit: 20
    }
    const { data, status } = await getCookieList(obj)
    setCountCookies(data.data.count)
    let res = []
    if (data && data.data && data.data.rows && data.data.rows.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.rows.length; i++) {
        let lengthExpire =
          data.data.rows[i].expire_type === 'Year'
            ? i18n[props.langFn].year
            : data.data.rows[i].expire_type === 'Month'
            ? i18n[props.langFn].month
            : i18n[props.langFn].day
        let dateExpire = data.data.rows[i].expire_date
          ? data.data.rows[i].expire_date + ' ' + lengthExpire
          : '-'

        _tempData = {
          cookie: (
            <a
              className="btn-link"
              onClick={() => openURLList('open', data.data.rows[i].path, data.data.rows[i].name)}
            >
              {data.data.rows[i].name}
            </a>
          ),
          domain: data.data.rows[i].domain.replace(/(^\w+:|^)\/\//, ''),
          description:
            data.data.rows[i].description && data.data.rows[i].description[detailLang]
              ? data.data.rows[i].description[detailLang]
              : '-',
          expire: dateExpire,
          cookie_category:
            data.data.rows[i].cookie_category && data.data.rows[i].cookie_category.name
              ? data.data.rows[i].cookie_category.name[detailLang]
              : '-',
          options: (
            <Fragment>
              {role &&
              role.permission_consents &&
              role.permission_consents[0] &&
              role.permission_consents[0].role &&
              role.permission_consents[0].role !== 'viewer' ? (
                <Fragment>
                  <span
                    className={`link-edit mx-2 ${
                      initDetail &&
                      initDetail.permission_consents &&
                      initDetail.permission_consents[0] &&
                      initDetail.permission_consents[0].role &&
                      initDetail.permission_consents[0].role !== 'viewer'
                        ? ''
                        : 'role-disble'
                    }`}
                    onClick={() =>
                      role &&
                      role.permission_consents &&
                      role.permission_consents[0] &&
                      role.permission_consents[0].role &&
                      role.permission_consents[0].role !== 'viewer'
                        ? (setDetailCookie(data.data.rows[i]), openModalEdit(data.data.rows[i].id))
                        : ''
                    }
                  >
                    <i className="mdi mdi-lead-pencil cursor-pointer"></i>
                  </span>

                  <span
                    className={`link-trash cursor-pointer mx-2 ${
                      initDetail &&
                      initDetail.permission_consents &&
                      initDetail.permission_consents[0] &&
                      initDetail.permission_consents[0].role &&
                      initDetail.permission_consents[0].role !== 'viewer'
                        ? ''
                        : 'role-disble'
                    }`}
                    onClick={() =>
                      role &&
                      role.permission_consents &&
                      role.permission_consents[0] &&
                      role.permission_consents[0].role &&
                      role.permission_consents[0].role !== 'viewer'
                        ? (setDetailCookie(data.data.rows[i]), popupDelete(data.data.rows[i].id))
                        : ''
                    }
                  >
                    <i className="mdi mdi-trash-can cursor-pointer"></i>
                  </span>
                </Fragment>
              ) : (
                ''
              )}
            </Fragment>
          )
        }
        res.push(_tempData)
      }
      if (page > 1) {
        let mergeData = listCookie
        mergeData.push(...res)

        setListCookie(mergeData.map(item => item))
      } else {
        setListCookie(res)
      }
    }
    return res
  }

  const handleChangePage = async e => {
    if (e * 10 === listCookie.length) {
      let page = paginationPage + 1
      setPaginationPage(page)
      await getCookieListFn(page)
    }
  }

  const createCookieFn = async () => {
    let obj = {
      consent_id: id,
      name: cookieName,
      domain: domainName,
      expire_date: cookieExpireDate, // day , month , year
      expire_type: cookieExpireType, // day , month , year
      category_cookie_id: categoryId,
      description: descCookie
    }
    const { data, status } = await createCookie(obj)
    if (data && status === 200) {
      setOpenPopupCeate(false)
      getCookieListFn()
      setIsShowLoading(false)
    } else {
      setIsShowLoading(false)
    }
  }

  const popupDelete = id => {
    setOpenPopupDelete(true)
    setDeleteId(id)
  }

  const removeCookie = async () => {
    let obj = {
      cookie_id: deleteId,
      consent_id: id
    }
    const { data, status } = await deleteCookie(obj)
    if (data && status === 200) {
      setOpenPopupDelete(false)
      getCookieListFn()
    }
  }

  const editCookie = async () => {
    let obj = {
      id: cookieId,
      consent_id: id,
      name: cookieName,
      domain: domainName,
      expire_date: cookieExpireDate, // day , month , year
      expire_type: cookieExpireType, // day , month , year
      category_cookie_id: categoryId,
      description: descCookie,
      lang: detailLang
    }
    const { data, status } = await updateCookie(obj)
    if (data && status === 200) {
      setOpenPopupCeate(false)
      getCookieListFn()
      setIsShowLoading(false)
    } else {
      setIsShowLoading(false)
    }
  }
  const getCookieDetail = async cookieId => {
    let obj = {
      cookie_id: cookieId,
      consent_id: id
    }
    const { data, status } = await cookieDetail(obj)
    if (data && status === 200) {
      setCookieName(data.data.name)
      setDomainName(data.data.domain)
      setCookieExpireDate(data.data.expire_date ? data.data.expire_date : '1')
      setCookieExpireType(data.data.expire_type ? data.data.expire_type : 'Year')
      setDescCookie(data.data.description ? data.data.description[detailLang] : '')
      setCategoryId(data.data.category_cookie_id)
    }
  }

  const openModalEdit = cookieId => {
    setCookieId(cookieId)
    getCookieDetail(cookieId)
    setIsUpdate(true)
    setTimeout(() => {
      setOpenPopupCeate(true)
    }, 200)
  }

  const getCategoryList = async () => {
    if (!categoryCookieList) {
      const { data, status } = await categoryList(id)
      if (data && data.data && status === 200) {
        setCategoryCookieList(data.data)
      }
    }
  }

  const openURLList = (option, path, name) => {
    if (option === 'open') {
      setscanURL(true)
      setscanlistCookie(false)
      setListCookieURL(path)
      setNameCookie(name)
    } else {
      setscanURL(false)
      setscanlistCookie(true)
    }
  }

  const stopScan = async () => {
    const { status } = await stopScanCookie(id)
    if (status === 200) {
      setOpenPopupScan(false)
    }
  }

  return (
    <Fragment>
      <SecondaryMenuComponent pinMenuFn={pinMenu} id={props.match.params.id} langFn={lang} />
      <div className="main-content">
        {isScanFail && (
          <div
            className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show mb-xl-0 xs-alert-center"
            role="alert"
          >
            <i className="ri-error-warning-line label-icon"></i>
            {i18n[lang].cannotScanDomain}
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}

        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header py-2">
                <div className="row mt-3">
                  <div className="col-5">
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="card-title mb-0 py-2">{i18n[lang].scanCookies} </h6>
                      </div>
                      <div className="col-8">
                        <span>{i18n[lang].language}</span>
                        <div className="btn-group px-4">
                          <button
                            className="btn btn-light dropdown-toggle"
                            type="button"
                            id="defaultDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="true"
                          >
                            <img
                              src={`/assets/images/${detailLang}-Flag-icon.png`}
                              alt=""
                              className="img-sm-px mx-1"
                            />{' '}
                            {detailLang === 'th' ? 'ภาษาไทย' : 'English'}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            {detailLang === 'th' ? (
                              <li>
                                <a className="dropdown-item" onClick={() => setDetailLang('en')}>
                                  <img
                                    src="/assets/images/EN-Flag-icon.png"
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  English
                                </a>
                              </li>
                            ) : (
                              <li>
                                <a className="dropdown-item" onClick={() => setDetailLang('th')}>
                                  <img
                                    src="/assets/images/TH-Flag-icon.png"
                                    alt=""
                                    className="img-sm-px mx-1"
                                  />{' '}
                                  ภาษาไทย
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-7 d-flex justify-content-end">
                    <div className="col-3 text-right">
                      {initDetail?.consent_settings &&
                      initDetail?.consent_settings[0].setting_auto_scan_cookie?.round_period &&
                      initDetail?.consent_settings[0].setting_auto_scan_cookie?.round_period !==
                        0 ? (
                        <button
                          className="btn badge-light-blue mt-1 text-12"
                          style={{ cursor: 'auto' }}
                        >
                          {' '}
                          <LuHistory /> {i18n[lang].scanEvery}{' '}
                          {initDetail?.consent_settings &&
                            initDetail?.consent_settings[0].setting_auto_scan_cookie
                              ?.round_period}{' '}
                          {i18n[lang].day}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-3 text-right mr-3">
                      <p className="mb-0">{i18n[lang].latestScan}</p>
                      <p>
                        {initDetail?.last_scan_cookie_at
                          ? convertDate(initDetail.last_scan_cookie_at)
                          : '-'}
                      </p>
                    </div>
                    <div className="col-auto">
                      <button
                        onClick={() => {
                          setIsUpdate(false)
                          setCookieName('')
                          setDomainName('')
                          setCookieExpireDate('')
                          setCookieExpireType('Year')
                          setDescCookie('')
                          setOpenPopupCeate(true)
                        }}
                        disabled={
                          initDetail &&
                          initDetail.permission_consents &&
                          initDetail.permission_consents[0] &&
                          initDetail.permission_consents[0].role &&
                          initDetail.permission_consents[0].role !== 'viewer'
                            ? openPopupScan
                              ? true
                              : false
                            : true
                        }
                        className="btn btn-primary btn-label  float-end mx-2"
                      >
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="mdi mdi-plus-circle-outline label-icon align-middle fs-16 me-2"></i>
                          </div>
                          <div className="flex-grow-1">{i18n[lang].addCookie}</div>
                        </div>
                      </button>
                      <button
                        onClick={() => scanCookieBtn()} //openScancookie(true)
                        className="btn btn-primary btn-label  float-end"
                        disabled={
                          initDetail &&
                          initDetail.permission_consents &&
                          initDetail.permission_consents[0] &&
                          initDetail.permission_consents[0].role &&
                          initDetail.permission_consents[0].role !== 'viewer'
                            ? openPopupScan
                              ? true
                              : false
                            : true
                        }
                      >
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="mdi mdi-magnify-scan label-icon align-middle fs-16 me-2"></i>
                          </div>
                          <div className="flex-grow-1">{i18n[lang].scanCookies}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body card-table-fix ">
                {scanlistCookie && (
                  <div id="customerList">
                    <div className="table-responsive table-card mb-1">
                      {openPopupScan ? (
                        <TableBox>
                          <div className="scan-cookie">
                            <lord-icon
                              src="https://cdn.lordicon.com/iltqorsz.json"
                              trigger="loop"
                              delay="1000"
                              colors="primary:#121331,secondary:#1298be"
                              style={{ width: '80px', height: '90px' }}
                            ></lord-icon>

                            <p className="text-Secondary mt-2">
                              {i18n[lang].scanningInProgress} <br /> {i18n[lang].scanComplete}
                            </p>
                            <button
                              onClick={() => stopScan()}
                              className="btn btn-danger waves-effect waves-light"
                            >
                              {i18n[lang].stopScanning}
                            </button>
                          </div>
                        </TableBox>
                      ) : (
                        <TableBox>
                          {listCookie ? (
                            <DataTable
                              paginationTotalRows={countCookies}
                              paginationPerPage={10}
                              pagination={true}
                              noRowsPerPage={true}
                              onChangePage={e => handleChangePage(e)}
                              className="-fadeIn mt-0"
                              center={true}
                              columns={columns}
                              data={listCookie}
                              customStyles={tableStyles}
                              paginationComponentOptions={paginationComponentOptions}
                            />
                          ) : (
                            <div className="zero-center">
                              <div className="zero-content">
                                <img
                                  src="../assets/images/Zero-domain.png"
                                  alt=""
                                  className="img-fluid"
                                />
                                <p className="text-Secondary mt-2">
                                  {i18n[lang].startManagingCookies}
                                </p>
                              </div>
                            </div>
                          )}
                        </TableBox>
                      )}
                    </div>
                  </div>
                )}
                {scanURL && (
                  <Fragment>
                    <div className="w-100 px-2 mb-2">
                      <button
                        type="button"
                        onClick={() => openURLList('')}
                        className="btn btn-light btn-icon waves-effect btn-sm"
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </button>{' '}
                      {i18n[lang].listPagesWithCookies} {nameCookie}
                    </div>
                    <div className="table-responsive table-card mb-1 p-4 card-max-fix">
                      <table className="table table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <td>URL</td>
                          </tr>
                        </thead>
                        <tbody>
                          {listCookieURL &&
                            listCookieURL.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupCeate}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupCeate(false)}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="zoomInModalLabel">
                  {isUpdate ? i18n[lang].editCookie : i18n[lang].addCookie}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpenPopupCeate(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="my-3">
                  <label htmlFor="basiInput" className="form-label font-weight-bold">
                    {i18n[lang].cookieName}
                  </label>
                  <input
                    placeholder={i18n[lang].cookieName}
                    type="text"
                    className={`form-control ${inValidateDomainName ? 'is-invalid' : ''}`}
                    onChange={e => setCookieName(e.target.value)}
                    value={cookieName}
                  />
                </div>

                <div className="my-3">
                  <label htmlFor="basiInput" className="form-label font-weight-bold">
                    {i18n[lang].domain}
                  </label>
                  <input
                    placeholder={i18n[lang].domain}
                    type="text"
                    className={`form-control ${inValidateDomainName ? 'is-invalid' : ''}`}
                    onChange={e => setDomainName(e.target.value)}
                    value={domainName}
                  />
                </div>

                <div className="my-3">
                  <label htmlFor="basiInput" className="form-label font-weight-bold">
                    {i18n[lang].cookieDuration}
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      aria-label="Text input with dropdown button"
                      placeholder=""
                      onChange={e => setCookieExpireDate(e.target.value)}
                      value={cookieExpireDate}
                    />
                    <button
                      className="btn btn-primary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {cookieExpireType === 'Year'
                        ? i18n[lang].year
                        : cookieExpireType === 'Month'
                        ? i18n[lang].month
                        : i18n[lang].day}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" onClick={() => setCookieExpireType('Year')}>
                          {i18n[lang].year}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => setCookieExpireType('Month')}>
                          {i18n[lang].month}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => setCookieExpireType('Day')}>
                          {i18n[lang].day}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="my-3">
                  <label htmlFor="basiInput" className="form-label font-weight-bold">
                    {i18n[lang].cookieCategory}
                  </label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={e => {
                      setCategoryId(e.target.value)
                    }}
                    defaultValue={categoryId}
                  >
                    <option value={0}>{i18n[lang].cookieCategory}</option>
                    {categoryCookieList &&
                      categoryCookieList.map((item, i) => {
                        return (
                          <option key={i} value={item.id}>
                            {item.name[detailLang]}
                          </option>
                        )
                      })}
                  </select>
                </div>

                <div className="my-3">
                  <label htmlFor="basiInput" className="form-label font-weight-bold">
                    {i18n[lang].description}
                  </label>
                  <textarea
                    className="form-control"
                    value={descCookie ? descCookie : ''}
                    onChange={e => setDescCookie(e.target.value)}
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect waves-light w-25 "
                  // data-bs-dismiss="modal"
                  onClick={() => setOpenPopupCeate(false)}
                >
                  {i18n[lang].cancel}
                </button>
                <button
                  onClick={() => (
                    isUpdate ? editCookie() : createCookieFn(), setIsShowLoading(true)
                  )}
                  type="button"
                  className="btn btn-primary w-25 btn-load"
                  disabled={
                    isShowLoading
                      ? isShowLoading
                      : cookieName && domainName && cookieExpireDate && descCookie
                      ? false
                      : true
                  }
                >
                  <span className="d-flex align-items-center justify-content-center">
                    {isShowLoading ? (
                      <Fragment>
                        {' '}
                        <span className="spinner-border flex-shrink-0" role="status"></span>{' '}
                        <span className="flex-grow-1 ms-2 mr-2">{i18n[lang].save}</span>{' '}
                      </Fragment>
                    ) : (
                      i18n[lang].save
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openPopupDelete}
          contentLabel="modal"
          style={customStyles}
          onRequestClose={() => setOpenPopupDelete(false)}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#ed3451,secondary:#c72e46"
                  style={{ width: '80px', height: '90px' }}
                ></lord-icon>
                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong>{i18n[lang].deleteCookieMessage}</strong>
                  </label>
                  <br />
                  <span>
                    {i18n[lang].deleteWarning}
                    <br />
                    {i18n[lang].deleteRecovery}
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  onClick={() => removeCookie()}
                  type="button"
                  className={`btn w-lg btn-outline-danger waves-effect waves-light  `}
                >
                  {i18n[lang].confirmDeletion}
                </button>
                <button
                  type="button"
                  className="btn w-lg btn-danger waves-effect waves-light"
                  // data-bs-dismiss="modal"
                  onClick={() => setOpenPopupDelete(false)}
                >
                  {i18n[lang].cancel}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default ScanCookieComponent
