import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { SwitchBox, RadioBox, Button } from './style'
import { createConsent } from '../../apis/management/api'
import { getAccountCredit } from '../../apis/Payment/api'
import { getCookie, numberWithCommas } from '../../helper/helper'
import { enumCode } from '../../helper/enum'
import { i18n } from '../../helper/i18n'

const AddDomainComponent = props => {
  const history = useHistory()

  const { REACT_APP_PREDICTIVE, REACT_APP_OMISE_SECRET_KEY } = process.env
  const [subscribePackage, setSubscribePackage] = useState(false)
  const [domainName, setDomainName] = useState('')
  const [inValidateDomainName, setInValidateDomainName] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [creditTotal, setCreditTotal] = useState(0)
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
    setErrorMsg(i18n[props.langFn].urlFormatError)
  }, [props.langFn])
  useEffect(() => {
    document.title = 'เพิ่มโดเมน | CookiePlus'
    window.scrollTo(0, 0)
    accountCredit()
  }, [])

  const accountCredit = async () => {
    const { data, status } = await getAccountCredit()
    if (data?.data && status === 200) {
      setCreditTotal(data.data.credit)
    }
  }

  const selectedPackage = async packageId => {
    let regx = /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/ //[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

    if (!domainName) {
      setInValidateDomainName(true)
      setErrorMsg(i18n[lang].urlFormatError)
      window.scrollTo(0, 0)
    } else {
      if (domainName.match(regx)) {
        setIsLoading(true)
        if (packageId === 1) {
          setInValidateDomainName(false)
          setErrorMsg('')
          let objPackageFree = {
            consent_domain: domainName,
            package_id: 1
          }
          const { data, status } = await createConsent(objPackageFree)
          if (status === 200) {
            setIsLoading(false)
            history.push('/domain')
            window.scrollTo(0, 0)
          } else {
            setIsLoading(false)
            setInValidateDomainName(true)
            setErrorMsg(enumCode[lang][data.code])
            window.scrollTo(0, 0)
          }
        } else {
          setIsLoading(false)
          setInValidateDomainName(false)
          setErrorMsg('')
          history.push(
            `/payment/${encodeURIComponent(domainName)}/${packageId}/${subscribePackage ? 'year' : 'month'
            }/adddomain`
          )
        }
      } else {
        setIsLoading(false)
        setInValidateDomainName(true)
        setErrorMsg(i18n[lang].urlFormatError)
        window.scrollTo(0, 0)
      }
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body py-2">
              <div className="row">
                <div className="col-6">
                  <h6 className="card-title mb-0 py-2">{i18n[lang].addDomain} </h6>
                </div>

                <div className="col-6 text-right">
                  <h6 className="card-title mb-0 py-2">
                    {creditTotal > 0 && (
                      <Fragment>
                        <img src={`/assets/images/coin.svg`} alt="" height={17} className="px-2" />
                        {i18n[lang].remainingCredit} :{' '}
                        <span className=" text-warning">{numberWithCommas(creditTotal)}</span>
                      </Fragment>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body p-4">
              <div className="container">
                <div className="row justify-content-md-center border-dashed-pk mb-5 pb-5">
                  <Fragment>
                    <div className="col-md-5 text-center">
                      <b className="fs-30">{i18n[lang].domainName}</b>

                      <div className="mb-3">
                        <input
                          type="text"
                          className={`form-control ${inValidateDomainName ? 'is-invalid' : ''}`}
                          value={domainName}
                          onChange={e => setDomainName(e.target.value.replaceAll(/\s/g, ''))}
                        />
                        {inValidateDomainName && (
                          <span className="invalid-feedback-err">{errorMsg}</span>
                        )}
                      </div>
                      <b className="text-primary ">
                        {' '}
                        <img
                          src="/assets/images/double-arrow-download-2.svg"
                          alt=""
                          height="12"
                        />{' '}
                        {i18n[lang].selectPackage}
                      </b>
                    </div>
                  </Fragment>
                </div>
              </div>

              <div className="text-center">
                <h1>{i18n[lang].pricesAndPackages}</h1>
                <p className="fs-16">{i18n[lang].save20Percent}</p>
                <div className="row justify-content-center mt-3">
                  <div className="col-7 text-center">
                    <SwitchBox>
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <label className=" fs-24 font-weight-bold mx-3">{i18n[lang].monthly}</label>
                        <div className="form-check form-switch form-switch-lg ">
                          <input
                            className="form-check-input left-0"
                            type="checkbox"
                            role="switch"
                            checked={subscribePackage}
                            onChange={() => {
                              setSubscribePackage(!subscribePackage)
                            }}
                          />
                        </div>

                        <label className=" fs-24 font-weight-bold mx-3 ">{i18n[lang].yearly}</label>
                      </div>
                      <span className="badge  bg-success mx-3 badge-pk text-white">{i18n[lang].discount20Percent}</span>
                    </SwitchBox>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-12">
                    <div className="row justify-content-md-center">
                      <div className="col-md-3">
                        <div className="card card-pk card-height-100">
                          <div className="card-header">
                            <span className="float-end text-secondary">
                              <img src="/assets/images/CP-Free.svg" alt="" height="30" />
                            </span>
                            <h6 className="card-title text-left fs-16"> Free Plan</h6>
                            <p className="card-subtitle text-muted mb-4 text-left fs-12">
                              {i18n[lang].smallBusinesses}
                            </p>
                            <p className="text-left">
                              <span className="h1">Free </span> {i18n[lang].thb} / {i18n[lang].domain} /{' '}
                              {!subscribePackage ? i18n[lang].months : i18n[lang].year}
                            </p>
                          </div>
                          <div className="card-body px-2 text-left">
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimited} Pageviews
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].compatibleWith} Subdomains
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              2,000  Sessions {i18n[lang].perMonth}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].upToUser} 1 {i18n[lang].user}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].scan} 50 URLs{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              100,000 {i18n[lang].consentRecords}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].storeDataFor} 3 {i18n[lang].months}
                            </p>

                            <p>
                              <i className="mdi mdi-close-circle align-middle text-Gray"></i>{' '}
                              {i18n[lang].customizeDetailedBanner}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-close-circle align-middle text-Gray"></i>{' '}
                              {i18n[lang].customizeLogoAndIcons}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-close-circle align-middle text-Gray"></i> Google
                              Consent Mode{' '}
                            </p>
                          </div>
                          <div className="card-footer border-0">
                            <Button>
                              <button
                                onClick={() => selectedPackage(1)}
                                className="btn btn-primary w-100"
                                disabled={isLoading}
                              >
                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}{' '}
                                {i18n[lang].selectPackage}
                              </button>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card card-pk card-height-100">
                          <div className="card-header">
                            <span className="float-end text-secondary">
                              <img src="/assets/images/CP-Basic.svg" alt="" height="35" />
                            </span>
                            <h6 className="card-title text-left fs-16"> Basic Plan</h6>
                            <p className="card-subtitle text-muted mb-4 text-left fs-12">
                              {i18n[lang].smallBusinesses}
                            </p>
                            {!subscribePackage ? (
                              ''
                            ) : (
                              <p className="text-left card-subtitle text-muted text-decoration-line-through">
                                2,388
                              </p>
                            )}
                            <p className="text-left">
                              {!subscribePackage ? (
                                <span className="h1">199</span>
                              ) : (
                                <span className="h1 text-success">1,900</span>
                              )}
                              {i18n[lang].thb} / {i18n[lang].domain} / {!subscribePackage ? i18n[lang].months : i18n[lang].year}
                            </p>
                          </div>
                          <div className="card-body px-2 text-left">
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimited} Pageviews
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].compatibleWith} Subdomains
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              20,000  Sessions {i18n[lang].perMonth}
                            </p>

                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].upToUser} 1 {i18n[lang].user}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].scan} 500 URLs{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              6,000,000 {i18n[lang].consentRecords}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].storeDataFor} 12 {i18n[lang].months}
                            </p>

                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].customizeDetailedBanner}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].customizeLogoAndIcons}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              Google Consent Mode v2{' '}
                            </p>
                          </div>
                          <div className="card-footer  border-0">
                            <Button>
                              <button
                                onClick={() => selectedPackage(2)}
                                className="btn btn-primary w-100"
                                disabled={isLoading}
                              >
                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}{' '}
                                {i18n[lang].selectPackage}
                              </button>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card card-pk card-height-100">
                          <div className="card-header">
                            <span className="float-end text-secondary">
                              <img src="/assets/images/CP-Pro.svg" alt="" height="50" />
                            </span>
                            <h6 className="card-title text-left fs-16"> Pro Plan</h6>
                            <p className="card-subtitle text-muted mb-4 text-left fs-12">
                              {i18n[lang].mediumBusinesses}
                            </p>
                            {!subscribePackage ? (
                              ''
                            ) : (
                              <p className="text-left card-subtitle text-muted text-decoration-line-through">
                                14,988
                              </p>
                            )}
                            <p className="text-left">
                              {!subscribePackage ? (
                                <span className="h1">1,249</span>
                              ) : (
                                <span className="h1 text-success">11,990</span>
                              )}
                              {i18n[lang].thb} / {i18n[lang].domain} / {!subscribePackage ? i18n[lang].months : i18n[lang].year}
                            </p>
                          </div>
                          <div className="card-body px-2 text-left">
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimited} Pageviews
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].compatibleWith} Subdomains
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              100,000  Sessions {i18n[lang].perMonth}
                            </p>

                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].upToUser} 10 {i18n[lang].user}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].scan} 10,000 URLs{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimitedConsentRecords}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].storeDataFor} 24 {i18n[lang].months}
                            </p>

                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].customizeDetailedBanner}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].customizeLogoAndIcons}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              Google Consent Mode v2{' '}
                            </p>
                          </div>
                          <div className="card-footer  border-0">
                            <Button>
                              <button
                                onClick={() => selectedPackage(3)}
                                className="btn btn-primary w-100"
                                disabled={isLoading}
                              >
                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}{' '}
                                {i18n[lang].selectPackage}
                              </button>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card card-pk card-height-100">
                          <div className="card-header">
                            <span className="float-end text-secondary">
                              <img src="/assets/images/CP-Ultimate.svg" alt="" height="55" />
                            </span>
                            <h6 className="card-title text-left fs-16"> Ultimate Plan</h6>
                            <p className="card-subtitle text-muted mb-4 text-left fs-12">
                              {i18n[lang].largeBusinesses}
                            </p>
                            {!subscribePackage ? (
                              ''
                            ) : (
                              <p className="text-left card-subtitle text-muted text-decoration-line-through">
                                31,788
                              </p>
                            )}
                            <p className="text-left">
                              {!subscribePackage ? (
                                <span className="h1">2,649</span>
                              ) : (
                                <span className="h1 text-success">25,400</span>
                              )}
                              {i18n[lang].thb} / {i18n[lang].domain} / {!subscribePackage ? i18n[lang].months : i18n[lang].year}
                            </p>
                          </div>
                          <div className="card-body px-2 text-left">
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimited} Pageviews
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].compatibleWith} Subdomains
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              500,000  Sessions {i18n[lang].perMonth}
                            </p>

                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimitedNumberOfUsers}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].scan} 50,000 URLs{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].unlimitedConsentRecords}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].storeDataFor} 24 {i18n[lang].months}
                            </p>

                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].customizeDetailedBanner}{' '}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              {i18n[lang].customizeLogoAndIcons}
                            </p>
                            <p>
                              <i className="mdi mdi-check-circle align-middle text-primary fs-16"></i>{' '}
                              Google Consent Mode v2{' '}
                            </p>
                          </div>
                          <div className="card-footer  border-0">
                            <Button>
                              <button
                                onClick={() => selectedPackage(4)}
                                className="btn btn-primary w-100"
                                disabled={isLoading}
                              >
                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}{' '}
                                {i18n[lang].selectPackage}
                              </button>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="card card-pk">
                          <div className=" card-body">
                            <div className="row">
                              <div className="col-2 m-auto">
                                <img src="/assets/images/CP-Enterpise.svg" alt="" height="70" />
                              </div>
                              <div className="col-8">
                                <h6 className="card-title text-left fs-16">Enterprise Plan</h6>
                                <p className="card-subtitle text-muted mb-4 text-left fs-12">
                                  {i18n[lang].largeUsage}
                                </p>
                                <div className="row">
                                  <div className="col-12">
                                    <p className="text-left">
                                      <i className="mdi mdi-check-circle text-gold fs-16 mr-2"></i>
                                      {i18n[lang].customizeUsages}
                                    </p>
                                  </div>
                                  <div className="col-12">
                                    <p className="text-left">
                                      <i className="mdi mdi-check-circle text-gold fs-16 mr-2"></i>
                                      {i18n[lang].dedicatedSupport}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 m-auto">
                                <button
                                  onClick={() => (window.open('https://meetings.hubspot.com/hataipatt/th-cookieplus-support', '_blank', 'noopener,noreferrer'))}
                                  className="btn btn-primary w-100"
                                  target="_blank"
                                >
                                  {i18n[lang].contactUs}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className=" text-left text-Gray">
                        <u>{i18n[lang].note}</u>: {i18n[lang].noteSessions}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-Gray text-8">
              Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
            </div>
            <div className="col-sm-6 text-Gray text-8">
              <div className="text-sm-end d-none d-sm-block">
                <a
                  className="text-Gray"
                  href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                  target="_blank"
                >
                  Privacy Policy.
                </a>{' '}
                <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default AddDomainComponent
