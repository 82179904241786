import styled from 'styled-components'

const RadioBox = styled.div`
  .form-check .form-check-input {
    float: left;
    margin-left: 0;
  }
  .pl-4rem {
    padding-left: 4rem;
  }
`

const PreviewBoxDefault = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    padding: 0px 11%;
    width: 100%;
    position: absolute;
    bottom: 3%;
    max-width: 100%;
  }

  .right-8 {
    right: 8px;
  }
  .break-word {
    word-wrap: break-word;
  }
`
const PreviewBoxBottom = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }

  .banner-preview {
    padding: 0px 0%;
    width: 100%;
    position: absolute;
    bottom: 0%;
    max-width: 100%;
  }

  .right-8 {
    right: 8px;
  }

  .h-419 {
    height: 419px !important;
  }
  .break-word {
    word-wrap: break-word;
  }
`
const PreviewBoxPopup = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }

  .banner-preview {
    width: 100%;
    height: 450px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .banner-preview-content {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 20%;
    width: 100%;
  }
  .right-8 {
    right: 8px;
  }
  .backdrop {
    width: 100%;
    height: 450px;
    position: absolute;
    bottom: 0%;
    background-color: #06060696;
  }

  .h-419 {
    height: 419px !important;
  }
  .h-450 {
    height: 450px !important;
  }
  .h-450 {
    max-height: 450px !important;
    height: 450px !important;
    min-height: 378px !important;
  }
  .break-word {
    word-wrap: break-word;
  }
`

const PreviewBoxBottomLeft = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    padding: 0px 2%;
    width: 350px;
    position: absolute;
    bottom: 3%;
    max-width: 100%;
  }
  .banner-previewmobile {
    padding: 0px 0%;
    width: 350px;
    position: absolute;
    bottom: 0%;
    max-width: 100%;
  }

  .right-8 {
    right: 8px;
  }
  .break-word {
    word-wrap: break-word;
  }
  button.btn {
    height: 30px !important;
    line-height: 1 !important;
  }
`
const PreviewBoxTopLeft = styled.div`
.sc-dPWrhe {
  position: position: unset !important;
  width: 100%;
  padding-bottom: 50px;
}
.modal-lg,
.modal-xl {
  max-width: 100%;
}
.banner-preview {
  padding: 0px 2%;
  width: 350px;
  position: absolute;
  top: 3%;
  max-width: 100%;
}
.banner-previewmobile {
  padding: 0px 0%;
  width: 350px;
  position: absolute;
  top: 0%;
  max-width: 100%;
  
}
.right-8 {
  right: 8px;
}
.break-word{
  word-wrap: break-word;
}
button.btn {
  height: 30px !important;
  line-height: 1 !important;
}
`
const PreviewBoxBottomRight = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    padding: 0px 2%;
    width: 350px;
    position: absolute;
    bottom: 3%;
    max-width: 100%;
    right: 0;
  }
  .banner-previewmobile {
    padding: 0px 0%;
    width: 350px;
    position: absolute;
    bottom: 0%;
    max-width: 100%;
    right: 0;
  }
  .right-8 {
    right: 8px;
  }
  .break-word {
    word-wrap: break-word;
  }
  button.btn {
    height: 30px !important;
    line-height: 1 !important;
  }
`
const PreviewBoxTopRight = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }
  .banner-preview {
    padding: 0px 2%;
    width: 350px;
    position: absolute;
    top: 3%;
    max-width: 100%;
    right: 0;
  }
  .banner-previewmobile {
    padding: 0px 0%;
    width: 350px;
    position: absolute;
    top: 0%;
    max-width: 100%;
    right: 0;
  }
  .right-8 {
    right: 8px;
  }
  .break-word {
    word-wrap: break-word;
  }
  button.btn {
    height: 30px !important;
    line-height: 1 !important;
  }
`
const AccordionBox = styled.div`
  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #1298be;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' 
  viewBox='0 0 149.35 88.05' fill='white'%3e%3cpath fill-rule='none' 
  d='M74.67,88.05L0,13.38,13.38,0l61.29,61.29L135.97,0l13.38,13.38L74.67,88.05Z'/%3e%3c/svg%3e");
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .btn-success {
    color: #676767;
    background-color: #ffffff;
    border-color: #ced4da;
  }
  .text-area {
    width: 100%;
    border-color: #d9d9d9;
    padding: 10px;
    outline: none;
  }
`

const TooltipBox = styled.div`
  .tooltips {
    display: inline-block;
    i {
      color: #1298be;
    }
  }

  .tooltips .tooltiptexts {
    display: none;
    width: 100%;
    top: 90%;
    margin-left: 65px;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 20;
  }

  .tooltips:hover .tooltiptexts {
    // visibility: visible;
    display: block;
  }
  .tooltiptexts {
    .box_tooltips {
      background: #000;
      position: static;
      width: 116px;
      border-radius: 5px;
    }
  }

  .float-left-mb0 {
    margin-bottom: 0;
    float: left;
  }
`
const PreviewBoxFullTop = styled.div`
  .modal-lg,
  .modal-xl {
    max-width: 100%;
  }

  .banner-preview {
    padding: 0px 0%;
    width: 100%;
    position: absolute;
    top: 0%;
    max-width: 100%;
  }

  .right-8 {
    right: 8px;
  }

  .h-419 {
    height: 419px !important;
  }
  .break-word {
    word-wrap: break-word;
  }
`
export {
  RadioBox,
  PreviewBoxDefault,
  PreviewBoxBottom,
  PreviewBoxPopup,
  PreviewBoxBottomLeft,
  PreviewBoxTopLeft,
  PreviewBoxBottomRight,
  PreviewBoxTopRight,
  AccordionBox,
  TooltipBox,
  PreviewBoxFullTop
}
