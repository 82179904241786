import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { connectSignUp, resendConfirmEmail } from '../../apis/authenication/api'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
import { RegisterBox } from './style'
import { i18n } from '../../helper/i18n'
import DropdownLangComponent from '../layout/dropdownLang'
import { getCookie } from '../../helper/helper'

const { REACT_APP_CAPTCHA, REACT_APP_PREDICTIVE } = process.env
var round = 0
const SigninComponent = props => {
  const [shownCF, setShownCF] = useState(false)
  const [shownPass, setShownPass] = useState(false)
  const [username, setUsername] = useState('')
  const [invalidUsername, setInvalidUsername] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [verifyToken, setVerifyToken] = useState()

  const [verifyCFPassword, setVerifyCFPassword] = useState(false)

  const [passwordInput, setPasswordInput] = useState('')
  const [CFPassword, setConfirmPassword] = useState('')
  const [checkContain, setCheckContain] = useState(false)

  const [checkedCondition, setCheckedCondition] = useState(false)
  const [noAcceptConfition, setNoAcceptConfition] = useState(false)

  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)
  const [isRegisterFail, setIsRegisterFail] = useState(false)
  const [isResentFail, setIsResendFail] = useState(false)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [checkedLetter, setCheckedLetter] = useState(false)
  const [checkedCapital, setCheckedCapital] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState(false)
  const [checkedSpecial, setCheckedSpecial] = useState(false)
  const [checkedLength, setCheckedLength] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))
  const [checkPasswordInput, setCheckPasswordInput] = useState('')

  const [isShowLoading, setIsShowLoading] = useState(false)

  const connectRegister = async () => {
    setIsShowLoading(true)

    let params = {
      email: username.toLowerCase(),
      password: passwordInput,
      token: verifyToken
    }
    const { data, status } = await connectSignUp(params)
    if (data && status === 200) {
      localStorage.setItem('token', data.access_token)
      setIsResendFail(false)
      setIsRegisterSuccess(true)
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
      setIsShowLoading(false)

    } else {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
      setIsRegisterSuccess(false)
      setIsResendFail(true)
      setIsShowLoading(false)

    }
  }
  useEffect(() => {
    document.title = 'สมัครสมาชิก | CookiePlus'
    refreshRecaptcha()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const onSubmit = () => {
    if (!username) {
      setInvalidUsername(true)
    } else {
      setInvalidUsername(false)
    }

    if (passwordInput) {
      if (checkedLetter && checkedCapital && checkedNumber && checkedSpecial && checkedLength) {
        setInvalidPassword(false)
      } else {
        setInvalidPassword(true)
        setCheckPasswordInput(i18n[lang].incorrectPassword)
      }

    } else {
      setInvalidPassword(true)
      setCheckPasswordInput(i18n[lang].enterPassword)

    }

    if (passwordInput !== CFPassword) {
      setVerifyCFPassword(true)

    } else {
      if (!CFPassword) {
        setVerifyCFPassword(true)
      } else {
        setVerifyCFPassword(false)
      }

    }

    if (username && passwordInput && CFPassword && passwordInput === CFPassword) {
      if (checkedCondition) {

        setNoAcceptConfition(false)
        connectRegister()
      } else {
        setNoAcceptConfition(true)
      }

    }
  }

  const resendEmail = async () => {
    let obj = {
      email: username.toLowerCase(),
      token: verifyToken
    }
    const { data, status } = await resendConfirmEmail(obj)
    if (data && status === 200) {
      setIsRegisterSuccess(true)
      setIsRegisterFail(false)
    } else {
      setIsRegisterSuccess(false)
      setIsRegisterFail(true)
    }
    round = round === 0 ? 1 : 0
    setRefreshReCaptcha(round)
  }

  const checkPassword = e => {
    const letter = document.getElementById('pass-lower')
    const capital = document.getElementById('pass-upper')
    const number = document.getElementById('pass-number')
    const special = document.getElementById('pass-special')
    const length = document.getElementById('pass-length')

    setPasswordInput(e.target.value)
    if (e.target.value) {
      setInvalidPassword(false)

    }
    const lowerCaseLetters = /[a-z]/g
    if (e.target.value.match(lowerCaseLetters)) {
      letter.classList.remove('invalid')
      letter.classList.add('valid')
      setCheckedLetter(true)
    } else {
      letter.classList.remove('valid')
      letter.classList.add('invalid')
      setCheckedLetter(false)
    }

    const upperCaseLetters = /[A-Z]/g
    if (e.target.value.match(upperCaseLetters)) {
      capital.classList.remove('invalid')
      capital.classList.add('valid')
      setCheckedCapital(true)
    } else {
      capital.classList.remove('valid')
      capital.classList.add('invalid')
      setCheckedCapital(false)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if (e.target.value.match(numbers)) {
      number.classList.remove('invalid')
      number.classList.add('valid')
      setCheckedNumber(true)
    } else {
      number.classList.remove('valid')
      number.classList.add('invalid')
      setCheckedNumber(false)
    }

    const specials = /[!@#$%^*_&=]/g
    if (e.target.value.match(specials)) {
      special.classList.remove('invalid')
      special.classList.add('valid')
      setCheckedSpecial(true)
    } else {
      special.classList.remove('valid')
      special.classList.add('invalid')
      setCheckedSpecial(false)
    }

    // Validate length
    if (e.target.value.length >= 8) {
      length.classList.remove('invalid')
      length.classList.add('valid')
      setCheckedLength(true)
    } else {
      length.classList.remove('valid')
      length.classList.add('invalid')
      setCheckedLength(false)
    }
  }

  const keepTokenFn = token => {
    setVerifyToken(token)
  }
  useEffect(() => {
    if (passwordInput) {
      setCheckPasswordInput(i18n[lang].incorrectPassword)

    } else {
      setCheckPasswordInput(i18n[lang].enterPassword)

    }
  }, [lang])
  return (
    <RegisterBox>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
          <div className="bg-overlay">
            {isRegisterSuccess && (
              <div
                className="alert alert-success alert-dismissible alert-solid alert-label-icon fade show sm-alert-center"
                role="alert"
              >
                <i className="ri-check-double-line label-icon"></i>
                {i18n[lang].sendEmailDone}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}

            {isResentFail && (
              <div
                className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].sendEmailFail}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}

            {isRegisterFail && (
              <div
                className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].registerFail}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}

            {noAcceptConfition && (
              <div
                className="alert alert-danger alert-dismissible alert-solid alert-label-icon fade show sm-alert-center"
                role="alert"
              >
                <i className="ri-error-warning-line label-icon"></i>
                {i18n[lang].pleaseAcceptTerm}
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
          </div>

          <div className="auth-page-content overflow-hidden pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card overflow-hidden">
                    <div className="row g-0">
                      <div className="col-lg-6">
                        <div className="p-lg-5 p-4 auth-one-bg h-100">
                          <div className="position-relative h-100 d-flex flex-column">
                            <div className="mb-4"></div>
                            <div className="my-auto row">
                              <div className="col-12 text-center">
                                <img
                                  src="assets/images/logo-dark.svg"
                                  alt="cookieplus"
                                  height="20"
                                />

                                <p className="fs-36 text-Dark font-weight-bold mt-2">
                                  {i18n[lang].welcome}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 pr-1">
                        {!isRegisterSuccess ? (
                          <div className="p-lg-5 p-4 scroll-content-500">
                            <div className="d-flex justify-content-between">
                              <p className="text-dark big-subtitle">
                                <p className="text-dark big-subtitle">{i18n[lang].signup}</p>
                              </p>

                              <DropdownLangComponent changeLangFn={setLang} valueLang={lang} />
                            </div>
                            <p class="text-muted text-email">
                              {i18n[lang].haveAccount}{' '}
                              <span>
                                {' '}
                                <a
                                  href="/login"
                                  class="text-email text-primary "
                                  id="btn-login-page"
                                >
                                  {i18n[lang].signin}
                                  {lang === 'en' ? '.' : ''}
                                </a>
                              </span>
                            </p>
                            <div className="mt-4">
                              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                              <div>
                                <label htmlFor="username" className="form-label text-16 ">
                                  {i18n[lang].email}
                                </label>
                                <input
                                  id="email"
                                  placeholder={i18n[lang].email}
                                  className={`form-control ${invalidUsername ? 'is-invalid' : ''}`}
                                  value={username}
                                  onChange={e => setUsername(e.target.value)}
                                />

                                <label className="invalid-feedback-err">
                                  {invalidUsername && i18n[lang].enterEmail}
                                </label>
                              </div>

                              <div>
                                <label className="form-label text-16 ">
                                  {i18n[lang].password} *
                                </label>
                                <div className={`position-relative auth-pass-inputgroup `}>
                                  <input
                                    placeholder={i18n[lang].password}
                                    onFocus={e => setCheckContain(true)}
                                    onBlur={e => setCheckContain(false)}
                                    onChange={e => checkPassword(e)}
                                    value={passwordInput}
                                    className={`form-control ${invalidPassword ? 'is-invalid' : ''
                                      }`}
                                    type={shownPass ? 'text' : 'password'}
                                    id="password"
                                  />

                                  <button
                                    className="btn position-absolute end-0 top-0  text-muted show_password"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setShownPass(!shownPass)}
                                  >
                                    <span>
                                      {shownPass ? (
                                        <i className="ri-eye-fill align-middle"></i>
                                      ) : (
                                        <i className="ri-eye-fill align-middle"></i>
                                      )}
                                    </span>
                                  </button>
                                  <label className="invalid-feedback-err">
                                    {invalidPassword && `${checkPasswordInput}`}
                                  </label>
                                  <div
                                    id="password-contain"
                                    className={`p-3 pt-0 bg-white mb-2 rounded ${checkContain ? 'd-block' : 'd-none'
                                      }`}
                                  >
                                    <h5 className="fs-14">{i18n[lang].passwordMustContain}</h5>
                                    <p id="pass-length" className="invalid mb-2">
                                      {i18n[lang].atLeast8Characters}
                                    </p>
                                    <p id="pass-lower" className="invalid mb-2">
                                      {i18n[lang].atLeastOneLowercase}
                                    </p>
                                    <p id="pass-upper" className="invalid mb-2">
                                      {i18n[lang].atLeastOneUppercase}
                                    </p>
                                    <p id="pass-number" className="invalid mb-2">
                                      {i18n[lang].atLeastOneNumber}
                                    </p>
                                    <p id="pass-special" className="invalid mb-0">
                                      {i18n[lang].atLeastOneSpecialChar}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label className="form-label text-16 ">
                                  {i18n[lang].ConfirmPassword} *
                                </label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <input
                                    placeholder={i18n[lang].password}
                                    className={`form-control ${verifyCFPassword ? 'is-invalid' : ''
                                      }`}
                                    type={shownCF ? 'text' : 'password'}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    id="cf-password"
                                  />

                                  <button
                                    className="btn position-absolute end-0 top-0 text-decoration-none text-muted show_password"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setShownCF(!shownCF)}
                                  >
                                    <span>
                                      {shownCF ? (
                                        <i className="ri-eye-fill align-middle"></i>
                                      ) : (
                                        <i className="ri-eye-fill align-middle"></i>
                                      )}
                                    </span>
                                  </button>
                                  <label className="invalid-feedback-err">
                                    {verifyCFPassword && i18n[lang].passwordConfirmationIncorrect}
                                  </label>
                                </div>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={() => setCheckedCondition(!checkedCondition)}
                                  checked={checkedCondition}
                                  id="auth-remember-check"
                                />
                                <label className="form-check-label" htmlFor="auth-remember-check">
                                  {i18n[lang].acceptTerm}{' '}
                                  <a
                                    href={REACT_APP_PREDICTIVE + `/terms`}
                                    className=" text-primary fs-14"
                                    id="btn-to-terms"
                                  >
                                    {i18n[lang].acceptService}
                                  </a>{' '}
                                  {i18n[lang].and}{' '}
                                  <a
                                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                                    className="text-primary fs-14"
                                    id="btn-to-privacy-policy"
                                  >
                                    {i18n[lang].privacyPolicy}
                                  </a>
                                </label>
                              </div>

                              <div className="mt-4">
                                <button
                                  className="btn btn-primary w-100 text-16 btn-load"
                                  onClick={() => (onSubmit())}
                                  id="btn-register"
                                  disabled={isShowLoading}
                                >
                                  <span className="d-flex align-items-center justify-content-center">

                                    {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                                    </span> <span className=" ms-2 mr-2">
                                        {i18n[lang].signup}
                                      </span> </Fragment> : i18n[lang].signup
                                    }

                                  </span>

                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="p-lg-5 p-4 text-center scroll-content-500">
                            <div className="avatar-lg flex-shrink-0 mx-auto">
                              <span className="avatar-title bg-light rounded-circle fs-2">
                                <i className="mdi mdi-check-circle text-Success mdi-48px"></i>
                              </span>
                            </div>
                            <p className="fs-24 mt-3">{i18n[lang].registrationCompleted}</p>
                            <p className="fs-14 text-Gray">
                              {i18n[lang].confirmationLinkSent}
                              {` `}
                              {username}
                              <br></br>
                              {i18n[lang].clickLinkToVerify}
                            </p>
                            <br />
                            <p className="fs-14 text-Gray">{i18n[lang].ifNoEmailReceived}</p>
                            <button
                              onClick={() => resendEmail()}
                              className="btn btn-primary w-100 text-16 mb-5"
                              id="resend-email"
                            >
                              {i18n[lang].sendAgian}
                            </button>
                            <footer className="footer-login start-0 text-right px-5">
                              <a
                                className="text-right fs-14 cursor-pointer"
                                href="/login"
                                id="btn-to-login"
                              >
                                <i className="mdi mdi-arrow-left-thin"></i>
                                {i18n[lang].backToLogin}
                              </a>
                            </footer>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer-page start-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div className="mb-0 text-10 d-inline">
                      Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
                    </div>
                    <div className="d-inline">
                      <a
                        className="text-white text-10 "
                        href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                        target="_blank"
                      >
                        &nbsp;Privacy Policy
                      </a>{' '}
                      <span className="text-10 ">●</span>{' '}
                      <a
                        className="text-white text-10 "
                        href={REACT_APP_PREDICTIVE + `/terms`}
                        target="_blank"
                      >
                        Terms and conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <ReCaptchaComponent keepTokenFn={keepTokenFn} refreshToken={refreshReCaptcha} />
        </GoogleReCaptchaProvider>
      </div>
    </RegisterBox>
  )
}

export default SigninComponent
