import React, { Fragment, useState, useEffect, useRef } from 'react'
import SecondaryMenuComponent from '../../components/menu/secondary-nav'
import { packageDetailAll, consentSetting, updateConsentSetting } from '../../apis/management/api'
import { MdLock } from 'react-icons/md'
import { CgSoftwareDownload } from 'react-icons/cg'
import { i18n } from '../../helper/i18n'

import Modal from 'react-modal'
import { getCookie } from '../../helper/helper'

const { REACT_APP_PREDICTIVE } = process.env

const IPSettingComponent = props => {
  const id = props.match.params.id

  const [monthlyTime, setMonthlyTime] = useState('')
  const [checkedAccept, setCheckedAccept] = useState(true)
  const [checkedDecline, setCheckedDecline] = useState(true)
  const [checkedPartial, setCheckedPartial] = useState(true)
  const [checkedShowIP, setCheckedShowIP] = useState(true)
  const [storagePeriod, setStoragePeriod] = useState([''])
  const [quotaSession, setQuotaSession] = useState(false)
  const [functionName, setFunctionName] = useState('')
  const [packagePlan, setPackagePlan] = useState('')
  const [scheduleScanCookie, setScheduleScanCookie] = useState(0)
  const [packageDetail, setPackageDetail] = useState('')

  const [checkedSessionsLimit, setCheckedSessionsLimit] = useState('')
  const [isShowLoading, setIsShowLoading] = useState(false)

  const [openLimitSessions, setOpenLimitSessions] = useState(false)
  const customStylesModalsm = {
    content: {
      background: 'none',
      border: 0
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1003'
    }
  }
  const [isSaveSetting, setIsSaveSetting] = useState({
    show: false,
    status: true,
    msg: ''
  })
  useEffect(() => {
    document.title = 'การตั้งค่า | CookiePlus'
    getPackageDetail(id)
    getConsentSetting(id)
  }, [])
  const [lang, setLang] = useState(getCookie('lang'))
  useEffect(() => {
    setLang(props.langFn)
  }, [props.langFn])

  const updateSetting = async () => {
    let obj = {
      consent_id: id,
      setting_collects_ip: {
        accept_all: checkedAccept,
        partial_accept: checkedPartial,
        decline_all: checkedDecline,
        show_ip: checkedShowIP
      },
      setting_collects_data_period: {
        store_period: parseInt(monthlyTime)
      },
      is_session_addon: quotaSession || false,
      setting_callback: {
        function_name: functionName
      },
      setting_auto_scan_cookie: {
        round_period: scheduleScanCookie || 0
      }
    }
    const { data, status } = await updateConsentSetting(obj)
    if (data && status === 200) {
      getPackageDetail(id)
      getConsentSetting(id)
      setIsSaveSetting({
        show: true,
        status: true,
        msg: i18n[lang].settingsUpdatedSuccessfully
      })
      setIsShowLoading(false)
    } else {
      setIsSaveSetting({
        show: true,
        status: false,
        msg: i18n[lang].settingsUpdateFailed
      })
      setIsShowLoading(false)
    }
    setTimeout(() => {
      setIsSaveSetting({
        show: false,
        status: true,
        msg: ''
      })
    }, 5000)
  }

  const getPackageDetail = async id => {
    let obj = {
      consent_id: id,
      payment_detail: false
    }
    const { data, status } = await packageDetailAll(obj)
    if (data?.data && status === 200) {
      setPackageDetail(data.data.packageDetail)
      checkPackageDetail(data.data.packageDetail.package.package_name)
    }
  }

  const getConsentSetting = async id => {
    const { data, status } = await consentSetting(id)
    if (data?.data && status === 200) {
      setCheckedAccept(data.data.setting_collects_ip.accept_all)
      setCheckedDecline(data.data.setting_collects_ip.decline_all)
      setCheckedPartial(data.data.setting_collects_ip.partial_accept)
      setCheckedShowIP(data.data.setting_collects_ip.show_ip)
      setMonthlyTime(
        data.data.setting_collects_data_period.store_period
          ? data.data.setting_collects_data_period.store_period
          : '0'
      )
      setQuotaSession(data?.data.package?.is_session_addon)
      setFunctionName(
        data.data.setting_callback.function_name ? data.data.setting_callback.function_name : ''
      )
      setScheduleScanCookie(data?.data?.setting_auto_scan_cookie?.round_period || 0)
    }
  }

  const checkPackageDetail = plan => {
    setPackagePlan(plan)
    if (plan === 'Free Plan') {
      setStoragePeriod(['3'])
    } else if (plan === 'Basic Plan') {
      setStoragePeriod(['3', '6', '12'])
    } else if (plan === 'Pro Plan') {
      setStoragePeriod(['3', '6', '12', '18', '24'])
    } else {
      setStoragePeriod(['3', '6', '12', '18', '24'])
    }
  }

  const checkedIPSetting = (e, option) => {
    if (option === 'accept') {
      setCheckedAccept(e.target.checked)
    } else if (option === 'partial') {
      setCheckedPartial(e.target.checked)
    } else if (option === 'decline') {
      setCheckedDecline(e.target.checked)
    } else if (option === 'sessionsLimit') {
      setCheckedSessionsLimit(e.target.checked)
    } else {
      setCheckedShowIP(e.target.checked)
    }
  }

  return (
    <Fragment>
      <SecondaryMenuComponent id={props.match.params.id} langFn={lang} />

      <div className="main-content">
        {isSaveSetting.show && (
          <div
            className={`alert ${isSaveSetting.status ? 'alert-success' : 'alert-danger'
              }  alert-dismissible alert-solid alert-label-icon fade show xs-alert-center`}
            role="alert"
          >
            {isSaveSetting.status ? (
              <i className="ri-check-double-line label-icon"></i>
            ) : (
              <i className="ri-error-warning-line label-icon"></i>
            )}

            {isSaveSetting.msg}
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={() => setIsSaveSetting({ show: false, status: true, msg: '' })}
            ></button>
          </div>
        )}
        <div className="page-content">
          <Fragment>
            <div className="container-fluid">
              <div className="card">
                <div className="card-body py-2">
                  <div className="row">
                    <div className="col-6">
                      {' '}
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="card-title mb-0 py-2">{i18n[lang].settings} </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      {' '}
                      <button
                        disabled={isShowLoading}
                        onClick={() => (updateSetting(), setIsShowLoading(true))}
                        className="btn btn-primary btn-label float-end mx-2 btn-load"
                      >
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="label-icon align-middle fs-16 me-2">
                              {isShowLoading ? <Fragment> <span className="spinner-border flex-shrink-0" role="status">
                              </span>  </Fragment> : <CgSoftwareDownload />
                              }


                            </i>
                          </div>
                        </div>
                        <div className="flex-grow-1">{i18n[lang].saveSettings}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body pb-5">
                  <p
                    className={`fs-16 font-weight-bold ${packagePlan === 'Free Plan' || packageDetail.is_limit_exceed
                      ? 'disable-div'
                      : ''
                      }`}
                  >
                    {packagePlan === 'Free Plan' && <MdLock />} {i18n[lang].ipAddressStorage}
                  </p>
                  <div className={`form-check mb-2  `}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck1"
                      onChange={e => checkedIPSetting(e, 'accept')}
                      checked={checkedAccept}
                      disabled={packageDetail.is_limit_exceed || packagePlan === 'Free Plan'}
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                      <p className="fs-14 text-Secondary m-0">
                        {i18n[lang].storeIpAddressFullConsent}
                      </p>
                    </label>
                  </div>

                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck2"
                      onChange={e => checkedIPSetting(e, 'partial')}
                      checked={checkedPartial}
                      disabled={packageDetail.is_limit_exceed || packagePlan === 'Free Plan'}
                    />
                    <label className="form-check-label" htmlFor="formCheck2">
                      <p className="fs-14 text-Secondary m-0">
                        {i18n[lang].storeIpAddressPartialConsent}
                      </p>
                    </label>
                  </div>

                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck3"
                      onChange={e => checkedIPSetting(e, 'decline')}
                      checked={checkedDecline}
                      disabled={packageDetail.is_limit_exceed || packagePlan === 'Free Plan'}
                    />
                    <label className="form-check-label" htmlFor="formCheck3">
                      <p className="fs-14 text-Secondary m-0">
                        {i18n[lang].storeIpAddressDeclinesConsent}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck4"
                      onChange={e => checkedIPSetting(e, 'showIP')}
                      checked={checkedShowIP}
                      disabled={packageDetail.is_limit_exceed || packagePlan === 'Free Plan'}
                    />
                    <label className="form-check-label" htmlFor="formCheck4">
                      <p className="fs-14 text-Secondary">{i18n[lang].displayIpAddressInConsentRecord}</p>
                    </label>
                  </div>

                  <p
                    className={`fs-16 font-weight-bold ${packageDetail.is_limit_exceed || packagePlan === 'Free Plan'
                      ? 'disable-div'
                      : ''
                      }`}
                  >
                    {i18n[lang].dataRetentionPeriod}
                  </p>
                  <p
                    className={`fs-14 text-Secondary m-0 ${packageDetail.is_limit_exceed || packagePlan === 'Free Plan'
                      ? 'disable-div'
                      : ''
                      }`}
                  >
                    {i18n[lang].dataRetentionInfo}
                  </p>
                  <div className="row mt-3 mb-4">
                    <div className="col-auto">
                      <div className="btn-group">
                        <button
                          className="btn btn-light dropdown-toggle px-4"
                          type="button"
                          id="defaultDropdown"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="true"
                          disabled={packageDetail.is_limit_exceed || packagePlan === 'Free Plan'}
                        >
                          {monthlyTime}
                        </button>
                        <ul
                          className="dropdown-menu min-w-auto px-3"
                          aria-labelledby="defaultDropdown"
                        >
                          {storagePeriod &&
                            storagePeriod.map((item, i) => {
                              return (
                                <li key={i} value={item.id} name={item.storagePeriod}>
                                  <a className="dropdown-item" onClick={() => setMonthlyTime(item)}>
                                    {item}
                                  </a>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-auto">
                      <h6
                        className={`card-title mb-0 my-2 ${packageDetail.is_limit_exceed || packagePlan === 'Free Plan'
                          ? 'disable-div'
                          : ''
                          }`}
                      >
                        {i18n[lang].months}{' '}
                      </h6>
                    </div>
                  </div>
                  <p
                    className={`fs-16 font-weight-bold ${packagePlan === 'Free Plan' || packageDetail.is_limit_exceed
                      ? 'disable-div'
                      : ''
                      }`}
                  >
                    {packagePlan === 'Free Plan' ? <MdLock /> : ''} {i18n[lang].automaticCookieScan}
                  </p>
                  <p
                    className={`fs-14 text-Secondary m-0 ${packagePlan === 'Free Plan' || packageDetail.is_limit_exceed
                      ? 'disable-div'
                      : ''
                      }`}
                  >
                    {i18n[lang].automaticCookieScanInfo}
                  </p>
                  <div className={`col-auto mt-3`}>
                    <div className="btn-group">
                      <button
                        className="btn btn-light dropdown-toggle px-4"
                        type="button"
                        id="defaultDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="true"
                        disabled={
                          packagePlan === 'Free Plan' || packageDetail.is_limit_exceed
                            ? true
                            : false
                        }
                      >
                        {scheduleScanCookie === 0 ? i18n[lang].noSchedule : scheduleScanCookie + i18n[lang].days}
                      </button>
                      <ul
                        className="dropdown-menu min-w-auto px-3"
                        aria-labelledby="defaultDropdown"
                      >
                        <li>
                          <a className="dropdown-item" onClick={() => setScheduleScanCookie(0)}>
                            {i18n[lang].noSchedule}
                          </a>
                          <a className="dropdown-item" onClick={() => setScheduleScanCookie(7)}>
                            {i18n[lang].every7Days}
                          </a>
                          <a className="dropdown-item" onClick={() => setScheduleScanCookie(15)}>
                            {i18n[lang].every15Days}
                          </a>
                          <a className="dropdown-item" onClick={() => setScheduleScanCookie(30)}>
                            {i18n[lang].every30Days}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {packagePlan && packagePlan === 'Enterprise Plan' && (
                    <Fragment>
                      <div className="row mt-3 mb-5">
                        <div className="col-auto">
                          <p className="fs-16 font-weight-bold">{i18n[lang].usageQuota}</p>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck5"
                              checked={quotaSession}
                              onChange={() => [
                                setQuotaSession(!quotaSession),
                                quotaSession
                                  ? setOpenLimitSessions(false)
                                  : setOpenLimitSessions(true)
                              ]}
                            />
                            <label className="form-check-label" htmlFor="formCheck5">
                              <p className="fs-14 text-Secondary m-0">
                                {i18n[lang].enableUsageBeyondQuota}
                              </p>
                              <p className="fs-14 text-lightGray">
                                {i18n[lang].usageQuotaInfo}
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3 mb-5">
                        <div className="col-auto">
                          <p
                            className={`fs-16 font-weight-bold m-0 ${packageDetail.is_limit_exceed ? 'disable-div' : ''
                              }`}
                          >
                            {i18n[lang].callBackFunction}
                          </p>

                          <p
                            className={`fs-14 text-Secondary m-0 ${packageDetail.is_limit_exceed ? 'disable-div' : ''
                              }`}
                          >
                            {i18n[lang].enableCallBackFunction}
                          </p>
                          <p
                            className={`fs-14 text-lightGray ${packageDetail.is_limit_exceed ? 'disable-div' : ''
                              }`}
                          >
                            {i18n[lang].callBackFunctionInfo}
                          </p>

                          <input
                            type="text"
                            className="form-control fx-form-control"
                            value={functionName}
                            onChange={e => setFunctionName(e.target.value)}
                            placeholder={i18n[lang].specifyFunctionName}
                            disabled={packageDetail.is_limit_exceed}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>

        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openLimitSessions}
          contentLabel="modal"
          style={customStylesModalsm}
          onRequestClose={() => setOpenLimitSessions(false)}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content p-1">
              <div className="modal-body text-center">
                <img
                  src="/assets/images/limitSessions.png"
                  alt=""
                  style={{ width: '226px', height: '226px', marginBottom: '20px' }}
                />
                <div className="mt-1">
                  <label htmlFor="basiInput" className="form-label">
                    <strong className="fs-20 text-Danger">
                      {i18n[lang].confirmOverQuota} <br />
                      {i18n[lang].confirmOverQuotaSession}
                    </strong>
                  </label>
                  <br />
                  <span className="fs-14">
                    {i18n[lang].usageChargesInfo}<br />
                    <b>{i18n[lang].usageChargesInfoSessions}</b>
                  </span>
                </div>
              </div>
              <div className="modal-footer justify-content-center ">
                <button
                  onClick={() => setOpenLimitSessions(false)}
                  type="button"
                  className={`btn w-lg btn-danger waves-effect waves-light  `}
                >
                  {i18n[lang].iUnderstand}
                </button>
                <button
                  onClick={() => [setOpenLimitSessions(false), setQuotaSession(!quotaSession)]}
                  type="button"
                  className={`btn w-lg btn-outline-danger waves-effect waves-light  `}
                >
                  {i18n[lang].letMeThink}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-Gray text-8">
                Copyright © 2023 by Predictive Co., Ltd. All Right Reserved.
              </div>
              <div className="col-sm-6 text-Gray text-8">
                <div className="text-sm-end d-none d-sm-block">
                  <a
                    className="text-Gray"
                    href={REACT_APP_PREDICTIVE + `/privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>{' '}
                  <a className="text-Gray" href={REACT_APP_PREDICTIVE + `/terms`} target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default IPSettingComponent
