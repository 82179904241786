import React, { Fragment, useState, useEffect, useRef } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import LoginComponent from './components/login/login'
import ResendComponent from './components/signin/resend'
import SigninComponent from './components/signin/signin'
import PrimaryMenuComponent from './components/menu/primary-nav'
import SecondaryMenuComponent from './components/menu/secondary-nav'
import useScript from './useScript'
import HeaderComponent from './components/header/header'
import DomainComponent from './components/domain/domain'
import ForgotPasswordComponent from './components/signin/forgotPassword'
import ResetPasswordComponent from './components/signin/resetPassword'
import ManageCookieComponent from './components/manageCookie/manageCookie'
import ScanCookieComponent from './components/scanCookie/scanCookie'
import TermConditionComponent from './components/termcondition/termcondition'
import CategoriescookieComponent from './components/categoriesCookie/categoriesCookie'
import AcceptCookieComponent from './components/recordsAcceptCookie/recordsAcceptCookie'
import PublishCookieComponent from './components/publishCookie/publishCookie'
import UserSettingComponent from './components/userSetting/userSetting'
import DashboardComponent from './components/dashboard/dashboard'
import ActivityLogComponent from './components/activityLog/activityLog'
import UserManagementComponent from './components/userManagement/userManagement'
import ForbidenComponent from './components/errorPage/forbiden'
import NotfoundComponent from './components/errorPage/notfound'
import PackageComponent from './components/package/package'
import CheckPaymentComponent from './components/payment/checkPayment'
import PaymentComponent from './components/payment/payment'
import AddDomainComponent from './components/domain/addDomain'
import IPSettingComponent from './components/ipSetting/ipSetting'
import { getCookie, saveCookie } from './helper/helper'
import PackageDetailsComponent from './components/packageDetails/packageDetails'

const App = () => {
  const [active, setActive] = useState(false)
  const [lang, setLang] = useState(getCookie('lang'))

  useEffect(() => {
    if (getCookie('lang')) {
      setLang(getCookie('lang'))
      saveCookie('lang', getCookie('lang'), 365)
    } else {
      setLang(getCookie(document.documentElement.lang.slice(0, 2).toLowerCase()))
      saveCookie('lang', document.documentElement.lang.slice(0, 2).toLowerCase(), 365)
    }
  }, [])

  const pinMenu = () => {
    setActive(!active)
    if (active) {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover-active'
    } else {
      document.querySelector('html').dataset.sidebarSize = 'sm-hover'
    }
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <LoginComponent langFn={lang} />
        </Route>
        <Route path="/resend">
          <ResendComponent />
        </Route>
        <Route path="/register">
          <SigninComponent />
        </Route>
        <Route path="/forgotpassword">
          <ForgotPasswordComponent />
        </Route>
        <Route path="/resetpassword">
          <ResetPasswordComponent />
        </Route>
        <Route path="/term">
          <TermConditionComponent />
        </Route>
        <Route path="/forbiden">
          <ForbidenComponent />
        </Route>
        <Route path="/notfound">
          <NotfoundComponent />
        </Route>
        {/* <div id="layout-wrapper"> */}
        {/* <HeaderComponent /> */}
        <Route exact path="/domain">
          <HeaderComponent setLangFn={setLang} langFn={lang} />
          <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
          <DomainComponent langFn={lang} />
        </Route>
        <Route exact path="/adddomain">
          <HeaderComponent setLangFn={setLang} langFn={lang} />
          <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
          <AddDomainComponent langFn={lang} />
        </Route>
        <Route
          exact
          path="/package/"
          render={props => (
            <Fragment>
              <HeaderComponent setLangFn={setLang} langFn={lang} />
              <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
              <PackageComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/checkpayment/:id"
          render={props => (
            <Fragment>
              <HeaderComponent setLangFn={setLang} langFn={lang} />
              <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
              <CheckPaymentComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/payment/:domain/:package/:type/:page"
          render={props => (
            <Fragment>
              <HeaderComponent setLangFn={setLang} langFn={lang} />
              <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
              <PaymentComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/package/:type"
          render={props => (
            <Fragment>
              <HeaderComponent setLangFn={setLang} langFn={lang} />
              <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
              <PackageComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>

        <Route exact path="/usersetting">
          <HeaderComponent setLangFn={setLang} langFn={lang} />
          <PrimaryMenuComponent pinMenuFn={pinMenu} langFn={lang} />
          <UserSettingComponent langFn={lang} />
        </Route>

        <Route
          exact
          path="/managecookie/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <ManageCookieComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/scancookie/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <ScanCookieComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/categoriescookie/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <CategoriescookieComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/acceptcookie/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <AcceptCookieComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/publishcookie/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <PublishCookieComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/dashboard/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <DashboardComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/usermanagement/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <UserManagementComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/ipsetting/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <IPSettingComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/log/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <ActivityLogComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>
        <Route
          exact
          path="/packagedetails/:id"
          render={props => (
            <Fragment>
              <HeaderComponent {...props} setLangFn={setLang} langFn={lang} />
              <PackageDetailsComponent {...props} langFn={lang} />
            </Fragment>
          )}
        ></Route>

        <Route exact={true} path="/">
          <LoginComponent langFn={lang} />
        </Route>
        <Route path="*" exact={true} component={NotfoundComponent} />
        {/* </div> */}
      </Switch>
    </BrowserRouter>
  )
}

export default App
