const redirectLogin = () => {
  localStorage.removeItem('token')
  window.location.href = '/login'
}

const redirectPermission = () => {
  window.location.href = '/forbiden'
}
const refreshToken = token => {
  localStorage.setItem('token', token)
}

const numberWithCommas = x => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
}
// ฟังก์ชันสำหรับอ่านค่าคุกกี้
const getCookie = name => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return document.documentElement.lang.slice(0, 2)
}

// ฟังก์ชันสำหรับบันทึกคุกกี้
const saveCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
export { redirectLogin, redirectPermission, refreshToken, numberWithCommas, saveCookie, getCookie }
