import * as axios from 'axios'
import { redirectLogin } from '../../helper/helper'

const { REACT_APP_API_AUTHENTICATION } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const connectLogin = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/login`
    const response = await axios.post(URL, params)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const connectSignUp = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/sign-up`
    const response = await axios.post(URL, params)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const forgotPassword = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/reset-password`
    const response = await axios.post(URL, params)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const changePassword = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/change-password`
    const response = await axios.patch(URL, params)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const resendConfirmEmail = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/get-confirm-email`
    const response = await axios.post(URL, obj)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const confirmEmail = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/confirm-email`
    const response = await axios.patch(URL, params)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const verifyToken = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/verify`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    } else {
      redirectLogin()
    }
  } catch (error) {
    redirectLogin()
  }
  return res
}

const acceptTerm = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/accept-term`
    const response = await axios.patch(URL, {}, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const changePasswordWithToken = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/change-password-user-profile`
    const response = await axios.patch(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const updateUserProfile = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/update-user-profile`
    const response = await axios.patch(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const userProfile = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/get-user-profile`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    } else {
      redirectLogin()
    }
  } catch (error) {
    redirectLogin()
  }
  return res
}

const checkEmailList = async (consent_id, objEmail) => {
  let res = {}
  const configHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '/',
      'Cache-Control': 'no-cache',
      consent_id: consent_id,
      Authorization: `Bearer ${token}`
    },
    credentials: 'same-origin'
  }
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/account/check-email`
    const response = await axios.post(URL, { emails: objEmail }, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

const updatePrivacyProfile = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/update-privacy-profile`
    const response = await axios.patch(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.response.data.error
    }
  }
  return res
}

export {
  connectLogin,
  connectSignUp,
  forgotPassword,
  changePassword,
  resendConfirmEmail,
  confirmEmail,
  verifyToken,
  acceptTerm,
  changePasswordWithToken,
  updateUserProfile,
  userProfile,
  checkEmailList,
  updatePrivacyProfile
}
